import { MutationUpdaterFn } from '@apollo/client';
import {
	CreateCategoryMutation,
	GetCategoriesQuery
} from '../../../generated/api';
import { GET_CATEGORIES } from '../query';
import { useUserPreferenceStore } from '../../../stores/useUserPreferenceStore';

export const createCategoryUpdate: MutationUpdaterFn<CreateCategoryMutation> = (
	cache,
	mutationResult
) => {
	const createdCategory = mutationResult.data?.createCategory;

	if (createdCategory?.__typename !== 'Category') {
		return;
	}

	const language = useUserPreferenceStore.getState().language;

	const data = cache.readQuery<GetCategoriesQuery>({
		query: GET_CATEGORIES,
		variables: {
			language
		}
	});

	if (data) {
		cache.writeQuery<GetCategoriesQuery>({
			query: GET_CATEGORIES,
			variables: {
				language
			},
			data: {
				categories: [...data.categories, createdCategory]
			}
		});
	}
};
