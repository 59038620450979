import create from 'zustand';
import { persistIndexedDB } from './IndexedDB';
import { CreateClassInput, Day, Language } from '../generated/api';
import produce from 'immer';
import { ClassTermAutoGenerateMethod, ClassTermAutoGeneration } from './useClassUpdateStore';

export type ClassCreateStoreStateValue = {
	step: 0 | 1 | 2;
	courseId: string;
	translations: Record<Language, Omit<CreateClassInput['translations'][0], 'language'>>;
	studentFeePerLesson: number;
	lecturerCommission: number;
	spaceId?: string;
	students: string[];
	lecturers: string[];
	color: string;
	termAutoGenerationAvailability: boolean;
	termAutoGenerateMethod: ClassTermAutoGenerateMethod;
	termAutoGeneration: ClassTermAutoGeneration;
	createTerm: {
		startFrom: Date;
		numberOfLessons: number;
	};
};

type ClassCreateStoreState = {
	setStep: (step: ClassCreateStoreState['step']) => void;
	setCourseId: (courseId: string) => void;
	setName: (name: string, language: Language) => void;
	setDescription: (description: string, language: Language) => void;
	setStudentFeePerLesson: (studentFeePerLesson: number) => void;
	setLecturerCommission: (lecturerCommission: number) => void;
	setSpaceId: (spaceId: string) => void;
	setStudents: (students: string[]) => void;
	setLecturers: (lecturers: string[]) => void;
	setColor: (color: string) => void;
	setTermAutoGenerationAvailability: (termAutoGenerationAvailability: boolean) => void;
	setTermAutoGenerateMethod: (termAutoGenerateMethod: ClassTermAutoGenerateMethod) => void;
	setTermAutoGeneration: (termAutoGeneration: ClassTermAutoGeneration) => void;
	setCreateTerm: (createTerm: ClassCreateStoreStateValue['createTerm']) => void;
	setAll: (data: ClassCreateStoreStateValue) => void;
	reset: () => void;
} & ClassCreateStoreStateValue;

const initialState: ClassCreateStoreStateValue = {
	step: 0,
	courseId: '',
	translations: {
		[Language.Zh]: {
			name: '',
			description: ''
		},
		[Language.En]: {
			name: '',
			description: ''
		}
	},
	studentFeePerLesson: 0,
	lecturerCommission: 0,
	spaceId: undefined,
	students: [],
	lecturers: [],
	color: '#188fff',
	termAutoGenerationAvailability: true,
	termAutoGenerateMethod: ClassTermAutoGenerateMethod.BY_MONTH,
	termAutoGeneration: {
		[ClassTermAutoGenerateMethod.BY_MONTH]: {
			day: Day.Saturday,
			startTime: new Date(),
			endTime: new Date(new Date().getTime() + 3_600_000)
		}
	},
	createTerm: {
		startFrom: new Date(),
		numberOfLessons: 0
	}
};

export const useClassCreateStore = create<ClassCreateStoreState>()(
	persistIndexedDB(
		(set, get) => ({
			...initialState,
			setCourseId: courseId => set({ courseId }),
			setStep: step => set({ step }),
			setName: (name, language) =>
				set(
					produce(draft => {
						draft.translations[language].name = name;
					})
				),
			setDescription: (description, language) =>
				set(
					produce(draft => {
						draft.translations[language].description = description;
					})
				),
			setStudentFeePerLesson: studentFeePerLesson => set({ studentFeePerLesson }),
			setLecturerCommission: lecturerCommission => set({ lecturerCommission }),
			setSpaceId: spaceId => set({ spaceId }),
			setStudents: students => set({ students }),
			setLecturers: lecturers => set({ lecturers }),
			setColor: color => set({ color }),
			setTermAutoGenerationAvailability: termAutoGenerationAvailability =>
				set({ termAutoGenerationAvailability }),
			setTermAutoGenerateMethod: termAutoGenerateMethod => set({ termAutoGenerateMethod }),
			setTermAutoGeneration: termAutoGeneration => set({ termAutoGeneration }),
			setCreateTerm: createTerm => set({ createTerm }),
			setAll: data => set({ ...data }),
			reset: () => set({ ...initialState })
		}),
		{
			name: 'class-create-form',
			version: 0.4
		}
	)
);
