import { Link, LinkProps } from 'react-router-dom';
import React from 'react';
import { preloadRouteComponent } from '../../utils/preloadRouteComponent';

interface LinkWithPreloadProps extends LinkProps {
	onPreload?: () => void;
}

const LinkWithPreload: React.FC<LinkWithPreloadProps> = React.forwardRef(
	({ to, onPreload, ...rest }, _) => {
		const handleMouseEnter = () => {
			preloadRouteComponent(to).catch(() => {});
		};

		return <Link to={to} onMouseEnter={handleMouseEnter} {...rest} />;
	}
);

export default LinkWithPreload;
