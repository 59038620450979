import React from 'react';
import {
	AppBar,
	Box,
	Button,
	DialogTitle,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useUserCreateStore } from '../../../stores/useUserCreateStore';
import { useNavigate } from 'react-router-dom';

interface UserCreateDialogHeaderProps {}

const UserCreateDialogHeader: React.FC<UserCreateDialogHeaderProps> = () => {
	const resetUser = useUserCreateStore(state => state.resetUser);
	const theme = useTheme();
	const isSM = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();

	const handleClose = () => {
		navigate(-1);
	};

	return (
		<>
			{!isSM ? (
				<AppBar position="static" color="inherit" elevation={0}>
					<DialogTitle color="textPrimary">创建用户</DialogTitle>
				</AppBar>
			) : (
				<>
					<AppBar position="fixed">
						<Toolbar>
							<IconButton onClick={handleClose} size="large">
								<Close sx={{ color: '#fff' }} />
							</IconButton>
							<Typography variant="h6" sx={{ ml: '10px' }}>
								创建用户
							</Typography>
							<Box sx={{ ml: 'auto', mr: 0 }}>
								<Button sx={{ color: '#fff' }} onClick={resetUser}>
									重置
								</Button>
							</Box>
						</Toolbar>
					</AppBar>
					<Toolbar />
				</>
			)}
		</>
	);
};

export default UserCreateDialogHeader;
