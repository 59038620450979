import create from 'zustand';
import { AdminStatus, LecturerStatus, StudentStatus, UserStatus } from 'generated/api';
import { UserRole } from '../types';

type OptionValue = '0' | UserStatus | StudentStatus | LecturerStatus | AdminStatus;

type Option = {
	label: string;
	value: OptionValue;
};

type UserTableControllerStoreState = {
	options: Option[];
	selectedValue: OptionValue;
	role: UserRole | null;
	setOptions: (options: Option[]) => void;
	setSelectedValue: (selectedValue: OptionValue) => void;
	setRole: (role: UserRole | null) => void;
};

export const useUserTableControllerStore = create<UserTableControllerStoreState>(set => ({
	options: [],
	selectedValue: '0',
	role: null,
	setOptions: options => set({ options }),
	setSelectedValue: selectedValue => set({ selectedValue }),
	setRole: role => set({ role })
}));
