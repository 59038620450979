import React, { PropsWithChildren } from 'react';
// import { SnackbarProvider as NSnackbarProvider } from 'notistack';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { useTheme } from '@mui/material';

interface SnackbarProviderProps {}

const SnackbarProvider: React.FC<PropsWithChildren<SnackbarProviderProps>> = ({ children }) => {
	// const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const theme = useTheme();

	return (
		<>
			{children}
			<ToastContainer
				position="top-center"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme={theme.palette.mode}
			/>
		</>
	);

	// return (
	// 	<NSnackbarProvider
	// 		anchorOrigin={
	// 			isXS
	// 				? {
	// 						vertical: 'bottom',
	// 						horizontal: 'right'
	// 				  }
	// 				: {
	// 						vertical: 'top',
	// 						horizontal: 'center'
	// 				  }
	// 		}
	// 		TransitionComponent={props => (
	// 			/*
	// 			//@ts-ignore*/
	// 			<Slide
	// 				{...props}
	// 				direction={isXS ? 'up' : 'down'}
	// 				children={props.children as React.ReactElement<any, any>}
	// 			/>
	// 		)}
	// 		autoHideDuration={3000}
	// 		style={isXS ? { marginBottom: 56 } : {}}
	// 	>
	// 		{children}
	// 	</NSnackbarProvider>
	// );
};

export default SnackbarProvider;
