import React, { useCallback, useDeferredValue, useMemo, useState } from 'react';
import { Checkbox, List, ListItem, ListItemButton, ListItemText, TextField } from '@mui/material';
import { useGetStudentsQuery } from '../../generated/api';

interface ClassStudentListSelectorProps {
	selectedStudents: string[];
	setSelectedStudents: (selectedStudents: string[]) => void;
}

const ClassStudentListSelector: React.FC<ClassStudentListSelectorProps> = ({
	selectedStudents,
	setSelectedStudents
}) => {
	const { data } = useGetStudentsQuery();
	const students = useMemo(() => data?.students || [], [data]);
	const [keywords, setKeywords] = useState('');
	const searchQuery = useDeferredValue(keywords);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setKeywords(event.target.value);
	};

	const handleClick = (id: string) => () => {
		if (!selectedStudents.includes(id)) {
			setSelectedStudents([...selectedStudents, id]);
		} else {
			setSelectedStudents(selectedStudents.filter(userId => userId !== id));
		}
	};

	const search = useCallback(
		<T extends Record<any, any>>(arr: T[]): T[] => {
			if (searchQuery === '') return arr;

			return arr.filter(
				s =>
					Object.values(s).findIndex(ss =>
						typeof ss === 'object'
							? search([ss]).length > 0
							: ss.toString().toLocaleLowerCase().indexOf(searchQuery.toLowerCase()) > -1
					) > -1
			);
		},
		[searchQuery]
	);

	return (
		<>
			<TextField variant="filled" value={keywords} onChange={handleChange} fullWidth label="搜索" />
			<List sx={{ pt: 0 }}>
				{search(students).map(student => (
					<ListItem
						key={student.user.id}
						secondaryAction={
							<Checkbox
								checked={selectedStudents.includes(student.user.id)}
								onClick={handleClick(student.user.id)}
							/>
						}
						disablePadding
						disableGutters
					>
						<ListItemButton onClick={handleClick(student.user.id)}>
							<ListItemText
								primary={`${student.matricId} ${student.user.cname} ${student.user.ename}`}
							/>
						</ListItemButton>
					</ListItem>
				))}
			</List>
		</>
	);
};

export default ClassStudentListSelector;
