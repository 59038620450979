import React, { useMemo, useState } from 'react';
import { InvoiceStatus, useGetInvoicesQuery } from '../../../generated/api';
import TableList from '../../common/TableList';
import { TableFilterHolder, useTableFilterStore } from '../../../stores/useTableFilterStore';
import { HeadCell } from '../../../types';
import {
	alpha,
	Avatar,
	Box,
	Breadcrumbs,
	Button,
	Grid,
	Link,
	MenuItem,
	Tab,
	Tabs,
	TextField,
	Typography
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useRefetchHandler } from '../../../hooks/useRefetchHandler';
import InvoicesOverview from './InvoicesOverview';
import {
	AccessTimeFilled,
	CheckCircle,
	InsertDriveFile,
	NavigateNext,
	Notifications,
	Receipt
} from '@mui/icons-material';
import shallow from 'zustand/shallow';
import { DatePicker } from '@mui/x-date-pickers';
import { Relay } from '../../../utils/relay';
import InvoiceStatusChip from './InvoiceStatusChip';

const HEAD_CELLS: HeadCell[] = [
	// {
	// 	id: 'name',
	// 	disablePadding: false,
	// 	label: '名字'
	// },
	{
		id: 'user',
		disablePadding: false,
		label: '用户'
	},
	{
		id: 'amount',
		disablePadding: false,
		label: '总额 (RM)'
	},
	{
		id: 'createdAt',
		disablePadding: false,
		label: '创建于'
	},
	{
		id: 'status',
		disablePadding: false,
		label: '状况'
	}
];

const INVOICE_STATUSES = [
	{
		title: '全部',
		color: 'rgb(24, 144, 255)',
		amount: 0,
		total: 0,
		count: 0,
		icon: Receipt,
		status: '0'
	},
	{
		title: '完成',
		color: 'rgb(84, 214, 44)',
		amount: 0,
		total: 0,
		count: 0,
		icon: CheckCircle,
		status: InvoiceStatus.Paid
	},
	{
		title: '未缴',
		color: 'rgb(255, 193, 7)',
		amount: 0,
		total: 0,
		count: 0,
		icon: AccessTimeFilled,
		status: InvoiceStatus.Open
	},
	{
		title: '失效',
		color: 'rgb(255, 72, 66)',
		amount: 0,
		total: 0,
		count: 0,
		icon: Notifications,
		status: InvoiceStatus.Void
	},
	{
		title: '草稿',
		color: 'rgb(99, 115, 129)',
		amount: 0,
		total: 0,
		count: 0,
		icon: InsertDriveFile,
		status: InvoiceStatus.Draft
	}
];

interface InvoicesProps {}

const Invoices: React.FC<InvoicesProps> = () => {
	const [status, setStatus] = useState<InvoiceStatus | '0'>('0');
	const { data, refetch, startPolling, stopPolling } = useGetInvoicesQuery({
		skip:
			status !== '0' &&
			status !== InvoiceStatus.Draft &&
			status !== InvoiceStatus.Open &&
			status !== InvoiceStatus.Paid &&
			status !== InvoiceStatus.Void
	});
	const invoices = useMemo(() => data?.invoices || [], [data]);
	const navigate = useNavigate();
	const [keywords, setKeywords, startFrom, setStartFrom, endAt, setEndAt] = useTableFilterStore(
		state => [
			state.filters[TableFilterHolder.INVOICE].keywords,
			state.setKeywords(TableFilterHolder.INVOICE),
			state.filters[TableFilterHolder.INVOICE].startFrom,
			state.setStartFrom(TableFilterHolder.INVOICE),
			state.filters[TableFilterHolder.INVOICE].endAt,
			state.setEndAt(TableFilterHolder.INVOICE)
		],
		shallow
	);
	const openInvoices = useMemo(
		() => invoices.filter(invoice => invoice.status === InvoiceStatus.Open),
		[invoices]
	);
	const paidInvoices = useMemo(
		() => invoices.filter(invoice => invoice.status === InvoiceStatus.Paid),
		[invoices]
	);
	const voidInvoices = useMemo(
		() => invoices.filter(invoice => invoice.status === InvoiceStatus.Void),
		[invoices]
	);
	const draftInvoices = useMemo(
		() => invoices.filter(invoice => invoice.status === InvoiceStatus.Draft),
		[invoices]
	);
	const statuses = useMemo(
		() => ({
			'0': {
				title: '全部',
				color: 'rgb(24, 144, 255)',
				amount: invoices.reduce((prev, curr) => prev + curr.amount, 0),
				total: invoices.length,
				count: invoices.length,
				icon: Receipt,
				status: '0' as const
			},
			[InvoiceStatus.Paid]: {
				title: '完成',
				color: 'rgb(84, 214, 44)',
				amount: paidInvoices.reduce((prev, curr) => prev + curr.amount, 0),
				total: invoices.length,
				count: paidInvoices.length,
				icon: CheckCircle,
				status: InvoiceStatus.Paid as const
			},
			[InvoiceStatus.Open]: {
				title: '未缴',
				color: 'rgb(255, 193, 7)',
				amount: openInvoices.reduce((prev, curr) => prev + curr.amount, 0),
				total: invoices.length,
				count: openInvoices.length,
				icon: AccessTimeFilled,
				status: InvoiceStatus.Open as const
			},
			[InvoiceStatus.Void]: {
				title: '失效',
				color: 'rgb(255, 72, 66)',
				amount: voidInvoices.reduce((prev, curr) => prev + curr.amount, 0),
				total: invoices.length,
				count: voidInvoices.length,
				icon: Notifications,
				status: InvoiceStatus.Void as const
			},
			[InvoiceStatus.Draft]: {
				title: '草稿',
				color: 'rgb(99, 115, 129)',
				amount: draftInvoices.reduce((prev, curr) => prev + curr.amount, 0),
				total: invoices.length,
				count: draftInvoices.length,
				icon: InsertDriveFile,
				status: InvoiceStatus.Draft as const
			}
		}),
		[invoices, paidInvoices, openInvoices, voidInvoices, draftInvoices]
	);

	useRefetchHandler(refetch, startPolling, stopPolling);

	const ITEMS =
		data?.invoices
			.map(invoice => ({ ...invoice, id: Relay.unmarshalId(invoice.id) }))
			.map(invoice => ({
				id: invoice.id,
				amount: invoice.amount,
				user: {
					label: (
						<Box sx={{ display: 'flex' }}>
							<Avatar sx={{ bgcolor: 'primary.main' }}>{invoice.user.ename[0]}</Avatar>
							<Box sx={{ ml: 2 }}>
								<Typography variant="body1" sx={{ fontWeight: 'bold' }}>
									{invoice.user.cname} {invoice.user.ename}
								</Typography>
								<Typography variant="subtitle2" sx={{ fontWeight: 'normal' }}>
									{invoice.id}
								</Typography>
							</Box>
						</Box>
					),
					value: `${invoice.id},${invoice.user.cname} ${invoice.user.ename}`
				},
				createdAt: new Date(invoice.createdAt).toLocaleDateString('en-MY'),
				status: {
					label: <InvoiceStatusChip status={invoice.status} chinese />,
					value: invoice.status
				}
			})) || [];

	const items = useMemo(
		() => (status === '0' ? ITEMS : ITEMS.filter(item => item.status.value === status)),
		[status, ITEMS]
	);

	return (
		<Box sx={{ mt: 2 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
					<Link
						to="/"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						主页
					</Link>
					<Typography variant="body2" sx={{ fontSize: '1.1rem' }}>
						发票
					</Typography>
				</Breadcrumbs>
				<Button
					variant="contained"
					size="large"
					sx={{
						borderRadius: '8px',
						color: theme => (theme.palette.mode === 'light' ? '#fff' : '#000')
					}}
					component={RouterLink}
					to="create"
				>
					创建发票
				</Button>
			</Box>
			<Box sx={{ mt: 4, mb: 6 }}>
				<InvoicesOverview statuses={statuses} />
			</Box>
			<TableList
				holder={TableFilterHolder.INVOICE}
				items={items}
				headCells={HEAD_CELLS}
				disableFirstAndLastPageButton
				paperProps={{
					variant: 'elevation',
					elevation: 0,
					sx: {
						borderRadius: '16px',
						boxShadow: theme =>
							theme.palette.mode !== 'dark'
								? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
								: 'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px'
					}
				}}
				tableStyle={{ minWidth: '900px', px: 1 }}
				clickFunc={({ id }) => {
					if (!id) {
						return;
					}
					navigate(id);
				}}
				tabBar={
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							variant="scrollable"
							scrollButtons="auto"
							allowScrollButtonsMobile
							aria-label="basic tabs example"
							value={status}
							onChange={(event, value) => setStatus(value)}
							sx={{
								backgroundColor: theme =>
									theme.palette.mode === 'light'
										? theme.palette.background.default
										: 'rgba(145, 158, 171, 0.16)'
							}}
						>
							{(Object.keys(statuses) as Array<keyof typeof statuses>).map(key => (
								<Tab
									key={statuses[key].status}
									label={statuses[key].title}
									value={statuses[key].status}
									icon={<TabBadge color={statuses[key].color}>{statuses[key].count}</TabBadge>}
									iconPosition="start"
									sx={{ minHeight: '48px' }}
								/>
							))}
						</Tabs>
					</Box>
				}
				filter={
					<Grid container spacing={2} sx={{ p: 3 }}>
						<Grid item xs={12} sm={3} md={2}>
							<TextField
								label="服务种类"
								select
								fullWidth
								defaultValue={0}
								InputProps={{ sx: { borderRadius: '8px' } }}
							>
								<MenuItem value={0}>全部</MenuItem>
								<MenuItem value={1}>课程</MenuItem>
							</TextField>
						</Grid>
						<Grid item xs={12} sm={3} md={2}>
							<DatePicker
								renderInput={props => <TextField {...props} fullWidth />}
								label="开始日期"
								value={startFrom}
								onChange={newValue => {
									setStartFrom(newValue);
								}}
								InputProps={{ sx: { borderRadius: '8px' } }}
							/>
						</Grid>
						<Grid item xs={12} sm={3} md={2}>
							<DatePicker
								renderInput={props => <TextField {...props} fullWidth />}
								label="结束日期"
								value={endAt}
								onChange={newValue => {
									setEndAt(newValue);
								}}
								InputProps={{ sx: { borderRadius: '8px' } }}
							/>
						</Grid>
						<Grid item xs={12} sm={3} md={6}>
							<TextField
								label="搜索"
								fullWidth
								value={keywords}
								onChange={event => setKeywords(event.target.value)}
								InputProps={{ sx: { borderRadius: '8px' } }}
							/>
						</Grid>
					</Grid>
				}
				tableHeadProps={{
					sx: {
						'>tr>th:first-of-type': {
							borderRadius: '8px 0 0 8px'
						},
						'>tr>th:last-of-type': {
							borderRadius: '0 8px 8px 0'
						},
						'.MuiTableCell-root': {
							borderBottom: 'none'
						}
					}
				}}
			/>
		</Box>
	);
};

const TabBadge: React.FC<React.PropsWithChildren<{ color: string }>> = ({ color, children }) => {
	return (
		<Box
			component="span"
			sx={{
				height: '22px',
				minWidth: '22px',
				lineHeight: '0',
				borderRadius: '6px',
				cursor: 'default',
				alignItems: 'center',
				whiteSpace: 'nowrap',
				display: 'inline-flex',
				justifyContent: 'center',
				padding: '0px 8px',
				color: color,
				fontSize: '0.75rem',
				fontWeight: '700',
				mr: 1
			}}
			style={{ backgroundColor: alpha(color, 0.16) }}
		>
			{children}
		</Box>
	);
};

export default Invoices;
