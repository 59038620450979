import { MutationUpdaterFn } from '@apollo/client';
import { CreateCourseMutation } from '../../../generated/api';
import { COURSE_FIELDS } from '../query';

export const createCourseUpdate: MutationUpdaterFn<CreateCourseMutation> = (
	cache,
	mutationResult
) => {
	const createdCourse = mutationResult.data?.createCourse;

	if (!createdCourse) {
		return;
	}

	cache.modify({
		id: cache.identify({
			id: createdCourse.category.id,
			__typename: 'Category'
		}),
		fields: {
			courses(existing = []) {
				const { category, ...rest } = createdCourse;
				const newCourseRef = cache.writeFragment({
					data: rest,
					fragment: COURSE_FIELDS
				});
				return [...existing, newCourseRef];
			}
		}
	});
};
