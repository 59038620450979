import create from 'zustand';
import { WatchQueryFetchPolicy } from '@apollo/client';
import { persist } from 'zustand/middleware';
import { Language } from '../generated/api';

export enum ThemeColor {
	AUTO,
	LIGHT,
	DARK
}

export const ThemeColorText: Record<ThemeColor, string> = {
	[ThemeColor.AUTO]: '自动',
	[ThemeColor.LIGHT]: '浅色',
	[ThemeColor.DARK]: '深色'
};

type UserPreferenceStoreState = {
	themeColor: ThemeColor;
	fetchPolicy: WatchQueryFetchPolicy;
	pollingInterval: number | null;
	language: Language;
	setThemeColor: (themeColor: ThemeColor) => void;
	setFetchPolicy: (fetchPolicy: WatchQueryFetchPolicy) => void;
	setPollingInterval: (pollingInterval: number | null) => void;
	setLanguage: (language: Language) => void;
};

export const useUserPreferenceStore = create<UserPreferenceStoreState>()(
	persist(
		set => ({
			themeColor: ThemeColor.AUTO,
			fetchPolicy: 'cache-and-network',
			pollingInterval: null,
			language: Language.Zh,
			setThemeColor: themeColor => set({ themeColor }),
			setFetchPolicy: fetchPolicy => set({ fetchPolicy }),
			setPollingInterval: pollingInterval => set({ pollingInterval }),
			setLanguage: language => set({ language })
		}),
		{
			name: 'user-preferences'
		}
	)
);
