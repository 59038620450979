import React, { useMemo } from 'react';
import { useGetCategoryCoursesQuery } from '../../../generated/api';
import { useUserPreferenceStore } from '../../../stores/useUserPreferenceStore';
import { Box, Button, Paper, Typography } from '@mui/material';
import TableList from '../../common/TableList';
import { TableFilterHolder } from '../../../stores/useTableFilterStore';
import { HeadCell } from '../../../types';
import CourseUpdateDialog from '../Course/CourseUpdateDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import CourseCreateDialog from '../Course/CourseCreateDialog';
import { useRefetchHandler } from '../../../hooks/useRefetchHandler';

const HEAD_CELLS: HeadCell[] = [
	{
		id: 'name',
		label: '名字',
		disablePadding: false
	},
	{
		id: 'lecturers',
		label: '老师',
		disablePadding: false
	}
];

interface CategoryPageCoursesProps {
	id: string;
}

const CategoryPageCourses: React.FC<CategoryPageCoursesProps> = ({ id }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const language = useUserPreferenceStore(state => state.language);
	const { data, refetch, startPolling, stopPolling } = useGetCategoryCoursesQuery({
		variables: { id, language }
	});
	const courses = useMemo(
		() =>
			data?.node?.__typename === 'Category'
				? data.node.courses.map(course => ({
						id: course.id,
						name: course.translation.name,
						lecturers: course.lecturers.map(lecturer => lecturer.user.cname).join(', ')
				  }))
				: [],
		[data]
	);

	useRefetchHandler(refetch, startPolling, stopPolling);

	return (
		<Paper sx={theme => ({ mt: 4, p: 4, [theme.breakpoints.down('sm')]: { p: 2 } })}>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'flex-start',
					mb: 2
				}}
			>
				<Typography variant="h6">课程</Typography>
				<Button
					variant="text"
					onClick={() => navigate('/courses/create', { state: { backgroundLocation: location } })}
				>
					创建课程
				</Button>
			</Box>
			<TableList
				holder={TableFilterHolder.COURSE}
				items={courses}
				headCells={HEAD_CELLS}
				tableStyle={{ minWidth: 300 }}
				clickFunc={({ id }) =>
					navigate(`/courses/${id}`, { state: { backgroundLocation: location } })
				}
			/>
			<CourseCreateDialog categoryId={id} />
			<CourseUpdateDialog />
		</Paper>
	);
};

export default CategoryPageCourses;
