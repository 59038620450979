import React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useUserUpdateStore } from '../../../../stores/useUserUpdateStore';
import { UserDetailsItemRecord } from '../../../../constants';
import UserDetailsUpdateDialogContent from './UserDetailsUpdateDialogContent';
import { User, useUpdateUserMutation } from '../../../../generated/api';
import { Loader } from '../../../common/SharedComponent';
import shallow from 'zustand/shallow';
import { updateUserUpdate } from '../../update/updateUserUpdate';
import { useAtomValue } from 'jotai/utils';
import { isMasterAtom } from '../../../../atoms';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';

interface UserDetailsUpdateDialogProps {
	user: User;
}

const UserDetailsUpdateDialog: React.FC<UserDetailsUpdateDialogProps> = ({ user }) => {
	const [itemKey, reset] = useUserUpdateStore(state => [state.itemKey, state.reset], shallow);
	const open = itemKey !== null;
	const [updateUserMutation] = useUpdateUserMutation();
	const isMaster = useAtomValue(isMasterAtom);

	if (itemKey === null || !user) {
		return <></>;
	}

	const handleClose = () => {
		reset();
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		const key = itemKey;
		const input = useUserUpdateStore.getState().variables;
		handleClose();

		try {
			await updateUserMutation({
				variables: {
					id: user.id,
					input,
					isMaster
				},
				update: updateUserUpdate(input, user),
				optimisticResponse: {
					__typename: 'Mutation',
					updateUser: {
						...user,
						...input,
						student: user.student
							? {
									...user.student,
									...input.student
							  }
							: null,
						lecturer: user.lecturer
							? {
									...user.lecturer,
									...input.lecturer
							  }
							: null,
						admin: user.admin
							? {
									...user.admin,
									...input.admin
							  }
							: null
					} as User
				}
			});
		} catch (err) {
			useUserUpdateStore.getState().setItemKey(key);
			if (err instanceof ApolloError) {
				if (err.message.includes('student_matrix_id_uindex')) {
					toast.error(`更改学号失败, 此学号已被使用`);
					return;
				}
				toast.error(`更改用户失败, ${err.message}`);
			}
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<Box
				component="form"
				onSubmit={handleSubmit}
				sx={{ width: '400px', maxWidth: '100%', position: 'relative' }}
			>
				<Loader loading={false} />
				<DialogTitle id="update-user-dialog-title">
					修改{UserDetailsItemRecord[itemKey]}
				</DialogTitle>
				<DialogContent>
					<UserDetailsUpdateDialogContent user={user} itemKey={itemKey} />
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						取消
					</Button>
					<Button type="submit" color="primary">
						更改
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default UserDetailsUpdateDialog;
