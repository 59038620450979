import React, { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import TermForm from '../../../share-components/Term/TermForm';
import TermStudents from './TermStudents';

interface TermContent {
	termId: string;
	name: string;
	durationPerLesson: number;
	studentFeePerLesson: number;
	lecturerCommission: number;
	lessonCount: number;
	setName: (name: string) => void;
	setDurationPerLesson: (durationPerLesson: number) => void;
	setStudentFeePerLesson: (studentFeePerLesson: number) => void;
	setLecturerCommission: (studentFeePerLesson: number) => void;
	setLessonCount: (lessonCount: number) => void;
}

const TermContent: React.FC<TermContent> = ({
	termId,
	name,
	durationPerLesson,
	studentFeePerLesson,
	lecturerCommission,
	lessonCount,
	setName,
	setDurationPerLesson,
	setStudentFeePerLesson,
	setLecturerCommission,
	setLessonCount
}) => {
	const [tabIndex, setTabIndex] = useState('1');

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabIndex(newValue);
	};

	return (
		<TabContext value={tabIndex}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<TabList onChange={handleChange} aria-label="学期资料类别" centered>
					<Tab label="基本资料" value="1" />
					<Tab label="学期学生" value="2" />
				</TabList>
			</Box>
			<TabPanel value="1" sx={{ p: 0 }}>
				<TermForm
					name={name}
					durationPerLesson={durationPerLesson}
					studentFeePerLesson={studentFeePerLesson}
					lecturerCommission={lecturerCommission}
					lessonCount={lessonCount}
					setName={setName}
					setDurationPerLesson={setDurationPerLesson}
					setStudentFeePerLesson={setStudentFeePerLesson}
					setLecturerCommission={setLecturerCommission}
					setLessonCount={setLessonCount}
				/>
			</TabPanel>
			<TabPanel value="2" sx={{ p: 0 }}>
				<TermStudents termId={termId} />
			</TabPanel>
		</TabContext>
	);
};

export default TermContent;
