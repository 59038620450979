import React, { Dispatch, SetStateAction, useMemo } from 'react';
import {
	GetReceivablesQueryResult,
	ReceivableStatus,
	useGetReceivablesQuery
} from '../../../generated/api';
import CustomDialog from '../../../share-components/CustomDialog';
import { Button, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

const ChooseReceivableDialog: React.FC<{
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	filterIds?: string[];
	onNewItemClick: () => void;
	onSelected: (
		receivable: Exclude<GetReceivablesQueryResult['data'], undefined>['receivables'][0]
	) => void;
}> = ({ open, setOpen, filterIds = [], onSelected, onNewItemClick }) => {
	const { data } = useGetReceivablesQuery({
		variables: { filter: { status: ReceivableStatus.Draft } }
	});

	const receivables = useMemo(
		() =>
			data?.receivables?.filter(
				receivable => !filterIds.includes(receivable.id) && !!receivable.item
			) || [],
		[data, filterIds]
	);

	const handleClose = () => {
		setOpen(false);
	};

	const handleSelected = (receivable: typeof receivables[0]) => () => {
		onSelected(receivable);
		handleClose();
	};

	const handleNewItemClicked = () => {
		onNewItemClick();
		handleClose();
	};

	return (
		<CustomDialog
			open={open}
			onClose={handleClose}
			title="Choose an Item"
			useSimpleBar
			maxWidth="xs"
			PaperProps={{
				sx: {
					borderRadius: '8px'
				}
			}}
			titleActions={[
				<Button variant="outlined" startIcon={<Add />} onClick={handleNewItemClicked}>
					New Item
				</Button>
			]}
		>
			<List sx={{ px: '12px' }}>
				{receivables.map(receivable => (
					<ListItemButton
						key={receivable.id}
						onClick={handleSelected(receivable)}
						sx={{ p: '12px', borderRadius: '8px' }}
					>
						<ListItemText
							primary={receivable.name}
							secondary={`${receivable.debtor.cname} ${receivable.debtor.ename}`}
						/>
						<Typography variant="h6" color="primary">
							RM{receivable.price * receivable.quantity}
						</Typography>
					</ListItemButton>
				))}
			</List>
		</CustomDialog>
	);
};

export default ChooseReceivableDialog;
