import React from 'react';
import {
	GetUsersQuery,
	GetUsersQueryVariables,
	User,
	UserRole,
	useUpdateUserMutation
} from '../../../../generated/api';
import { Button } from '@mui/material';
import { useAtomValue } from 'jotai/utils';
import { isMasterAtom } from '../../../../atoms';
import { GET_USERS } from '../../query';

interface UserDetailsLecturerDeleteProps {
	user: User;
}

const UserDetailsLecturerDelete: React.FC<UserDetailsLecturerDeleteProps> = ({
	user
}) => {
	const isMaster = useAtomValue(isMasterAtom);
	const [updateUserMutation] = useUpdateUserMutation({
		variables: {
			id: user.id,
			input: {
				lecturer: null
			},
			isMaster
		},
		optimisticResponse: {
			__typename: 'Mutation',
			updateUser: {
				...user,
				lecturer: null
			}
		},
		update: (cache, mutationResult) => {
			const lecturer = user.lecturer;
			if (!lecturer) {
				return;
			}
			const variables: GetUsersQueryVariables = {
				roleFilter: {
					roles: [UserRole.Lecturer]
				}
			};
			const data = cache.readQuery<GetUsersQuery>({
				query: GET_USERS,
				variables
			});
			if (data) {
				cache.writeQuery<GetUsersQuery>({
					query: GET_USERS,
					variables,
					data: {
						users: data.users.filter(u => u.id !== user.id)
					}
				});
			}
			cache.evict({
				id: cache.identify(lecturer)
			});
			cache.gc();
		}
	});

	const handleClick = async () => {
		try {
			await updateUserMutation();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Button
			variant="contained"
			fullWidth
			color="secondary"
			size="large"
			onClick={handleClick}
		>
			删除此用户的老师身份
		</Button>
	);
};

export default UserDetailsLecturerDelete;
