import React from 'react';
import { Button, DialogActions, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { useUpdateAtom } from 'jotai/utils';
import { courseCreateDialogOpenAtom } from '../../../../atoms';
import { useCourseCreateStore } from '../../../../stores/useCourseCreateStore';
import { useNavigate } from 'react-router-dom';

interface CourseCreateDialogActionsProps {}

const CourseCreateDialogActions: React.FC<CourseCreateDialogActionsProps> = () => {
	const navigate = useNavigate();
	const reset = useCourseCreateStore(state => state.reset);
	const theme = useTheme();
	const isSM = useMediaQuery(theme.breakpoints.down('sm'));

	const handleClose = () => {
		navigate(-1);
	};

	return (
		<Toolbar>
			<DialogActions sx={{ width: '100%', p: 0 }}>
				{!isSM && (
					<>
						<Button color="primary" onClick={handleClose}>
							取消
						</Button>
						<Button color="primary" onClick={reset}>
							重置
						</Button>
					</>
				)}
				<Button
					type="submit"
					variant="contained"
					sx={{ color: theme => (theme.palette.mode === 'light' ? '#fff' : '#000') }}
				>
					创建
				</Button>
			</DialogActions>
		</Toolbar>
	);
};

export default CourseCreateDialogActions;
