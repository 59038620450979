import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, TabProps, Tabs, TabsProps, Theme, useMediaQuery, useTheme } from '@mui/material';
import { HeaderRoute } from '../types';
import { styled } from '@mui/material/styles';

function a11yProps(index: any) {
	return {
		id: `scrollable-auto-tab-${index}`,
		'aria-controls': `scrollable-auto-tabpanel-${index}`
	};
}

interface ContentHeaderProps {
	route: HeaderRoute;
}

const ContentHeaderTabs: React.FC<ContentHeaderProps> = ({ route }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [currentTab, setCurrentTab] = useState(0);
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));

	useEffect(() => {
		if (!route) {
			return;
		}
		const tabIndex = route.tabs.findIndex((tab, index) => {
			if (index === 0) {
				return decodeURI(location.pathname) === route.link + tab.link;
			}
			return decodeURI(location.pathname).startsWith(route.link + tab.link);
		});
		if (tabIndex !== -1) {
			setCurrentTab(tabIndex);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, route]);

	const onChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		navigate(route.link + route.tabs[Number(newValue)].link);
	};

	return (
		<Container>
			{route.tabs.map(tab => route.link + tab.link) && route.tabs.length > 0 && (
				<>
					{!mdDown ? (
						<StyledTabs value={currentTab} onChange={onChange} aria-label="Tabs">
							{route.tabs.map((tab, index) => (
								<StyledTab key={route.link + tab.link} label={tab.name} {...a11yProps(index)} />
							))}
						</StyledTabs>
					) : (
						<ToolbarTabs
							value={currentTab}
							onChange={onChange}
							variant={route.tabs.length > 4 ? 'scrollable' : 'standard'}
							centered={route.tabs.length <= 4}
							scrollButtons={false}
							aria-label="Tabs"
						>
							{route.tabs.map((tab, index) => (
								<ToolbarTab key={route.link + tab.link} label={tab.name} {...a11yProps(index)} />
							))}
						</ToolbarTabs>
					)}
				</>
			)}
		</Container>
	);
};

const Container = styled('div')(({ theme }) => ({
	[theme.breakpoints.up('md')]: {
		paddingBottom: '1.4em'
	},
	width: '100%'
}));

const StyledTabs = styled((props: TabsProps) => (
	<Tabs
		{...props}
		TabIndicatorProps={{ children: <div /> }}
		scrollButtons="auto"
		variant="scrollable"
	/>
))(({ theme }) => ({
	transform: 'translateX(-6px)',
	'& .MuiTabs-indicator': {
		display: 'flex',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		'& > div': {
			maxWidth: '2.2em',
			width: '100%',
			backgroundColor: theme.palette.mode === 'light' ? 'red' : 'rgba(255, 255, 255, 0.9)'
		}
	}
}));

const StyledTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: 'none',
	color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.5)' : 'rgba(255, 255, 255, 0.7)',
	// fontSize: '1.25em',
	fontSize: theme.typography.h6.fontSize,
	marginRight: theme.spacing(1),
	paddingBottom: 0,
	minWidth: 0,
	transition: `color 300ms`,
	opacity: 1,
	paddingTop: 6,
	paddingLeft: 12,
	paddingRight: 12,
	'&:focus': {
		color: theme.palette.mode === 'light' ? 'red' : 'rgba(255, 255, 255, 0.9)'
	},
	'&.Mui-selected': {
		color: theme.palette.mode === 'light' ? 'red' : 'rgba(255, 255, 255, 0.9)'
	}
}));

const ToolbarTabs = styled(Tabs)(({ theme }) => ({
	width: '100%',
	'& .MuiTabs-indicator': {
		backgroundColor: theme.palette.mode === 'light' ? 'red' : '#fff'
	}
}));

const ToolbarTab = styled(Tab)(({ theme }) => ({
	fontSize: theme.typography.body1.fontSize,
	minWidth: 72,
	color: theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.7)' : 'rgba(255, 255, 255, 0.7)',
	transition: 'color .3s',
	[theme.breakpoints.down('md')]: {
		'&.Mui-selected': {
			color: theme.palette.mode === 'light' ? 'red' : '#fff'
		}
	}
}));

export default ContentHeaderTabs;
