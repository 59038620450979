import React, { useCallback, useMemo } from 'react';
import { Receivable, ReceivableType } from '../../generated/api';
import { Box, Button, Divider, Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { Delete } from '@mui/icons-material';

interface InvoiceItemProps {
	item: {
		id: string;
		title: string;
		description: string;
		service: ReceivableType | null;
		quantity: number;
		price: number;
		receivable?: Omit<Receivable, 'debtor'>;
	};
	setItemTitle: (id: string, title: string) => void;
	setItemDescription: (id: string, description: string) => void;
	setItemService: (id: string, service: ReceivableType | null) => void;
	setItemQuantity: (id: string, quantity: number) => void;
	setItemPrice: (id: string, price: number) => void;
	removeItem: (id: string) => void;
}

const InvoiceItem: React.FC<InvoiceItemProps> = ({
	item,
	setItemTitle,
	setItemDescription,
	setItemService,
	setItemQuantity,
	setItemPrice,
	removeItem
}) => {
	const editable = useMemo(() => !item.receivable || !item.receivable.item, [item]);

	const handleTitleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setItemTitle(item.id, event.target.value);
		},
		[item.id, setItemTitle]
	);

	const handleDescriptionChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setItemDescription(item.id, event.target.value);
		},
		[item.id, setItemDescription]
	);

	const handleServiceChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setItemService(item.id, event.target.value as ReceivableType | null);
		},
		[item.id, setItemService]
	);

	const handleQuantityChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setItemQuantity(item.id, Number(event.target.value));
		},
		[item.id, setItemQuantity]
	);

	const handlePriceChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setItemPrice(item.id, Number(event.target.value));
		},
		[item.id, setItemPrice]
	);

	const handleRemoveItem = useCallback(() => {
		removeItem(item.id);
	}, [item.id]);

	return (
		<Box sx={{ mt: 4 }}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={3}>
					<TextField
						label="Title"
						value={item?.title || ''}
						onChange={handleTitleChange}
						fullWidth
						InputProps={{ sx: { borderRadius: '8px' } }}
						InputLabelProps={{
							shrink: true
						}}
						disabled={!editable}
						required
					/>
				</Grid>
				<Grid item xs={12} md={3}>
					<TextField
						label="Description"
						value={item?.description || ''}
						onChange={handleDescriptionChange}
						fullWidth
						InputProps={{ sx: { borderRadius: '8px' } }}
						InputLabelProps={{
							shrink: true
						}}
					/>
				</Grid>
				<Grid item xs={12} md={2}>
					<TextField
						label="Service"
						select
						value={item?.service || ''}
						onChange={handleServiceChange}
						fullWidth
						InputProps={{ sx: { borderRadius: '8px' } }}
						InputLabelProps={{
							shrink: true
						}}
						disabled={!editable}
						required
					>
						<MenuItem value={ReceivableType.Lesson}>课程 Lesson</MenuItem>
					</TextField>
				</Grid>
				<Grid item xs={12} md={4 / 3}>
					<TextField
						label="Quantity"
						value={item?.quantity || 1}
						onChange={handleQuantityChange}
						fullWidth
						InputProps={{ sx: { borderRadius: '8px' } }}
						InputLabelProps={{
							shrink: true
						}}
						type="number"
						inputProps={{
							min: 1
						}}
						disabled={!editable}
						required
					/>
				</Grid>
				<Grid item xs={12} md={4 / 3}>
					<TextField
						label="Price"
						value={item?.price || 0}
						onChange={handlePriceChange}
						fullWidth
						InputProps={{
							startAdornment: <InputAdornment position="start">RM</InputAdornment>,
							sx: { borderRadius: '8px' }
						}}
						disabled={!editable}
						required
					/>
				</Grid>
				<Grid item xs={12} md={4 / 3}>
					<TextField
						label="Total"
						value={(item?.price || 0) * (item?.quantity || 0)}
						fullWidth
						InputProps={{
							startAdornment: <InputAdornment position="start">RM</InputAdornment>,
							sx: { borderRadius: '8px' }
						}}
						disabled
					/>
				</Grid>
			</Grid>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
				<Button color="error" startIcon={<Delete />} onClick={handleRemoveItem}>
					Remove
				</Button>
			</Box>
			<Divider sx={{ mt: 2, borderStyle: 'dashed' }} />
		</Box>
	);
};

export default InvoiceItem;
