import { UserDetailsItemKey, UserRole } from './types';

export const UserDetailsItemRecord: Record<UserDetailsItemKey, string> = {
	[UserDetailsItemKey.CNAME]: '中文名',
	[UserDetailsItemKey.ENAME]: '英文名',
	[UserDetailsItemKey.GENDER]: '性别',
	[UserDetailsItemKey.IC]: 'I/C',
	[UserDetailsItemKey.PHONE]: '电话',
	[UserDetailsItemKey.ADDRESS]: '地址',
	[UserDetailsItemKey.STATUS]: '在籍状况',
	[UserDetailsItemKey.EMAIL]: '邮箱',
	[UserDetailsItemKey.PASSWORD]: '密码',
	[UserDetailsItemKey.REGISTER_DATE]: '注册日期',
	[UserDetailsItemKey.LAST_LOGIN_TIME]: '最后登陆时间',
	[UserDetailsItemKey.STUDENT_MATRIC_ID]: '学号',
	[UserDetailsItemKey.STUDENT_PHONES]: '联系号码',
	[UserDetailsItemKey.STUDENT_STATUS]: '在籍状况',
	[UserDetailsItemKey.LECTURER_MATRIC_ID]: '编号',
	[UserDetailsItemKey.LECTURER_STATUS]: '在籍状况',
	[UserDetailsItemKey.ADMIN_MATRIC_ID]: '编号',
	[UserDetailsItemKey.ADMIN_STATUS]: '在籍状况',
	[UserDetailsItemKey.ADMIN_IS_MASTER]: '高级管理'
};

export const UserRoleCname = {
	[UserRole.STUDENT]: '学生',
	[UserRole.LECTURER]: '老师',
	[UserRole.ADMIN]: '管理'
} as const;

export const UserRoleEname = {
	[UserRole.STUDENT]: 'student',
	[UserRole.LECTURER]: 'lecturer',
	[UserRole.ADMIN]: 'admin'
} as const;
