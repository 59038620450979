import React from 'react';
import ReactPDF, { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useTheme } from '@mui/material';
import { GetInvoiceQueryResult, InvoiceStatus } from '../../../generated/api';
import { Relay } from '../../../utils/relay';
import ViewProps = ReactPDF.ViewProps;

const styles = StyleSheet.create({
	tableHead: {
		fontWeight: 'bold'
	},
	tableRow: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		borderBottom: '1px solid #eee',
		fontSize: 9.5,
		paddingTop: 8,
		paddingBottom: 8
	},
	tableCell: {
		flex: 1
	},
	alignRight: {
		textAlign: 'right'
	}
});

Font.register({
	family: 'NotoSansSC',
	format: 'opentype',
	fonts: [
		{
			src: '/NotoSansSC-Regular.otf',
			fontWeight: 'normal'
		},
		{
			src: '/NotoSansSC-Bold.otf',
			fontWeight: 'bold'
		}
	]
});

const TableHead: React.FC<React.PropsWithChildren<ViewProps>> = ({ children, style, ...props }) => (
	<View
		style={[
			styles.tableRow,
			styles.tableHead,
			...(style ? (Array.isArray(style) ? style : [style]) : [{}])
		]}
		{...props}
	>
		{children}
	</View>
);

const TableRow: React.FC<React.PropsWithChildren<ViewProps>> = ({ children, style, ...props }) => (
	<View
		style={[styles.tableRow, ...(style ? (Array.isArray(style) ? style : [style]) : [{}])]}
		{...props}
	>
		{children}
	</View>
);

const TableCell: React.FC<React.PropsWithChildren<ViewProps & { alignRight?: boolean }>> = ({
	children,
	alignRight,
	style,
	...props
}) => (
	<View
		style={[
			styles.tableCell,
			alignRight ? styles.alignRight : {},
			...(style ? (Array.isArray(style) ? style : [style]) : [{}])
		]}
		{...props}
	>
		{children}
	</View>
);

interface InvoicePdfProps {
	invoice: Exclude<Exclude<GetInvoiceQueryResult['data'], undefined>['node'], undefined | null>;
}

const InvoicePdf: React.FC<InvoicePdfProps> = ({ invoice }) => {
	const theme = useTheme();

	if (invoice.__typename !== 'Invoice') {
		return null;
	}

	return (
		<Document title={`${Relay.unmarshalId(invoice.id)} - ${invoice.user.ename}`}>
			<Page size="A4" style={{ fontFamily: 'NotoSansSC', lineHeight: 1.15, paddingVertical: 32 }}>
				<View
					style={{
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'space-between',
						minHeight: '100%'
					}}
				>
					<View style={{ padding: '32px', paddingTop: 0 }}>
						<View style={{ display: 'flex', flexDirection: 'row' }}>
							<Image
								src="/images/ekids_logo.png"
								style={{
									width: 150,
									height: 85,
									marginRight: 16,
									marginBottom: 16
								}}
							/>
							<View style={{ marginBottom: 8, flexGrow: 1 }}>
								<View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
									<Text style={{ fontSize: 13.5, fontWeight: 'bold' }}>
										ELEMENTE EDUCATION SDN. BHD.
									</Text>
									<Text style={{ fontSize: 9.5, fontWeight: 'normal', marginLeft: 4 }}>
										(Co. No.1280836-M)
									</Text>
								</View>
								<Text style={{ opacity: 0.8, fontSize: 9.5, marginTop: 8 }}>
									Level 2, L2.27 KL GATEWAY MALL.
								</Text>
								<Text style={{ opacity: 0.8, fontSize: 9.5, marginTop: 4 }}>
									2, Jalan Kerinchi, Kampung Kerinchi, 59200 Kuala Lumpur,
								</Text>
								<Text style={{ opacity: 0.8, fontSize: 9.5, marginTop: 2 }}>
									Wilayah Persekutuan Kuala Lumpur, Malaysia.
								</Text>
								<Text style={{ opacity: 0.8, fontSize: 9.5, marginTop: 4 }}>H/P: 012-336 6124</Text>
							</View>
							<Text style={{ fontSize: 13.5, fontWeight: 'bold' }}>
								{invoice.status === InvoiceStatus.Open ? 'UNPAID' : invoice.status}
							</Text>
						</View>
						{/*<View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>*/}
						{/*	<Text style={{ fontSize: 13.5, fontWeight: 'bold' }}>*/}
						{/*		{invoice.status === InvoiceStatus.Open ? 'UNPAID' : invoice.status}*/}
						{/*	</Text>*/}
						{/*</View>*/}
						<View
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'space-between',
								width: '100%',
								flexWrap: 'wrap',
								marginTop: 16
							}}
						>
							<View>
								<Text style={{ opacity: 0.5, fontSize: 9.5, fontWeight: 'bold', marginBottom: 8 }}>
									INVOICE TO
								</Text>
								<Text style={{ fontSize: 11.5, marginBottom: 8, opacity: 0.8 }}>
									{invoice.user.cname}
									{invoice.user.cname !== '' ? ' ' : ''}
									{invoice.user.ename}
								</Text>
								<Text style={{ fontSize: 11.5, maxWidth: 200, lineHeight: 1.4 }}>
									{invoice.user.address}
								</Text>
							</View>
							<View>
								<Text style={{ opacity: 0.5, fontSize: 9.5, fontWeight: 'bold', marginBottom: 8 }}>
									INVOICE NO
								</Text>
								<Text style={{ fontSize: 11.5 }}>{Relay.unmarshalId(invoice.id)}</Text>
								<Text
									style={{
										opacity: 0.5,
										fontSize: 9.5,
										fontWeight: 'bold',
										marginTop: 24,
										marginBottom: 8
									}}
								>
									DATE CREATE
								</Text>
								<Text style={{ fontSize: 11.5 }}>
									{new Date(invoice.createdAt).toLocaleDateString('en-MY')}
								</Text>
							</View>
						</View>
						<View style={{ marginTop: 48 }}>
							<View style={{ display: 'flex', flexDirection: 'column' }}>
								<TableHead>
									<TableCell style={{ flex: 0.5 }}>
										<Text>#</Text>
									</TableCell>
									<TableCell style={{ flex: 2.5 }}>
										<Text>Description</Text>
									</TableCell>
									<TableCell alignRight>
										<Text>Quantity</Text>
									</TableCell>
									<TableCell alignRight>
										<Text>Unit Price</Text>
									</TableCell>
									<TableCell alignRight>
										<Text>Total</Text>
									</TableCell>
								</TableHead>
								{invoice.items.map((item, index) => (
									<TableRow key={index}>
										<TableCell style={{ flex: 0.5 }}>
											<Text>{index + 1}</Text>
										</TableCell>
										<TableCell style={{ flex: 2.5 }}>
											<Text>{item.receivable.name}</Text>
											<Text style={{ opacity: 0.6 }}>{item.description}</Text>
										</TableCell>
										<TableCell alignRight>
											<Text>{item.receivable.quantity}</Text>
										</TableCell>
										<TableCell alignRight>
											<Text>{item.receivable.price}</Text>
										</TableCell>
										<TableCell alignRight>
											<Text>{item.receivable.price * item.receivable.quantity}</Text>
										</TableCell>
									</TableRow>
								))}
								<TableRow style={{ borderBottom: 'none', paddingTop: 24 }}>
									<TableCell />
									<TableCell style={{ flex: 2.5 }} />
									<TableCell />
									<TableCell alignRight>
										<Text>Subtotal</Text>
									</TableCell>
									<TableCell alignRight>
										<Text>
											RM
											{invoice.items
												.reduce(
													(prev, curr) => prev + curr.receivable.price * curr.receivable.quantity,
													0
												)
												.toFixed(2)}
										</Text>
									</TableCell>
								</TableRow>
								<TableRow style={{ borderBottom: 'none' }}>
									<TableCell />
									<TableCell style={{ flex: 2.5 }} />
									<TableCell />
									<TableCell alignRight>
										<Text>Discount</Text>
									</TableCell>
									<TableCell alignRight>
										<Text style={invoice.discount ? { color: theme.palette.error.main } : {}}>
											{invoice.discount ? `- RM${invoice.discount.toFixed(2)}` : '-'}
										</Text>
									</TableCell>
								</TableRow>
								<TableRow style={{ borderBottom: 'none' }}>
									<TableCell />
									<TableCell style={{ flex: 2.5 }} />
									<TableCell />
									<TableCell alignRight>
										<Text>Taxes</Text>
									</TableCell>
									<TableCell alignRight>
										<Text>{invoice.tax ? `RM${invoice.tax.toFixed(2)}` : '-'}</Text>
									</TableCell>
								</TableRow>
								<TableRow style={{ borderBottom: 'none', fontSize: 11.5, fontWeight: 'bold' }}>
									<TableCell />
									<TableCell style={{ flex: 2.5 }} />
									<TableCell />
									<TableCell alignRight>
										<Text>Total</Text>
									</TableCell>
									<TableCell alignRight>
										<Text>RM{invoice.amount.toFixed(2)}</Text>
									</TableCell>
								</TableRow>
							</View>
						</View>
					</View>
					<View
						style={{ borderTop: '1px solid #eee', padding: 24, paddingBottom: 0, marginBottom: -8 }}
					>
						<Text style={{ fontSize: 8.5, fontWeight: 'bold', marginBottom: 4 }}>NOTES</Text>
						<Text style={{ fontSize: 8.5 }}>
							This is a computer generated invoice and no signature is required.
						</Text>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default InvoicePdf;
