import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material';
import { useAtom } from 'jotai';
import { invoiceDeleteIdAtom } from '../../../atoms';
import useLastNotNullValue from '../../../hooks/useLastNotNullvalue';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { useDeleteInvoiceMutation } from '../../../generated/api';
import { useNavigate } from 'react-router-dom';
import { Relay } from '../../../utils/relay';

interface InvoiceDeleteDialogProps {}

const InvoiceDeleteDialog: React.FC<InvoiceDeleteDialogProps> = () => {
	const [invoiceIdRaw, setInvoiceId] = useAtom(invoiceDeleteIdAtom);
	const invoiceId = useLastNotNullValue(invoiceIdRaw ? Relay.unmarshalId(invoiceIdRaw) : '');
	const [deleteInvoiceMutation] = useDeleteInvoiceMutation({
		update: (cache, result) => {
			const id = result.data?.deleteInvoice?.id;
			if (!id) {
				return;
			}
			cache.evict({
				id: cache.identify({
					id,
					__typename: 'Invoice'
				})
			});
			cache.gc();
		}
	});
	const navigate = useNavigate();

	const handleClose = () => {
		setInvoiceId('');
	};

	const handleDeleteClick = async () => {
		try {
			await deleteInvoiceMutation({ variables: { id: invoiceIdRaw } });
			toast.success(`删除发票${invoiceId}成功!`);
			handleClose();
			navigate('/payment-management/invoices', { replace: true });
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(err.message);
			} else {
				toast.error(`${err}`);
			}
		}
	};

	return (
		<Dialog open={!!invoiceIdRaw} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>删除{invoiceId}</DialogTitle>
			<DialogContent>
				<DialogContentText>确定要删除{invoiceId}吗？</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>取消</Button>
				<Button onClick={handleDeleteClick}>确定</Button>
			</DialogActions>
		</Dialog>
	);
};

export default InvoiceDeleteDialog;
