import React from 'react';
import { Grid, TextField } from '@mui/material';
import { Language } from '../../../../generated/api';
import { useCategoryCreateStore } from '../../../../stores/useCategoryCreateStore';

interface CategoryCreateBasicInfoProps {}

const CategoryCreateBasicInfo: React.FC<CategoryCreateBasicInfoProps> = () => {
	const { translations, setName, setDescription } = useCategoryCreateStore();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;

		switch (name) {
			case 'category_zh_name':
				setName(value, Language.Zh);
				break;
			case 'category_en_name':
				setName(value, Language.En);
				break;
			case 'category_zh_description':
				setDescription(value, Language.Zh);
				break;
			case 'category_en_description':
				setDescription(value, Language.En);
				break;
		}
	};

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						label="中文名字"
						name="category_zh_name"
						value={translations.ZH.name}
						onChange={handleChange}
						fullWidth
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						label="英文名字"
						name="category_en_name"
						value={translations.EN.name}
						onChange={handleChange}
						fullWidth
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						label="中文简介"
						name="category_zh_description"
						value={translations.ZH.description}
						onChange={handleChange}
						fullWidth
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						label="英文简介"
						name="category_en_description"
						value={translations.EN.description}
						onChange={handleChange}
						fullWidth
						multiline
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default CategoryCreateBasicInfo;
