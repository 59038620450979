import React, { useEffect } from 'react';
import { StudentStatus, useGetStudentsQuery } from 'generated/api';
import { useUserStatusStore } from '../../stores/useUserStatusStore';
import { Typography } from '@mui/material';
import TableList from '../common/TableList';
import { UserRole } from '../../types';
import { useLocation, useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { useUserTableControllerStore } from '../../stores/useUserTableControllerStore';
import { userManagementLastRouteAtom } from '../../atoms';
import { useRefetchFunctionsStore } from '../../stores/useRefetchFunctionsStore';
import TableSkeletonLoading from '../../share-components/Table/TableSkeletonLoading';
import { useUpdateAtom } from 'jotai/utils';
import { useUserPreferenceStore } from '../../stores/useUserPreferenceStore';
import { TableFilterHolder } from '../../stores/useTableFilterStore';

const options = [
	{
		label: '全部学生',
		value: '0' as const
	},
	{
		label: '在籍学生',
		value: StudentStatus.Active
	},
	{
		label: '试课学生',
		value: StudentStatus.Temporary
	},
	{
		label: '不在籍学生',
		value: StudentStatus.Inactive
	}
];

const activeLabel = {
	[StudentStatus.Active]: '在籍学生',
	[StudentStatus.Temporary]: '试课学生',
	[StudentStatus.Inactive]: '不在籍学生'
};

interface StudentsProps {}

const Students: React.FC<StudentsProps> = () => {
	const [status, setStatus] = useUserStatusStore(
		state => [state.studentStatus, state.setStudentStatus],
		shallow
	);
	const [selectedValue, setOptions, setSelectedValue, setRole] = useUserTableControllerStore(
		state => [state.selectedValue, state.setOptions, state.setSelectedValue, state.setRole],
		shallow
	);
	const { data, loading, error, refetch, startPolling, stopPolling } = useGetStudentsQuery({
		variables: {
			status: status !== '0' ? status : undefined
		}
	});
	const setUserManagementLastRoute = useUpdateAtom(userManagementLastRouteAtom);
	const navigate = useNavigate();
	const [addRefetch, removeRefetch] = useRefetchFunctionsStore(
		state => [state.addFunction, state.removeFunction],
		shallow
	);
	const location = useLocation();

	useEffect(() => {
		setOptions(options);
		setSelectedValue(status);
		setUserManagementLastRoute('/student');
		setRole(UserRole.STUDENT);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const pollingInterval = useUserPreferenceStore.getState().pollingInterval;
		if (!pollingInterval) {
			return;
		}
		startPolling(pollingInterval);
		return () => {
			stopPolling();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (refetch) {
			addRefetch(refetch);
		}

		return () => {
			if (refetch) {
				removeRefetch(refetch);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetch]);

	useEffect(() => {
		switch (selectedValue) {
			case '0':
			case StudentStatus.Active:
			case StudentStatus.Temporary:
			case StudentStatus.Inactive:
				setStatus(selectedValue);
				break;
			default:
				return;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue]);

	if (!data) {
		if (loading) {
			return <TableSkeletonLoading />;
		}
		if (error) {
			return <>{error.message}</>;
		}
		return <></>;
	}

	const users = data.students.map(student => {
		const user = {
			...student,
			...student.user
		};

		if (user.phone === '' && user.phones.length !== 0) {
			user.phone = `${user.phones[0].number} (${user.phones[0].name})`;
		}

		return user;
	});

	const handleItemClick = (user: typeof users[0]) => {
		navigate(`/users/${user.id}`, { state: { backgroundLocation: location } });
	};

	return (
		<>
			{!users || users.length === 0 ? (
				<Typography variant="body1" align="center" style={{ marginTop: '3em' }}>
					没有{status !== '0' ? activeLabel[status] : '学生'}
				</Typography>
			) : (
				<TableList
					holder={TableFilterHolder.STUDENT}
					items={users}
					headCells={[
						{ id: 'matricId', disablePadding: false, label: '学号' },
						{ id: 'cname', disablePadding: false, label: '中文名' },
						{ id: 'ename', disablePadding: false, label: '英文名' },
						{ id: 'gender', disablePadding: false, label: '性别' },
						{ id: 'email', disablePadding: false, label: 'Email' },
						{ id: 'ic', disablePadding: false, label: 'I/C' },
						{ id: 'phone', disablePadding: false, label: '电话' }
					]}
					clickFunc={handleItemClick}
				/>
			)}
		</>
	);
};

export default Students;
