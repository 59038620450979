import create from 'zustand';
import { Receivable, ReceivableType } from '../generated/api';
import produce from 'immer';

type InvoiceUpdateStoreState = {
	debtor: {
		id: string;
		cname: string;
		ename: string;
		address: string;
	} | null;
	createAt: Date | null;
	discount: number;
	tax: number;
	items: Array<{
		id: string;
		title: string;
		description: string;
		service: ReceivableType | null;
		quantity: number;
		price: number;
		receivable?: Omit<Receivable, 'debtor'>;
	}>;
	setDebtor: (debtor: InvoiceUpdateStoreState['debtor']) => void;
	setCreateAt: (createAt: Date | null) => void;
	setDiscount: (discount: number) => void;
	setTax: (tax: number) => void;
	setItems: (items: InvoiceUpdateStoreState['items']) => void;
	setItemTitle: (id: string, title: string) => void;
	setItemDescription: (id: string, description: string) => void;
	setItemService: (id: string, service: ReceivableType | null) => void;
	setItemQuantity: (id: string, quantity: number) => void;
	setItemPrice: (id: string, price: number) => void;
	addItems: (items: InvoiceUpdateStoreState['items']) => void;
	removeItem: (id: string) => void;
	setAll: (
		all: Pick<InvoiceUpdateStoreState, 'debtor' | 'discount' | 'tax' | 'createAt' | 'items'>
	) => void;
	reset: () => void;
};

export const useInvoiceUpdateStore = create<InvoiceUpdateStoreState>(set => ({
	debtor: null,
	createAt: new Date(),
	discount: 0,
	tax: 0,
	items: [],
	setDebtor: debtor => set({ debtor }),
	setCreateAt: createAt => set({ createAt }),
	setDiscount: discount => set({ discount }),
	setTax: tax => set({ tax }),
	setItems: items => set({ items }),
	setItemTitle: (id, title) =>
		set(
			produce<InvoiceUpdateStoreState>(draft => {
				const item = draft.items.find(item => item.id === id);
				if (!item) {
					return;
				}
				item.title = title;
			})
		),
	setItemDescription: (id, description) =>
		set(
			produce<InvoiceUpdateStoreState>(draft => {
				const item = draft.items.find(item => item.id === id);
				if (!item) {
					return;
				}
				item.description = description;
			})
		),
	setItemService: (id, service) =>
		set(
			produce<InvoiceUpdateStoreState>(draft => {
				const item = draft.items.find(item => item.id === id);
				if (!item) {
					return;
				}
				item.service = service;
			})
		),
	setItemQuantity: (id, quantity) =>
		set(
			produce<InvoiceUpdateStoreState>(draft => {
				const item = draft.items.find(item => item.id === id);
				if (!item) {
					return;
				}
				item.quantity = quantity;
			})
		),
	setItemPrice: (id, price) =>
		set(
			produce<InvoiceUpdateStoreState>(draft => {
				const item = draft.items.find(item => item.id === id);
				if (!item) {
					return;
				}
				item.price = price;
			})
		),
	addItems: items =>
		set(
			produce<InvoiceUpdateStoreState>(draft => {
				draft.items.push(...items);
			})
		),
	removeItem: id =>
		set(
			produce<InvoiceUpdateStoreState>(draft => {
				const index = draft.items.findIndex(item => item.id === id);
				if (index !== -1) {
					draft.items.splice(index, 1);
				}
			})
		),
	reset: () =>
		set({
			debtor: null,
			createAt: new Date(),
			items: []
		}),
	setAll: all => set(all)
}));
