import React, { useState } from 'react';
import { courseDeleteItemAtom } from '../../../../atoms';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FilledInput,
	FormHelperText,
	Toolbar,
	Typography
} from '@mui/material';
import { useDeleteCourseMutation } from '../../../../generated/api';
import { deleteCourseUpdate } from '../../update/deleteCourseUpdate';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';

interface CourseDeleteDialogProps {}

const CourseDeleteDialog: React.FC<CourseDeleteDialogProps> = () => {
	const navigate = useNavigate();
	const [course, setCourse] = useAtom(courseDeleteItemAtom);
	const [confirmationText, setConfirmationText] = useState('');
	const [isError, setIsError] = useState(false);
	const [deleteCourseMutation] = useDeleteCourseMutation({
		update: deleteCourseUpdate
	});

	const handleClose = () => {
		setCourse(null);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (isError) {
			setIsError(false);
		}
		setConfirmationText(event.target.value);
	};

	const handleDelete = async () => {
		if (!course) {
			return;
		}

		if (confirmationText !== course.name) {
			setIsError(true);
			return;
		}

		try {
			await deleteCourseMutation({
				variables: {
					id: course.id
				},
				optimisticResponse: {
					deleteCourse: {
						id: course.id,
						__typename: 'DeletePayload'
					}
				}
			});
			handleClose();
			navigate(-1);
			toast.success('删除课程成功！');
		} catch (err) {
			toast.error(`删除课程失败！${err}`);
		}
	};

	return (
		<Dialog open={!!course} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>删除{course?.name}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					确定要删除{course?.name}吗？删除{course?.name}
					将连带删除此课程的所有班级。
				</DialogContentText>
				<Typography variant="body2" sx={{ marginTop: '10px', marginBottom: '5px' }}>
					输入
					<Typography variant="body2" color="primary" component="span" sx={{ margin: '0 2px' }}>
						{course?.name}
					</Typography>
					以确认删除
				</Typography>
				<StyledFilledInput
					value={confirmationText}
					onChange={handleChange}
					error={isError}
					fullWidth
					autoFocus
				/>
				<FormHelperText error={isError}>
					{isError ? `请输入${course?.name || ''}` : ''}
				</FormHelperText>
			</DialogContent>
			<Toolbar>
				<DialogActions sx={{ width: '100%', p: 0 }}>
					<Button color="primary" onClick={handleClose}>
						取消
					</Button>
					<Button color="error" variant="contained" onClick={handleDelete}>
						删除
					</Button>
				</DialogActions>
			</Toolbar>
		</Dialog>
	);
};

const StyledFilledInput = styled(FilledInput)({
	'& .MuiFilledInput-root': {
		paddingTop: '16px'
	}
});

export default CourseDeleteDialog;
