import { CssBaseline, useMediaQuery, useTheme } from '@mui/material';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React, { PropsWithChildren, useEffect } from 'react';
import { useAtom } from 'jotai';
import { darkModeAtom, muiThemeAtom } from '../atoms';
import { useUpdateAtom } from 'jotai/utils';
import { Style, Meta } from 'react-head';

interface ThemeProviderProps {}

const ThemeProvider: React.FC<PropsWithChildren<ThemeProviderProps>> = ({ children }) => {
	const setDarkMode = useUpdateAtom(darkModeAtom);
	const [muiTheme] = useAtom(muiThemeAtom);
	const mediaQueryDark = useMediaQuery('(prefers-color-scheme: dark)');

	useEffect(() => {
		setDarkMode(mediaQueryDark);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mediaQueryDark]);

	return (
		<MuiThemeProvider theme={muiTheme}>
			<ThemeColorMetaTag />
			<ThemeColorSchema />
			{children}
			<CssBaseline />
		</MuiThemeProvider>
	);
};

const ThemeColorMetaTag: React.FC = () => {
	const theme = useTheme();

	return (
		<Meta
			name="theme-color"
			content={
				theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.background.paper
			}
		/>
	);
};

const ThemeColorSchema: React.FC = () => {
	const theme = useTheme();

	return (
		<Style>{`
		html {
			color-scheme: ${theme.palette.mode}
		}
	`}</Style>
	);
};

export default ThemeProvider;
