import React, { useCallback, useEffect } from 'react';
import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { isMasterAtom, userRoleCreateDialogOpenAtom } from '../../../atoms';
import UserRoleCreateDialogContent from './UserRoleCreateDialogContent';
import UserRoleCreateDialogActions from './UserRoleCreateDialogActions';
import { UserRoleCname, UserRoleEname } from '../../../constants';
import { useUserTableControllerStore } from '../../../stores/useUserTableControllerStore';
import { UserRole } from '../../../types';
import { useUserRoleCreateStore } from '../../../stores/useUserRoleCreateStore';
import { useGetUserQuery, useUpdateUserMutation } from '../../../generated/api';
import shallow from 'zustand/shallow';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { createStudentUpdate } from '../update/createStudentUpdate';
import { createLecturerUpdate } from '../update/createLecturerUpdate';
import { createAdminUpdate } from '../update/createAdminUpdate';
import { SlideUp } from '../../../share-components/Transitions/SlideUp';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';

interface UserRoleCreateDialogProps {}

const UserRoleCreateDialog: React.FC<UserRoleCreateDialogProps> = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const open = useAtomValue(userRoleCreateDialogOpenAtom);
	const role = useUserTableControllerStore(state => state.role);
	const [updateUserMutation] = useUpdateUserMutation();
	const [userId, reset] = useUserRoleCreateStore(
		state =>
			role === UserRole.STUDENT
				? [state.student.userId, state.resetStudent]
				: role === UserRole.LECTURER
				? [state.lecturer.userId, state.resetLecturer]
				: role === UserRole.ADMIN
				? [state.admin.userId, state.resetAdmin]
				: ['', () => {}],
		shallow
	);
	const isMaster = useAtomValue(isMasterAtom);
	const { data } = useGetUserQuery({
		variables: {
			id: userId,
			isMaster
		},
		skip: !userId
	});
	const theme = useTheme();
	const isXS = useMediaQuery(theme.breakpoints.down('sm'));

	const handleClose = () => {
		navigate(-1);
	};

	const handleReset = () => {
		reset();
	};

	const handleSubmit = async (event: React.FormEvent<{}>) => {
		event.preventDefault();

		if (role === null) {
			return;
		}

		handleClose();

		const input = useUserRoleCreateStore.getState()[UserRoleEname[role]];
		const { userId, ...others } = input;

		if (data?.node?.__typename !== 'User') {
			return;
		}

		const user = data.node;

		try {
			await updateUserMutation({
				variables: {
					id: userId,
					input: {
						[UserRoleEname[role]]: others
					},
					isMaster
				},
				optimisticResponse: {
					__typename: 'Mutation',
					updateUser: {
						...user,
						[UserRoleEname[role]]: {
							...others,
							__typename:
								role === UserRole.STUDENT
									? 'Student'
									: role === UserRole.LECTURER
									? 'Lecturer'
									: role === UserRole.ADMIN
									? 'Admin'
									: ''
						}
					}
				},
				update:
					role === UserRole.STUDENT
						? createStudentUpdate
						: role === UserRole.LECTURER
						? createLecturerUpdate
						: role === UserRole.ADMIN
						? createAdminUpdate
						: undefined
			});

			handleReset();
		} catch (err) {
			console.log(err);
			navigate('/users/roles/create', {
				state: { backgroundLocation: location }
			});
		}
	};

	if (role === null) {
		return <></>;
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth
			fullScreen={isXS}
			TransitionComponent={isXS ? SlideUp : undefined}
			PaperProps={{
				sx: { overflowY: 'hidden', backgroundImage: 'none' }
			}}
		>
			<Box component="form" onSubmit={handleSubmit}>
				{!isXS ? (
					<AppBar position="static" elevation={0} color="inherit">
						<DialogTitle>添加{UserRoleCname[role]}</DialogTitle>
					</AppBar>
				) : (
					<AppBar position="static" color="inherit">
						<Toolbar>
							<IconButton onClick={handleClose} size="large">
								<Close />
							</IconButton>
							<Typography variant="h6" sx={{ ml: '10px' }}>
								添加{UserRoleCname[role]}
							</Typography>
							<Box sx={{ ml: 'auto', mr: 0 }}>
								<Button color="primary" onClick={handleReset}>
									重置
								</Button>
							</Box>
						</Toolbar>
					</AppBar>
				)}
				<StyledDialogContent dividers={isXS}>
					<UserRoleCreateDialogContent role={role} />
				</StyledDialogContent>
				<UserRoleCreateDialogActions isXS={isXS} />
			</Box>
		</Dialog>
	);
};

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	flex: 1,
	paddingBottom: 24,
	paddingTop: 24,
	overflow: 'auto',
	maxHeight: '76vh',
	height: '100%',
	[theme.breakpoints.down('sm')]: {
		maxHeight: 'calc(100vh - 108px)'
	}
}));

export const UserRoleCreateRoute = () => {
	const setOpen = useUpdateAtom(userRoleCreateDialogOpenAtom);

	useEffect(() => {
		setOpen(true);

		return () => {
			setOpen(false);
		};
	}, []);

	return <></>;
};

export default UserRoleCreateDialog;
