import React, { useEffect, useRef } from 'react';
import { Dialog, DialogContent, useMediaQuery, useTheme } from '@mui/material';
import { isMasterAtom, userCreateDialogOpenAtom } from '../../../atoms';
import { useUserCreateStore } from '../../../stores/useUserCreateStore';
import UserCreateDialogHeader from './UserCreateDialogHeader';
import UserCreateDialogActions from './UserCreateDialogActions';
import { CreateUserInput, useCreateUserMutation } from '../../../generated/api';
import UserCreateDialogContent from './UserCreateDialogContent';
import shallow from 'zustand/shallow';
import UserCreateDialogStepper from './UserCreateDialogStepper';
import { v4 } from 'uuid';
import { createUserUpdate } from '../update/createUserUpdate';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { SlideUp } from '../../../share-components/Transitions/SlideUp';
import { useLocation, useNavigate } from 'react-router-dom';
import FormWrapper from '../../common/FormWrapper';
import { Relay } from '../../../utils/relay';

interface UserCreateDialogProps {}

const UserCreateDialog: React.FC<UserCreateDialogProps> = () => {
	const open = useAtomValue(userCreateDialogOpenAtom);
	const theme = useTheme();
	const isSM = useMediaQuery(theme.breakpoints.down('sm'));
	const [createUserMutation, { loading, error, reset }] = useCreateUserMutation({
		update: createUserUpdate
	});
	const [step, setStep, resetUser] = useUserCreateStore(
		state => [state.step, state.setStep, state.resetUser],
		shallow
	);
	const isMaster = useAtomValue(isMasterAtom);
	const contentElem = useRef<HTMLDivElement>(null);
	const navigate = useNavigate();
	const location = useLocation();

	const handleClose = () => {
		navigate(-1);
	};

	const handleSubmit = async (event: React.FormEvent<{}>) => {
		event.preventDefault();

		if (step === 0) {
			setStep(1);
			return;
		}

		const user = useUserCreateStore.getState().user;
		const roles = useUserCreateStore.getState().roles;
		let input: CreateUserInput = { ...user };
		input.id = v4();
		(Object.keys(roles) as Array<keyof typeof roles>).forEach(role => {
			if (!roles[role]) {
				delete input[role];
			}
		});

		handleClose();

		const id = Relay.marshalId('User', input.id);

		try {
			await createUserMutation({
				variables: { input, isMaster },
				optimisticResponse: {
					__typename: 'Mutation',
					createUser: {
						...input,
						__typename: 'User',
						id,
						registerDate: new Date().toISOString(),
						student: input.student
							? {
									...input.student,
									user: {
										id
									},
									__typename: 'Student'
							  }
							: null,
						lecturer: input.lecturer
							? {
									...input.lecturer,
									user: {
										id
									},
									__typename: 'Lecturer'
							  }
							: null,
						admin: input.admin
							? {
									...input.admin,
									user: {
										id
									},
									__typename: 'Admin'
							  }
							: null
					}
				}
			});

			resetUser();
		} catch (err) {
			navigate('/users/create', { state: { backgroundLocation: location } });
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			fullScreen={isSM}
			TransitionComponent={isSM ? SlideUp : undefined}
			fullWidth
			maxWidth="sm"
			PaperProps={{
				//@ts-expect-error
				component: 'form',
				onSubmit: handleSubmit,
				sx: { overflowY: 'hidden' }
			}}
		>
			<UserCreateDialogHeader />
			<DialogContent
				ref={contentElem}
				sx={theme => ({
					paddingBottom: '24px',
					overflow: 'auto',
					maxHeight: '76vh',
					height: '100%',
					backgroundColor: theme.palette.background.paper,
					[theme.breakpoints.down('sm')]: {
						maxHeight: 'calc(100vh - 96px)'
					}
				})}
				dividers={isSM}
			>
				<FormWrapper
					loading={loading}
					error={error}
					reset={reset}
					sx={{ '& .MuiAlert-root': { mb: 1 } }}
				>
					<UserCreateDialogStepper />
					<UserCreateDialogContent />
				</FormWrapper>
			</DialogContent>
			<UserCreateDialogActions />
		</Dialog>
	);
};

export const UserCreateDialogRoute = () => {
	const setOpen = useUpdateAtom(userCreateDialogOpenAtom);

	useEffect(() => {
		setOpen(true);

		return () => {
			setOpen(false);
		};
	}, []);

	return <></>;
};

export default UserCreateDialog;
