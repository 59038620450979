import create from 'zustand';

type IsMasterStoreState = {
	isMaster: boolean;
	setIsMaster: (isMaster: boolean) => void;
};

export const useIsMasterStore = create<IsMasterStoreState>(set => ({
	isMaster: false,
	setIsMaster: isMaster => set({ isMaster })
}));
