import React from 'react';
import ClassInheritStudentList from '../../../../share-components/Class/ClassInheritStudentList';

interface TermCreateDialogStudentsProps {
	classStudents: {
		__typename?: 'Student' | undefined;
		matricId: string;
		user: { __typename?: 'User' | undefined; id: string; cname: string; ename: string };
	}[];
	selectedStudents: string[];
	setSelectedStudents: (selectedStudents: string[]) => void;
}

const TermCreateDialogStudents: React.FC<TermCreateDialogStudentsProps> = ({
	classStudents,
	selectedStudents,
	setSelectedStudents
}) => {
	return (
		<ClassInheritStudentList
			classStudents={classStudents}
			selectedStudents={selectedStudents}
			setSelectedStudents={setSelectedStudents}
		/>
	);
};

export default TermCreateDialogStudents;
