import {
	Admin,
	Category,
	Class,
	Course,
	Lecturer,
	Location,
	Space,
	Student,
	UpdateUserInput,
	UserTableFieldsFragment
} from './generated/api';
import { ToolbarProps } from '@mui/material';

// type Nullable<T> = T | null;

interface HeaderTab {
	name: string;
	link: string;
}

export interface HeaderRoute {
	link: string;
	tabs: Array<HeaderTab>;
}

export type Order = 'asc' | 'desc';

export interface HeadCell {
	id: string;
	disablePadding: boolean;
	label: string;
}

export interface TableListToolbar {
	title: JSX.Element[];
	add?: {
		func: () => void;
		button?: (props: { onClick: () => void }) => JSX.Element;
	};
	edit?: {
		func: (row: any) => void;
		button?: (props: { onClick: () => void }) => JSX.Element;
	};
	delete?: {
		func: (rows: any[]) => void;
		button?: (props: { onClick: () => void }) => JSX.Element;
	};
}

export type TableItemUserType =
	| Omit<UserTableFieldsFragment, 'status'>
	| (UserTableFieldsFragment &
			Omit<Student, '__typename' | 'user' | 'classes' | 'status' | 'phones'> &
			Omit<Lecturer, '__typename' | 'user' | 'courses' | 'status'> &
			Partial<Omit<Admin, '__typename' | 'user' | 'status'>>);

export type TableItemType = TableItemUserType | Category | Course | Class | Location | Space;

export interface TableAction {
	icon: JSX.Element;
	label: string;
	func: (params?: any) => void;
}

export interface UserDialogField {
	title: string;
	entities: {
		title: string;
		content: any;
		api: keyof UpdateUserInput;
	}[];
}

export enum UserDetailsItemKey {
	CNAME,
	ENAME,
	GENDER,
	IC,
	PHONE,
	ADDRESS,
	STATUS,
	EMAIL,
	PASSWORD,
	REGISTER_DATE,
	LAST_LOGIN_TIME,
	STUDENT_MATRIC_ID,
	STUDENT_PHONES,
	STUDENT_STATUS,
	LECTURER_MATRIC_ID,
	LECTURER_STATUS,
	ADMIN_MATRIC_ID,
	ADMIN_STATUS,
	ADMIN_IS_MASTER
}

export enum UserRole {
	STUDENT,
	LECTURER,
	ADMIN
}

export enum ErrCode {
	InternalError = 'INTERNAL_SERVER_ERROR',
	AuthenticationError = 'UNAUTHENTICATED',
	UserInputError = 'BAD_USER_INPUT'
}

export type LoginResponse = {
	access_token: string;
	expiry: number;
};
