import create from 'zustand';
import {
	AdminStatus,
	CreateAdminInput,
	CreateLecturerInput,
	CreateStudentInput,
	LecturerStatus,
	StudentStatus
} from '../generated/api';
import { persistIndexedDB } from './IndexedDB';

export const initialStudent = {
	userId: '',
	matricId: '',
	phones: [],
	status: StudentStatus.Active
};

export const initialLecturer = {
	userId: '',
	matricId: '',
	status: LecturerStatus.Active
};

export const initialAdmin = {
	userId: '',
	matricId: '',
	isMaster: false,
	status: AdminStatus.Active
};

type UserRoleCreateStoreState = {
	student: CreateStudentInput;
	lecturer: CreateLecturerInput;
	admin: CreateAdminInput;
	setStudent: (student: Partial<CreateStudentInput>) => void;
	setLecturer: (lecturer: Partial<CreateLecturerInput>) => void;
	setAdmin: (admin: Partial<CreateAdminInput>) => void;
	resetStudent: () => void;
	resetLecturer: () => void;
	resetAdmin: () => void;
};

export const useUserRoleCreateStore = create<UserRoleCreateStoreState>()(
	persistIndexedDB(
		(set, get) => ({
			student: initialStudent,
			lecturer: initialLecturer,
			admin: initialAdmin,
			setStudent: student => set({ student: { ...get().student, ...student } }),
			setLecturer: lecturer => set({ lecturer: { ...get().lecturer, ...lecturer } }),
			setAdmin: admin => set({ admin: { ...get().admin, ...admin } }),
			resetStudent: () => set({ student: initialStudent }),
			resetLecturer: () => set({ lecturer: initialLecturer }),
			resetAdmin: () => set({ admin: initialAdmin })
		}),
		{
			name: 'user-role-create-dialog'
		}
	)
);
