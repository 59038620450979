import create from 'zustand';
import { AdminStatus, LecturerStatus, StudentStatus, UserStatus } from 'generated/api';

type UserStatusStoreState = {
	userStatus: UserStatus | '0';
	studentStatus: StudentStatus | '0';
	lecturerStatus: LecturerStatus | '0';
	adminStatus: AdminStatus | '0';
	setUserStatus: (userStatus: UserStatus | '0') => void;
	setStudentStatus: (studentStatus: StudentStatus | '0') => void;
	setLecturerStatus: (lecturerStatus: LecturerStatus | '0') => void;
	setAdminStatus: (adminStatus: AdminStatus | '0') => void;
};

export const useUserStatusStore = create<UserStatusStoreState>(set => ({
	userStatus: '0',
	studentStatus: '0',
	lecturerStatus: '0',
	adminStatus: '0',
	setUserStatus: userStatus => set({ userStatus }),
	setStudentStatus: studentStatus => set({ studentStatus }),
	setLecturerStatus: lecturerStatus => set({ lecturerStatus }),
	setAdminStatus: adminStatus => set({ adminStatus })
}));
