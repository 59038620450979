import React from 'react';
import { useUserDetailsRoleCreateStore } from '../../../../stores/useUserDetailsRoleCreateStore';
import shallow from 'zustand/shallow';
import { User, useUpdateUserMutation } from '../../../../generated/api';
import { Button, Paper } from '@mui/material';
import CreateAdminForm from '../../../../share-components/User/CreateAdminForm';
import { useAtomValue } from 'jotai/utils';
import { isMasterAtom } from '../../../../atoms';
import { createAdminUpdate } from '../../update/createAdminUpdate';

interface UserDetailsAdminCreateProps {
	user: User;
}

const UserDetailsAdminCreate: React.FC<UserDetailsAdminCreateProps> = ({
	user
}) => {
	const [admin, setAdmin] = useUserDetailsRoleCreateStore(
		state => [state.admin, state.setAdmin],
		shallow
	);
	const isMaster = useAtomValue(isMasterAtom);
	const [updateUserMutation] = useUpdateUserMutation({
		variables: {
			id: user.id,
			input: {
				admin
			},
			isMaster
		},
		optimisticResponse: {
			__typename: 'Mutation',
			updateUser: {
				...user,
				admin: {
					...admin,
					user: {
						id: user.id
					}
				}
			}
		},
		update: createAdminUpdate
	});

	const handleSubmit = async (event: React.FormEvent<{}>) => {
		event.preventDefault();

		try {
			await updateUserMutation();
		} catch {}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Paper
				variant="outlined"
				sx={{ padding: '20px', background: 'transparent' }}
			>
				<CreateAdminForm admin={admin} setAdmin={setAdmin} />
			</Paper>
			<br />
			<Button
				type="submit"
				variant="contained"
				color="primary"
				fullWidth
				sx={theme => ({
					color: theme.palette.mode === 'dark' ? '#000' : '#fff'
				})}
			>
				添加此用户为管理
			</Button>
		</form>
	);
};

export default UserDetailsAdminCreate;
