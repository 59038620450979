import React, { useEffect, useMemo } from 'react';
import TableList from '../../../common/TableList';
import { useGetClassStudentsQuery } from '../../../../generated/api';
import { HeadCell } from '../../../../types';
import { Box, Button, Theme } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ClassStudentCreateDialog from '../../ClassStudent/ClassStudentCreateDialog';
import ClassStudentDeleteDialog from '../../ClassStudent/ClassStudentDeleteDialog';
import { useUpdateAtom } from 'jotai/utils';
import { classStudentCreateDialogOpenAtom, classStudentDeleteIdsAtom } from '../../../../atoms';
import { TableFilterHolder, useTableFilterStore } from '../../../../stores/useTableFilterStore';
import shallow from 'zustand/shallow';
import { useAtom } from 'jotai';

const headCells: HeadCell[] = [
	{
		id: 'matricId',
		label: '学号',
		disablePadding: false
	},
	{
		id: 'cname',
		label: '中文名',
		disablePadding: false
	},
	{
		id: 'ename',
		label: '英文名',
		disablePadding: false
	}
];

interface ClassDetailsDialogStudentsProps {
	classId: string;
}

const ClassDetailsDialogStudents: React.FC<ClassDetailsDialogStudentsProps> = ({ classId }) => {
	const { data } = useGetClassStudentsQuery({ variables: { id: classId } });
	const navigate = useNavigate();
	const location = useLocation();
	const [selected, setSelected] = useTableFilterStore(
		state => [
			state.filters[TableFilterHolder.CLASS_STUDENT].selected,
			state.setSelected(TableFilterHolder.CLASS_STUDENT)
		],
		shallow
	);
	const selectedCount = selected.length;
	const setCreateDialogOpen = useUpdateAtom(classStudentCreateDialogOpenAtom);
	const [deleteIds, setDeleteIds] = useAtom(classStudentDeleteIdsAtom);

	const classStudents = useMemo(
		() => (data?.node?.__typename === 'Class' ? data.node.students : []),
		[data]
	);
	const students = useMemo(
		() =>
			classStudents.map(s => ({
				id: s.user.id,
				matricId: s.matricId,
				cname: s.user.cname,
				ename: s.user.ename
			})),
		[classStudents]
	);

	useEffect(() => {
		if (deleteIds.length === 0) {
			setSelected([]);
		}
	}, [deleteIds]);

	const handleClick = () => {
		if (!selectedCount) {
			setCreateDialogOpen(true);
		} else {
			setDeleteIds(selected);
		}
	};

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column' }}>
			<Button
				variant="contained"
				sx={{ mb: 2, color: (theme: Theme) => (theme.palette.mode === 'light' ? '#fff' : '#000') }}
				onClick={handleClick}
				color={!selectedCount ? 'primary' : 'error'}
			>
				{!selectedCount ? '添加学生' : `删除选中的${selectedCount}位学生`}
			</Button>
			<TableList
				holder={TableFilterHolder.CLASS_STUDENT}
				items={students}
				headCells={headCells}
				clickFunc={student => {
					navigate(`/users/${student.id}`, {
						state: { backgroundLocation: location }
					});
				}}
				tableStyle={{ minWidth: 150 }}
				selectable
				disableFirstAndLastPageButton
			/>
			<ClassStudentCreateDialog classId={classId} classStudents={classStudents} />
			{/*//@ts-ignore*/}
			<ClassStudentDeleteDialog classId={classId} classStudents={classStudents} />
		</Box>
	);
};

export default ClassDetailsDialogStudents;
