import React, { useDeferredValue, useState } from 'react';
import { useAtom } from 'jotai';
import { classStudentCreateDialogOpenAtom } from '../../../../atoms';
import {
	AppBar,
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	List,
	ListItemButton,
	ListItemText,
	TextField,
	Theme,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { SlideUp } from '../../../../share-components/Transitions/SlideUp';
import { Close } from '@mui/icons-material';
import {
	CreateClassStudentsMutationOptions,
	GetStudentsQuery,
	Student,
	StudentStatus,
	useCreateClassStudentsMutation,
	useGetStudentsQuery,
	User,
	UserStatus
} from '../../../../generated/api';

interface ClassStudentCreateDialogProps {
	classId: string;
	classStudents: (Pick<Student, 'matricId' | 'phones'> & {
		user: Pick<User, 'id' | 'cname' | 'ename' | 'email' | 'gender' | 'ic' | 'phone' | 'status'>;
	})[];
}

const ClassStudentCreateDialog: React.FC<ClassStudentCreateDialogProps> = ({
	classId,
	classStudents
}) => {
	const [open, setOpen] = useAtom(classStudentCreateDialogOpenAtom);
	const theme = useTheme();
	const isXS = useMediaQuery(theme.breakpoints.down('sm'));
	const { data } = useGetStudentsQuery({
		variables: {
			status: StudentStatus.Active
		}
	});
	const [checkedList, setCheckedList] = useState<Array<GetStudentsQuery['students'][0]>>([]);
	const [createClassStudentsMutation] = useCreateClassStudentsMutation();
	const [keyword, setKeyword] = useState('');
	const deferredKeyword = useDeferredValue(keyword);

	const handleClose = () => {
		setOpen(false);
	};

	const handleReset = () => {
		setCheckedList([]);
	};

	const handleToggle = (value: GetStudentsQuery['students'][0]) => () => {
		const currentIndex = checkedList.findIndex(c => c.user.id === value.user.id);
		const newCheckedList = [...checkedList];

		if (currentIndex === -1) {
			newCheckedList.push(value);
		} else {
			newCheckedList.splice(currentIndex, 1);
		}

		setCheckedList(newCheckedList);
	};

	const handleKeywordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setKeyword(event.target.value);
	};

	const handleClick = async () => {
		handleClose();

		let optimisticResponse: CreateClassStudentsMutationOptions['optimisticResponse'] = {
			createClassStudents: {
				__typename: 'Class',
				id: classId,
				students: []
			}
		};

		for (const student of [
			...classStudents,
			...checkedList.map(c => ({
				matricId: c.matricId || '',
				phones: c.phones || [],
				user: c.user,
				__typename: 'Student' as const
			}))
		]) {
			optimisticResponse.createClassStudents?.students.push(student);
		}

		try {
			await createClassStudentsMutation({
				variables: {
					input: {
						classId: classId,
						students: checkedList.map(c => c.user.id)
					}
				},
				optimisticResponse
			});
			handleReset();
		} catch {
			setOpen(true);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="xs"
			fullWidth
			fullScreen={isXS}
			TransitionComponent={isXS ? SlideUp : undefined}
		>
			<AppBar position="static" color="inherit" elevation={!isXS ? 0 : 4}>
				{!isXS ? (
					<DialogTitle>添加班级学生</DialogTitle>
				) : (
					<Toolbar>
						<IconButton onClick={handleClose} size="large">
							<Close />
						</IconButton>
						<Typography variant="h6" sx={{ ml: '10px' }}>
							添加班级学生
						</Typography>
						<Box sx={{ ml: 'auto', mr: 0 }}>
							<Button color="primary" onClick={handleReset}>
								重置
							</Button>
						</Box>
					</Toolbar>
				)}
			</AppBar>
			<DialogContent dividers sx={{ padding: 0, maxHeight: '100%' }}>
				<TextField
					fullWidth
					variant="filled"
					label="搜索学生"
					value={keyword}
					onChange={handleKeywordChange}
					sx={{
						position: 'sticky',
						top: 0,
						zIndex: 10,
						backgroundColor: (theme: Theme) => theme.palette.background.paper
					}}
				/>
				<List>
					{data?.students
						.filter(
							u =>
								u.status !== StudentStatus.Inactive &&
								u.user.status === UserStatus.Active &&
								classStudents?.findIndex(s => s.user.id === u.user.id) === -1 &&
								(deferredKeyword === ''
									? true
									: getObjectValues(u)
											.toString()
											.toLowerCase()
											.indexOf(deferredKeyword.toLowerCase()) > -1)
						)
						.sort((f, s) => Number(f.matricId) - Number(s.matricId))
						.map(student => (
							<ListItemButton key={student.user.id} onClick={handleToggle(student)}>
								<ListItemText>
									{student.matricId} {student.user.cname} {student.user.ename}
								</ListItemText>
								<Checkbox
									color="primary"
									checked={checkedList.findIndex(c => c.user.id === student.user.id) !== -1}
									disableRipple
									tabIndex={-1}
								/>
							</ListItemButton>
						))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>
					取消
				</Button>
				{!isXS && (
					<Button color="primary" onClick={handleReset}>
						重置
					</Button>
				)}
				<Button color="primary" onClick={handleClick}>
					添加
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const getObjectValues = (obj: Record<any, any>): FlatArray<any[], 1>[] =>
	obj && typeof obj === 'object' ? Object.values(obj).map(getObjectValues).flat() : [obj];

export default ClassStudentCreateDialog;
