import React, { useEffect, useMemo } from 'react';
import { courseDeleteItemAtom, courseUpdateIdAtom } from '../../../../atoms';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	TextField,
	Toolbar,
	Tooltip
} from '@mui/material';
import { useCourseUpdateStore } from '../../../../stores/useCourseUpdateStore';
import { useApolloClient } from '@apollo/client';
import { COURSE_FIELDS } from '../../query';
import {
	CourseFieldsFragment,
	CourseTranslation,
	Language,
	UpdateCourseInput,
	useGetCourseTranslationQuery,
	useUpdateCourseMutation
} from '../../../../generated/api';
import { useUserPreferenceStore } from '../../../../stores/useUserPreferenceStore';
import LecturersSelect from '../../../../share-components/LecturersSelect';
import deepEqual from 'fast-deep-equal';
import { Delete } from '@mui/icons-material';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useNavigate, useParams } from 'react-router-dom';
import CourseDeleteDialog from '../CourseDeleteDialog';
import { toast } from 'react-toastify';

interface CourseUpdateDialogProps {}

const CourseUpdateDialog: React.FC<CourseUpdateDialogProps> = () => {
	const courseId = useAtomValue(courseUpdateIdAtom);
	const navigate = useNavigate();
	const {
		translations,
		lecturers: selectedLecturers,
		setTranslationName,
		setTranslationDescription,
		setLecturers,
		setAll,
		reset
	} = useCourseUpdateStore();
	const language = useUserPreferenceStore(state => state.language);
	const otherLang = useMemo(
		() => (language === Language.Zh ? Language.En : Language.Zh),
		[language]
	);
	const { cache } = useApolloClient();
	const course = courseId
		? cache.readFragment<CourseFieldsFragment>({
				id: cache.identify({
					id: courseId,
					__typename: 'Course'
				}),
				fragment: COURSE_FIELDS,
				variables: {
					language
				}
		  })
		: null;
	const { data: transData } = useGetCourseTranslationQuery({
		variables: {
			id: courseId,
			language: otherLang
		},
		skip: !courseId,
		fetchPolicy: 'cache-and-network'
	});
	const courseTranslations = useMemo(() => {
		if (!course || transData?.node?.__typename !== 'Course') {
			return {
				[Language.Zh]: {
					name: '',
					description: ''
				},
				[Language.En]: {
					name: '',
					description: ''
				}
			};
		}

		const { __typename: _, ...main } = course.translation;
		const { __typename: __, ...sub } = transData.node.translation;

		//@ts-ignore
		const translations: Record<Language, CourseTranslation> = {
			[language]: main,
			[otherLang]: sub
		};

		return translations;
	}, [course, transData]);
	const [updateCourseMutation] = useUpdateCourseMutation();
	const setCourseDeleteItem = useUpdateAtom(courseDeleteItemAtom);

	useEffect(() => {
		handleReset();
	}, [course, courseTranslations]);

	const handleClose = () => {
		reset();
		navigate(-1);
	};

	const handleReset = () => {
		if (!course) {
			return;
		}

		setAll({
			translations: courseTranslations,
			lecturers: course.lecturers.map(lecturer => lecturer.user.id)
		});
	};

	const handleNameChange = (language: Language) => (event: React.ChangeEvent<HTMLInputElement>) => {
		setTranslationName(event.target.value, language);
	};

	const handleDescriptionChange =
		(language: Language) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setTranslationDescription(event.target.value, language);
		};

	const handleDelete = () => {
		if (!course) {
			return;
		}
		setCourseDeleteItem({ id: course.id, name: course.translation.name });
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!course) {
			return;
		}

		let input: UpdateCourseInput = {};

		if (!deepEqual(translations, courseTranslations)) {
			input.translations = (Object.keys(translations) as Language[]).map(language => ({
				...translations[language],
				language
			}));
		}

		if (
			[...selectedLecturers].sort().toString() !==
			course.lecturers
				.map(lecturer => lecturer.user.id)
				.sort()
				.toString()
		) {
			input.lecturers = selectedLecturers;
		}

		if (Object.keys(input).length === 0) {
			navigate(-1);
			return;
		}

		try {
			await updateCourseMutation({
				variables: {
					id: courseId,
					input,
					language
				}
			});
			navigate(-1);
			reset();
			toast.success('更改课程成功！');
		} catch (err) {
			toast.error(`更改课程失败！${err}`);
		}
	};

	return (
		<Dialog
			open={!!course}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth
			PaperProps={{
				style: { overflowY: 'hidden' }
			}}
		>
			<Box component="form" onSubmit={handleSubmit}>
				<DialogTitle>{course?.translation.name}</DialogTitle>
				<DialogContent>
					<Grid container spacing={2} sx={{ mt: 0.5 }}>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								label="中文名字"
								name="translations.ZH.name"
								value={translations.ZH.name}
								onChange={handleNameChange(Language.Zh)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								label="英文名字"
								name="translations.EN.name"
								value={translations.EN.name}
								onChange={handleNameChange(Language.En)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								label="中文简介"
								name="translations.ZH.description"
								value={translations.ZH.description}
								onChange={handleDescriptionChange(Language.Zh)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TextField
								variant="outlined"
								label="英文简介"
								name="translations.EN.description"
								value={translations.EN.description}
								onChange={handleDescriptionChange(Language.En)}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sx={{ mt: 1 }}>
							<LecturersSelect selectedLecturers={selectedLecturers} setLecturers={setLecturers} />
						</Grid>
					</Grid>
				</DialogContent>
				<Toolbar>
					<DialogActions sx={{ p: 0, width: '100%' }}>
						<Tooltip title="删除">
							<IconButton onClick={handleDelete} color="error" sx={{ ml: -1, mr: 'auto' }}>
								<Delete />
							</IconButton>
						</Tooltip>
						<Button color="primary" onClick={handleClose}>
							取消
						</Button>
						<Button color="primary" onClick={handleReset}>
							重置
						</Button>
						<Button
							variant="contained"
							color="primary"
							type="submit"
							sx={{ color: theme => (theme.palette.mode === 'light' ? '#fff' : '#000') }}
						>
							保存
						</Button>
					</DialogActions>
				</Toolbar>
			</Box>
			<CourseDeleteDialog />
		</Dialog>
	);
};

export const CourseUpdateDialogRoute: React.FC = () => {
	const { id } = useParams();
	const setCourseUpdateId = useUpdateAtom(courseUpdateIdAtom);

	useEffect(() => {
		if (!id) {
			return;
		}
		setCourseUpdateId(id);
		return () => {
			setCourseUpdateId('');
		};
	}, [id]);

	return <></>;
};

export default CourseUpdateDialog;
