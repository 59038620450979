import create from 'zustand';
import {
	AdminStatus,
	CreateUserAdminInput,
	CreateUserLecturerInput,
	CreateUserStudentInput,
	LecturerStatus,
	StudentStatus
} from '../generated/api';

export const initialStudent = {
	matricId: '',
	phones: [],
	status: StudentStatus.Active
};

export const initialLecturer = {
	matricId: '',
	status: LecturerStatus.Active
};

export const initialAdmin = {
	matricId: '',
	isMaster: false,
	status: AdminStatus.Active
};

type UserDetailsRoleCreateStoreState = {
	student: CreateUserStudentInput;
	lecturer: CreateUserLecturerInput;
	admin: CreateUserAdminInput;
	setStudent: (student: Partial<CreateUserStudentInput>) => void;
	setLecturer: (lecturer: Partial<CreateUserLecturerInput>) => void;
	setAdmin: (admin: Partial<CreateUserAdminInput>) => void;
	reset: () => void;
};

export const useUserDetailsRoleCreateStore =
	create<UserDetailsRoleCreateStoreState>((set, get) => ({
		student: initialStudent,
		lecturer: initialLecturer,
		admin: initialAdmin,
		setStudent: student => set({ student: { ...get().student, ...student } }),
		setLecturer: lecturer =>
			set({ lecturer: { ...get().lecturer, ...lecturer } }),
		setAdmin: admin => set({ admin: { ...get().admin, ...admin } }),
		reset: () =>
			set({
				student: initialStudent,
				lecturer: initialLecturer,
				admin: initialAdmin
			})
	}));
