import create from 'zustand';
import { ClassTranslation, Day, Language } from 'generated/api';
import produce from 'immer';

export enum ClassTermAutoGenerateMethod {
	BY_MONTH = 'byMonth'
}

export type ClassTermAutoGeneration = {
	[ClassTermAutoGenerateMethod.BY_MONTH]?: {
		day: Day;
		startTime: Date;
		endTime: Date;
	};
};

type ClassUpdateStoreStateValue = {
	tabIndex: string;
	translations: {
		[x in Language]: Omit<ClassTranslation, '__typename'>;
	};
	studentFeePerLesson: number;
	lecturerCommission: number;
	spaceId?: string;
	lecturers: string[];
	color: string;
	termAutoGenerationAvailability: boolean;
	termAutoGenerateMethod: ClassTermAutoGenerateMethod;
	termAutoGeneration: ClassTermAutoGeneration;
};

type ClassUpdateStoreState = {
	setTabIndex: (tabIndex: string) => void;
	setName: (name: string, language: Language) => void;
	setDescription: (description: string, language: Language) => void;
	setStudentFeePerLesson: (studentFeePerLesson: number) => void;
	setLecturerCommission: (lecturerCommission: number) => void;
	setSpaceId: (spaceId: string) => void;
	setLecturers: (lecturers: string[]) => void;
	setColor: (color: string) => void;
	setTermAutoGenerationAvailability: (termAutoGenerationAvailability: boolean) => void;
	setTermAutoGenerateMethod: (termAutoGenerateMethod: ClassTermAutoGenerateMethod) => void;
	setTermAutoGeneration: (termAutoGeneration: ClassTermAutoGeneration) => void;
	setAll: (data: Omit<ClassUpdateStoreStateValue, 'tabIndex'>) => void;
	getValues: () => Omit<ClassUpdateStoreStateValue, 'tabIndex'>;
} & ClassUpdateStoreStateValue;

export const useClassUpdateStore = create<ClassUpdateStoreState>((set, get) => ({
	tabIndex: '1',
	translations: {
		[Language.Zh]: {
			name: '',
			description: ''
		},
		[Language.En]: {
			name: '',
			description: ''
		}
	},
	studentFeePerLesson: 0,
	lecturerCommission: 0,
	spaceId: undefined,
	lecturers: [],
	color: '#188fff',
	termAutoGenerationAvailability: true,
	termAutoGenerateMethod: ClassTermAutoGenerateMethod.BY_MONTH,
	termAutoGeneration: {
		[ClassTermAutoGenerateMethod.BY_MONTH]: {
			day: Day.Saturday,
			startTime: new Date(),
			endTime: new Date(new Date().getTime() + 3_600_000)
		}
	},
	setTabIndex: tabIndex => set({ tabIndex }),
	setName: (name, language) =>
		set(
			produce(draft => {
				draft.translations[language].name = name;
			})
		),
	setDescription: (description, language) =>
		set(
			produce(draft => {
				draft.translations[language].description = description;
			})
		),
	setStudentFeePerLesson: studentFeePerLesson => set({ studentFeePerLesson }),
	setLecturerCommission: lecturerCommission => set({ lecturerCommission }),
	setSpaceId: spaceId => set({ spaceId }),
	setLecturers: lecturers => set({ lecturers }),
	setColor: color => set({ color }),
	setTermAutoGenerationAvailability: termAutoGenerationAvailability =>
		set({ termAutoGenerationAvailability }),
	setTermAutoGenerateMethod: termAutoGenerateMethod => set({ termAutoGenerateMethod }),
	setTermAutoGeneration: termAutoGeneration => set({ termAutoGeneration }),
	setAll: data => set({ ...data }),
	getValues: () => {
		const data = get();

		return {
			translations: data.translations,
			studentFeePerLesson: data.studentFeePerLesson,
			lecturerCommission: data.lecturerCommission,
			spaceId: data.spaceId,
			lecturers: data.lecturers,
			color: data.color,
			termAutoGenerationAvailability: data.termAutoGenerationAvailability,
			termAutoGenerateMethod: data.termAutoGenerateMethod,
			termAutoGeneration: data.termAutoGeneration
			// template: data.template,
			// templateAvailability: data.templateAvailability
		};
	}
}));
