import React, { useEffect, useMemo } from 'react';
import { Box, Breadcrumbs, Button, Link, Typography } from '@mui/material';
import { Relay } from '../../../utils/relay';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import {
	UpdateInvoiceInput,
	useCreateReceivablesMutation,
	useGetInvoiceQuery,
	useUpdateInvoiceMutation
} from '../../../generated/api';
import InvoiceForm from '../../../share-components/Invoice/InvoiceForm';
import shallow from 'zustand/shallow';
import { useInvoiceUpdateStore } from '../../../stores/useInvoiceUpdateStore';
import { NavigateNext } from '@mui/icons-material';
import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

interface InvoiceEditPageProps {}

const InvoiceEditPage: React.FC<InvoiceEditPageProps> = () => {
	const params = useParams();
	const id = params['id'];
	const { data } = useGetInvoiceQuery({
		variables: { id: Relay.marshalId('Invoice', id || '') },
		skip: id === ''
	});
	const invoice = useMemo(() => (data?.node?.__typename === 'Invoice' ? data.node : null), [data]);
	const [
		debtor,
		createAt,
		discount,
		tax,
		items,
		setDebtor,
		setCreateAt,
		setDiscount,
		setTax,
		addItems,
		setItemTitle,
		setItemDescription,
		setItemService,
		setItemQuantity,
		setItemPrice,
		removeItem,
		setAll,
		reset
	] = useInvoiceUpdateStore(
		state => [
			state.debtor,
			state.createAt,
			state.discount,
			state.tax,
			state.items,
			state.setDebtor,
			state.setCreateAt,
			state.setDiscount,
			state.setTax,
			state.addItems,
			state.setItemTitle,
			state.setItemDescription,
			state.setItemService,
			state.setItemQuantity,
			state.setItemPrice,
			state.removeItem,
			state.setAll,
			state.reset
		],
		shallow
	);
	const [updateInvoiceMutation] = useUpdateInvoiceMutation();
	const [createReceivablesMutation] = useCreateReceivablesMutation();
	const navigate = useNavigate();

	if (!invoice) {
		return null;
	}

	useEffect(() => {
		setAll({
			...invoice,
			createAt: invoice.createdAt,
			debtor: invoice.user,
			items: invoice.items.map(item => ({
				id: item.receivable.id,
				title: item.receivable.name,
				description: item.description,
				service: item.receivable.type,
				quantity: item.receivable.quantity,
				price: item.receivable.price,
				status: item.receivable.status,
				receivable: item.receivable
			}))
		});
	}, [invoice]);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!debtor) {
			toast.error('请选择一个付款者');
			return;
		}

		if (items.length === 0) {
			toast.error('请至少添加一个ITEM');
			return;
		}

		try {
			let input: UpdateInvoiceInput = {};

			if (debtor.id !== invoice.user.id) {
				input.userId = debtor.id;
			}

			if (invoice.tax !== tax) {
				input.tax = tax;
			}

			if (invoice.discount !== discount) {
				input.discount = discount;
			}

			if (createAt) {
				if (
					new Date(invoice.createdAt).toLocaleDateString() !==
					new Date(createAt).toLocaleDateString()
				) {
					input.createdAt = createAt;
				}
			}

			if (
				JSON.stringify(
					items.map(item => ({ id: item.id, description: item.description })).sort()
				) !==
				JSON.stringify(
					invoice.items
						.map(item => ({ id: item.receivable.id, description: item.description }))
						.sort()
				)
			) {
				const newReceivables = items.filter(item => !item.receivable);
				input.items = items
					.filter(item => !!item.receivable)
					.map(item => ({ id: item.id, description: item.description }));

				if (newReceivables.length > 0) {
					const res = await createReceivablesMutation({
						variables: {
							input: newReceivables.map(item => ({
								id: item.id,
								name: item.title,
								type: item.service!,
								quantity: item.quantity,
								price: item.price,
								debtor: debtor.id
							}))
						}
					});

					if (!res.data?.createReceivables) {
						return;
					}

					input.items.push(
						...res.data.createReceivables.map(receivable => ({
							id: receivable.id,
							description:
								newReceivables.find(r => r.id === Relay.unmarshalId(receivable.id))?.description ||
								''
						}))
					);
				}
			}

			if (Object.keys(input).length === 0) {
				return;
			}

			await updateInvoiceMutation({
				variables: {
					id: invoice.id,
					input
				}
			});

			toast.success('成功更改发票');

			navigate('..');
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(err.message);
			} else {
				toast.error(`${err}`);
			}
		}
	};

	return (
		<Box sx={{ mt: 2, mb: 8 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
					<Link
						to="/"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						主页
					</Link>
					<Link
						to="/payment-management/invoices"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						发票
					</Link>
					<Link
						to=".."
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						{id || ''}
					</Link>
					<Typography variant="body2" sx={{ fontSize: '1.1rem', opacity: 0.7 }}>
						更改
					</Typography>
				</Breadcrumbs>
			</Box>
			<form onSubmit={handleSubmit}>
				<InvoiceForm
					id={id || ''}
					debtor={debtor}
					createAt={createAt}
					discount={discount}
					tax={tax}
					items={items}
					setDebtor={setDebtor}
					setCreateAt={setCreateAt}
					setDiscount={setDiscount}
					setTax={setTax}
					setItemTitle={setItemTitle}
					setItemDescription={setItemDescription}
					setItemService={setItemService}
					setItemQuantity={setItemQuantity}
					setItemPrice={setItemPrice}
					addItems={addItems}
					removeItem={removeItem}
				/>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						size="large"
						sx={{
							color: theme => (theme.palette.mode === 'light' ? '#fff' : '#000'),
							borderRadius: '8px'
						}}
					>
						保存更改
					</Button>
				</Box>
			</form>
		</Box>
	);
};

export default InvoiceEditPage;
