import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { useAtom } from 'jotai';
import { headerTitleAtom } from '../../atoms';
import { styled } from '@mui/material/styles';

interface HeaderTitleProps {}

const HeaderTitle: React.FC<HeaderTitleProps> = () => {
	const [title] = useAtom(headerTitleAtom);
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<>
			{!mdDown ? (
				<Title variant="h4" color="primary">
					{title}
				</Title>
			) : (
				<AppBarTitle variant="h6" noWrap>
					{title}
				</AppBarTitle>
			)}
		</>
	);
};

const Title = styled(Typography)({
	fontWeight: 'bold',
	padding: '0.6em 0 0.6em 0'
});

const AppBarTitle = styled(Typography)({
	marginLeft: '12px'
});

export default HeaderTitle;
