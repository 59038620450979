import React from 'react';
import { createRoot } from 'react-dom/client';
// import reportWebVitals from './reportWebVitals';
import Providers from './providers';
import './index.css';
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import { enableMapSet } from 'immer';
// import { register } from './serviceWorkerRegistration';

enableMapSet();

const root = createRoot(document.getElementById('root')!);

root.render(
	<React.StrictMode>
		<Providers>
			<BrowserRouter>
				<Routes />
			</BrowserRouter>
		</Providers>
	</React.StrictMode>
);

// if ('serviceWorker' in navigator) {
// 	const intervalMS = 60 * 60 * 1000;
//
// 	useRegisterSW({
// 		onRegistered(r) {
// 			r &&
// 				setInterval(() => {
// 					r.update().then(() => {});
// 				}, intervalMS);
// 		}
// 	});
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
// register();
