import { MutationUpdaterFn } from '@apollo/client';
import {
	CreateUserMutation,
	GetAdminsQuery,
	GetCurrentUserQuery,
	GetLecturersQuery,
	GetStudentsQuery,
	GetUsersQuery,
	UserRole
} from '../../../generated/api';
import { GET_ADMINS, GET_LECTURERS, GET_STUDENTS, GET_USERS } from '../query';
import { GET_CURRENT_USER } from '../../../query';

export const createUserUpdate: MutationUpdaterFn<CreateUserMutation> = (
	cache,
	mutationResult
) => {
	const createdUser = mutationResult.data?.createUser;

	if (!createdUser) {
		return;
	}

	const currentUserData = cache.readQuery<GetCurrentUserQuery>({
		query: GET_CURRENT_USER
	});

	if (currentUserData) {
		const isMaster = currentUserData?.currentUser?.admin?.isMaster;
		const variables = {
			status: createdUser.status,
			roleFilter: {
				roles: !isMaster ? [UserRole.Student, UserRole.Lecturer] : undefined,
				includeNoRole: true
			}
		};

		const data = cache.readQuery<GetUsersQuery>({
			query: GET_USERS,
			variables
		});

		if (data) {
			const users = data.users || [];

			cache.writeQuery({
				query: GET_USERS,
				variables,
				data: {
					users: [...users, createdUser]
				}
			});
		}

		const user = { ...createdUser };
		delete user.student;
		delete user.lecturer;
		delete user.admin;

		const student = createdUser.student;

		if (student) {
			const variables = {
				status: student.status
			};

			const data = cache.readQuery<GetStudentsQuery>({
				query: GET_STUDENTS,
				variables
			});

			if (data) {
				cache.writeQuery({
					query: GET_STUDENTS,
					variables,
					data: {
						students: [
							...data.students,
							{
								...student,
								user
							}
						]
					}
				});
			}
		}

		const lecturer = createdUser.lecturer;

		if (lecturer) {
			const variables = {
				status: lecturer.status
			};

			const data = cache.readQuery<GetLecturersQuery>({
				query: GET_LECTURERS,
				variables
			});

			if (data) {
				cache.writeQuery({
					query: GET_LECTURERS,
					variables,
					data: {
						lecturers: [
							...data.lecturers,
							{
								...lecturer,
								user
							}
						]
					}
				});
			}
		}

		const admin = createdUser.admin;

		if (admin) {
			const variables = {
				status: admin.status
			};

			const data = cache.readQuery<GetAdminsQuery>({
				query: GET_ADMINS,
				variables
			});

			if (data) {
				cache.writeQuery({
					query: GET_ADMINS,
					variables,
					data: {
						admins: [
							...data.admins,
							{
								...admin,
								user
							}
						]
					}
				});
			}
		}
	}
};
