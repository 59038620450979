import create from 'zustand';
import { CourseTranslation, Language } from '../generated/api';
import produce from 'immer';

const initialState: Pick<CourseUpdateStoreState, 'translations' | 'lecturers'> = {
	translations: {
		[Language.Zh]: {
			name: '',
			description: ''
		},
		[Language.En]: {
			name: '',
			description: ''
		}
	},
	lecturers: []
};

type CourseUpdateStoreState = {
	translations: {
		[x in Language]: Omit<CourseTranslation, '__typename'>;
	};
	lecturers: string[];
	setTranslationName: (name: string, language: Language) => void;
	setTranslationDescription: (description: string, language: Language) => void;
	setLecturers: (lecturers: string[]) => void;
	addLecturers: (lecturers: string[]) => void;
	removeLecturer: (lecturerId: string) => void;
	setAll: (data: Pick<CourseUpdateStoreState, 'translations' | 'lecturers'>) => void;
	reset: () => void;
};

export const useCourseUpdateStore = create<CourseUpdateStoreState>(set => ({
	...initialState,
	setTranslationName: (name, language) =>
		set(
			produce<CourseUpdateStoreState>(draft => {
				draft.translations[language].name = name;
			})
		),
	setTranslationDescription: (description, language) =>
		set(
			produce<CourseUpdateStoreState>(draft => {
				draft.translations[language].description = description;
			})
		),
	setLecturers: lecturers => set({ lecturers }),
	addLecturers: lecturers =>
		set(
			produce<CourseUpdateStoreState>(draft => {
				draft.lecturers.push(...lecturers);
			})
		),
	removeLecturer: lecturerId =>
		set(
			produce<CourseUpdateStoreState>(draft => {
				const index = draft.lecturers.findIndex(l => l === lecturerId);
				if (index !== -1) {
					draft.lecturers.splice(index, 1);
				}
			})
		),
	setAll: data => set({ ...data }),
	reset: () => set({ ...initialState })
}));
