import gql from 'graphql-tag';
import { ADMIN_FIELDS, LECTURER_FIELDS, STUDENT_FIELDS, USER_FIELDS } from '../../../query';

export const USER_TABLE_FIELDS = gql`
	${STUDENT_FIELDS}
	${LECTURER_FIELDS}
	fragment UserTableFields on User {
		id
		cname
		ename
		email
		gender
		ic
		phone
		status
		student {
			user {
				id
			}
			...StudentFields
		}
		lecturer {
			user {
				id
			}
			...LecturerFields
		}
	}
`;

export const GET_USERS = gql`
	${USER_TABLE_FIELDS}
	query GetUsers($status: UserStatus, $roleFilter: UserRoleFilterInput) {
		users(status: $status, roleFilter: $roleFilter) {
			...UserTableFields
		}
	}
`;

export const GET_STUDENTS = gql`
	${USER_TABLE_FIELDS}
	${STUDENT_FIELDS}
	query GetStudents($status: StudentStatus) {
		students(status: $status) {
			...StudentFields
			user {
				...UserTableFields
			}
		}
	}
`;

export const GET_LECTURERS = gql`
	${USER_TABLE_FIELDS}
	${LECTURER_FIELDS}
	query GetLecturers($status: LecturerStatus) {
		lecturers(status: $status) {
			...LecturerFields
			user {
				...UserTableFields
			}
		}
	}
`;

export const GET_ADMINS = gql`
	${USER_TABLE_FIELDS}
	${ADMIN_FIELDS}
	query GetAdmins($status: AdminStatus) {
		admins(status: $status) {
			...AdminFields
			user {
				...UserTableFields
			}
		}
	}
`;

export const GET_USER = gql`
	${USER_FIELDS}
	${STUDENT_FIELDS}
	${LECTURER_FIELDS}
	${ADMIN_FIELDS}
	query GetUser($id: ID!, $isMaster: Boolean!) {
		node(id: $id) {
			... on User {
				...UserFields
				student {
					user {
						id
					}
					...StudentFields
				}
				lecturer {
					user {
						id
					}
					...LecturerFields
				}
				admin @include(if: $isMaster) {
					user {
						id
					}
					...AdminFields
				}
			}
		}
	}
`;

export const CREATE_USER_MUTATION = gql`
	${USER_FIELDS}
	${STUDENT_FIELDS}
	${LECTURER_FIELDS}
	${ADMIN_FIELDS}
	mutation CreateUser($input: CreateUserInput!, $isMaster: Boolean!) {
		createUser(input: $input) {
			...UserFields
			student {
				user {
					id
				}
				...StudentFields
			}
			lecturer {
				user {
					id
				}
				...LecturerFields
			}
			admin @include(if: $isMaster) {
				user {
					id
				}
				...AdminFields
			}
		}
	}
`;

export const UPDATE_USER_MUTATION = gql`
	${USER_FIELDS}
	${STUDENT_FIELDS}
	${LECTURER_FIELDS}
	${ADMIN_FIELDS}
	mutation UpdateUser($id: ID!, $input: UpdateUserInput!, $isMaster: Boolean!) {
		updateUser(id: $id, input: $input) {
			...UserFields
			student {
				user {
					id
				}
				...StudentFields
			}
			lecturer {
				user {
					id
				}
				...LecturerFields
			}
			admin @include(if: $isMaster) {
				user {
					id
				}
				...AdminFields
			}
		}
	}
`;

export const DELETE_USER_MUTATION = gql`
	mutation DeleteUser($id: ID!) {
		deleteUser(id: $id) {
			id
		}
	}
`;

export const CREATE_STUDENT_MUTATION = gql`
	${USER_FIELDS}
	${STUDENT_FIELDS}
	mutation CreateStudent($input: CreateStudentInput!) {
		createStudent(input: $input) {
			...StudentFields
			user {
				...UserFields
			}
		}
	}
`;

export const CREATE_LECTURER_MUTATION = gql`
	${USER_FIELDS}
	${LECTURER_FIELDS}
	mutation CreateLecturer($input: CreateLecturerInput!) {
		createLecturer(input: $input) {
			...LecturerFields
			user {
				...UserFields
			}
		}
	}
`;

export const CREATE_ADMIN_MUTATION = gql`
	${USER_FIELDS}
	${ADMIN_FIELDS}
	mutation CreateAdmin($input: CreateAdminInput!) {
		createAdmin(input: $input) {
			...AdminFields
			user {
				...UserFields
			}
		}
	}
`;

// export const GET_USERS_WITH_ROLES = gql`
// 	${GET_USER_TABLE_FIELD_FRAGMENT}
// 	query GetUsersWithRoles($roleFilter: UserRoleFilterInput) {
// 		users(roleFilter: $roleFilter) {
// 			...GetUserTableField_user
// 			student {
// 				matricId
// 			}
// 			lecturer {
// 				matricId
// 			}
// 			admin {
// 				matricId
// 				isMaster
// 			}
// 		}
// 	}
// `;
