import React, { useMemo, useState } from 'react';
import {
	Box,
	Breadcrumbs,
	IconButton,
	Link,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography
} from '@mui/material';
import { Download, Edit, NavigateNext, Print, Send, Share, Visibility } from '@mui/icons-material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { InvoiceStatus, useGetInvoiceQuery } from '../../../generated/api';
import { Relay } from '../../../utils/relay';
import SimpleBar from 'simplebar-react';
import { useRefetchHandler } from '../../../hooks/useRefetchHandler';
import InvoicePdf from './InvoicePdf';
import InvoiceStatusChip from './InvoiceStatusChip';
import { BlobProvider } from '@react-pdf/renderer';
import InvoicePdfDialog from './InvoicePdfDialog';
import { toast } from 'react-toastify';
import InvoiceDetailsPageActions from './InvoiceDetailsPageActions';

interface InvoiceDetailsPageProps {}

const InvoiceDetailsPage: React.FC<InvoiceDetailsPageProps> = () => {
	const params = useParams();
	const id = params['id'];
	const { data, refetch, startPolling, stopPolling } = useGetInvoiceQuery({
		variables: { id: Relay.marshalId('Invoice', id || '') },
		skip: id === ''
	});
	const invoice = useMemo(() => (data?.node?.__typename === 'Invoice' ? data.node : null), [data]);
	const [open, setOpen] = useState(false);
	const invoiceFileName = useMemo(
		() => (invoice ? `${Relay.unmarshalId(invoice.id)} - ${invoice.user.ename}` : ''),
		[invoice]
	);

	useRefetchHandler(refetch, startPolling, stopPolling);

	if (!invoice) {
		return null;
	}

	const handleViewClick = () => {
		setOpen(true);
	};

	const handleShare = (blob: Blob | null) => async () => {
		if (!blob) {
			return;
		}

		try {
			await navigator.share({
				files: [new File([blob], `${invoiceFileName}.pdf`, { type: blob.type })],
				title: invoiceFileName
			});
		} catch (err) {
			toast.error(`${err}`);
		}
	};

	return (
		<Box sx={{ mt: 2, mb: 8 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
					<Link
						to="/"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						主页
					</Link>
					<Link
						to="/payment-management/invoices"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						发票
					</Link>
					<Typography variant="body2" sx={{ fontSize: '1.1rem', opacity: 0.7 }}>
						{id}
					</Typography>
				</Breadcrumbs>
			</Box>
			<Box
				sx={theme => ({
					display: 'flex',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
					alignItems: 'center',
					mt: 6,
					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',
						alignItems: 'flex-start'
					}
				})}
			>
				<Box
					sx={{
						display: 'flex',
						'& > .MuiIconButton-root': {
							mr: 1
						}
					}}
				>
					{invoice.status === InvoiceStatus.Draft && (
						<IconButton component={RouterLink} to="edit">
							<Edit />
						</IconButton>
					)}
					<IconButton onClick={handleViewClick}>
						<Visibility />
					</IconButton>
					<BlobProvider document={<InvoicePdf invoice={invoice} />}>
						{({ blob, url, loading, error }) => (
							<>
								<IconButton component="a" href={url || ''} download={invoiceFileName}>
									<Download />
								</IconButton>
								<IconButton component="a" href={url || ''} target="_blank">
									<Print />
								</IconButton>
								<IconButton>
									<Send />
								</IconButton>
								{!!navigator.canShare && (
									<IconButton onClick={handleShare(blob)}>
										<Share />
									</IconButton>
								)}
							</>
						)}
					</BlobProvider>
				</Box>
				<Box
					sx={theme => ({
						[theme.breakpoints.down('sm')]: {
							ml: 'auto',
							mr: 0,
							mt: 2
						}
					})}
				>
					<InvoiceDetailsPageActions invoice={invoice} />
				</Box>
			</Box>
			<Paper
				sx={{
					mt: 6,
					minHeight: 600,
					borderRadius: '16px',
					p: 4,
					boxShadow: theme =>
						theme.palette.mode === 'light'
							? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
							: 'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px'
				}}
			>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={theme => ({
							display: 'flex',
							flexWrap: 'nowrap',
							[theme.breakpoints.down('md')]: {
								flexWrap: 'wrap'
							},
							flex: 1
						})}
					>
						<img
							src="/images/ekids_logo.webp"
							css={{
								maxWidth: 200,
								height: 'max-content',
								marginRight: 16,
								marginBottom: 16,
								'@media(max-width:900px)': {
									maxWidth: 160,
									marginBottom: 8
								}
							}}
						/>
						<Box sx={{ mb: 2 }}>
							<Typography variant="h6">
								ELEMENTE EDUCATION SDN. BHD.{' '}
								<span css={{ fontSize: '0.875rem', fontWeight: 'normal' }}>(Co. No.1280836-M)</span>
							</Typography>
							<Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
								Level 2, L2.27 KL GATEWAY MALL.
								<br />
								2, Jalan Kerinchi, Kampung Kerinchi, 59200 Kuala Lumpur, Wilayah Persekutuan Kuala
								Lumpur, Malaysia.
							</Typography>
							<Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
								H/P: 012-336 6124
							</Typography>
						</Box>
					</Box>
					<Box>
						<InvoiceStatusChip status={invoice.status} />
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						flexWrap: 'wrap'
					}}
				>
					<Box sx={{ mt: 1, width: 360, maxWidth: '100%' }}>
						<Typography variant="h6" sx={{ opacity: 0.5, fontSize: '0.875rem !important', mb: 1 }}>
							INVOICE TO
						</Typography>
						<Typography variant="subtitle1" sx={{ my: 1 }}>
							{invoice.user.cname}
							{invoice.user.cname !== '' ? ' ' : ''}
							{invoice.user.ename}
						</Typography>
						<Typography variant="subtitle1">{invoice.user.address}</Typography>
					</Box>
					<Box
						sx={theme => ({
							display: 'flex',
							flexDirection: 'column',
							mt: 2,
							[theme.breakpoints.down('md')]: {
								width: '100%',
								mt: 4
							}
						})}
					>
						<Typography variant="h6" sx={{ opacity: 0.5, fontSize: '0.875rem !important', mb: 1 }}>
							INVOICE NO
						</Typography>
						<Typography variant="body1">{Relay.unmarshalId(invoice.id)}</Typography>
						<Typography
							variant="h6"
							sx={{ opacity: 0.5, fontSize: '0.875rem !important', mb: 1, mt: 4 }}
						>
							DATE CREATE
						</Typography>
						<Typography variant="body1">
							{new Date(invoice.createdAt).toLocaleDateString('en-MY')}
						</Typography>
					</Box>
				</Box>
				<SimpleBar>
					<Table sx={{ mt: 6 }}>
						<TableHead>
							<TableRow
								sx={{
									'& > .MuiTableCell-root': {
										opacity: 0.7
									}
								}}
							>
								<TableCell>#</TableCell>
								<TableCell>Description</TableCell>
								<TableCell align="right">Quantity</TableCell>
								<TableCell align="right">Unit Price</TableCell>
								<TableCell align="right">Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{invoice.items.map((item, index) => (
								<TableRow key={item.receivable.id}>
									<TableCell>{index + 1}</TableCell>
									<TableCell>
										<Box sx={{ maxWidth: 560 }}>
											<Typography variant="h6" sx={{ fontSize: '1rem !important' }}>
												{item.receivable.name}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													opacity: 0.6,
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap',
													overflow: 'hidden'
												}}
											>
												{item.description}
											</Typography>
										</Box>
									</TableCell>
									<TableCell align="right">{item.receivable.quantity}</TableCell>
									<TableCell align="right">{item.receivable.price}</TableCell>
									<TableCell align="right">
										{item.receivable.quantity * item.receivable.price}
									</TableCell>
								</TableRow>
							))}
							<TableRow
								sx={{
									'& > td': {
										borderBottom: 'none'
									}
								}}
							>
								<TableCell colSpan={3} />
								<TableCell align="right">
									<Box sx={{ mt: 4 }} />
									<Typography variant="body1">Subtotal</Typography>
								</TableCell>
								<TableCell align="right">
									<Box sx={{ mt: 4 }} />
									<Typography variant="body1">
										RM
										{invoice.items
											.reduce(
												(total, item) => total + item.receivable.price * item.receivable.quantity,
												0
											)
											.toFixed(2)}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow
								sx={{
									'& > td': {
										borderBottom: 'none'
									}
								}}
							>
								<TableCell colSpan={3} />
								<TableCell align="right">
									<Typography variant="body1">Discount</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="body1" color={invoice.discount ? 'error' : 'inherit'}>
										{invoice.discount ? `- RM${invoice.discount.toFixed(2)}` : '-'}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow
								sx={{
									'& > td': {
										borderBottom: 'none'
									}
								}}
							>
								<TableCell colSpan={3} />
								<TableCell align="right">
									<Typography variant="body1">Taxes</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="body1">
										{invoice.tax ? `RM${invoice.tax.toFixed(2)}` : '-'}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow
								sx={{
									'& > td': {
										borderBottom: 'none'
									}
								}}
							>
								<TableCell colSpan={3} />
								<TableCell align="right">
									<Typography variant="h6">Total</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h6">
										{(() => {
											if (invoice.amount < 0) {
												return `- RM${(-invoice.amount).toFixed(2)}`;
											} else {
												return `RM${invoice.amount.toFixed(2)}`;
											}
										})()}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</SimpleBar>
			</Paper>
			<InvoicePdfDialog open={open} setOpen={setOpen} invoice={invoice} />
		</Box>
	);
};

export default InvoiceDetailsPage;
