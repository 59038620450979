import React from 'react';
import { AppBar, Box, Button, DialogTitle, IconButton, Toolbar, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

interface ClassCreateDialogHeaderProps {
	handleClose: () => void;
	handleReset: () => void;
	isXS: boolean;
}

const ClassCreateDialogHeader: React.FC<ClassCreateDialogHeaderProps> = ({
	handleClose,
	handleReset,
	isXS
}) => {
	return (
		<>
			{isXS ? (
				<>
					<AppBar color="inherit" position={!isXS ? 'static' : 'fixed'}>
						<Toolbar>
							<IconButton onClick={handleClose} size="large">
								<Close />
							</IconButton>
							<Typography variant="h6" sx={{ ml: '10px' }}>
								创建班级
							</Typography>
							<Box sx={{ ml: 'auto', mr: 0 }}>
								<Button color="primary" onClick={handleReset}>
									重置
								</Button>
							</Box>
						</Toolbar>
					</AppBar>
					<Toolbar />
				</>
			) : (
				<DialogTitle>创建班级</DialogTitle>
			)}
		</>
	);
};

export default ClassCreateDialogHeader;
