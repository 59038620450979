import React, { useEffect, useState } from 'react';
import { ApolloError } from '@apollo/client';
import { Loading } from './SharedComponent';
import { Alert, Box, BoxProps } from '@mui/material';

interface FormWrapperProps extends BoxProps {
	loading: boolean;
	error: ApolloError | undefined;
	reset?: () => void;
}

const FormWrapper: React.FC<FormWrapperProps> = ({ loading, error, reset, children, ...props }) => {
	return (
		<Box {...props} sx={{ position: 'relative', height: '100%', width: '100%', ...props.sx }}>
			{error && (
				<Alert severity="error" onClose={reset}>
					{error.message}
				</Alert>
			)}
			{children}
			{loading && (
				<Loading
					sx={{
						height: '100%',
						position: 'absolute',
						top: 0,
						left: 0,
						zIndex: 100,
						backgroundColor: theme => theme.palette.background.paper
					}}
				/>
			)}
		</Box>
	);
};

export default FormWrapper;
