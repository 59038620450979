import React, { useEffect } from 'react';
import {
	Box,
	Button,
	FormControlLabel,
	Grid,
	MenuItem,
	Paper,
	Switch,
	SwitchProps,
	TextField,
	Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { AddAPhoto } from '@mui/icons-material';
import {
	Gender,
	UpdateMyProfileInput,
	useGetCurrentUserQuery,
	useUpdateMyProfileMutation
} from '../../generated/api';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useRefetchHandler } from '../../hooks/useRefetchHandler';

type FormValues = UpdateMyProfileInput;

interface GeneralProps {}

const General: React.FC<GeneralProps> = () => {
	const { data, refetch, startPolling, stopPolling } = useGetCurrentUserQuery();
	const { handleSubmit, control, reset } = useForm<FormValues>({
		defaultValues: data?.currentUser
			? {
					cname: data.currentUser.cname,
					ename: data.currentUser.ename,
					email: data.currentUser.email,
					phone: data.currentUser.phone,
					gender: data.currentUser.gender,
					ic: data.currentUser.ic,
					address: data.currentUser.address
			  }
			: { gender: Gender.Female }
	});
	const [updateMyProfileMutation] = useUpdateMyProfileMutation();

	useRefetchHandler(refetch, startPolling, stopPolling);

	const onSubmit: SubmitHandler<FormValues> = async values => {
		if (!data) {
			return;
		}

		try {
			const { errors } = await updateMyProfileMutation({
				variables: {
					input: values
				}
			});

			if (errors) {
				toast.error('保存更改失败！');
			} else {
				toast.success('保存更改成功！');
			}
		} catch {}
	};

	useEffect(() => {
		if (!data) {
			return;
		}
		reset({
			cname: data.currentUser.cname,
			ename: data.currentUser.ename,
			email: data.currentUser.email,
			phone: data.currentUser.phone,
			gender: data.currentUser.gender,
			ic: data.currentUser.ic,
			address: data.currentUser.address
		});
	}, [data, reset]);

	if (!data) {
		return null;
	}

	return (
		<Grid container spacing={3}>
			<Grid item xs={12} md={4}>
				<Paper
					elevation={0}
					sx={{
						borderRadius: '16px',
						boxShadow:
							'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
						py: 10,
						px: 3
					}}
				>
					<div>
						<Box
							component="label"
							htmlFor="profile-picture-input"
							sx={{
								width: '144px',
								height: '144px',
								margin: 'auto',
								display: 'flex',
								cursor: 'pointer',
								overflow: 'hidden',
								borderRadius: '50%',
								alignItems: 'center',
								position: 'relative',
								justifyContent: 'center',
								border: '1px dashed rgba(145, 158, 171, 0.32)',
								'&:hover': {
									'& > div': {
										opacity: 0.72
									}
								}
							}}
						>
							<img
								src="/images/avatar_default.jpg"
								alt=""
								css={{
									lineHeight: 1,
									display: 'block',
									overflow: 'hidden',
									position: 'absolute',
									zIndex: 8,
									borderRadius: '50%',
									width: 'calc(100% - 16px)',
									height: 'calc(100% - 16px)'
								}}
							/>
							<Box
								sx={{
									position: 'absolute',
									zIndex: 9,
									borderRadius: '50%',
									width: 'calc(100% - 16px)',
									height: 'calc(100% - 16px)',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									bgcolor: 'rgba(22, 28, 36, 0.64)',
									opacity: 0
								}}
							>
								<AddAPhoto sx={{ color: '#fff' }} />
								<Typography variant="caption" sx={{ mt: 1, color: '#fff' }}>
									上传照片
								</Typography>
							</Box>
						</Box>
						<Typography
							variant="caption"
							align="center"
							sx={{ display: 'block', mt: 2, opacity: 0.7 }}
						>
							允许 *.jpeg, *.jpg, *.png, *.gif
							<br />
							最大3.1 MB
						</Typography>
						<input
							id="profile-picture-input"
							type="file"
							accept="image/*"
							css={{ display: 'none' }}
						/>
					</div>
					<Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
						<FormControlLabel
							control={<IOSSwitch defaultChecked={true} />}
							label="公共照片"
							labelPlacement="start"
							componentsProps={{
								typography: {
									fontSize: '1rem',
									sx: {
										mr: 2
									}
								}
							}}
						/>
					</Box>
				</Paper>
			</Grid>
			<Grid item xs={12} md={8}>
				<Paper
					component="form"
					onSubmit={handleSubmit(onSubmit)}
					elevation={0}
					sx={{
						borderRadius: '16px',
						boxShadow:
							'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
						p: 3
					}}
				>
					<Grid
						container
						spacing={2}
						sx={{
							'& .MuiOutlinedInput-root': {
								borderRadius: '8px',
								fontSize: '1rem',
								borderColor: 'rgb(145, 158, 171)'
							},
							'& .MuiFormLabel-root': {
								fontSize: '1rem',
								color: 'rgb(145, 158, 171)',
								'&.Mui-focused': {
									color: theme => theme.palette.primary.main
								}
							}
						}}
					>
						<Grid item xs={12} md={6}>
							<Controller
								name="cname"
								control={control}
								render={({ field }) => <TextField {...field} label="中文名" fullWidth />}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Controller
								name="ename"
								control={control}
								render={({ field }) => <TextField {...field} label="英文名" fullWidth />}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Controller
								name="email"
								control={control}
								render={({ field }) => <TextField {...field} label="邮箱" type="email" fullWidth />}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Controller
								name="phone"
								control={control}
								render={({ field }) => <TextField {...field} label="电话" fullWidth />}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Controller
								name="gender"
								control={control}
								render={({ field }) => (
									<TextField {...field} label="性别" fullWidth select>
										<MenuItem value={Gender.Female}>女</MenuItem>
										<MenuItem value={Gender.Male}>男</MenuItem>
									</TextField>
								)}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Controller
								name="ic"
								control={control}
								render={({ field }) => <TextField {...field} label="身份证号码" fullWidth />}
							/>
						</Grid>
						<Grid item xs={12}>
							<Controller
								name="address"
								control={control}
								render={({ field }) => (
									<TextField {...field} label="地址" fullWidth multiline minRows={3} />
								)}
							/>
						</Grid>
					</Grid>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Button
							type="submit"
							variant="contained"
							size="large"
							sx={{ mt: 3, borderRadius: '8px' }}
						>
							保存更改
						</Button>
					</Box>
				</Paper>
			</Grid>
		</Grid>
	);
};

const Android12Switch = styled(Switch)(({ theme }) => ({
	padding: 8,
	'& .MuiSwitch-track': {
		borderRadius: 22 / 2,
		'&:before, &:after': {
			content: '""',
			position: 'absolute',
			top: '50%',
			transform: 'translateY(-50%)',
			width: 16,
			height: 16
		},
		'&:before': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
			left: 12
		},
		'&:after': {
			backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
				theme.palette.getContrastText(theme.palette.primary.main)
			)}" d="M19,13H5V11H19V13Z" /></svg>')`,
			right: 12
		}
	},
	'& .MuiSwitch-thumb': {
		boxShadow: 'none',
		width: 16,
		height: 16,
		margin: 2
	}
}));

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(16px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
				opacity: 1,
				border: 0
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5
			}
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#33cf4d',
			border: '6px solid #fff'
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[600]
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
		}
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 22,
		height: 22
	},
	'& .MuiSwitch-track': {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500
		})
	}
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
	width: 28,
	height: 16,
	padding: 0,
	display: 'flex',
	'&:active': {
		'& .MuiSwitch-thumb': {
			width: 15
		},
		'& .MuiSwitch-switchBase.Mui-checked': {
			transform: 'translateX(9px)'
		}
	},
	'& .MuiSwitch-switchBase': {
		padding: 2,
		'&.Mui-checked': {
			transform: 'translateX(12px)',
			color: '#fff',
			'& + .MuiSwitch-track': {
				opacity: 1,
				backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff'
			}
		}
	},
	'& .MuiSwitch-thumb': {
		boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
		width: 12,
		height: 12,
		borderRadius: 6,
		transition: theme.transitions.create(['width'], {
			duration: 200
		})
	},
	'& .MuiSwitch-track': {
		borderRadius: 16 / 2,
		opacity: 1,
		backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
		boxSizing: 'border-box'
	}
}));

export default General;
