import create from 'zustand';
import { LessonStatus, UpdateLessonAttendanceInput } from '../generated/api';

type LessonUpdateStoreState = {
	startTime: Date;
	endTime: Date;
	color: string;
	classId: string;
	termId: string;
	spaceId: string;
	status: LessonStatus;
	lecturers: string[];
	students: string[];
	attendances: UpdateLessonAttendanceInput[];
	setStartTime: (startTime: Date) => void;
	setEndTime: (endTime: Date) => void;
	setColor: (color: string) => void;
	setClassId: (classId: string) => void;
	setTermId: (termId: string) => void;
	setSpaceId: (spaceId: string) => void;
	setStatus: (status: LessonStatus) => void;
	setLecturers: (lecturers: string[]) => void;
	setStudents: (students: string[]) => void;
	setAttendances: (attendances: LessonUpdateStoreState['attendances']) => void;
	setAll: (
		data: Pick<
			LessonUpdateStoreState,
			| 'classId'
			| 'termId'
			| 'spaceId'
			| 'lecturers'
			| 'startTime'
			| 'endTime'
			| 'color'
			| 'status'
			| 'students'
			| 'attendances'
		>
	) => void;
	reset: () => void;
};

const initialState: Pick<
	LessonUpdateStoreState,
	'classId' | 'termId' | 'spaceId' | 'lecturers' | 'color' | 'status' | 'students' | 'attendances'
> = {
	classId: '',
	termId: '',
	spaceId: '',
	lecturers: [],
	color: '#188fff',
	status: LessonStatus.Coming,
	students: [],
	attendances: []
};

export const useLessonUpdateStore = create<LessonUpdateStoreState>(set => ({
	...initialState,
	startTime: new Date(),
	endTime: new Date(),
	setStartTime: startTime => set({ startTime }),
	setEndTime: endTime => set({ endTime }),
	setColor: color => set({ color }),
	setClassId: classId => set({ classId }),
	setTermId: termId => set({ termId }),
	setSpaceId: spaceId => set({ spaceId }),
	setStatus: status => set({status}),
	setLecturers: lecturers => set({ lecturers }),
	setStudents: students => set({ students }),
	setAttendances: attendances => set({ attendances }),
	setAll: data => set({ ...data }),
	reset: () => set({ ...initialState })
}));
