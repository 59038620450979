import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useGetUsersWithAddressQuery } from '../../../generated/api';
import CustomDialog from '../../../share-components/CustomDialog';
import { List, ListItemButton, ListItemText } from '@mui/material';

const InvoiceToDialog: React.FC<{
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	setDebtor: (payer: { id: string; cname: string; ename: string; address: string }) => void;
}> = ({ open, setOpen, setDebtor }) => {
	const { data } = useGetUsersWithAddressQuery();

	const users = useMemo(() => data?.users || [], [data]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = (user: typeof users[0]) => () => {
		setDebtor(user);
		handleClose();
	};

	return (
		<CustomDialog
			open={open}
			onClose={handleClose}
			title="选择付款人"
			maxWidth="xs"
			useSimpleBar={true}
			PaperProps={{
				sx: {
					borderRadius: '8px'
				}
			}}
		>
			<List sx={{ px: '12px' }}>
				{users.map(user => (
					<ListItemButton
						key={user.id}
						onClick={handleClick(user)}
						sx={{ p: '12px', borderRadius: '8px' }}
					>
						<ListItemText primary={`${user.cname} ${user.ename}`} secondary={user.address} />
					</ListItemButton>
				))}
			</List>
		</CustomDialog>
	);
};

export default InvoiceToDialog;
