import create from 'zustand';
import {
	AdminStatus,
	CreateUserAdminInput,
	CreateUserInput,
	CreateUserLecturerInput,
	CreateUserStudentInput,
	Gender,
	LecturerStatus,
	StudentStatus,
	UserStatus
} from '../generated/api';
import { persistIndexedDB } from './IndexedDB';

const initStudent = {
	matricId: '',
	phones: [],
	status: StudentStatus.Active
};

const initLecturer = {
	matricId: '',
	status: LecturerStatus.Active
};

const initAdmin = {
	matricId: '',
	isMaster: false,
	status: AdminStatus.Active
};

const initialUser: CreateUserInput & {
	student: CreateUserStudentInput;
	lecturer: CreateUserLecturerInput;
	admin: CreateUserAdminInput;
} = {
	cname: '',
	ename: '',
	email: '',
	password: '',
	gender: Gender.Female,
	ic: '',
	phone: '',
	address: '',
	status: UserStatus.Active,
	student: initStudent,
	lecturer: initLecturer,
	admin: initAdmin
};

const initialRoles = {
	student: false,
	lecturer: false,
	admin: false
};

type UserCreateStoreState = {
	user: CreateUserInput & {
		student: CreateUserStudentInput;
		lecturer: CreateUserLecturerInput;
		admin: CreateUserAdminInput;
	};
	roles: {
		student: boolean;
		lecturer: boolean;
		admin: boolean;
	};
	step: number;
	setUser: (user: Partial<UserCreateStoreState['user']>) => void;
	setRoles: (roles: Partial<UserCreateStoreState['roles']>) => void;
	setStep: (step: number) => void;
	resetUser: () => void;
};

export const useUserCreateStore = create<UserCreateStoreState>()(
	persistIndexedDB(
		(set, get) => ({
			user: initialUser,
			roles: initialRoles,
			step: 0,
			setUser: user => set({ user: { ...get().user, ...user } }),
			setRoles: roles => set({ roles: { ...get().roles, ...roles } }),
			setStep: step => set({ step }),
			resetUser: () => set({ user: initialUser, roles: initialRoles, step: 0 })
		}),
		{
			name: 'user-create-dialog'
		}
	)
);
