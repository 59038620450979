import React, { useEffect } from 'react';
import { useUpdateAtom } from 'jotai/utils';
import { lessonCreateDialogOpenAtom } from '../../atoms';

interface LessonCreateRouteProps {}

const LessonCreateRoute: React.FC<LessonCreateRouteProps> = () => {
	const setOpen = useUpdateAtom(lessonCreateDialogOpenAtom);

	useEffect(() => {
		setOpen(true);

		return () => {
			setOpen(false);
		};
	}, []);

	return <></>;
};

export default LessonCreateRoute;
