import React from 'react';
import { Button, Paper } from '@mui/material';
import CreateLecturerForm from '../../../../share-components/User/CreateLecturerForm';
import { useUserDetailsRoleCreateStore } from '../../../../stores/useUserDetailsRoleCreateStore';
import shallow from 'zustand/shallow';
import { User, useUpdateUserMutation } from '../../../../generated/api';
import { useAtomValue } from 'jotai/utils';
import { isMasterAtom } from '../../../../atoms';
import { createLecturerUpdate } from '../../update/createLecturerUpdate';

interface UserDetailsLecturerCreateProps {
	user: User;
}

const UserDetailsLecturerCreate: React.FC<UserDetailsLecturerCreateProps> = ({
	user
}) => {
	const [lecturer, setLecturer] = useUserDetailsRoleCreateStore(
		state => [state.lecturer, state.setLecturer],
		shallow
	);
	const isMaster = useAtomValue(isMasterAtom);
	const [updateUserMutation] = useUpdateUserMutation({
		variables: {
			id: user.id,
			input: {
				lecturer
			},
			isMaster
		},
		optimisticResponse: {
			__typename: 'Mutation',
			updateUser: {
				...user,
				lecturer: {
					...lecturer,
					user: {
						id: user.id
					},
					__typename: 'Lecturer'
				}
			}
		},
		update: createLecturerUpdate
	});

	const handleSubmit = async (event: React.FormEvent<{}>) => {
		event.preventDefault();

		try {
			await updateUserMutation();
		} catch {}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Paper
				variant="outlined"
				sx={{ padding: '20px', background: 'transparent' }}
			>
				<CreateLecturerForm lecturer={lecturer} setLecturer={setLecturer} />
			</Paper>
			<br />
			<Button
				type="submit"
				variant="contained"
				color="primary"
				fullWidth
				sx={theme => ({
					color: theme.palette.mode === 'dark' ? '#000' : '#fff'
				})}
			>
				添加此用户为老师
			</Button>
		</form>
	);
};

export default UserDetailsLecturerCreate;
