import React, { useMemo } from 'react';
import {
	Box,
	Chip,
	MenuItem,
	OutlinedTextFieldProps,
	SelectChangeEvent,
	TextField
} from '@mui/material';
import { useGetLecturersQuery } from '../generated/api';

interface LecturersSelectProps extends Partial<OutlinedTextFieldProps> {
	selectedLecturers: Array<string>;
	setLecturers: (lecturers: string[]) => void;
}

const LecturersSelect: React.FC<LecturersSelectProps> = ({
	selectedLecturers,
	setLecturers,
	...props
}) => {
	const { data: lecturersData } = useGetLecturersQuery();
	const lecturers = useMemo(
		() => lecturersData?.lecturers || [],
		[lecturersData]
	);

	const handleLecturersChange = (event: SelectChangeEvent<string[]>) => {
		const value = event.target.value;
		setLecturers(typeof value === 'string' ? value.split(',') : value);
	};

	return (
		<TextField
			{...props}
			value={selectedLecturers}
			//@ts-ignore
			onChange={handleLecturersChange}
			label="老师"
			select
			SelectProps={{
				multiple: true,
				renderValue: selected => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{(selected as string[]).map(value => (
							<Chip
								key={value}
								label={
									lecturers?.find(lecturer => lecturer.user.id === value)?.user
										.cname || ''
								}
							/>
						))}
					</Box>
				)
			}}
			fullWidth
			variant="outlined"
		>
			{lecturers.map(lecturer => (
				<MenuItem key={lecturer.user.id} value={lecturer.user.id}>
					{lecturer.matricId} {lecturer.user.cname} {lecturer.user.ename}
				</MenuItem>
			))}
		</TextField>
	);
};

export default LecturersSelect;
