import React from 'react';
import { CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

interface LoadingProps {}

const Loading: React.FC<LoadingProps> = () => {
	return (
		<Root>
			<CircularProgress />
		</Root>
	);
};

const Root = styled('div')({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '100vw',
	height: '100vh',
	position: 'absolute',
	top: 0,
	left: 0
});

export default Loading;
