import React, { useCallback } from 'react';
import {
	Checkbox,
	Grid,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	TextField
} from '@mui/material';
import {
	ClassTermAutoGenerateMethod,
	ClassTermAutoGeneration
} from '../../stores/useClassUpdateStore';
import { TimePicker } from '@mui/x-date-pickers';
import '@mui/lab';
import produce from 'immer';
import { Day } from 'generated/api';

interface ClassTermAutoGenerationFormProps {
	termAutoGenerationAvailability: boolean;
	setTermAutoGenerationAvailability: (termAutoGenerationAvailability: boolean) => void;
	termAutoGenerateMethod: ClassTermAutoGenerateMethod;
	setTermAutoGenerateMethod: (termAutoGenerateMethod: ClassTermAutoGenerateMethod) => void;
	termAutoGeneration: ClassTermAutoGeneration;
	setTermAutoGeneration: (termAutoGeneration: ClassTermAutoGeneration) => void;
}

const ClassTermAutoGenerationForm: React.FC<ClassTermAutoGenerationFormProps> = ({
	termAutoGenerationAvailability,
	setTermAutoGenerationAvailability,
	termAutoGenerateMethod,
	setTermAutoGenerateMethod,
	termAutoGeneration,
	setTermAutoGeneration
}) => {
	const handleTermAutoGenerationAvailabilityToggle = () => {
		setTermAutoGenerationAvailability(!termAutoGenerationAvailability);
	};

	const handleTermAutoGenerateMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTermAutoGenerateMethod(event.target.value as ClassTermAutoGenerateMethod);
	};

	const handleByMonthStartTimeChange = (date: Date | null) => {
		if (!date) {
			return;
		}
		setTermAutoGeneration({
			[ClassTermAutoGenerateMethod.BY_MONTH]: produce(
				termAutoGeneration[ClassTermAutoGenerateMethod.BY_MONTH]!,
				draft => {
					draft.startTime = date;
				}
			)
		});
	};

	const handleByMonthEndTimeChange = (date: Date | null) => {
		if (!date) {
			return;
		}
		setTermAutoGeneration({
			[ClassTermAutoGenerateMethod.BY_MONTH]: produce(
				termAutoGeneration[ClassTermAutoGenerateMethod.BY_MONTH]!,
				draft => {
					draft.endTime = date;
				}
			)
		});
	};

	const handleByMonthDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTermAutoGeneration({
			[ClassTermAutoGenerateMethod.BY_MONTH]: produce(
				termAutoGeneration[ClassTermAutoGenerateMethod.BY_MONTH]!,
				draft => {
					draft.day = event.target.value as Day;
				}
			)
		});
	};

	const getContent = useCallback(() => {
		switch (termAutoGenerateMethod) {
			case ClassTermAutoGenerateMethod.BY_MONTH:
				const generateByMonth = termAutoGeneration[ClassTermAutoGenerateMethod.BY_MONTH];
				if (!generateByMonth) {
					return <></>;
				}
				return (
					<>
						<Grid item xs={12} sm={6}>
							<TimePicker
								label="开始时间"
								value={generateByMonth.startTime}
								onChange={handleByMonthStartTimeChange}
								disabled={!termAutoGenerationAvailability}
								renderInput={params => (
									<TextField
										{...params}
										required
										fullWidth
										InputLabelProps={{
											shrink: true
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<TimePicker
								label="结束时间"
								value={generateByMonth.endTime}
								onChange={handleByMonthEndTimeChange}
								disabled={!termAutoGenerationAvailability}
								renderInput={params => (
									<TextField
										{...params}
										required
										fullWidth
										InputLabelProps={{
											shrink: true
										}}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label="星期"
								select
								fullWidth
								value={generateByMonth.day}
								onChange={handleByMonthDayChange}
								disabled={!termAutoGenerationAvailability}
								required
							>
								<MenuItem value={Day.Sunday}>星期日</MenuItem>
								<MenuItem value={Day.Monday}>星期一</MenuItem>
								<MenuItem value={Day.Tuesday}>星期二</MenuItem>
								<MenuItem value={Day.Wednesday}>星期三</MenuItem>
								<MenuItem value={Day.Thursday}>星期四</MenuItem>
								<MenuItem value={Day.Friday}>星期五</MenuItem>
								<MenuItem value={Day.Saturday}>星期六</MenuItem>
							</TextField>
						</Grid>
					</>
				);
		}
	}, [termAutoGenerateMethod, termAutoGenerationAvailability, termAutoGeneration]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sx={{ listStyleType: 'none' }}>
				<ListItemButton
					onClick={handleTermAutoGenerationAvailabilityToggle}
					sx={{ borderRadius: '4px' }}
					disableRipple
				>
					<ListItemText
						primary="开启学期生成"
						secondary="开启学期生成将会自动创建班级学期及其班次"
					/>
					<Checkbox checked={termAutoGenerationAvailability} />
				</ListItemButton>
			</Grid>
			<Grid item xs={12} sm={12}>
				<TextField
					label="自动创建方式"
					value={termAutoGenerateMethod}
					onChange={handleTermAutoGenerateMethodChange}
					select
					fullWidth
					required
					disabled={!termAutoGenerationAvailability}
					InputLabelProps={{
						shrink: true
					}}
				>
					<MenuItem value={ClassTermAutoGenerateMethod.BY_MONTH}>每个月每个星期一堂课</MenuItem>
				</TextField>
			</Grid>
			{getContent()}
		</Grid>
	);
};

export default ClassTermAutoGenerationForm;
