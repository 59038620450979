import React from 'react';
import { UserRole } from '../../../../types';
import UserRoleCreateStudent from './UserRoleCreateStudent';
import UserRoleCreateLecturer from './UserRoleCreateLecturer';
import UserRoleCreateAdmin from './UserRoleCreateAdmin';

interface UserRoleCreateDialogContentProps {
	role: UserRole;
}

const UserRoleCreateDialogContent: React.FC<UserRoleCreateDialogContentProps> =
	({ role }) => {
		return (
			<div>
				<div style={{ padding: '10px 0' }}>
					{role === UserRole.STUDENT ? (
						<UserRoleCreateStudent />
					) : role === UserRole.LECTURER ? (
						<UserRoleCreateLecturer />
					) : role === UserRole.ADMIN ? (
						<UserRoleCreateAdmin />
					) : (
						<></>
					)}
				</div>
			</div>
		);
	};

export default UserRoleCreateDialogContent;
