import React from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	FormControlLabel,
	Grid,
	InputAdornment,
	MenuItem,
	TextField
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useUserCreateStore } from '../../../../../stores/useUserCreateStore';
import shallow from 'zustand/shallow';
import { AdminStatus, UserStatus } from '../../../../../generated/api';

interface UserCreateRolesAdminProps {
	handleChange: (
		event: React.ChangeEvent<{
			name: string;
			value: any;
			type: string;
			checked?: boolean;
		}>
	) => void;
}

const UserCreateRolesAdmin: React.FC<UserCreateRolesAdminProps> = ({ handleChange }) => {
	const [admin, adminExists, setRoles, userStatus] = useUserCreateStore(
		state => [state.user.admin, state.roles.admin, state.setRoles, state.user.status],
		shallow
	);

	const handleExpandedChange = (event: React.ChangeEvent<{}>, expanded: boolean) => {
		setRoles({ admin: expanded });
	};

	return (
		<Accordion variant="outlined" expanded={adminExists} onChange={handleExpandedChange}>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<FormControlLabel
					onClick={event => event.stopPropagation()}
					onFocus={event => event.stopPropagation()}
					control={
						<Checkbox
							name="admin"
							checked={adminExists}
							onChange={event => handleExpandedChange(event, event.target.checked)}
						/>
					}
					label="添加此用户为管理"
				/>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							label="编号"
							name="admin.matricId"
							placeholder="XXX"
							value={admin.matricId.slice(1)}
							onChange={handleChange}
							fullWidth
							disabled={!adminExists}
							InputProps={{
								startAdornment: <InputAdornment position="start">A</InputAdornment>
							}}
							inputProps={{
								pattern: '(^$|[0-9]{3}$)'
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							label="在籍状况"
							name="admin.status"
							value={admin.status}
							onChange={handleChange}
							select
							fullWidth
							disabled={!adminExists}
						>
							{userStatus === UserStatus.Active && (
								<MenuItem value={AdminStatus.Active}>在籍管理</MenuItem>
							)}
							<MenuItem value={AdminStatus.Inactive}>不在籍管理</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<TextField
							variant="outlined"
							label="高级管理"
							name="admin.isMaster"
							value={Number(admin.isMaster)}
							onChange={handleChange}
							select
							fullWidth
							disabled={!adminExists}
						>
							<MenuItem value={1}>是</MenuItem>
							<MenuItem value={0}>否</MenuItem>
						</TextField>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

export default UserCreateRolesAdmin;
