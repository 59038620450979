import React, { PropsWithChildren } from 'react';
import { List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface UserDetailsGroupProps {
	title: string;
}

const UserDetailsGroup: React.FC<PropsWithChildren<UserDetailsGroupProps>> = ({
	title,
	children
}) => {
	return (
		<Root>
			<Header>
				<Typography variant="h6">{title}</Typography>
			</Header>
			<Body>
				<List>{children}</List>
			</Body>
		</Root>
	);
};

const Root = styled('div')({
	marginBottom: '1em',
	border: '1px solid #ccc',
	borderRadius: '5px',
	padding: '1em',
	background: 'transparent'
});

const Header = styled('div')({
	margin: '0.5em'
});

const Body = styled('div')({
	fontSize: '1.1em'
});

export default UserDetailsGroup;
