import React from 'react';
import { useUserCreateStore } from '../../../../stores/useUserCreateStore';
import UserCreateFormContent from './UserCreateFormContent';
import UserCreateRolesContent from './UserCreateRolesContent';

interface UserCreateDialogContentProps {}

const UserCreateDialogContent: React.FC<UserCreateDialogContentProps> = () => {
	const step = useUserCreateStore(state => state.step);

	return (
		<>{step === 0 ? <UserCreateFormContent /> : <UserCreateRolesContent />}</>
	);
};

export default UserCreateDialogContent;
