import React, { useEffect } from 'react';
import {
	Box,
	BoxProps,
	ButtonBase,
	Drawer,
	IconButton,
	SwipeableDrawer,
	Tooltip,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import {
	AccountCircleOutlined,
	BusinessOutlined,
	DashboardOutlined,
	EventNoteOutlined,
	ExitToAppOutlined,
	HowToRegOutlined,
	MenuRounded,
	PaidOutlined,
	PaletteOutlined,
	PeopleOutlineOutlined,
	SettingsOutlined
} from '@mui/icons-material';
import SideDrawerItem from './SideDrawerItem';
import { useTokenStore } from '../stores/useTokenStore';
import { useGetCurrentUserQuery } from 'generated/api';
import { useAtom } from 'jotai';
import { drawerOpenAtom, userManagementLastRouteAtom } from '../atoms';
import LinkWithPreload from '../share-components/Route/LinkWithPreload';
import { styled } from '@mui/material/styles';
import { useDrawerMinimizedStore } from '../stores/useDrawerMinimizedStore';
import { clear } from 'idb-keyval';
import { Page } from '../hooks/usePage';
import SimpleBar from 'simplebar-react';

const navItemIconStyle = {
	height: '40%',
	marginRight: '15px'
};

interface SideDrawerProps {}

const SideDrawer: React.FC<SideDrawerProps> = () => {
	const { data, client } = useGetCurrentUserQuery();
	const [drawerOpen, setDrawerOpen] = useAtom(drawerOpenAtom);
	const setToken = useTokenStore(state => state.setToken);
	const currentUser = data?.currentUser;
	const [userManagementLastRoute] = useAtom(userManagementLastRouteAtom);
	const theme = useTheme();
	const mdUp = useMediaQuery(theme.breakpoints.up('md'));
	const { minimized: drawerMinimized, setMinimized: setDrawerMinimized } =
		useDrawerMinimizedStore();

	const routes = [
		{
			name: '主页',
			image: <DashboardOutlined sx={navItemIconStyle} />,
			icon: <DashboardOutlined />,
			link: '/',
			page: Page.DASHBOARD
		},
		{
			name: currentUser?.cname || currentUser?.ename,
			image: <AccountCircleOutlined sx={navItemIconStyle} />,
			icon: <AccountCircleOutlined />,
			link: '/userpage',
			page: Page.USERPAGE
		},
		{
			name: '用户管理',
			image: <PeopleOutlineOutlined sx={navItemIconStyle} />,
			icon: <PeopleOutlineOutlined />,
			link: '/user-management' + userManagementLastRoute,
			page: Page.USER_MANAGEMENT
		},
		{
			name: '课程管理',
			image: <PaletteOutlined sx={navItemIconStyle} />,
			icon: <PaletteOutlined />,
			link: '/course-management',
			page: Page.COURSE_MANAGEMENT
		},
		{
			name: '课程报名',
			image: <HowToRegOutlined sx={navItemIconStyle} />,
			icon: <HowToRegOutlined />,
			link: '/course-registration',
			page: Page.COURSE_REGISTRATION
		},
		{
			name: '排课',
			image: <EventNoteOutlined sx={navItemIconStyle} />,
			icon: <EventNoteOutlined />,
			link: '/class-arrangement',
			page: Page.CLASS_ARRANGEMENT
		},
		{
			name: '学费管理',
			image: <PaidOutlined sx={navItemIconStyle} />,
			icon: <PaidOutlined />,
			link: '/payment-management',
			page: Page.PAYMENT_MANAGEMENT
		},
		{
			name: '学院管理',
			image: <BusinessOutlined sx={navItemIconStyle} />,
			icon: <BusinessOutlined />,
			link: '/location-management',
			page: Page.LOCATION_MANAGEMENT
		},
		{
			name: '系统设置',
			image: <SettingsOutlined sx={navItemIconStyle} />,
			icon: <SettingsOutlined />,
			link: '/settings',
			page: Page.SETTINGS
		}
	];

	const closeDrawer = () => {
		if (drawerOpen) {
			setDrawerOpen(false);
		}
	};

	useEffect(() => {
		window.addEventListener('resize', closeDrawer);

		return () => window.removeEventListener('resize', closeDrawer);
	}, []);

	const handleDrawerToggle = () => {
		setDrawerOpen(!drawerOpen);
	};

	const handleSignout = async () => {
		setToken('');
		try {
			await client.resetStore();
			await clear();
			await fetch(`${import.meta.env.VITE_REST_API_URI}/logout`, {
				credentials: 'include'
			});
		} catch {}
	};

	return (
		<nav>
			{!mdUp ? (
				<StyledSwipeableDrawer
					variant="temporary"
					open={drawerOpen}
					onOpen={handleDrawerToggle}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true
					}}
				>
					<NavBarHeader sx={{ padding: '25px 35px' }}>
						<Link to="/">
							<NavBarHeaderImage
								src="/images/ekids_logo.webp"
								alt="ekids logo"
								width="120"
								height="68"
								loading="lazy"
								// sx={{ maxHeight: 'auto' }}
							/>
						</Link>
					</NavBarHeader>
					{routes.map(route => (
						<SideDrawerItem key={route.link} route={route} />
					))}
					<SideDrawerButton onClick={handleSignout}>
						<NavItem>
							<ExitToAppOutlined sx={navItemIconStyle} />
							<Typography sx={{ whiteSpace: 'nowrap' }}>登出</Typography>
						</NavItem>
					</SideDrawerButton>
				</StyledSwipeableDrawer>
			) : (
				<StyledDrawer
					variant="permanent"
					open
					sx={{
						'& > .MuiPaper-root': {
							width: !drawerMinimized ? '250px' : '70px',
							transition: 'width 200ms'
						}
					}}
				>
					<SimpleBar style={{ maxHeight: '100%' }}>
						<NavBarHeader
							sx={{
								padding: !drawerMinimized ? '25px' : '10px',
								justifyContent: !drawerMinimized ? 'space-between' : 'center'
							}}
						>
							{!drawerMinimized && (
								<LinkWithPreload to="/">
									<NavBarHeaderImage
										src="/images/ekids_logo.webp"
										alt="ekids logo"
										width="120"
										height="68"
										sx={{ maxHeight: '60px' }}
									/>
								</LinkWithPreload>
							)}
							<Box
								sx={{
									display: 'flex',
									alignItems: 'flex-start'
								}}
							>
								<IconButton
									onClick={() => setDrawerMinimized(!drawerMinimized)}
									sx={theme => ({
										backgroundColor:
											theme.palette.mode === 'light' ? 'rgb(230, 235, 248)' : '#303f9f',
										borderRadius: '10px',
										padding: 0.7
									})}
								>
									<MenuRounded />
								</IconButton>
							</Box>
						</NavBarHeader>
						{routes.map(route => (
							<Tooltip
								key={route.link}
								title={route.name!}
								placement="right"
								disableHoverListener={!drawerMinimized}
								disableFocusListener={!drawerMinimized}
							>
								<Box>
									<SideDrawerItem route={route} />
								</Box>
							</Tooltip>
						))}
						<Tooltip
							title="登出"
							placement="right"
							disableHoverListener={!drawerMinimized}
							disableFocusListener={!drawerMinimized}
						>
							<Box>
								<SideDrawerButton onClick={handleSignout}>
									<NavItem>
										<ExitToAppOutlined sx={navItemIconStyle} />
										{!drawerMinimized && <Typography>登出</Typography>}
									</NavItem>
								</SideDrawerButton>
							</Box>
						</Tooltip>
					</SimpleBar>
				</StyledDrawer>
			)}
			<SideDrawerLocationListener />
		</nav>
	);
};

const SideDrawerLocationListener = () => {
	const [drawerOpen, setDrawerOpen] = useAtom(drawerOpenAtom);
	const location = useLocation();

	useEffect(() => {
		if (drawerOpen) {
			setDrawerOpen(false);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return <></>;
};

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
	'& > .MuiPaper-root': {
		width: 300,
		height: '100vh',
		position: 'relative',
		backgroundColor: theme.palette.background.default,
		backgroundImage: 'none'
	}
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
	'& > .MuiPaper-root': {
		width: 250,
		height: '100vh',
		position: 'relative',
		backgroundColor: theme.palette.background.default
		// borderRight: '1px dotted #eee'
		// [theme.breakpoints.up('xl')]: {
		// 	width: 300
		// }
	},
	'& > .MuiDrawer-paperAnchorDockedLeft': {
		// borderRight: 'none'
		borderRight: '1px dashed rgba(145, 158, 171, 0.24)'
	}
}));

const NavBarHeader = styled('div')({
	width: '100%',
	// marginBottom: '20px',
	display: 'flex'
});

const NavBarHeaderImage = styled('img')({
	width: 'auto'
});

const SideDrawerButton = styled(ButtonBase)(({ theme }) => ({
	display: 'block',
	width: '86%',
	height: 60,
	minHeight: 60,
	margin: '0 7%',
	color: theme.palette.text.primary,
	'& svg': {
		color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.38)'
	},
	borderRadius: '5px',
	fontSize: '1em'
}));

export const NavItem = styled(props => {
	const drawerMinimized = useDrawerMinimizedStore(state => state.minimized);
	const theme = useTheme();
	const mdUp = useMediaQuery(theme.breakpoints.up('md'));

	return (
		<Box
			{...props}
			sx={
				drawerMinimized && mdUp
					? {
							display: 'flex',
							justifyContent: 'center',
							'& .MuiSvgIcon-root': {
								marginRight: 0
							}
					  }
					: {}
			}
		/>
	);
})<BoxProps>({
	width: '100%',
	height: '100%',
	padding: '0 5%',
	borderRadius: '5px',
	display: 'flex',
	flexFlow: 'row nowrap',
	alignItems: 'center',
	transition: 'background 100ms'
});

export default SideDrawer;
