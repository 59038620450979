import { findComponentForRoute } from './findComponentForRoute';
import { routes } from '../Routes';
import { LinkProps } from 'react-router-dom';

export const preloadRouteComponent = async (path: LinkProps['to']) => {
	const component = findComponentForRoute(path.toString(), routes);

	if (component && component.preload) {
		await component.preload();
	}
};
