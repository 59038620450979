import create from 'zustand';
import { UserDetailsItemKey } from '../types';
import { UpdateUserInput } from '../generated/api';

type UserUpdateStoreState = {
	itemKey: UserDetailsItemKey | null;
	variables: UpdateUserInput;
	setItemKey: (itemKey: UserDetailsItemKey | null) => void;
	setVariables: (variables: UpdateUserInput) => void;
	reset: () => void;
};

export const useUserUpdateStore = create<UserUpdateStoreState>(set => ({
	itemKey: null,
	variables: {},
	setItemKey: itemKey => set({ itemKey }),
	setVariables: variables => set({ variables }),
	reset: () => set({ itemKey: null, variables: {} })
}));
