import { MutationUpdaterFn } from '@apollo/client';
import { DeleteCourseMutation } from '../../../generated/api';

export const deleteCourseUpdate: MutationUpdaterFn<DeleteCourseMutation> = (
	cache,
	mutationResult
) => {
	const id = mutationResult.data?.deleteCourse?.id;

	if (!id) {
		return;
	}

	cache.evict({
		id: cache.identify({
			id,
			__typename: 'Course'
		})
	});

	cache.gc();
};
