import React, { PropsWithChildren } from 'react';
import { ListItemButton, Typography } from '@mui/material';
import { UserDetailsItemKey } from '../../../types';
import { UserDetailsItemRecord } from '../../../constants';
import { useUserUpdateStore } from '../../../stores/useUserUpdateStore';
import { styled } from '@mui/material/styles';

interface UserDetailsItemProps {
	itemKey: UserDetailsItemKey;
	updateDisabled?: boolean;
}

const UserDetailsItem: React.FC<PropsWithChildren<UserDetailsItemProps>> = ({
	children,
	itemKey,
	updateDisabled = false
}) => {
	const setItemKey = useUserUpdateStore(state => state.setItemKey);

	const handleClick = () => {
		if (updateDisabled) {
			return;
		}
		setItemKey(itemKey);
	};

	return (
		<StyledListItemButton onClick={handleClick}>
			<Title>{UserDetailsItemRecord[itemKey]}</Title>
			{children}
		</StyledListItemButton>
	);
};

const StyledListItemButton = styled(ListItemButton)({
	padding: '1.2em 0.3em',
	borderBottom: '1px solid #ccc'
});

const Title = styled(Typography)({
	opacity: '0.6',
	width: '30%'
});

export default UserDetailsItem;
