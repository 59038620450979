import React from 'react';
import {
	AppBar,
	Button,
	DialogActions,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { userCreateDialogOpenAtom } from '../../../atoms';
import { useUserCreateStore } from '../../../stores/useUserCreateStore';
import shallow from 'zustand/shallow';
import { useUpdateAtom } from 'jotai/utils';
import { useNavigate } from 'react-router-dom';

interface UserCreateDialogActionsProps {}

const UserCreateDialogActions: React.FC<UserCreateDialogActionsProps> = () => {
	const [step, setStep, resetUser] = useUserCreateStore(
		state => [state.step, state.setStep, state.resetUser],
		shallow
	);
	const theme = useTheme();
	const isSM = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();

	const handleClose = () => {
		navigate(-1);
	};

	const prevStep = () => {
		setStep(0);
	};

	return (
		<>
			<AppBar
				position="static"
				color="inherit"
				component="footer"
				elevation={0}
			>
				<DialogActions>
					{!isSM ? (
						<>
							{step === 1 && (
								<Button color="primary" onClick={prevStep}>
									上一步
								</Button>
							)}
							<Button color="primary" onClick={handleClose}>
								取消
							</Button>
							<Button color="primary" onClick={resetUser}>
								重置
							</Button>
						</>
					) : (
						<Button
							color="primary"
							onClick={prevStep}
							sx={{ marginLeft: 0, marginRight: 'auto' }}
							disabled={step === 0}
						>
							上一步
						</Button>
					)}
					<Button color="primary" type="submit">
						{step === 0 ? '下一步' : '创建'}
					</Button>
				</DialogActions>
			</AppBar>
		</>
	);
};

export default UserCreateDialogActions;
