import { Day } from 'generated/api';

export const dayToNumber = (day: Day) => {
	switch (day) {
		case Day.Sunday:
			return 0;
		case Day.Monday:
			return 1;
		case Day.Tuesday:
			return 2;
		case Day.Wednesday:
			return 3;
		case Day.Thursday:
			return 4;
		case Day.Friday:
			return 5;
		case Day.Saturday:
			return 6;
	}
};
