import React, { useState } from 'react';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	List,
	ListItemButton,
	ListItemSecondaryAction,
	ListItemText,
	Paper,
	Theme
} from '@mui/material';
import {
	ClassFieldsFragment,
	GetClassDocument,
	GetClassQuery,
	TermFieldsFragment,
	useCreateLessonsMutation,
	useCreateTermMutation,
	useGetClassQuery,
	useGetClassStudentsQuery
} from '../../../../generated/api';
import { useUpdateAtom } from 'jotai/utils';
import { classTermCreateDialogIdAtom, termDetailsIdAtom } from '../../../../atoms';
import { ArrowForwardIos } from '@mui/icons-material';
import TermDeleteDialog from '../../Term/TermDeleteDialog';
import { useUserPreferenceStore } from '../../../../stores/useUserPreferenceStore';
import ClassTermAutoGenerationCreateTermAndLessons from '../../../../share-components/Class/ClassTermAutoGenerationCreateTermAndLessons';
import { ClassTermAutoGenerateMethod } from '../../../../stores/useClassUpdateStore';
import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { monthToAbbreviations } from '../../../../utils/monthToAbbreviations';
import { getFirstDateOfDayInMonth } from '../../../../utils/getFirstDateOfDayInMonth';
import { dayToNumber } from '../../../../utils/daytoNumber';

interface ClassDetailsDialogTermsProps {
	classId: string;
	terms: TermFieldsFragment[];
}

const ClassDetailsDialogTerms: React.FC<ClassDetailsDialogTermsProps> = ({ classId, terms }) => {
	const setClassId = useUpdateAtom(classTermCreateDialogIdAtom);
	const setTermDetailsId = useUpdateAtom(termDetailsIdAtom);
	// const setDeleteId = useUpdateAtom(termDeleteIdAtom);

	const handleTermCreateClick = () => {
		setClassId(classId);
	};

	const handleTermClick = (termId: string) => () => {
		setTermDetailsId(termId);
	};

	// const handleDeleteClick = (termId: string) => () => {
	// 	setDeleteId(termId);
	// };

	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<TermAutoGenerationTermCreateButton classId={classId} />
				<Button
					variant="contained"
					fullWidth
					sx={{ color: (theme: Theme) => (theme.palette.mode === 'light' ? '#fff' : '#000') }}
					onClick={handleTermCreateClick}
				>
					添加新的班级学期
				</Button>
				{/*<Divider orientation="horizontal" flexItem sx={{ my: 2 }} />*/}
				{terms.length > 0 && (
					<List disablePadding sx={{ mt: 2 }}>
						{terms.map((term, index) => (
							<Paper
								key={term.id}
								sx={{ mb: 1, backgroundColor: (theme: Theme) => theme.palette.background.default }}
							>
								<ListItemButton
									onClick={handleTermClick(term.id)}
									// sx={index != terms.length - 1 ? { borderBottom: 1, borderColor: 'divider' } : {}}
								>
									<ListItemText
										primary={term.name}
										secondary={`建立于 ${new Date(term.createdAt).toLocaleString('en-MY')}`}
									/>
									<ListItemSecondaryAction>
										<ArrowForwardIos fontSize="small" sx={{ mt: 0.5 }} />
									</ListItemSecondaryAction>
									{/*<ListItemSecondaryAction>*/}
									{/*	<IconButton>*/}
									{/*		<Edit fontSize="small" />*/}
									{/*	</IconButton>*/}
									{/*	<IconButton onClick={handleDeleteClick(term.id)}>*/}
									{/*		<Delete fontSize="small" />*/}
									{/*	</IconButton>*/}
									{/*</ListItemSecondaryAction>*/}
								</ListItemButton>
							</Paper>
						))}
					</List>
				)}
			</Box>
			<TermDeleteDialog />
		</>
	);
};

const TermAutoGenerationTermCreateButton: React.FC<{ classId: string }> = ({ classId }) => {
	const language = useUserPreferenceStore(state => state.language);
	const { data } = useGetClassQuery({
		variables: { id: classId, language },
		fetchPolicy: 'cache-first'
	});
	const { data: studentsData } = useGetClassStudentsQuery({
		variables: { id: classId },
		skip: !data
	});
	const [open, setOpen] = useState(false);
	const [createTerm, setCreateTerm] = useState({ startFrom: new Date(), numberOfLessons: 0 });
	const [createTermMutation] = useCreateTermMutation({
		update: (cache, result) => {
			const createdTerm = result.data?.createTerm;
			if (!createdTerm) {
				return;
			}
			const data = cache.readQuery<GetClassQuery>({
				query: GetClassDocument,
				variables: {
					id: classId,
					language
				}
			});
			if (data?.node?.__typename === 'Class') {
				cache.writeQuery<GetClassQuery>({
					query: GetClassDocument,
					variables: {
						id: classId,
						language
					},
					data: {
						...data,
						node: {
							...data.node,
							terms: [...data.node.terms, createdTerm]
						}
					}
				});
			}
		}
	});
	const [createLessonsMutation] = useCreateLessonsMutation();

	if (data?.node?.__typename !== 'Class' || !data.node.termAutoGeneration) {
		return null;
	}

	const classItem = data.node;

	if (classItem.termAutoGeneration?.__typename !== 'TermAutoGenerationByMonth') {
		return null;
	}

	const byMonth = {
		...classItem.termAutoGeneration,
		startTime: new Date(classItem.termAutoGeneration.startTime),
		endTime: new Date(classItem.termAutoGeneration.endTime)
	};

	if (studentsData?.node?.__typename !== 'Class') {
		return null;
	}

	const students = studentsData.node.students;

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleCreate = async () => {
		try {
			const res = await createTermMutation({
				variables: {
					input: {
						classId,
						name: `${monthToAbbreviations(
							createTerm.startFrom.getMonth()
						)} ${createTerm.startFrom.getFullYear()}`,
						studentFeePerLesson: classItem.studentFeePerLesson,
						lecturerCommission: classItem.lecturerCommission,
						students: students.map(student => student.user.id),
						durationPerLesson: Math.ceil(
							(byMonth.endTime.getTime() - byMonth.startTime.getTime()) / 1000 / 60
						),
						lessonCount: createTerm.numberOfLessons
					}
				}
			});

			const term = res.data?.createTerm;

			if (!term) {
				return;
			}

			const termId = term.id;

			await createLessonsMutation({
				variables: {
					input: Array.from(new Array(createTerm.numberOfLessons)).map((_, index) => {
						const year = createTerm.startFrom.getFullYear();
						const month = createTerm.startFrom.getMonth();
						const date = getFirstDateOfDayInMonth(dayToNumber(byMonth.day), month, year);
						return {
							termId,
							startTime: new Date(
								year,
								month,
								date + 7 * index,
								byMonth.startTime.getHours(),
								byMonth.startTime.getMinutes(),
								byMonth.startTime.getSeconds()
							),
							endTime: new Date(
								year,
								month,
								date + 7 * index,
								byMonth.endTime.getHours(),
								byMonth.endTime.getMinutes(),
								byMonth.endTime.getSeconds()
							),
							spaceId: classItem.space?.id ?? null,
							students: students.map(student => student.user.id),
							lecturers: classItem.lecturers.map(lecturer => lecturer.user.id),
							color: classItem.color
						};
					})
				}
			});

			toast.success('创建成功');
			handleClose();
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(err.message);
			} else {
				toast.error(`${err}`);
			}
		}
	};

	return (
		<>
			<Button
				variant="contained"
				fullWidth
				color="success"
				sx={{ color: (theme: Theme) => (theme.palette.mode === 'light' ? '#fff' : '#000'), mb: 2 }}
				onClick={handleClick}
			>
				基于"学期生成"创建学期
			</Button>
			<Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
				<DialogTitle>基于"学期生成"创建学期</DialogTitle>
				<DialogContent>
					<ClassTermAutoGenerationCreateTermAndLessons
						termAutoGeneration={{
							[ClassTermAutoGenerateMethod.BY_MONTH]: data.node.termAutoGeneration
						}}
						setCreateTerm={setCreateTerm}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>取消</Button>
					<Button onClick={handleCreate}>创建</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ClassDetailsDialogTerms;
