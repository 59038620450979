import React from 'react';
import { useCourseCreateStore } from '../../../../stores/useCourseCreateStore';
import { Grid, TextField } from '@mui/material';
import shallow from 'zustand/shallow';
import { Language } from '../../../../generated/api';
import LecturersSelect from '../../../../share-components/LecturersSelect';

interface CourseCreateDialogContentProps {}

const CourseCreateDialogContent: React.FC<CourseCreateDialogContentProps> =
	() => {
		const [
			translations,
			selectedLecturers,
			setTranslationName,
			setTranslationDescription,
			setLecturers
		] = useCourseCreateStore(
			state => [
				state.translations,
				state.lecturers,
				state.setTranslationName,
				state.setTranslationDescription,
				state.setLecturers
			],
			shallow
		);

		const handleChange = (
			event: React.ChangeEvent<{ name: string; value: any }>
		) => {
			const name = event.target.name;
			const value = event.target.value;

			switch (name) {
				case 'course_zh_name':
					setTranslationName(value, Language.Zh);
					break;
				case 'course_en_name':
					setTranslationName(value, Language.En);
					break;
				case 'course_zh_description':
					setTranslationDescription(value, Language.Zh);
					break;
				case 'course_en_description':
					setTranslationDescription(value, Language.En);
					break;
			}
		};

		return (
			<Grid container spacing={2} sx={{ pt: 1 }}>
				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						label="中文名字"
						name="course_zh_name"
						value={translations[Language.Zh].name}
						onChange={handleChange}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						label="英文名字"
						name="course_en_name"
						value={translations[Language.En].name}
						onChange={handleChange}
						required
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						label="中文简介"
						name="course_zh_description"
						value={translations[Language.Zh].description}
						onChange={handleChange}
						fullWidth
						multiline
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						variant="outlined"
						label="英文简介"
						name="course_en_description"
						value={translations[Language.En].description}
						onChange={handleChange}
						fullWidth
						multiline
					/>
				</Grid>
				<Grid item xs={12}>
					<LecturersSelect
						selectedLecturers={selectedLecturers}
						setLecturers={setLecturers}
					/>
				</Grid>
			</Grid>
		);
	};

export default CourseCreateDialogContent;
