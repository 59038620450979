import gql from 'graphql-tag';
import { USER_TABLE_FIELDS } from '../../UserManagement/query';
import { SPACE_FIELDS } from '../../LocationManagement/query';

export const CATEGORY_FIELDS = gql`
	fragment CategoryFields on Category {
		id
		translation(language: $language) {
			name
			description
		}
	}
`;

export const GET_CATEGORIES = gql`
	query GetCategories($language: Language!) {
		categories {
			...CategoryFields
		}
	}
	${CATEGORY_FIELDS}
`;

export const GET_CATEGORY_CLASSES = gql`
	query GetCategoryClasses($id: ID!, $language: Language!) {
		node(id: $id) {
			id
			... on Category {
				courses {
					id
					translation(language: $language) {
						name
					}
					classes {
						id
						translation(language: $language) {
							name
						}
						hasOngoingTerm
					}
				}
			}
		}
	}
`;

export const CREATE_CATEGORY = gql`
	mutation CreateCategory($input: CreateCategoryInput!, $language: Language!) {
		createCategory(input: $input) {
			...CategoryFields
		}
	}
	${CATEGORY_FIELDS}
`;

export const UPDATE_CATEGORY = gql`
	mutation UpdateCategory($id: ID!, $input: UpdateCategoryInput!, $language: Language!) {
		updateCategory(id: $id, input: $input) {
			...CategoryFields
		}
	}
	${CATEGORY_FIELDS}
`;

export const UPDATE_CATEGORIES = gql`
	mutation UpdateCategories($input: UpdateCategoriesInput!, $language: Language!) {
		updateCategories(input: $input) {
			...CategoryFields
		}
	}
`;

export const DELETE_CATEGORY = gql`
	mutation DeleteCategory($id: ID!) {
		deleteCategory(id: $id) {
			id
		}
	}
`;

export const GET_CATEGORY_TRANSLATION = gql`
	query GetCategoryTranslation($id: ID!, $language: Language!) {
		node(id: $id) {
			id
			... on Category {
				translation(language: $language) {
					name
					description
				}
			}
		}
	}
`;

export const GET_CATEGORY_INFO = gql`
	query GetCategoryInfo($id: ID!) {
		node(id: $id) {
			... on Category {
				translationZH: translation(language: ZH) {
					name
					description
				}
				translationEN: translation(language: EN) {
					name
					description
				}
			}
		}
	}
`;

export const COURSE_FIELDS = gql`
	fragment CourseFields on Course {
		id
		translation(language: $language) {
			name
			description
		}
		lecturers {
			matricId
			user {
				id
				cname
			}
		}
	}
`;

export const GET_CATEGORY_COURSES = gql`
	query GetCategoryCourses($id: ID!, $language: Language!) {
		node(id: $id) {
			... on Category {
				id
				courses {
					...CourseFields
				}
			}
		}
	}
	${COURSE_FIELDS}
`;

export const GET_COURSE_TRANSLATION = gql`
	query GetCourseTranslation($id: ID!, $language: Language!) {
		node(id: $id) {
			id
			... on Course {
				translation(language: $language) {
					name
					description
				}
			}
		}
	}
`;

export const CREATE_COURSE = gql`
	mutation CreateCourse($input: CreateCourseInput!, $language: Language!) {
		createCourse(input: $input) {
			...CourseFields
			category {
				id
			}
		}
	}
	${COURSE_FIELDS}
`;

export const UPDATE_COURSE = gql`
	mutation UpdateCourse($id: ID!, $input: UpdateCourseInput!, $language: Language!) {
		updateCourse(id: $id, input: $input) {
			...CourseFields
		}
	}
	${COURSE_FIELDS}
`;

export const ADD_COURSE_LECTURERS = gql`
	mutation AddLecturers($id: ID!, $lecturers: [ID!]!) {
		addCourseLecturers(id: $id, lecturers: $lecturers) {
			lecturers {
				matricId
				user {
					id
					cname
				}
			}
		}
	}
`;

export const DELETE_COURSE = gql`
	mutation DeleteCourse($id: ID!) {
		deleteCourse(id: $id) {
			id
		}
	}
`;

export const CLASS_FIELDS = gql`
	fragment ClassFields on Class {
		id
		translation(language: $language) {
			name
			description
		}
		studentFeePerLesson
		lecturerCommission
		color
		termAutoGeneration {
			... on TermAutoGenerationByMonth {
				day
				startTime
				endTime
			}
		}
		hasOngoingTerm
	}
`;

export const TERM_FIELDS = gql`
	fragment TermFields on Term {
		id
		name
		durationPerLesson
		studentFeePerLesson
		lecturerCommission
		lessonCount
		createdAt
	}
`;

export const GET_COURSE_CLASSES = gql`
	query GetCourseClasses($id: ID!, $language: Language!) {
		node(id: $id) {
			id
			... on Course {
				classes {
					...ClassFields
					space {
						...SpaceFields
					}
					lecturers {
						matricId
						user {
							id
							cname
							ename
						}
					}
				}
			}
		}
	}
	${CLASS_FIELDS}
	${SPACE_FIELDS}
`;

export const GET_CLASS = gql`
	query GetClass($id: ID!, $language: Language!) {
		node(id: $id) {
			... on Class {
				...ClassFields
				space {
					...SpaceFields
				}
				lecturers {
					matricId
					user {
						id
						cname
						ename
					}
				}
				terms {
					...TermFields
				}
			}
		}
	}
	${CLASS_FIELDS}
	${SPACE_FIELDS}
	${TERM_FIELDS}
`;

const GET_CLASS_TRANSLATION = gql`
	query GetClassTranslation($id: ID!, $language: Language!) {
		node(id: $id) {
			id
			... on Class {
				translation(language: $language) {
					name
					description
				}
			}
		}
	}
`;

export const CREATE_CLASS = gql`
	mutation CreateClass($input: CreateClassInput!, $language: Language!) {
		createClass(input: $input) {
			...ClassFields
			space {
				...SpaceFields
			}
			lecturers {
				matricId
				user {
					id
					cname
					ename
				}
			}
		}
	}
	${CLASS_FIELDS}
	${SPACE_FIELDS}
`;

export const UPDATE_CLASS = gql`
	mutation UpdateClass($id: ID!, $input: UpdateClassInput!, $language: Language!) {
		updateClass(id: $id, input: $input) {
			...ClassFields
			space {
				...SpaceFields
			}
			lecturers {
				matricId
				user {
					id
					cname
					ename
				}
			}
		}
	}
	${CLASS_FIELDS}
	${SPACE_FIELDS}
`;

export const DELETE_CLASS = gql`
	mutation DeleteClass($id: ID!) {
		deleteClass(id: $id) {
			id
		}
	}
`;

export const CREATE_TERM = gql`
	mutation CreateTerm($input: CreateTermInput!) {
		createTerm(input: $input) {
			...TermFields
		}
	}
	${TERM_FIELDS}
`;

export const UPDATE_TERM = gql`
	mutation UpdateTerm($id: ID!, $input: UpdateTermInput!) {
		updateTerm(id: $id, input: $input) {
			...TermFields
		}
	}
	${TERM_FIELDS}
`;

export const UPDATE_TERM_STUDENTS = gql`
	mutation UpdateTermStudents($id: ID!, $input: UpdateTermInput!) {
		updateTerm(id: $id, input: $input) {
			id
			students {
				matricId
				user {
					id
					cname
					ename
				}
			}
		}
	}
`;

export const DELETE_TERM = gql`
	mutation DeleteTerm($id: ID!) {
		deleteTerm(id: $id) {
			id
		}
	}
`;

export const CLASS_STUDENT_FIELDS = gql`
	fragment ClassStudentFields on Student {
		matricId
		phones {
			name
			number
		}
		user {
			...UserTableFields
		}
	}
	${USER_TABLE_FIELDS}
`;

export const GET_CLASS_STUDENTS = gql`
	query GetClassStudents($id: ID!) {
		node(id: $id) {
			id
			... on Class {
				students {
					...ClassStudentFields
				}
			}
		}
	}
	${CLASS_STUDENT_FIELDS}
`;

export const CREATE_CLASS_STUDENTS = gql`
	mutation CreateClassStudents($input: CreateClassStudentsInput!) {
		createClassStudents(input: $input) {
			id
			students {
				...ClassStudentFields
			}
		}
	}
	${CLASS_STUDENT_FIELDS}
`;

export const DELETE_CLASS_STUDENTS = gql`
	mutation DeleteClassStudents($input: DeleteClassStudentsInput!) {
		deleteClassStudents(input: $input) {
			id
			students {
				...ClassStudentFields
			}
		}
	}
	${CLASS_STUDENT_FIELDS}
`;

export const GET_TERM_STUDENTS = gql`
	query GetTermStudents($id: ID!) {
		node(id: $id) {
			id
			... on Term {
				students {
					matricId
					user {
						id
						cname
						ename
					}
				}
			}
		}
	}
`;
