import React, { useEffect } from 'react';
import { useUserCreateStore } from '../../../../../stores/useUserCreateStore';
import shallow from 'zustand/shallow';
import UserCreateRolesStudent from './UserCreateRolesStudent';
import UserCreateRolesLecturer from './UserCreateRolesLecturer';
import UserCreateRolesAdmin from './UserCreateRolesAdmin';
import Users from 'components/UserManagement/Users';
import { AdminStatus, LecturerStatus, StudentStatus, UserStatus } from 'generated/api';

interface UserCreateRolesContentProps {}

const UserCreateRolesContent: React.FC<UserCreateRolesContentProps> = () => {
	const [user, setUser] = useUserCreateStore(state => [state.user, state.setUser], shallow);

	const handleChange = (
		event: React.ChangeEvent<{
			name?: string;
			value: any;
			type: string;
			checked?: boolean;
		}>
	) => {
		const name = event.target.name;
		const type = event.target.type;
		let value = type === 'checkbox' ? event.target.checked : event.target.value;

		if (!name) {
			return;
		}

		const parts = name.split('.', 2);

		if (parts.length <= 1) {
			return;
		}

		if (parts[1] === 'matricId' && isNaN(value)) {
			return;
		}

		switch (parts[0]) {
			case 'student':
				if (parts[1] === 'matricId' && value.length > 7) {
					return;
				}
				setUser({ student: { ...user.student, [parts[1]]: value } });
				break;
			case 'lecturer':
				if (parts[1] === 'matricId') {
					if (value.length > 5) {
						return;
					}
					if (value.length > 0) {
						value = 'L' + value;
					} else {
						value = '';
					}
				}
				setUser({ lecturer: { ...user.lecturer, [parts[1]]: value } });
				break;
			case 'admin':
				if (parts[1] === 'matricId') {
					if (value.length > 5) {
						return;
					}
					if (value.length > 0) {
						value = 'A' + value;
					} else {
						value = '';
					}
				}
				setUser({ admin: { ...user.admin, [parts[1]]: value } });
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		if (user.status == UserStatus.Inactive) {
			setUser({
				student: {
					...user.student,
					status: StudentStatus.Inactive
				},
				lecturer: {
					...user.lecturer,
					status: LecturerStatus.Inactive
				},
				admin: {
					...user.admin,
					status: AdminStatus.Inactive
				}
			});
		}
	}, [user.status]);

	return (
		<div>
			<div>
				<UserCreateRolesStudent handleChange={handleChange} />
			</div>
			<div style={{ margin: '10px 0' }}>
				<UserCreateRolesLecturer handleChange={handleChange} />
			</div>
			<div>
				<UserCreateRolesAdmin handleChange={handleChange} />
			</div>
		</div>
	);
};

export default UserCreateRolesContent;
