import create from 'zustand';

export enum Recurrence {
	NONE = '0',
	DAILY = '1',
	WEEKLY = '2',
	MONTHLY = '3'
}

type LessonCreateStoreState = {
	startTime: Date;
	endTime: Date;
	classId: string;
	termId: string;
	spaceId: string;
	lecturers: string[];
	color: string;
	students: string[];
	recurrence: Recurrence;
	recurrenceCount: number;
	setStartTime: (startTime: Date) => void;
	setEndTime: (endTime: Date) => void;
	setClassId: (classId: string) => void;
	setTermId: (termId: string) => void;
	setSpaceId: (spaceId: string) => void;
	setLecturers: (lecturers: string[]) => void;
	setColor: (color: string) => void;
	setStudents: (students: string[]) => void;
	setRecurrence: (recurrence: Recurrence) => void;
	setRecurrenceCount: (recurrenceCount: number) => void;
	reset: () => void;
};

const initialState: Pick<
	LessonCreateStoreState,
	| 'classId'
	| 'termId'
	| 'spaceId'
	| 'lecturers'
	| 'color'
	| 'recurrence'
	| 'recurrenceCount'
	| 'students'
> = {
	classId: '',
	termId: '',
	spaceId: '',
	lecturers: [],
	color: '#188fff',
	recurrence: Recurrence.NONE,
	recurrenceCount: 1,
	students: []
};

export const useLessonCreateStore = create<LessonCreateStoreState>(set => ({
	...initialState,
	startTime: new Date(),
	endTime: new Date(),
	setStartTime: startTime => set({ startTime }),
	setEndTime: endTime => set({ endTime }),
	setClassId: classId => set({ classId }),
	setTermId: termId => set({ termId }),
	setSpaceId: spaceId => set({ spaceId }),
	setLecturers: lecturers => set({ lecturers }),
	setColor: color => set({ color }),
	setStudents: students => set({ students }),
	setRecurrence: recurrence => set({ recurrence }),
	setRecurrenceCount: recurrenceCount => set({ recurrenceCount }),
	reset: () => set({ ...initialState })
}));
