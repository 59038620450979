import React from 'react';
import { Grid, InputAdornment, TextField } from '@mui/material';

interface TermFormProps {
	name: string;
	durationPerLesson: number;
	studentFeePerLesson: number;
	lecturerCommission: number;
	lessonCount: number;
	setName: (name: string) => void;
	setDurationPerLesson: (durationPerLesson: number) => void;
	setStudentFeePerLesson: (studentFeePerLesson: number) => void;
	setLecturerCommission: (lecturerCommission: number) => void;
	setLessonCount: (lessonCount: number) => void;
}

const TermForm: React.FC<TermFormProps> = ({
	name,
	durationPerLesson,
	studentFeePerLesson,
	lecturerCommission,
	lessonCount,
	setName,
	setDurationPerLesson,
	setStudentFeePerLesson,
	setLecturerCommission,
	setLessonCount
}) => {
	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	};

	const handleDurationPerLessonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDurationPerLesson(Number(event.target.value));
	};

	const handleStudentFeePerLessonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStudentFeePerLesson(Number(event.target.value));
	};

	const handleLecturerCommissionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLecturerCommission(Number(event.target.value));
	};

	const handleLessonCountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setLessonCount(Number(event.target.value));
	};

	return (
		<Grid container spacing={2} sx={{ mt: 2 }}>
			<Grid item xs={12}>
				<TextField
					label="名字"
					value={name}
					onChange={handleNameChange}
					required
					fullWidth
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="上课时长（每堂课）"
					value={durationPerLesson}
					onChange={handleDurationPerLessonChange}
					type="number"
					required
					fullWidth
					InputProps={{
						endAdornment: <InputAdornment position="end">mins</InputAdornment>
					}}
					inputProps={{
						min: 1
					}}
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="学费（每堂课）"
					value={studentFeePerLesson}
					onChange={handleStudentFeePerLessonChange}
					type="number"
					required
					fullWidth
					InputProps={{
						startAdornment: <InputAdornment position="start">RM</InputAdornment>
					}}
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="上课次数"
					value={lessonCount}
					onChange={handleLessonCountChange}
					type="number"
					required
					fullWidth
					InputLabelProps={{
						shrink: true
					}}
					inputProps={{
						min: 1
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					label="导师分成"
					value={lecturerCommission}
					onChange={handleLecturerCommissionChange}
					type="number"
					required
					fullWidth
					InputProps={{
						endAdornment: <InputAdornment position="end">%</InputAdornment>
					}}
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default TermForm;
