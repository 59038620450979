import create from 'zustand';
import { PaymentMethod } from '../generated/api';
import { persistIndexedDB } from './IndexedDB';
import produce from 'immer';
import { v4 } from 'uuid';

export interface PaymentProofFile {
	id: string;
	key: string;
	file: File;
	status: 'READY' | 'UPLOADING' | 'FAILED' | 'COMPLETED';
	error?: string;
}

type PaymentCreateStoreState = {
	invoices: Record<
		string,
		{
			paymentId: string;
			method: PaymentMethod | null;
			methodDescription: string;
			proofs: PaymentProofFile[];
		}
	>;
	setPaymentId: (invoiceId: string, paymentId: string) => void;
	setPaymentMethod: (invoiceId: string, method: PaymentMethod) => void;
	setPaymentMethodDescription: (invoiceId: string, methodDescription: string) => void;
	setPaymentProofs: (invoiceId: string, proofs: PaymentProofFile[]) => void;
	setPaymentProofKey: (invoiceId: string, proofId: string, key: PaymentProofFile['key']) => void;
	setPaymentProofStatus: (
		invoiceId: string,
		proofId: string,
		status: PaymentProofFile['status']
	) => void;
	setPaymentProofError: (
		invoiceId: string,
		proofId: string,
		error: PaymentProofFile['error']
	) => void;
	setPayment: (
		invoiceId: string,
		payment: Partial<PaymentCreateStoreState['invoices'][keyof PaymentCreateStoreState['invoices']]>
	) => void;
};

export const usePaymentCreateStore = create<PaymentCreateStoreState>()(
	persistIndexedDB(
		set => ({
			invoices: {},
			setPaymentId: (invoiceId, paymentId) =>
				set(
					produce<PaymentCreateStoreState>(draft => {
						const payment = draft.invoices[invoiceId];
						if (payment) {
							payment.paymentId = paymentId;
						}
					})
				),
			setPaymentMethod: (invoiceId, method) =>
				set(
					produce<PaymentCreateStoreState>(draft => {
						const payment = draft.invoices[invoiceId];
						if (payment) {
							payment.method = method;
						}
					})
				),
			setPaymentMethodDescription: (invoiceId, methodDescription) =>
				set(
					produce<PaymentCreateStoreState>(draft => {
						const payment = draft.invoices[invoiceId];
						if (payment) {
							payment.methodDescription = methodDescription;
						}
					})
				),
			setPaymentProofs: (invoiceId, proofs) =>
				set(
					produce<PaymentCreateStoreState>(draft => {
						const payment = draft.invoices[invoiceId];
						if (payment) {
							payment.proofs = proofs;
						}
					})
				),
			setPaymentProofKey: (invoiceId, proofId, key) =>
				set(
					produce<PaymentCreateStoreState>(draft => {
						const payment = draft.invoices[invoiceId];
						if (payment) {
							const proof = payment.proofs.find(proof => proof.id === proofId);
							if (proof) {
								proof.key = key;
							}
						}
					})
				),
			setPaymentProofStatus: (invoiceId, proofId, status) =>
				set(
					produce<PaymentCreateStoreState>(draft => {
						const payment = draft.invoices[invoiceId];
						if (payment) {
							const proof = payment.proofs.find(proof => proof.id === proofId);
							if (proof) {
								proof.status = status;
							}
						}
					})
				),
			setPaymentProofError: (invoiceId, proofId, error) =>
				set(
					produce<PaymentCreateStoreState>(draft => {
						const payment = draft.invoices[invoiceId];
						if (payment) {
							const proof = payment.proofs.find(proof => proof.id === proofId);
							if (proof) {
								proof.error = error;
							}
						}
					})
				),
			setPayment: (invoiceId, payment) =>
				set(
					produce<PaymentCreateStoreState>(draft => {
						draft.invoices[invoiceId] = {
							...{
								paymentId: v4(),
								method: null,
								methodDescription: '',
								proofs: []
							},
							...draft.invoices[invoiceId],
							...payment
						};
					})
				)
		}),
		{
			name: 'create-payment-state',
			version: 0.1
		}
	)
);
