import React, { FormEventHandler } from 'react';
import {
	AppBar,
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogProps,
	DialogTitle,
	IconButton,
	PaperProps,
	Toolbar,
	Typography
} from '@mui/material';
import { SlideUp } from './Transitions/SlideUp';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import SimpleBar from 'simplebar-react';
import { useWindowSize } from '../hooks/useWindowSize';

interface CustomDialogProps {
	open: boolean;
	onClose: () => void;
	maxWidth: DialogProps['maxWidth'];
	fullWidth?: boolean;
	fullScreen?: DialogProps['fullScreen'];
	onSubmit?: FormEventHandler<HTMLFormElement>;
	title: string;
	actions?: React.ReactNode;
	appBarActions?: React.ReactNode;
	appBarTabs?: React.ReactNode;
	useSimpleBar?: boolean;
	PaperProps?: PaperProps;
	titleActions?: React.ReactElement[];
	children: React.ReactNode;
}

const CustomDialog: React.FC<CustomDialogProps> = ({
	open,
	onClose,
	maxWidth = 'sm',
	fullWidth = true,
	fullScreen,
	onSubmit,
	title,
	actions,
	appBarActions,
	appBarTabs,
	useSimpleBar = false,
	titleActions,
	PaperProps,
	children
}) => {
	const size = useWindowSize();

	return (
		<Dialog
			open={open}
			onClose={onClose}
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			fullScreen={fullScreen}
			TransitionComponent={fullScreen ? SlideUp : undefined}
			PaperProps={PaperProps}
			// scroll="paper"
		>
			<Box
				component="form"
				onSubmit={onSubmit}
				sx={{ overflowY: 'hidden', display: 'flex', flexDirection: 'column', height: '100%' }}
			>
				{fullScreen ? (
					<>
						<StyledAppBar position="sticky" sx={{ '*': { color: '#fff' } }}>
							<Toolbar>
								<IconButton onClick={onClose} size="large" sx={{ color: '#fff' }}>
									<Close />
								</IconButton>
								<Typography variant="h6" sx={{ ml: '10px' }}>
									{title}
								</Typography>
								<Box sx={{ ml: 'auto', mr: 0 }}>{appBarActions}</Box>
							</Toolbar>
							{appBarTabs && <Toolbar>{appBarTabs}</Toolbar>}
						</StyledAppBar>
					</>
				) : (
					<>
						{!titleActions ? (
							<DialogTitle>{title}</DialogTitle>
						) : (
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: '20px 24px'
								}}
							>
								<Typography variant="h6">{title}</Typography>
								<Box>
									{titleActions.map((action, index) => (
										<React.Fragment key={index}>{action}</React.Fragment>
									))}
								</Box>
							</Box>
						)}
					</>
				)}
				<DialogContent
					sx={{
						maxHeight: '100%',
						display: 'flex',
						flexDirection: 'column',
						...(useSimpleBar ? { p: 0 } : {})
					}}
					dividers={fullScreen}
				>
					{useSimpleBar ? (
						<SimpleBar style={{ maxHeight: size.height ? size.height * 0.8 : 640 }}>
							{children}
						</SimpleBar>
					) : (
						children
					)}
				</DialogContent>
				{actions && <DialogActions>{actions}</DialogActions>}
			</Box>
		</Dialog>
	);
};

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	background:
		theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.background.paper
}));

export default CustomDialog;
