import React, { useMemo, useState } from 'react';
import {
	GetCategoriesDocument,
	GetCategoriesQuery,
	useGetCategoriesQuery,
	useGetCategoryClassesQuery,
	useUpdateCategoriesMutation
} from '../../../generated/api';
import {
	Box,
	Button,
	ButtonBase,
	Collapse,
	Fab,
	Grid,
	IconButton,
	Paper,
	Portal,
	Theme,
	Typography,
	Zoom
} from '@mui/material';
import { useUserPreferenceStore } from '../../../stores/useUserPreferenceStore';
import { styled } from '@mui/material/styles';
import { useRefetchHandler } from '../../../hooks/useRefetchHandler';
import {
	Add,
	ArrowForward,
	CheckCircle,
	DragHandle,
	Error,
	ExpandLess,
	ExpandMore
} from '@mui/icons-material';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import CategoryCreateDialog from './CategoryCreateDialog';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loading } from '../../common/SharedComponent';
import { toast } from 'react-toastify';
import ClassDetailsDialog from '../Class/ClassDetailsDialog';
import ClassCreateDialog from '../Class/ClassCreateDialog';

interface CategoriesProps {}

const Categories: React.FC<CategoriesProps> = () => {
	const language = useUserPreferenceStore(state => state.language);
	const { data, loading, error, refetch, startPolling, stopPolling } = useGetCategoriesQuery({
		variables: { language }
	});
	const categories = useMemo(() => data?.categories || [], [data]);
	const [collapsed, setCollapsed] = useState<Record<string, boolean>>({});
	const location = useLocation();
	const navigate = useNavigate();
	const [updateCategoriesMutation] = useUpdateCategoriesMutation();

	useRefetchHandler(refetch, startPolling, stopPolling);

	const handleCategoryCreateClick = () => {
		navigate('/categories/create', { state: { backgroundLocation: location } });
	};

	if (!data) {
		if (loading) {
			return <Loading />;
		}
		if (error) {
			return <>{error.message}</>;
		}
		return <></>;
	}

	return (
		<Box sx={theme => ({ py: 3, [theme.breakpoints.down('sm')]: { py: 1 } })}>
			<DragDropContext
				onDragEnd={async ({ source: { index: from }, destination }) => {
					if (!destination) {
						return;
					}
					const to = destination.index;
					const newCategories = Array.from(categories);
					const [reorderedCategory] = newCategories.splice(from, 1);
					newCategories.splice(to, 0, reorderedCategory);

					try {
						await updateCategoriesMutation({
							variables: {
								input: {
									categories: newCategories.map((category, index) => ({
										id: category.id,
										order: index + 1
									}))
								},
								language
							},
							optimisticResponse: {
								updateCategories: newCategories
							},
							update: (cache, result) => {
								if (!result.data?.updateCategories) {
									return;
								}
								cache.writeQuery<GetCategoriesQuery>({
									query: GetCategoriesDocument,
									data: {
										categories: result.data.updateCategories
									},
									variables: {
										language
									}
								});
							}
						});
					} catch (err) {
						toast.error(`改变顺序失败！${err}`);
					}
				}}
			>
				<Droppable droppableId="categories">
					{provided => (
						<Box
							component="ul"
							className="categories"
							{...provided.droppableProps}
							ref={provided.innerRef}
							sx={{
								pl: 0,
								m: 0,
								'& > li': {
									listStyleType: 'none',
									m: 0,
									pt: 1,
									pb: 4,
									cursor: 'default !important'
								}
							}}
						>
							{categories.map((category, index) => {
								return (
									<Draggable
										key={category.id}
										draggableId={category.id}
										index={index}
										disableInteractiveElementBlocking={false}
									>
										{provided => (
											<Box
												component="li"
												ref={provided.innerRef}
												{...provided.draggableProps}
												{...provided.dragHandleProps}
											>
												<Box sx={{ display: 'flex', alignItems: 'center' }}>
													<Typography variant="h5">{category.translation.name}</Typography>
													{/*<Button sx={{ml: 2, textTransform: 'unset'}}>See More</Button>*/}
													<IconButton
														sx={{
															ml: 1,
															'& > svg': {
																transition: 'transform 200ms'
															},
															'&:hover': {
																'& > svg': {
																	transform: 'translateX(5px)'
																}
															}
														}}
														onClick={() =>
															navigate(`categories/${category.id}/${category.translation.name}`)
														}
													>
														<ArrowForward />
													</IconButton>
													<Box sx={{ mr: 0, ml: 'auto', display: 'flex', alignItems: 'center' }}>
														<Button
															variant="outlined"
															sx={{
																mr: 3
															}}
															onClick={() =>
																navigate(`/categories/${category.id}/classes/create`, {
																	state: { backgroundLocation: location }
																})
															}
														>
															创建班级
														</Button>
														<Box
															sx={{
																cursor: 'grab',
																display: 'flex',
																color: (theme: Theme) =>
																	theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.54)' : '#fff'
															}}
														>
															<DragHandle />
														</Box>
														<IconButton
															size="small"
															onClick={() =>
																setCollapsed({
																	...collapsed,
																	[category.id]: !collapsed[category.id]
																})
															}
															sx={{ ml: 2 }}
														>
															{collapsed[category.id] ? (
																<ExpandMore fontSize="large" />
															) : (
																<ExpandLess fontSize="large" />
															)}
														</IconButton>
													</Box>
												</Box>
												<Collapse in={!collapsed[category.id]}>
													<Box sx={{ mt: 3 }}>
														<CategorySection id={category.id} />
													</Box>
												</Collapse>
											</Box>
										)}
									</Draggable>
								);
							})}
							{provided.placeholder}
						</Box>
					)}
				</Droppable>
			</DragDropContext>
			{/*<RootGrid container spacing={3}>*/}
			{/*	{categories.map(category => (*/}
			{/*		<Grid key={category.id} item {...gridSizing}>*/}
			{/*			<CategoriesItem category={category} />*/}
			{/*		</Grid>*/}
			{/*	))}*/}
			{/*	<Grid item {...gridSizing}>*/}
			{/*		<CategoriesItemCreate />*/}
			{/*	</Grid>*/}
			{/*</RootGrid>*/}
			<CategoryCreateDialog />
			<ClassDetailsDialog />
			<ClassCreateDialog />
			{/*<CategoriesDeleteDialog />*/}
			{/*<CategoriesDetailsDialog categories={categories} />*/}
			<Portal>
				<Zoom in={true}>
					<Fab
						variant="extended"
						color="primary"
						aria-label="add"
						size="large"
						sx={theme => ({
							position: 'absolute',
							bottom: 50,
							right: '5vw',
							color: theme.palette.mode === 'light' ? '#fff' : '#000',
							[theme.breakpoints.down('md')]: {
								bottom: 30
							}
						})}
						onClick={handleCategoryCreateClick}
					>
						<Add sx={{ mr: 1 }} />
						创建课程种类
					</Fab>
				</Zoom>
			</Portal>
		</Box>
	);
};

const CategorySection: React.FC<{ id: string }> = ({ id }) => {
	const [language, fetchPolicy] = useUserPreferenceStore(state => [
		state.language,
		state.fetchPolicy
	]);
	const { data, loading, error, refetch, startPolling, stopPolling } = useGetCategoryClassesQuery({
		variables: {
			id,
			language
		},
		fetchPolicy
	});
	const navigate = useNavigate();
	const location = useLocation();

	useRefetchHandler(refetch, startPolling, stopPolling);

	const classes = useMemo(() => {
		let classes: {
			id: string;
			name: string;
			hasOngoingTerm: boolean;
			course: { id: string; name: string };
		}[] = [];
		if (data?.node?.__typename === 'Category') {
			data.node.courses.forEach(course => {
				course.classes.forEach(c => {
					classes.push({
						id: c.id,
						name: c.translation.name,
						hasOngoingTerm: c.hasOngoingTerm,
						course: {
							id: course.id,
							name: course.translation.name
						}
					});
				});
			});
		}
		return classes;
	}, [data]);

	if (!data) {
		if (loading) {
			return <>Loading....</>;
		}
		if (error) {
			return <>{error.message}</>;
		}
		return <></>;
	}

	return (
		<Grid container spacing={2}>
			{classes.map(c => (
				<Grid
					key={c.id}
					item
					xs={6}
					md={4}
					lg={3}
					xl={2}
					// sx={theme => ({
					// 	[theme.breakpoints.up(2100)]: {
					// 		flexBasis: '12.5%',
					// 		maxWidth: '12.5%'
					// 	}
					// })}
				>
					<ButtonBase
						sx={{ width: '100%', height: 110, textAlign: 'left' }}
						onClick={() =>
							navigate(`/classes/${c.id}`, { state: { backgroundLocation: location } })
						}
					>
						<Paper variant="outlined" sx={{ p: 2, pb: 0, width: '100%', height: '100%' }}>
							<Box sx={{ display: 'flex' }}>
								<div>
									<Typography variant="h6">{c.name}</Typography>
									<Typography variant="body2" sx={{ opacity: 0.8 }}>
										{c.course.name}
									</Typography>
								</div>
								<Box sx={{ ml: 'auto', mr: 0, mt: 0.5 }}>
									{c.hasOngoingTerm ? <CheckCircle color="success" /> : <Error color="error" />}
								</Box>
							</Box>
						</Paper>
					</ButtonBase>
				</Grid>
			))}
			<Grid
				item
				xs={6}
				md={4}
				lg={3}
				xl={2}
				// sx={theme => ({
				// 	[theme.breakpoints.up(2100)]: {
				// 		flexBasis: '12.5%',
				// 		maxWidth: '12.5%'
				// 	}
				// })}
			>
				<ClassCreate categoryId={id} />
			</Grid>
		</Grid>
	);
};

const ClassCreate: React.FC<{ categoryId: string }> = ({ categoryId }) => {
	const navigate = useNavigate();
	const location = useLocation();

	const handleClick = () => {
		navigate(`/categories/${categoryId}/classes/create`, {
			state: { backgroundLocation: location }
		});
	};

	return (
		<ButtonBase sx={{ width: '100%', height: 110 }} onClick={handleClick}>
			<StyledPaper variant="outlined">
				<Add color="primary" fontSize="large" sx={{ mb: 0 }} />
				<Typography variant="body2">创建新的班级</Typography>
			</StyledPaper>
		</ButtonBase>
	);
};

const StyledPaper = styled(Paper)(({ theme }) => ({
	borderStyle: 'dashed',
	height: '100%',
	width: '100%',
	cursor: 'pointer',
	transition: 'border-color 300ms',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	flexDirection: 'column',
	'&:hover': {
		borderColor: theme.palette.primary.main
	}
}));

export default Categories;
