import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material';
import { useDeleteUserMutation, User } from '../../../generated/api';
import { useAtom } from 'jotai';
import { userDeleteDialogOpenAtom } from '../../../atoms';
import { deleteUserUpdate } from '../update/deleteUserUpdate';
import { useLocation, useNavigate } from 'react-router-dom';

interface UserDeleteDialogProps {
	user: User;
}

const UserDeleteDialog: React.FC<UserDeleteDialogProps> = ({ user }) => {
	const [open, setOpen] = useAtom(userDeleteDialogOpenAtom);
	const [deleteUserMutation] = useDeleteUserMutation({
		fetchPolicy: 'no-cache',
		update: deleteUserUpdate
	});
	const navigate = useNavigate();
	const location = useLocation();

	const handleClose = () => {
		setOpen(false);
	};

	const handleDelete = async () => {
		try {
			handleClose();
			navigate(-1);

			await deleteUserMutation({
				variables: { id: user.id },
				optimisticResponse: {
					deleteUser: {
						__typename: 'DeletePayload',
						id: user.id
					}
				}
			});
		} catch {
			setOpen(true);
			navigate(`/users/${user.id}`, { state: { backgroundLocation: location } });
		}
	};

	return (
		<Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
			<DialogTitle>删除用户</DialogTitle>
			<DialogContent>
				<DialogContentText>确定要删除此用户吗?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>
					取消
				</Button>
				<Button color="primary" onClick={handleDelete}>
					确定
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default UserDeleteDialog;
