import React from 'react';
import { GetInvoicesQueryResult, InvoiceStatus } from '../../../generated/api';
import { Button } from '@mui/material';
import { Block, Delete, Done, Payment, Receipt } from '@mui/icons-material';
import { useUpdateAtom } from 'jotai/utils';
import { invoiceCloseIdAtom, invoiceDeleteIdAtom, invoiceOpenIdAtom } from '../../../atoms';
import InvoiceDeleteDialog from './InvoiceDeleteDialog';
import InvoiceCloseDialog from './InvoiceCloseDialog';
import InvoiceOpenDialog from './InvoiceOpenDialog';
import { Link as RouterLink } from 'react-router-dom';

interface InvoiceDetailsPageActionsProps {
	invoice: Exclude<Exclude<GetInvoicesQueryResult, undefined>['data'], undefined>['invoices'][0];
}

const InvoiceDetailsPageActions: React.FC<InvoiceDetailsPageActionsProps> = ({ invoice }) => {
	const setDeleteId = useUpdateAtom(invoiceDeleteIdAtom);
	const setOpenId = useUpdateAtom(invoiceOpenIdAtom);
	const setCloseId = useUpdateAtom(invoiceCloseIdAtom);

	const handleDeleteClick = () => {
		setDeleteId(invoice.id);
	};

	const handleOpenInvoiceClick = () => {
		setOpenId(invoice.id);
	};

	const handleCloseInvoiceClick = () => {
		setCloseId(invoice.id);
	};

	switch (invoice.status) {
		case InvoiceStatus.Draft:
			return (
				<>
					<Button
						variant="outlined"
						color="error"
						startIcon={<Delete />}
						onClick={handleDeleteClick}
						sx={{ mr: 2 }}
					>
						删除发票
					</Button>
					<Button variant="outlined" startIcon={<Done />} onClick={handleOpenInvoiceClick}>
						开启发票
					</Button>
					<InvoiceDeleteDialog />
					<InvoiceOpenDialog />
				</>
			);
		case InvoiceStatus.Open:
			return (
				<>
					<Button
						variant="outlined"
						color="error"
						startIcon={<Block />}
						onClick={handleCloseInvoiceClick}
						sx={{ mr: 2 }}
					>
						关闭发票
					</Button>
					<Button variant="outlined" startIcon={<Payment />} component={RouterLink} to="payment">
						创建付款
					</Button>
					<InvoiceCloseDialog />
				</>
			);
		case InvoiceStatus.Paid:
			return (
				<>
					<Button
						variant="outlined"
						color="error"
						startIcon={<Block />}
						onClick={handleCloseInvoiceClick}
						sx={{ mr: 2 }}
					>
						关闭发票
					</Button>
					<Button variant="outlined" startIcon={<Receipt />} component={RouterLink} to="receipt">
						查看收据
					</Button>
					<InvoiceCloseDialog />
				</>
			);
		default:
			return <></>;
	}
};

export default InvoiceDetailsPageActions;
