import create from 'zustand';
import produce from 'immer';
import { persistIndexedDB } from './IndexedDB';
import { CreateCategoryTranslationInput, Language } from '../generated/api';

type CategoryCreateStoreState = {
	translations: Record<Language, Omit<CreateCategoryTranslationInput, 'language'>>;
	setName: (name: string, language: Language) => void;
	setDescription: (description: string, language: Language) => void;
	reset: () => void;
};

const initialData: Pick<CategoryCreateStoreState, 'translations'> = {
	translations: {
		[Language.Zh]: {
			name: '',
			description: ''
		},
		[Language.En]: {
			name: '',
			description: ''
		}
	}
};

export const useCategoryCreateStore = create<CategoryCreateStoreState>()(
	persistIndexedDB(
		set => ({
			...initialData,
			setName: (name, language) =>
				set(
					produce<CategoryCreateStoreState>(draft => {
						draft.translations[language].name = name;
					})
				),
			setDescription: (description, language) =>
				set(
					produce<CategoryCreateStoreState>(draft => {
						draft.translations[language].description = description;
					})
				),
			reset: () => set(initialData)
		}),
		{
			name: 'create-category-state',
			version: 0
		}
	)
);
