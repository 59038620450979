import React from 'react';
import UserDetailsGroup from './UserDetailsGroup';
import UserDetailsItem from './UserDetailsItem';
import { UserDetailsItemKey } from '../../../types';
import { Box, SxProps, Typography } from '@mui/material';
import { StudentStatus, User } from '../../../generated/api';
import UserDetailsStudentCreate from './UserDetailsRoleCreate/UserDetailsStudentCreate';
import UserDetailsStudentDelete from './UserDetailsRoleDelete/UserDetailsStudentDelete';

interface UserStudentsDetailsProps {
	user: User;
}

const UserStudentDetails: React.FC<UserStudentsDetailsProps> = ({ user }) => {
	return (
		<div>
			{user.student ? (
				<>
					<UserDetailsGroup title="学生资料">
						<UserDetailsItem itemKey={UserDetailsItemKey.STUDENT_MATRIC_ID}>
							<Typography sx={userDetailsValueStyle}>
								{user.student.matricId}
							</Typography>
						</UserDetailsItem>
						<UserDetailsItem itemKey={UserDetailsItemKey.STUDENT_PHONES}>
							<Box sx={userDetailsValueStyle}>
								{user.student.phones?.map((phone, index) => (
									<Typography key={index}>
										{phone.number} ({phone.name})
									</Typography>
								))}
							</Box>
						</UserDetailsItem>
						<UserDetailsItem itemKey={UserDetailsItemKey.STUDENT_STATUS}>
							<Typography sx={userDetailsValueStyle}>
								{user.student.status === StudentStatus.Active
									? '在籍'
									: user.student.status === StudentStatus.Temporary
									? '试课'
									: '不在籍'}
							</Typography>
						</UserDetailsItem>
					</UserDetailsGroup>
					<UserDetailsStudentDelete user={user} />
				</>
			) : (
				<UserDetailsStudentCreate user={user} />
			)}
		</div>
	);
};

const userDetailsValueStyle: SxProps = {
	width: '70%'
};

export default UserStudentDetails;
