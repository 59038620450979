import React from 'react';
import { InvoiceStatus } from '../../../generated/api';
import { Box } from '@mui/material';

interface InvoiceStatusChipProps {
	status: InvoiceStatus;
	chinese?: boolean;
}

const InvoiceStatusChip: React.FC<InvoiceStatusChipProps> = ({ status, chinese }) => {
	const { color, backgroundColor, label } = statusChip[status];

	return (
		<Box
			sx={theme => ({
				height: !chinese ? '22px' : '30px',
				minWidth: !chinese ? '22px' : '48px',
				lineHeight: '0',
				borderRadius: '6px',
				cursor: 'default',
				alignItems: 'center',
				whiteSpace: 'nowrap',
				display: 'inline-flex',
				justifyContent: 'center',
				padding: !chinese ? '0px 8px' : '4px 8px',
				color: color[theme.palette.mode],
				backgroundColor: backgroundColor[theme.palette.mode],
				fontSize: !chinese ? '0.75rem' : '0.875rem',
				fontWeight: !chinese ? 700 : 400,
				textTransform: 'uppercase',
				marginBottom: '8px'
			})}
		>
			{label[chinese ? 'chinese' : 'english']}
		</Box>
	);
};

const statusChip: {
	[x in InvoiceStatus]: {
		label: {
			chinese: string;
			english: string;
		};
		backgroundColor: {
			light: string;
			dark: string;
		};
		color: {
			light: string;
			dark: string;
		};
	};
} = {
	[InvoiceStatus.Draft]: {
		label: {
			chinese: '草稿',
			english: 'DRAFT'
		},
		backgroundColor: {
			light: 'rgb(99, 115, 129, 0.16)',
			dark: 'rgb(223, 227, 232)'
		},
		color: {
			light: 'rgb(99, 115, 129)',
			dark: 'rgb(0, 0, 0)'
		}
	},
	[InvoiceStatus.Open]: {
		label: {
			chinese: '未缴',
			english: 'UNPAID'
		},
		backgroundColor: {
			light: 'rgb(183, 129, 3, 0.16)',
			dark: 'rgb(255, 193, 7)'
		},
		color: {
			light: 'rgb(183, 129, 3)',
			dark: 'rgb(0, 0, 0)'
		}
	},
	[InvoiceStatus.Paid]: {
		label: {
			chinese: '完成',
			english: 'PAID'
		},
		backgroundColor: {
			light: 'rgba(84, 214, 44, 0.16)',
			dark: 'rgb(84, 214, 44)'
		},
		color: {
			light: 'rgb(34, 154, 22)',
			dark: 'rgb(0, 0, 0)'
		}
	},
	[InvoiceStatus.Void]: {
		label: {
			chinese: '失效',
			english: 'VOID'
		},
		backgroundColor: {
			light: 'rgb(255, 72, 66, 0.16)',
			dark: 'rgb(255, 72, 66)'
		},
		color: {
			light: 'rgb(183, 33, 54)',
			dark: 'rgb(255, 255, 255)'
		}
	}
};

export default InvoiceStatusChip;
