import React from 'react';
import { useUserCreateStore } from '../../../stores/useUserCreateStore';
import { Step, StepLabel, Stepper } from '@mui/material';
import { styled } from '@mui/material/styles';

interface UserCreateDialogStepperProps {}

const UserCreateDialogStepper: React.FC<UserCreateDialogStepperProps> = () => {
	const step = useUserCreateStore(state => state.step);

	return (
		<StyledStepper activeStep={step} alternativeLabel>
			<Step>
				<StepLabel>填写用户基本资料</StepLabel>
			</Step>
			<Step>
				<StepLabel>添加用户身份</StepLabel>
			</Step>
		</StyledStepper>
	);
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
	paddingTop: '20px',
	marginBottom: '20px',
	'& .MuiStepIcon-text': {
		fill: theme.palette.mode === 'light' ? '#fff' : '#000'
	}
}));

export default UserCreateDialogStepper;
