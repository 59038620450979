import React, { useState } from 'react';
import {
	Box,
	Button,
	Paper,
	TextField,
	Theme,
	Tooltip,
	Typography,
	useMediaQuery
} from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import InvoiceToDialog from '../../components/PaymentManagement/Invoices/InvoiceToDialog';
import { DatePicker } from '@mui/x-date-pickers';
import InvoiceItem from './InvoiceItem';
import ChooseReceivableDialog from '../../components/PaymentManagement/Invoices/ChooseReceivableDialog';
import { v4 } from 'uuid';
import { Receivable, ReceivableType } from '../../generated/api';

interface InvoiceFormProps {
	id: string;
	debtor: {
		id: string;
		cname: string;
		ename: string;
		address: string;
	} | null;
	createAt: Date | null;
	discount: number;
	tax: number;
	items: Array<{
		id: string;
		title: string;
		description: string;
		service: ReceivableType | null;
		quantity: number;
		price: number;
		receivable?: Omit<Receivable, 'debtor'>;
	}>;
	setDebtor: (debtor: InvoiceFormProps['debtor']) => void;
	setCreateAt: (createAt: Date | null) => void;
	setDiscount: (discount: number) => void;
	setTax: (tax: number) => void;
	setItemTitle: (id: string, title: string) => void;
	setItemDescription: (id: string, description: string) => void;
	setItemService: (id: string, service: ReceivableType | null) => void;
	setItemQuantity: (id: string, quantity: number) => void;
	setItemPrice: (id: string, price: number) => void;
	addItems: (items: InvoiceFormProps['items']) => void;
	removeItem: (id: string) => void;
}

const InvoiceForm: React.FC<InvoiceFormProps> = ({
	id,
	debtor,
	createAt,
	discount,
	tax,
	items,
	setDebtor,
	setCreateAt,
	setDiscount,
	setTax,
	setItemDescription,
	setItemPrice,
	setItemQuantity,
	setItemService,
	setItemTitle,
	addItems,
	removeItem
}) => {
	const [destinationAddressDialogOpen, setDestinationAddressDialogOpen] = React.useState(false);
	const [newItemOpen, setNewItemOpen] = useState(false);
	const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

	const handleDestinationAddressAddClick = () => {
		setDestinationAddressDialogOpen(open => !open);
	};

	const handleNewItemClick = () => {
		setNewItemOpen(true);
	};

	return (
		<Paper
			sx={{
				mt: 6,
				borderRadius: '16px',
				p: 4,
				boxShadow: theme =>
					theme.palette.mode === 'light'
						? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
						: 'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px'
			}}
		>
			<Box
				sx={theme => ({
					display: 'flex',
					flexWrap: 'nowrap',
					[theme.breakpoints.down('md')]: {
						flexWrap: 'wrap'
					}
				})}
			>
				<img
					src="/images/ekids_logo.webp"
					css={{
						maxWidth: 200,
						height: 'max-content',
						marginRight: 16,
						marginBottom: 16,
						'@media(max-width:900px)': {
							maxWidth: 160,
							marginBottom: 8
						}
					}}
				/>
				<Box sx={{ mb: 2 }}>
					<Typography variant="h6">
						ELEMENTE EDUCATION SDN. BHD.{' '}
						<span css={{ fontSize: '0.875rem', fontWeight: 'normal' }}>(Co. No.1280836-M)</span>
					</Typography>
					<Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
						Level 2, L2.27 KL GATEWAY MALL.
						<br />
						2, Jalan Kerinchi, Kampung Kerinchi, 59200 Kuala Lumpur, Wilayah Persekutuan Kuala
						Lumpur, Malaysia.
					</Typography>
					<Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
						H/P: 012-336 6124
					</Typography>
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
					flexWrap: 'wrap'
				}}
			>
				<Box sx={{ mt: 1, width: 360, maxWidth: '100%' }}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Typography variant="h6" sx={{ opacity: 0.6, fontSize: '1.125rem !important' }}>
							To:
						</Typography>
						<Button
							startIcon={!debtor ? <Add /> : <Edit />}
							onClick={handleDestinationAddressAddClick}
						>
							{!debtor ? 'Add' : 'Change'}
						</Button>
						<InvoiceToDialog
							open={destinationAddressDialogOpen}
							setOpen={setDestinationAddressDialogOpen}
							setDebtor={setDebtor}
						/>
					</Box>
					<Typography variant="subtitle1" sx={{ my: 1 }}>
						{debtor?.cname}
						{debtor?.cname !== '' ? ' ' : ''}
						{debtor?.ename}
					</Typography>
					<Typography variant="subtitle1">{debtor?.address}</Typography>
				</Box>
				<Box
					sx={theme => ({
						display: 'flex',
						flexDirection: 'column',
						mt: 2,
						[theme.breakpoints.down('md')]: {
							width: '100%',
							mt: 4
						}
					})}
				>
					<Tooltip title="Invoice Number只是暂做参考，实际请在创建后进行确认" placement="top">
						<TextField
							label="Invoice Number"
							value={id}
							disabled
							InputProps={{ sx: { borderRadius: '8px' } }}
							sx={{ mb: 2 }}
							fullWidth={isMD}
						/>
					</Tooltip>
					<DatePicker
						renderInput={props => <TextField {...props} fullWidth={isMD} />}
						label="Date"
						value={createAt}
						onChange={newValue => {
							setCreateAt(newValue);
						}}
						InputProps={{ sx: { borderRadius: '8px' } }}
					/>
				</Box>
			</Box>
			<Box sx={{ mt: 6 }}>
				<Typography variant="h6" sx={{ opacity: 0.6, mb: 2 }}>
					Items:
				</Typography>
				{items.map(item => (
					<InvoiceItem
						key={item.id}
						item={item}
						setItemTitle={setItemTitle}
						setItemDescription={setItemDescription}
						setItemService={setItemService}
						setItemQuantity={setItemQuantity}
						setItemPrice={setItemPrice}
						removeItem={removeItem}
					/>
				))}
			</Box>
			<Box
				sx={theme => ({
					mt: 2,
					display: 'flex',
					justifyContent: 'space-between',
					[theme.breakpoints.down('md')]: {
						flexDirection: 'column-reverse',
						justifyContent: 'flex-start',
						alignItems: 'flex-start'
					}
				})}
			>
				<Button startIcon={<Add />} onClick={handleNewItemClick}>
					Add New Item
				</Button>
				<Box
					sx={theme => ({
						display: 'flex',
						flexWrap: 'wrap',
						[theme.breakpoints.down('md')]: {
							width: '100%',
							flexBasis: '100%',
							mt: 1
						}
					})}
				>
					<TextField
						label="Discount"
						type="number"
						value={discount || ''}
						onChange={event => {
							setDiscount(Number(event.target.value));
						}}
						InputProps={{
							// startAdornment: <InputAdornment position="start">RM</InputAdornment>,
							sx: { borderRadius: '8px' }
						}}
						fullWidth={isMD}
						sx={theme => ({
							mr: 2,
							[theme.breakpoints.down('md')]: {
								mr: 0,
								mb: 2
							}
						})}
					/>
					<TextField
						label="Taxes"
						type="number"
						value={tax || ''}
						onChange={event => {
							setTax(Number(event.target.value));
						}}
						InputProps={{
							// startAdornment: <InputAdornment position="start">RM</InputAdornment>,
							sx: { borderRadius: '8px' }
						}}
						sx={theme => ({
							[theme.breakpoints.down('md')]: {
								mb: 2
							}
						})}
						fullWidth={isMD}
					/>
				</Box>
				<ChooseReceivableDialog
					open={newItemOpen}
					setOpen={setNewItemOpen}
					filterIds={items.map(item => item.id)}
					onSelected={item =>
						addItems([
							{
								id: item.id,
								title: item.name,
								description: '',
								service: item.type,
								quantity: item.quantity,
								price: item.price,
								receivable: item
							}
						])
					}
					onNewItemClick={() =>
						addItems([
							{
								id: v4(),
								title: '',
								description: '',
								service: null,
								quantity: 1,
								price: 0
							}
						])
					}
				/>
			</Box>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					mt: 4,
					'& > div': {
						display: 'flex',
						justifyContent: 'flex-end',
						textAlign: 'right',
						mt: 2,
						'& > .MuiTypography-root': {
							'&:last-of-type': {
								width: 140
							}
						}
					}
				}}
			>
				<div>
					<Typography variant="body1">Subtotal:</Typography>
					<Typography variant="body1">
						RM
						{items.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2)}
					</Typography>
				</div>
				<div>
					<Typography variant="body1">Discount:</Typography>
					<Typography variant="body1" color={discount ? 'error' : 'inherit'}>
						{discount ? `- RM${discount.toFixed(2)}` : '-'}
					</Typography>
				</div>
				<div>
					<Typography variant="body1">Taxes:</Typography>
					<Typography variant="body1">{tax ? `RM${tax.toFixed(2)}` : '-'}</Typography>
				</div>
				<div>
					<Typography variant="h6">Total price:</Typography>
					<Typography variant="h6">
						{(() => {
							const total =
								items.reduce((total, item) => total + item.price * item.quantity, 0) -
								discount +
								tax;
							if (total < 0) {
								return `- RM${(-total).toFixed(2)}`;
							} else {
								return `RM${total.toFixed(2)}`;
							}
						})()}
					</Typography>
				</div>
			</Box>
		</Paper>
	);
};

export default InvoiceForm;
