import React from 'react';
import { ListItemButton, ListItemIcon, ListItemText, Theme } from '@mui/material';
import NavLinkWithPreload from '../share-components/Route/NavLinkWithPreload';
import { NavLinkProps } from 'react-router-dom';
import { styled, SxProps } from '@mui/material/styles';
import { useDrawerMinimizedStore } from '../stores/useDrawerMinimizedStore';
import { Page } from '../hooks/usePage';
import { useAtomValue } from 'jotai/utils';
import { pageAtom } from '../atoms';

interface SideDrawerItemProps {
	route: {
		name: string | undefined;
		image: JSX.Element;
		icon: JSX.Element;
		link: string;
		page: Page;
	};
}

const SideDrawerItem: React.FC<SideDrawerItemProps> = ({ route, ...props }) => {
	const drawerMinimized = useDrawerMinimizedStore(state => state.minimized);

	const page = useAtomValue(pageAtom);

	const active = (route.link === '/' && page === 0) || (route.link !== '/' && page === route.page);

	// TODO: should remove NavLink altogether in the future, but it is totally fine in current implementation

	return (
		<>
			<StyledNavLink to={route.link} active={active} {...props}>
				<ListItemIcon
					sx={{
						minWidth: !drawerMinimized ? 37 : 20,
						ml: drawerMinimized ? 0 : -0.4,
						'& > .MuiSvgIcon-root': {
							mr: drawerMinimized ? 0 : 'auto'
						}
					}}
				>
					{React.cloneElement(route.image, { fontSize: 'small' })}
				</ListItemIcon>
				{!drawerMinimized && (
					<ListItemText
						primary={route.name}
						sx={{ whiteSpace: 'nowrap', flex: drawerMinimized ? 0 : '1 1 auto' }}
					/>
				)}
			</StyledNavLink>
		</>
	);
};

const StyledNavLink = styled(
	({ children, active, sx, ...props }: NavLinkProps & { active: boolean; sx?: SxProps<Theme> }) => (
		<NavLinkWithPreload {...props}>
			{({ isActive }) => (
				<ListItemButton
					sx={{
						height: '100%',
						width: '100%',
						borderRadius: '6px',
						display: 'flex',
						justifyContent: 'center',
						...(active
							? {
									background: (theme: Theme) =>
										theme.palette.mode === 'light' ? 'rgb(230, 235, 248)' : '#303f9f',
									color: (theme: Theme) =>
										theme.palette.mode === 'light'
											? theme.palette.primary.dark
											: theme.palette.text.primary,
									'& > div > svg': {
										color: (theme: Theme) =>
											theme.palette.mode === 'light'
												? theme.palette.primary.dark
												: theme.palette.text.primary
									}
							  }
							: {}),
						...sx
					}}
				>
					{/**
					 //@ts-ignore*/}
					{children}
				</ListItemButton>
			)}
		</NavLinkWithPreload>
	)
)(({ theme }) => ({
	display: 'block',
	width: '86%',
	height: 50,
	minHeight: 50,
	margin: '3px 7%',
	color: theme.palette.text.primary,
	'& svg': {
		color: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.6)' : 'rgba(0, 0, 0, 0.38)'
	},
	borderRadius: 6,
	fontSize: '1em',
	textDecoration: 'none',
	position: 'relative'
}));

export default SideDrawerItem;
