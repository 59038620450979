import React, { useCallback } from 'react';
import { Button, DialogActions } from '@mui/material';
import { useUpdateAtom } from 'jotai/utils';
import { userRoleCreateDialogOpenAtom } from '../../../atoms';
import { useUserRoleCreateStore } from '../../../stores/useUserRoleCreateStore';
import { useUserTableControllerStore } from '../../../stores/useUserTableControllerStore';
import { UserRole } from '../../../types';
import { useNavigate } from 'react-router-dom';

interface UserRoleCreateDialogActionsProps {
	isXS: boolean;
}

const UserRoleCreateDialogActions: React.FC<UserRoleCreateDialogActionsProps> =
	({ isXS }) => {
		const navigate = useNavigate();
		const role = useUserTableControllerStore(state => state.role);
		const resetState = useUserRoleCreateStore(
			useCallback(
				state =>
					role === UserRole.STUDENT
						? state.resetStudent
						: role === UserRole.LECTURER
						? state.resetLecturer
						: role === UserRole.ADMIN
						? state.resetAdmin
						: () => {},
				[role]
			)
		);

		const handleClose = () => {
			navigate(-1);
		};

		const handleReset = () => {
			resetState();
		};

		return (
			<DialogActions>
				<Button color="primary" onClick={handleClose}>
					取消
				</Button>
				{!isXS && (
					<Button color="primary" onClick={handleReset}>
						重置
					</Button>
				)}
				<Button color="primary" type="submit">
					添加
				</Button>
			</DialogActions>
		);
	};

export default UserRoleCreateDialogActions;
