import React, { useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { termDeleteIdAtom, termDetailsIdAtom } from '../../../atoms';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FilledInput,
	FormHelperText,
	Typography
} from '@mui/material';
import { ApolloError, useApolloClient } from '@apollo/client';
import {
	TermFieldsFragment,
	TermFieldsFragmentDoc,
	useDeleteTermMutation
} from '../../../generated/api';
import { styled } from '@mui/material/styles';
import FormWrapper from '../../common/FormWrapper';
import { useUpdateAtom } from 'jotai/utils';
import { toast } from 'react-toastify';

interface TermDeleteDialogProps {}

const TermDeleteDialog: React.FC<TermDeleteDialogProps> = () => {
	const [termId, setTermId] = useAtom(termDeleteIdAtom);
	const setTermDetailsId = useUpdateAtom(termDetailsIdAtom);
	const { cache } = useApolloClient();
	const term = useMemo(
		() =>
			termId
				? cache.readFragment<TermFieldsFragment>({
						fragment: TermFieldsFragmentDoc,
						id: cache.identify({
							__typename: 'Term',
							id: termId
						})
				  })
				: null,
		[termId]
	);
	const [confirmationText, setConfirmationText] = useState('');
	const [isError, setIsError] = useState(false);
	const [deleteTermMutation, { loading, error }] = useDeleteTermMutation({
		update: (cache, result) => {
			const id = result.data?.deleteTerm?.id;
			if (!id) {
				return;
			}
			cache.evict({
				id: cache.identify({
					__typename: 'Term',
					id
				})
			});
			cache.gc();
		}
	});

	const handleClose = () => {
		setTermId('');
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (isError) {
			setIsError(false);
		}
		setConfirmationText(event.target.value);
	};

	const handleDelete = async () => {
		if (!term) {
			return;
		}

		if (confirmationText !== term.name) {
			setIsError(true);
			return;
		}

		try {
			await deleteTermMutation({ variables: { id: term.id } });
			toast.success('删除学期成功!');
			// enqueueSnackbar('删除学期成功!', { variant: 'success' });
			handleClose();
			setConfirmationText('');
			setTermDetailsId('');
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(`删除学期失败! ${err.message}`);
				// enqueueSnackbar(`删除学期失败! ${err.message}`, { variant: 'error' });
			}
		}
	};

	return (
		<Dialog open={!!term} onClose={handleClose}>
			<DialogTitle>删除{term?.name}</DialogTitle>
			<DialogContent>
				<FormWrapper loading={loading} error={error}>
					<DialogContentText>
						确定要删除{term?.name}吗? 删除{term?.name}
						将会连带删除属于该学期的班次及所有其上课和学费相关记录。
					</DialogContentText>
					<Typography variant="body2" style={{ marginTop: 10, marginBottom: 5 }}>
						输入
						<Typography variant="body2" color="primary" component="span" sx={{ margin: '0 2px' }}>
							{term?.name}
						</Typography>
						以确认删除
					</Typography>
					<StyledFilledInput
						value={confirmationText}
						onChange={handleChange}
						error={isError}
						fullWidth
						autoFocus
					/>
					<FormHelperText error={isError}>
						{isError ? `请输入${term?.name || ''}` : ''}
					</FormHelperText>
				</FormWrapper>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>取消</Button>
				<Button onClick={handleDelete}>删除</Button>
			</DialogActions>
		</Dialog>
	);
};

const StyledFilledInput = styled(FilledInput)({
	'& .MuiFilledInput-input': {
		paddingTop: '16px'
	}
});

export default TermDeleteDialog;
