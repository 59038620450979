export class Relay {
	public static marshalId(kind: string, id: string): string {
		return btoa(kind + ':' + id)
			.split('=')
			.join('');
	}

	public static unmarshalId(id: string): string {
		return atob(id).split(':')[1];
	}
}
