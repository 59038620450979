import create from 'zustand';
import { Order } from '../types';
import { persistIndexedDB } from './IndexedDB';
import produce from 'immer';
import { persist } from 'zustand/middleware';

export enum TableFilterHolder {
	USER,
	STUDENT,
	LECTURER,
	ADMIN,
	COURSE,
	CLASS,
	CLASS_STUDENT,
	LOCATION,
	SPACE,
	COURSE_REGISTRATION,
	INVOICE,
	PAYMENT,
	REFUND,
	TERM_STUDENT
}

const initialState: TableFilter = {
	order: 'asc',
	orderBy: '',
	selected: [],
	rowsPerPage: 10,
	page: 0,
	keywords: '',
	startFrom: null,
	endAt: null
};

type TableFilter = {
	order: Order;
	orderBy: string;
	selected: string[];
	rowsPerPage: number;
	page: number;
	keywords: string;
	startFrom: Date | null;
	endAt: Date | null;
};

export type TableFilterStoreState = {
	filters: Record<TableFilterHolder, TableFilter>;
	// use for component that outside of table that need to function with filter, exp. SearchBar
	currentHolder: TableFilterHolder;
	setCurrentHolder: (holder: TableFilterHolder) => void;
	setOrder: (holder: TableFilterHolder) => (order: Order) => void;
	setOrderBy: (holder: TableFilterHolder) => (orderBy: string) => void;
	setSelected: (holder: TableFilterHolder) => (selected: string[]) => void;
	setRowsPerPage: (holder: TableFilterHolder) => (rowsPerPage: number) => void;
	setPage: (holder: TableFilterHolder) => (page: number) => void;
	setKeywords: (holder: TableFilterHolder) => (keywords: string) => void;
	setStartFrom: (holder: TableFilterHolder) => (startFrom: Date | null) => void;
	setEndAt: (holder: TableFilterHolder) => (endAt: Date | null) => void;
	resetFilter: (holder: TableFilterHolder) => () => void;
};

export const useTableFilterStore = create<TableFilterStoreState>()(
	persistIndexedDB(
		set => ({
			filters: {
				[TableFilterHolder.USER]: {
					...initialState,
					orderBy: 'cname'
				},
				[TableFilterHolder.STUDENT]: {
					...initialState,
					orderBy: 'matricId'
				},
				[TableFilterHolder.LECTURER]: {
					...initialState,
					orderBy: 'matricId'
				},
				[TableFilterHolder.ADMIN]: {
					...initialState,
					orderBy: 'matricId'
				},
				[TableFilterHolder.COURSE]: {
					...initialState,
					orderBy: 'name'
				},
				[TableFilterHolder.CLASS]: {
					...initialState,
					orderBy: 'name'
				},
				[TableFilterHolder.CLASS_STUDENT]: {
					...initialState,
					orderBy: 'matricId'
				},
				[TableFilterHolder.LOCATION]: {
					...initialState,
					orderBy: 'name'
				},
				[TableFilterHolder.SPACE]: {
					...initialState,
					orderBy: 'name'
				},
				[TableFilterHolder.COURSE_REGISTRATION]: {
					...initialState,
					orderBy: 'createdAt',
					order: 'desc' as const
				},
				[TableFilterHolder.INVOICE]: {
					...initialState,
					orderBy: 'user',
					order: 'desc' as const
				},
				[TableFilterHolder.PAYMENT]: {
					...initialState,
					orderBy: 'createdAt',
					order: 'desc' as const
				},
				[TableFilterHolder.REFUND]: {
					...initialState,
					orderBy: 'createdAt',
					order: 'desc' as const
				},
				[TableFilterHolder.TERM_STUDENT]: {
					...initialState,
					orderBy: 'matricId'
				}
			},
			currentHolder: TableFilterHolder.USER,
			setCurrentHolder: holder => set({ currentHolder: holder }),
			setOrder: holder => order =>
				set(
					produce(draft => {
						draft.filters[holder].order = order;
					})
				),
			setOrderBy: holder => orderBy =>
				set(
					produce(draft => {
						draft.filters[holder].orderBy = orderBy;
					})
				),
			setSelected: holder => selected =>
				set(
					produce(draft => {
						draft.filters[holder].selected = selected;
					})
				),
			setRowsPerPage: holder => rowsPerPage =>
				set(
					produce(draft => {
						draft.filters[holder].rowsPerPage = rowsPerPage;
					})
				),
			setPage: holder => page =>
				set(
					produce(draft => {
						draft.filters[holder].page = page;
					})
				),
			setKeywords: holder => keywords =>
				set(
					produce(draft => {
						draft.filters[holder].keywords = keywords;
					})
				),
			setStartFrom: holder => startFrom =>
				set(
					produce(draft => {
						draft.filters[holder].startFrom = startFrom;
					})
				),
			setEndAt: holder => endAt =>
				set(
					produce(draft => {
						draft.filters[holder].endAt = endAt;
					})
				),
			resetFilter: holder => () =>
				set(
					produce(draft => {
						draft.filters[holder] = { ...initialState };
					})
				)
		}),
		{
			name: 'table-filter',
			version: 0.6
		}
	)
);

// ...initialState,
// 	setOrder: order => set({ order }),
// 	setOrderBy: orderBy => set({ orderBy }),
// 	setSelected: selected => set({ selected }),
// 	setRowsPerPage: rowsPerPage => set({ rowsPerPage }),
// 	setPage: page => set({ page }),
// 	setKeywords: keywords => set({ keywords }),
// 	resetFilter: () => set({ ...initialState })
