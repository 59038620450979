import React, { Dispatch, SetStateAction } from 'react';
import { GetInvoiceQueryResult } from '../../../generated/api';
import { Dialog, IconButton, Toolbar } from '@mui/material';
import { Close } from '@mui/icons-material';
import { PDFViewer } from '@react-pdf/renderer';
import ReceiptPdf from './ReceiptPdf';

interface ReceiptPdfDialogProps {
	open: boolean;
	setOpen: Dispatch<SetStateAction<boolean>>;
	invoice: Exclude<Exclude<GetInvoiceQueryResult['data'], undefined>['node'], undefined | null>;
}

const ReceiptPdfDialog: React.FC<ReceiptPdfDialogProps> = ({ open, setOpen, invoice }) => {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen>
			<Toolbar>
				<IconButton onClick={handleClose} sx={{ ml: 'auto', mr: 0 }}>
					<Close />
				</IconButton>
			</Toolbar>
			<PDFViewer height="100%" style={{ border: 'none' }}>
				<ReceiptPdf invoice={invoice} />
			</PDFViewer>
		</Dialog>
	);
};

export default ReceiptPdfDialog;
