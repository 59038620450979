import gql from 'graphql-tag';
import { CLASS_FIELDS, TERM_FIELDS } from '../../CourseManagement/query';

export const LESSON_FIELDS = gql`
	fragment LessonFields on Lesson {
		id
		startTime
		endTime
		status
		color
	}
`;

export const GET_LESSONS = gql`
	query GetLessons($filter: LessonFilterInput, $language: Language!) {
		lessons(filter: $filter) {
			...LessonFields
			term {
				id
				class {
					id
					translation(language: $language) {
						name
					}
				}
			}
			space {
				id
				translation(language: $language) {
					name
				}
			}
			lecturers {
				matricId
				user {
					id
				}
			}
			students {
				student {
					matricId
					user {
						id
						cname
						ename
					}
				}
				status
			}
		}
	}
	${LESSON_FIELDS}
`;

export const SUBSCRIBE_LESSON_CREATED = gql`
	subscription SubscribeLessonCreated($filter: LessonFilterInput, $language: Language!) {
		lessonCreated(filter: $filter) {
			...LessonFields
			term {
				id
				class {
					id
					translation(language: $language) {
						name
					}
				}
			}
			space {
				id
				translation(language: $language) {
					name
				}
			}
			lecturers {
				matricId
				user {
					id
				}
			}
			students {
				student {
					matricId
					user {
						id
						cname
						ename
					}
				}
				status
			}
		}
	}
	${LESSON_FIELDS}
`;

export const SUBSCRIBE_LESSON_UPDATED = gql`
	subscription SubscribeLessonUpdated($language: Language!) {
		lessonUpdated {
			...LessonFields
			term {
				id
				class {
					id
					translation(language: $language) {
						name
					}
				}
			}
			space {
				id
				translation(language: $language) {
					name
				}
			}
			lecturers {
				matricId
				user {
					id
				}
			}
			students {
				student {
					matricId
					user {
						id
						cname
						ename
					}
				}
				status
			}
		}
	}
	${LESSON_FIELDS}
`;

export const SUBSCRIBE_LESSON_DELETED = gql`
	subscription SubscribeLessonDeleted {
		lessonDeleted {
			id
		}
	}
`;

export const GET_CLASSES = gql`
	query GetClasses($language: Language!) {
		classes {
			...ClassFields
			course {
				id
				translation(language: $language) {
					name
				}
			}
		}
	}
	${CLASS_FIELDS}
`;

export const CREATE_LESSON = gql`
	mutation CreateLesson($input: CreateLessonInput!) {
		createLesson(input: $input) {
			id
		}
	}
`;

export const CREATE_LESSONS = gql`
	mutation CreateLessons($input: [CreateLessonInput!]!) {
		createLessons(input: $input) {
			id
		}
	}
`;

export const UPDATE_LESSON = gql`
	mutation UpdateLesson($id: ID!, $input: UpdateLessonInput!) {
		updateLesson(id: $id, input: $input) {
			id
		}
	}
`;

export const DELETE_LESSON = gql`
	mutation DeleteLesson($id: ID!) {
		deleteLesson(id: $id) {
			id
		}
	}
`;

export const GET_CLASS_TERMS = gql`
	query GetClassTerms($id: ID!) {
		node(id: $id) {
			id
			... on Class {
				terms {
					...TermFields
				}
			}
		}
	}
	${TERM_FIELDS}
`;

export const CLASS_ARRANGEMENT_WARNING_FIELDS = gql`
	fragment ClassArrangementWarningFields on ClassArrangementWarning {
		id
		reason
		message
	}
`;

export const CLASS_ARRANGEMENT_ERROR_FIELDS = gql`
	fragment ClassArrangementErrorFields on ClassArrangementError {
		id
		reason
		message
	}
`;

export const GET_CLASS_ARRANGEMENT_WARNINGS = gql`
	query GetClassArrangementWarnings($language: Language!) {
		classArrangementWarnings {
			...ClassArrangementWarningFields
			term {
				id
				name
				class {
					id
					translation(language: $language) {
						name
					}
					course {
						id
						translation(language: $language) {
							name
						}
					}
				}
			}
		}
	}
	${CLASS_ARRANGEMENT_WARNING_FIELDS}
`;

export const GET_CLASS_ARRANGEMENT_ERRORS = gql`
	query GetClassArrangementErrors($language: Language!) {
		classArrangementErrors {
			...ClassArrangementErrorFields
			term {
				id
				name
				class {
					id
					translation(language: $language) {
						name
					}
					course {
						id
						translation(language: $language) {
							name
						}
					}
				}
			}
		}
	}
	${CLASS_ARRANGEMENT_ERROR_FIELDS}
`;

export const SUBSCRIBE_CLASS_ARRANGEMENT_WARNING_CREATED = gql`
	subscription SubscribeClassArrangementWarningCreated($language: Language!) {
		classArrangementWarningCreated {
			...ClassArrangementWarningFields
			term {
				id
				name
				class {
					id
					translation(language: $language) {
						name
					}
					course {
						id
						translation(language: $language) {
							name
						}
					}
				}
			}
		}
	}
	${CLASS_ARRANGEMENT_WARNING_FIELDS}
`;

export const SUBSCRIBE_CLASS_ARRANGEMENT_WARNING_UPDATED = gql`
	subscription SubscribeClassArrangementWarningUpdated($language: Language!) {
		classArrangementWarningUpdated {
			...ClassArrangementWarningFields
			term {
				id
				name
				class {
					id
					translation(language: $language) {
						name
					}
					course {
						id
						translation(language: $language) {
							name
						}
					}
				}
			}
		}
	}
	${CLASS_ARRANGEMENT_WARNING_FIELDS}
`;

export const SUBSCRIBE_CLASS_ARRANGEMENT_WARNING_DELETED = gql`
	subscription SubscribeClassArrangementWarningDeleted {
		classArrangementWarningDeleted {
			id
		}
	}
`;

export const SUBSCRIBE_CLASS_ARRANGEMENT_ERROR_CREATED = gql`
	subscription SubscribeClassArrangementErrorCreated($language: Language!) {
		classArrangementErrorCreated {
			...ClassArrangementErrorFields
			term {
				id
				name
				class {
					id
					translation(language: $language) {
						name
					}
					course {
						id
						translation(language: $language) {
							name
						}
					}
				}
			}
		}
	}
	${CLASS_ARRANGEMENT_ERROR_FIELDS}
`;

export const SUBSCRIBE_CLASS_ARRANGEMENT_ERROR_UPDATED = gql`
	subscription SubscribeClassArrangementErrorUpdated($language: Language!) {
		classArrangementErrorUpdated {
			...ClassArrangementErrorFields
			term {
				id
				name
				class {
					id
					translation(language: $language) {
						name
					}
					course {
						id
						translation(language: $language) {
							name
						}
					}
				}
			}
		}
	}
	${CLASS_ARRANGEMENT_ERROR_FIELDS}
`;

export const SUBSCRIBE_CLASS_ARRANGEMENT_ERROR_DELETED = gql`
	subscription SubscribeClassArrangementErrorDeleted {
		classArrangementErrorDeleted {
			id
		}
	}
`;

export const GET_MINIMAL_CLASS_STUDENTS = gql`
	query GetMinimalClassStudents($id: ID!) {
		node(id: $id) {
			id
			... on Class {
				students {
					matricId
					user {
						id
						cname
						ename
					}
				}
			}
		}
	}
`;

export const UPDATE_LESSON_ATTENDANCES = gql`
	mutation UpdateLessonAttendances($id: ID!, $input: UpdateLessonAttendancesInput!) {
		updateLessonAttendances(id: $id, input: $input) {
			id
		}
	}
`;

export const GET_LESSON_STUDENTS = gql`
	query GetLessonStudents($id: ID!) {
		node(id: $id) {
			... on Lesson {
				students {
					student {
						matricId
						user {
							id
							cname
							ename
						}
					}
					status
				}
			}
		}
	}
`;

// export const GET_TERM = gql`
// 	query GetTerm($id: ID!, $language: Language!) {
// 		node(id: $id) {
// 			... on TermCreateDialog {
// 				...TermFields
// 				class {
// 					id
// 					translation(language: $language) {
// 						name
// 					}
// 				}
// 			}
// 		}
// 	}
// `;
