import React from 'react';
import { Button, Divider, IconButton, Tooltip } from '@mui/material';
import StudentPhoneItem from './StudentPhoneItem';
import { RemoveCircle } from '@mui/icons-material';
import { StudentPhone } from '../../generated/api';

export interface StudentPhonesProps {
	phones: StudentPhone[];
	setPhones: (phones: StudentPhone[]) => void;
	disabled?: boolean;
}

const StudentPhones: React.FC<StudentPhonesProps> = ({
	phones = [],
	setPhones,
	disabled,
	...props
}) => {
	const handleNewPhone = () => {
		setPhones([...phones, { name: '', number: '' }]);
	};

	const handleDelete = (index: number) => () => {
		setPhones([...phones.slice(0, index), ...phones.slice(index + 1)]);
	};

	const handleNameChange = (index: number) => (name: string) => {
		let newPhones = [...phones];
		newPhones[index] = {
			...newPhones[index],
			name
		};
		setPhones(newPhones);
	};

	const handleNumberChange = (index: number) => (number: string) => {
		if (isNaN(Number(number)) || number.length > 10) {
			return;
		}
		let newPhones = [...phones];
		newPhones[index] = {
			...newPhones[index],
			number: `+60${number}`
		};
		setPhones(newPhones);
	};

	return (
		<div {...props} style={{ width: '100%' }}>
			<Button
				variant="contained"
				disableElevation
				color="primary"
				fullWidth
				onClick={handleNewPhone}
				disabled={disabled}
				sx={theme => ({
					color: theme.palette.mode === 'light' ? '#fff' : '#000'
				})}
			>
				新的联系号码
			</Button>
			{phones?.length > 0 && (
				<Divider variant="fullWidth" style={{ margin: '20px 0 10px' }} />
			)}
			<div>
				{phones.map((phone, index) => (
					<div key={index} style={{ padding: '10px 0' }}>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<StudentPhoneItem
								name={phone.name}
								number={phone.number.slice(3)}
								setName={handleNameChange(index)}
								setNumber={handleNumberChange(index)}
							/>
							<Tooltip title="删除">
								<IconButton
									edge="end"
									color="secondary"
									onClick={handleDelete(index)}
									size="large"
								>
									<RemoveCircle />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default StudentPhones;
