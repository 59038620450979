import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Tab, Theme, useMediaQuery } from '@mui/material';
import FormWrapper from 'components/common/FormWrapper';
import {
	GetClassQuery,
	useCreateTermMutation,
	useGetClassQuery,
	useGetMinimalClassStudentsQuery
} from 'generated/api';
import { useAtom } from 'jotai';
import { classTermCreateDialogIdAtom } from 'atoms';
import { GET_CLASS } from '../../query';
import { useUserPreferenceStore } from 'stores/useUserPreferenceStore';
import { v4 } from 'uuid';
import { Relay } from 'utils/relay';
import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import TermCreateDialogContent from './TermCreateDialogContent';
import CustomDialog from '../../../../share-components/CustomDialog';
import { TabContext, TabList } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { monthToAbbreviations } from '../../../../utils/monthToAbbreviations';

interface TermCreateDialogProps {}

const TermCreateDialog: React.FC<TermCreateDialogProps> = () => {
	const [classId, setClassId] = useAtom(classTermCreateDialogIdAtom);
	const [createTermMutation, { loading, error, reset }] = useCreateTermMutation({
		update: (cache, result) => {
			const createdTerm = result.data?.createTerm;

			if (!createdTerm) {
				return;
			}

			const res = cache.readQuery<GetClassQuery>({
				query: GET_CLASS,
				variables: {
					id: classId,
					language: useUserPreferenceStore.getState().language
				}
			});

			if (res?.node?.__typename !== 'Class') {
				return;
			}

			cache.writeQuery<GetClassQuery>({
				query: GET_CLASS,
				variables: {
					id: classId,
					language: useUserPreferenceStore.getState().language
				},
				data: {
					...res,
					node: {
						...res.node,
						terms: [createdTerm, ...res.node.terms]
					}
				}
			});
		}
	});
	const today = new Date();
	const { data: classData } = useGetClassQuery({
		variables: { id: classId, language: useUserPreferenceStore.getState().language },
		skip: !classId
	});
	const classItem = useMemo(
		() => (classData?.node?.__typename === 'Class' ? classData.node : null),
		[classData]
	);
	const [name, setName] = useState(
		`${monthToAbbreviations(today.getMonth())} ${today.getFullYear()}`
	);
	const [durationPerLesson, setDurationPerLesson] = useState(0);
	const [studentFeePerLesson, setStudentFeePerLesson] = useState(
		classItem?.studentFeePerLesson || 0
	);
	const [lecturerCommission, setLecturerCommission] = useState(
		(classItem?.lecturerCommission || 0) * 100
	);
	const [lessonCount, setLessonCount] = useState(0);
	const [selectedStudents, setSelectedStudents] = useState<string[]>([]);
	const [tabIndex, setTabIndex] = useState('1');
	const fullScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const { data: classStudentsData } = useGetMinimalClassStudentsQuery({
		variables: { id: classId },
		skip: !classId
	});
	const classStudents = useMemo(
		() => (classStudentsData?.node?.__typename === 'Class' ? classStudentsData.node.students : []),
		[classStudentsData]
	);
	const classStudentIds = useMemo(
		() => classStudents.map(student => student.user.id),
		[classStudents]
	);

	useEffect(() => {
		if (!classItem) {
			return;
		}
		setStudentFeePerLesson(classItem.studentFeePerLesson);
		setLecturerCommission(classItem.lecturerCommission * 100);
	}, [classItem]);

	useEffect(() => {
		setSelectedStudents(classStudentIds);
	}, [classStudentIds]);

	const handleTabIndexChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabIndex(newValue);
	};

	const handleClose = () => {
		setClassId('');
		reset();
	};

	const handleReset = () => {
		setName(`${monthToAbbreviations(today.getMonth())} ${today.getFullYear()}`);
		if (classItem) {
			setDurationPerLesson(0);
			setStudentFeePerLesson(classItem.studentFeePerLesson);
			setLecturerCommission(classItem.lecturerCommission * 100);
		}
		setSelectedStudents(classStudentIds);
		reset();
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (selectedStudents.length == 0) {
			return;
		}

		try {
			const id = v4();
			const termId = Relay.marshalId('Term', id);

			await createTermMutation({
				variables: {
					input: {
						id,
						classId,
						name,
						durationPerLesson,
						studentFeePerLesson,
						lecturerCommission: lecturerCommission / 100,
						lessonCount,
						students: selectedStudents
					}
				},
				optimisticResponse: {
					createTerm: {
						__typename: 'Term',
						id: termId,
						name,
						durationPerLesson,
						studentFeePerLesson,
						lecturerCommission: lecturerCommission / 100,
						lessonCount,
						createdAt: new Date().toISOString()
					}
				}
			});
			handleClose();
			handleReset();
			toast.success('添加班级学期成功!');
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(`添加班级学期失败! ${err.message}`);
			}
		}
	};

	return (
		<TabContext value={tabIndex}>
			<CustomDialog
				open={!!classId}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				onSubmit={handleSubmit}
				title="添加班级学期"
				actions={
					<>
						{!fullScreen && (
							<>
								<Button onClick={handleClose}>取消</Button>
								<Button onClick={handleReset}>重置</Button>
							</>
						)}
						<Button type="submit">创建</Button>
					</>
				}
				appBarActions={
					<>
						<Button onClick={handleReset}>重置</Button>
					</>
				}
				appBarTabs={
					<StyledTabs
						onChange={handleTabIndexChange}
						aria-label="学期资料类别"
						centered
						sx={{ width: '100%', mb: -1 }}
					>
						<StyledTab label="基本资料" value="1" sx={{ fontSize: '1em' }} />
						<StyledTab label="学期学生" value="2" sx={{ fontSize: '1em' }} />
					</StyledTabs>
				}
				fullScreen={fullScreen}
			>
				<FormWrapper loading={loading} error={error}>
					<TermCreateDialogContent
						tabs={
							!fullScreen ? (
								<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
									<TabList onChange={handleTabIndexChange} aria-label="学期资料类别" centered>
										<Tab label="基本资料" value="1" />
										<Tab label="学期学生" value="2" />
									</TabList>
								</Box>
							) : null
						}
						classId={classId}
						name={name}
						durationPerLesson={durationPerLesson}
						studentFeePerLesson={studentFeePerLesson}
						lecturerCommission={lecturerCommission}
						lessonCount={lessonCount}
						classStudents={classStudents}
						selectedStudents={selectedStudents}
						setName={setName}
						setDurationPerLesson={setDurationPerLesson}
						setStudentFeePerLesson={setStudentFeePerLesson}
						setLecturerCommission={setLecturerCommission}
						setSelectedStudents={setSelectedStudents}
						setLessonCount={setLessonCount}
					/>
				</FormWrapper>
			</CustomDialog>
		</TabContext>
	);
};

const StyledTabs = styled(TabList)({
	color: 'white',
	'& .MuiTabs-indicator': {
		backgroundColor: 'white'
	}
});

const StyledTab = styled(Tab)({
	fontSize: '1rem',
	color: '#fff',
	opacity: '0.8',
	'&.Mui-selected': {
		color: '#fff',
		opacity: 1
	}
});

export default TermCreateDialog;
