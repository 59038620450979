import gql from 'graphql-tag';

export const USER_FIELDS = gql`
	fragment UserFields on User {
		id
		cname
		ename
		email
		gender
		ic
		phone
		address
		registerDate
		status
	}
`;

export const STUDENT_FIELDS = gql`
	fragment StudentFields on Student {
		matricId
		phones {
			name
			number
		}
		status
	}
`;

export const LECTURER_FIELDS = gql`
	fragment LecturerFields on Lecturer {
		matricId
		status
	}
`;

export const ADMIN_FIELDS = gql`
	fragment AdminFields on Admin {
		matricId
		isMaster
		status
	}
`;

export const GET_CURRENT_USER = gql`
	${USER_FIELDS}
	${ADMIN_FIELDS}
	query GetCurrentUser {
		currentUser {
			...UserFields
			admin {
				user {
					id
				}
				...AdminFields
			}
		}
	}
`;
