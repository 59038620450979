import React, { useCallback, useMemo, useState } from 'react';
import {
	Checkbox,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	TableCell,
	TableRow,
	Typography
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { TableAction, TableItemType } from '../../types';
import { TableFilterHolder, useTableFilterStore } from '../../stores/useTableFilterStore';
import { Gender, User } from 'generated/api';
import { styled } from '@mui/material/styles';
import shallow from 'zustand/shallow';

const getValue = (item: any, key: string): any => {
	//@ts-ignore
	if (item[key]['label']) {
		return item[key]['label'];
	}

	let value = item[key];

	if (item.__typename === 'User') {
		if ((key as keyof User) === 'gender') {
			value = value === Gender.Female ? '女' : '男';
		}
	}

	return value;
};

// const getURL = (userid: string) => {
// 	switch (userid[0]) {
// 		case 'L':
// 			return '/lecturer';
// 		case 'A':
// 			return '/admin';
// 		default:
// 			return '';
// 	}
// };

const initialPosition = {
	mouseX: null,
	mouseY: null
};

interface TableItemProps {
	holder: TableFilterHolder;
	item: any;
	keys: Array<keyof TableItemType>;
	actions: Array<TableAction>;
	clickFunc?: any;
	labelId: string;
	showToolbar: boolean;
	selectable: boolean;
	contextMenu?: (params: { closeContextMenu: () => void; item: any }) => JSX.Element[];
}

const TableItem: React.FC<TableItemProps> = ({
	holder,
	item,
	keys,
	actions,
	clickFunc,
	labelId,
	showToolbar,
	selectable,
	contextMenu,
	...props
}) => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [position, setPosition] = useState<{
		mouseX: null | number;
		mouseY: null | number;
	}>(initialPosition);
	const [contextMenuAnchorEl, setContextMenuAnchorEl] = useState<null | HTMLElement>(null);
	// const history = useNavigate();
	const [selected, setSelected] = useTableFilterStore(
		state => [state.filters[holder].selected, state.setSelected(holder)],
		shallow
	);
	const isItemSelected = useMemo(() => selected.indexOf(item.id) !== -1, [selected, item]);

	const handleSelected = (id: string) => (event: React.MouseEvent<unknown>) => {
		event.stopPropagation();

		const selectedIndex = selected.indexOf(id);
		let newSelected: string[] = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, id);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	const handleMenuClose = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.stopPropagation();
		closeMenu();
	};

	const closeContextMenu = () => {
		setPosition(initialPosition);
		setContextMenuAnchorEl(null);
	};

	const handleContextMenuOpen = (
		event: React.MouseEvent<HTMLButtonElement | HTMLTableRowElement>
	) => {
		if (!contextMenu) {
			return;
		}
		event.preventDefault();
		setPosition({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4
		});
		setContextMenuAnchorEl(event.currentTarget);
	};

	const handleContextMenuClose = (event: React.MouseEvent<HTMLButtonElement | HTMLLIElement>) => {
		event.stopPropagation();
		closeContextMenu();
	};

	const handleClick = () => {
		if (!item) return;

		if (!clickFunc) {
			return;
		}

		try {
			clickFunc(item);
		} catch {}
	};

	return (
		<>
			<TableRow
				hover
				sx={{
					cursor: clickFunc ? 'pointer' : 'default'
				}}
				onClick={handleClick}
				role="button"
				aria-checked={isItemSelected}
				tabIndex={-1}
				selected={isItemSelected}
				onContextMenu={handleContextMenuOpen}
				id={showToolbar ? labelId : ''}
				{...props}
			>
				{selectable && (
					<TableCell padding="checkbox">
						<Checkbox
							checked={isItemSelected}
							inputProps={{ 'aria-labelledby': labelId }}
							onClick={handleSelected(item.id)}
						/>
					</TableCell>
				)}
				{keys.map(key => (
					<TableCell key={key as any}>{getValue(item, key)}</TableCell>
				))}
				{actions.length > 0 && (
					<TableCell style={{ width: '50px', padding: '0 6px 0 4px' }}>
						<IconButton
							aria-controls="actions-menu"
							aria-haspopup="true"
							onClick={handleMenuOpen}
							size="large"
						>
							<MoreVert />
						</IconButton>
						<Menu
							id="actions-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleMenuClose}
						>
							{actions.map((action, index) => (
								<StyledMenuItem
									key={`action:${index}`}
									onClick={e => {
										e.stopPropagation();
										action.func(item);
										closeMenu();
									}}
								>
									<ListItemIcon sx={{ minWidth: '35px' }}>
										{React.cloneElement(action.icon, { fontSize: 'small' })}
									</ListItemIcon>
									<Typography variant="inherit">{action.label}</Typography>
								</StyledMenuItem>
							))}
						</Menu>
					</TableCell>
				)}
			</TableRow>
			{contextMenu && (
				<Menu
					id="context-menu"
					anchorEl={contextMenuAnchorEl}
					keepMounted
					open={Boolean(contextMenuAnchorEl)}
					onClose={handleContextMenuClose}
					anchorReference="anchorPosition"
					anchorPosition={
						position.mouseY !== null && position.mouseX !== null
							? { top: position.mouseY, left: position.mouseX }
							: undefined
					}
				>
					{contextMenu({ closeContextMenu, item })}
				</Menu>
			)}
		</>
	);
};

const StyledMenuItem = styled(MenuItem)({
	minWidth: '140px',
	paddingTop: 4,
	paddingBottom: 4
});

export default TableItem;
