import React, { useEffect, useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { termDeleteIdAtom, termDetailsIdAtom } from '../../../atoms';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton
} from '@mui/material';
import { useApolloClient } from '@apollo/client';
import {
	TermFieldsFragment,
	TermFieldsFragmentDoc,
	useUpdateTermMutation
} from '../../../generated/api';
import FormWrapper from '../../common/FormWrapper';
import { Delete } from '@mui/icons-material';
import { useUpdateAtom } from 'jotai/utils';
import { toast } from 'react-toastify';
import TermContent from './TermContent';

interface TermDetailsDialogProps {}

const TermDetailsDialog: React.FC<TermDetailsDialogProps> = () => {
	const [termId, setTermId] = useAtom(termDetailsIdAtom);
	const { cache } = useApolloClient();
	const term = useMemo(
		() =>
			termId
				? cache.readFragment<TermFieldsFragment>({
						fragment: TermFieldsFragmentDoc,
						id: cache.identify({
							__typename: 'Term',
							id: termId
						})
				  })
				: null,
		[termId]
	);
	const [name, setName] = useState(term?.name || '');
	const [durationPerLesson, setDurationPerLesson] = useState(term?.durationPerLesson || 0);
	const [studentFeePerLesson, setStudentFeePerLesson] = useState(term?.studentFeePerLesson || 0);
	const [lecturerCommission, setLecturerCommission] = useState(
		(term?.lecturerCommission || 0) * 100
	);
	const [lessonCount, setLessonCount] = useState(term?.lessonCount || 0);
	const [updateTermMutation, { loading, error, reset }] = useUpdateTermMutation({
		update: (cache, result) => {
			const updatedTerm = result.data?.updateTerm;

			if (!updatedTerm) {
				return;
			}

			cache.writeFragment<TermFieldsFragment>({
				fragment: TermFieldsFragmentDoc,
				id: cache.identify({
					__typename: 'Term',
					id: updatedTerm.id
				}),
				data: updatedTerm
			});
		}
	});
	const setDeleteId = useUpdateAtom(termDeleteIdAtom);

	useEffect(() => {
		handleReset();
	}, [term]);

	const handleClose = () => {
		setTermId('');
	};

	const handleReset = () => {
		if (!term) {
			return;
		}

		setName(term.name);
		setDurationPerLesson(term.durationPerLesson);
		setStudentFeePerLesson(term.studentFeePerLesson);
		setLecturerCommission(term.lecturerCommission * 100);
		setLessonCount(term.lessonCount);
		reset();
	};

	const handleDelete = () => {
		setDeleteId(termId);
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		try {
			await updateTermMutation({
				variables: {
					id: termId,
					input: {
						name,
						durationPerLesson,
						studentFeePerLesson,
						lecturerCommission: lecturerCommission / 100,
						lessonCount
					}
				},
				optimisticResponse: {
					updateTerm: {
						__typename: 'Term',
						id: termId,
						name,
						durationPerLesson,
						studentFeePerLesson,
						lecturerCommission: lecturerCommission / 100,
						lessonCount,
						createdAt: new Date().toISOString()
					}
				}
			});
			toast.success('更改学期成功!');
			// enqueueSnackbar('更改学期成功!', { variant: 'success' });
			handleClose();
		} catch (err) {
			toast.error(`更改学期失败! ${err}`);
			// enqueueSnackbar(`更改学期失败! ${err}`, { variant: 'error' });
		}
	};

	return (
		<Dialog open={!!term} onClose={handleClose} maxWidth="sm" fullWidth>
			<Box component="form" onSubmit={handleSubmit}>
				<DialogTitle>{term?.name}</DialogTitle>
				<DialogContent>
					<FormWrapper loading={loading} error={error}>
						<TermContent
							termId={termId}
							name={name}
							durationPerLesson={durationPerLesson}
							studentFeePerLesson={studentFeePerLesson}
							lecturerCommission={lecturerCommission}
							lessonCount={lessonCount}
							setName={setName}
							setDurationPerLesson={setDurationPerLesson}
							setStudentFeePerLesson={setStudentFeePerLesson}
							setLecturerCommission={setLecturerCommission}
							setLessonCount={setLessonCount}
						/>
					</FormWrapper>
				</DialogContent>
				<DialogActions>
					<IconButton onClick={handleDelete} sx={{ ml: 1, mr: 'auto' }} color="error">
						<Delete />
					</IconButton>
					<Button onClick={handleClose}>取消</Button>
					<Button onClick={handleReset}>重置</Button>
					<Button type="submit">保存</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};

export default TermDetailsDialog;
