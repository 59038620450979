import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
	Autocomplete,
	Grid,
	Link,
	ListItem,
	ListSubheader,
	MenuItem,
	TextField,
	Typography
} from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import '@mui/lab';
import LecturersSelect from '../LecturersSelect';
import FormWrapper from '../../components/common/FormWrapper';
import { useUserPreferenceStore } from '../../stores/useUserPreferenceStore';
import { useGetClassesQuery, useGetClassTermsQuery, useGetSpacesQuery } from '../../generated/api';
import { ApolloError } from '@apollo/client';

interface LessonFormProps {
	loading: boolean;
	error?: ApolloError;
	startTime: Date;
	endTime: Date;
	classId: string;
	termId: string;
	spaceId: string;
	lecturers: string[];
	setStartTime: (startTime: Date) => void;
	setEndTime: (endTime: Date) => void;
	setClassId: (classId: string) => void;
	setTermId: (termId: string) => void;
	setSpaceId: (spaceId: string) => void;
	setLecturers: (lecturers: string[]) => void;
	onTermCreateClick: (classId: string) => void;
}

const LessonForm: React.FC<LessonFormProps> = ({
	loading,
	error,
	startTime,
	endTime,
	classId,
	termId,
	spaceId,
	lecturers,
	setStartTime,
	setEndTime,
	setClassId,
	setTermId,
	setSpaceId,
	setLecturers,
	onTermCreateClick
}) => {
	const language = useUserPreferenceStore(state => state.language);
	const { data: classesData } = useGetClassesQuery({
		variables: { language }
	});
	const { data: spacesData } = useGetSpacesQuery({ variables: { language } });
	const { data: termsData } = useGetClassTermsQuery({ variables: { id: classId }, skip: !classId });
	const [classItem, setClassItem] = useState<typeof classes[0] | null>(null);

	const classes = useMemo(() => classesData?.classes || [], [classesData]);
	const spaces = useMemo(() => spacesData?.spaces || [], [spacesData]);
	const terms = useMemo(
		() => (termsData?.node?.__typename === 'Class' ? termsData.node.terms : []),
		[termsData]
	);

	useEffect(() => {
		const selectedClass = classes.find(c => c.id === classId);

		if (selectedClass) {
			setClassItem(selectedClass);
		}
	}, [classes]);

	useEffect(() => {
		if (!classId) {
			setClassItem(null);
		}
	}, [classId]);

	const handleTermCreateClick = () => {
		onTermCreateClick(classId);
	};

	const handleClassChange = (event: React.SyntheticEvent, value: typeof classes[0] | null) => {
		setClassItem(value);
		setClassId(value?.id || '');
		setTermId('');
	};

	const handleStartTimeChange: DateTimePickerProps<Date, Date>['onChange'] = date => {
		if (!date) {
			return;
		}
		setStartTime(date);
	};

	const handleEndTimeChange: DateTimePickerProps<Date, Date>['onChange'] = date => {
		if (!date) {
			return;
		}
		setEndTime(date);
	};

	const handleTermIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTermId(event.target.value);
	};

	const handleSpaceIdChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSpaceId(event.target.value);
	};

	const getSpaceSelectContent = useCallback(() => {
		const locations = spaces.reduce(
			(locations, space) => ({
				...locations,
				[space.location.id]: [...(locations[space.location.id] || []), space]
			}),
			{} as Record<string, Array<typeof spaces[0]>>
		);

		return Object.keys(locations).map((location, index) => [
			<ListSubheader key={location + index} sx={{ ml: -0.3 }}>
				{locations[location][0].location.name}
			</ListSubheader>,
			...locations[location].map(space => (
				<MenuItem key={space.id} value={space.id}>
					{space.translation.name}
				</MenuItem>
			))
		]);
	}, [spaces]);

	return (
		<FormWrapper loading={loading} error={error}>
			<Grid container spacing={2} sx={{ mt: 0 }}>
				<Grid item xs={12}>
					<Autocomplete
						id="classes-select"
						options={[...classes].sort((a, b) => -a.course.id.localeCompare(b.course.id))}
						groupBy={c => c.course.translation.name}
						getOptionLabel={option => option.translation.name}
						fullWidth
						value={classItem}
						onChange={handleClassChange}
						renderInput={params => <TextField {...params} label="班级" required />}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="学期"
						fullWidth
						variant="outlined"
						value={termId}
						onChange={handleTermIdChange}
						select
						disabled={!classId}
						required
					>
						<ListItem divider={terms.length > 0}>
							<Typography align="center" width="100%">
								<Link onClick={handleTermCreateClick} sx={{ cursor: 'pointer' }}>
									新学期
								</Link>
							</Typography>
						</ListItem>
						{terms.map(term => (
							<MenuItem key={term.id} value={term.id}>
								{term.name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
				<Grid item xs={12}>
					<DateTimePicker
						renderInput={props => <TextField {...props} fullWidth required />}
						openTo="hours"
						label="开始时间"
						value={startTime}
						onChange={handleStartTimeChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<DateTimePicker
						renderInput={props => <TextField {...props} fullWidth required />}
						openTo="hours"
						label="结束时间"
						value={endTime}
						onChange={handleEndTimeChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						label="上课地点"
						fullWidth
						variant="outlined"
						select
						value={spaceId || '0'}
						onChange={handleSpaceIdChange}
						required
					>
						<MenuItem value="0">线上</MenuItem>
						{getSpaceSelectContent()}
					</TextField>
				</Grid>
				<Grid item xs={12}>
					<LecturersSelect selectedLecturers={lecturers} setLecturers={setLecturers} required />
				</Grid>
			</Grid>
		</FormWrapper>
	);
};

export default LessonForm;
