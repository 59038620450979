import create from 'zustand';
import { persistIndexedDB } from './IndexedDB';
import { CourseTranslation, Language } from '../generated/api';
import produce from 'immer';

const initialState: Pick<CourseCreateStoreState, 'step' | 'translations' | 'lecturers'> = {
	step: 0,
	translations: {
		[Language.Zh]: {
			name: '',
			description: ''
		},
		[Language.En]: {
			name: '',
			description: ''
		}
	},
	lecturers: []
};

type CourseCreateStoreState = {
	step: 0 | 1;
	translations: {
		[x in Language]: CourseTranslation;
	};
	lecturers: string[];
	setStep: (step: 0 | 1) => void;
	setTranslationName: (name: string, language: Language) => void;
	setTranslationDescription: (description: string, language: Language) => void;
	setLecturers: (lecturers: string[]) => void;
	addLecturers: (lecturers: string[]) => void;
	removeLecturer: (lecturerId: string) => void;
	reset: () => void;
};

export const useCourseCreateStore = create<CourseCreateStoreState>()(
	persistIndexedDB(
		set => ({
			...initialState,
			setStep: step => set({ step }),
			setTranslationName: (name, language) =>
				set(
					produce<CourseCreateStoreState>(draft => {
						draft.translations[language].name = name;
					})
				),
			setTranslationDescription: (description, language) =>
				set(
					produce<CourseCreateStoreState>(draft => {
						draft.translations[language].description = description;
					})
				),
			setLecturers: lecturers => set({ lecturers }),
			addLecturers: lecturers =>
				set(
					produce<CourseCreateStoreState>(draft => {
						draft.lecturers.push(...lecturers);
					})
				),
			removeLecturer: lecturerId =>
				set(
					produce<CourseCreateStoreState>(draft => {
						const index = draft.lecturers.findIndex(l => l === lecturerId);
						if (index !== -1) {
							draft.lecturers.splice(index, 1);
						}
					})
				),
			reset: () => set({ ...initialState })
		}),
		{ name: 'course-create-form', version: 0.2 }
	)
);
