import React, { useEffect } from 'react';
import {
	Location,
	Navigate,
	Route,
	Routes as RouterRoutes,
	useLocation,
	useNavigate
} from 'react-router-dom';
import App from './App';
import Lecturers from './components/UserManagement/Lecturers';
import { useAtomValue } from 'jotai/utils';
import { isMasterAtom } from './atoms';
import Admins from './components/UserManagement/Admins';
import Users from './components/UserManagement/Users';
import Students from './components/UserManagement/Students';
import loadable from '@loadable/component';
import Loading from './pages/Loading';
import UserDetailsDialog from './components/UserManagement/UserDetailsDialog';
import { UserCreateDialogRoute } from './components/UserManagement/UserCreateDialog';
import { UserRoleCreateRoute } from './components/UserManagement/UserRoleCreateDialog';
import LessonCreateRoute from './components/ClassArrangement/LessonCreateRoute';
import { LessonUpdateRoute } from './components/ClassArrangement/LessonUpdateDialog';
import UserDetailsPage from './components/UserManagement/UserDetailsDialog/UserDetailsPage';
import { CategoryCreateRoute } from './components/CourseManagement/Category/CategoryCreateDialog';
import Categories from './components/CourseManagement/Category/Categories';
import CategoryPage from './components/CourseManagement/Category/CategoryPage';
import { CourseUpdateDialogRoute } from './components/CourseManagement/Course/CourseUpdateDialog';
import { CourseCreateDialogRoute } from './components/CourseManagement/Course/CourseCreateDialog';
import { ClassDetailsDialogRoute } from './components/CourseManagement/Class/ClassDetailsDialog';
import { ClassCreateDialogRoute } from './components/CourseManagement/Class/ClassCreateDialog';
import Invoices from './components/PaymentManagement/Invoices';
import InvoiceDetailsPage from './components/PaymentManagement/Invoices/InvoiceDetailsPage';
import InvoiceCreatePage from './components/PaymentManagement/Invoices/InvoiceCreatePage';
import InvoiceEditPage from './components/PaymentManagement/Invoices/InvoiceEditPage';
import ReceiptDetailsPage from './components/PaymentManagement/Payments/ReceiptDetailsPage';
import PaymentCreatePage from './components/PaymentManagement/Payments/PaymentCreatePage';
import ProfilePage from './pages/ProfilePage';

const DashboardPage: any = loadable(
	() => import(/* webpackPrefetch: true */ './pages/DashboardPage'),
	{
		fallback: <Loading />
	}
);
const UserPage: any = loadable(() => import(/* webpackPrefetch: true */ './pages/UserPage'), {
	fallback: <Loading />
});
const UserManagementPage: any = loadable(
	() => import(/* webpackPrefetch: true */ './pages/UserManagementPage'),
	{ fallback: <Loading /> }
);
const CourseManagementPage: any = loadable(
	() => import(/* webpackPrefetch: true */ './pages/CourseManagementPage'),
	{ fallback: <Loading /> }
);
const ClassArrangementPage: any = loadable(
	() => import(/* webpackPrefetch: true */ './pages/ClassArrangementPage'),
	{ fallback: <Loading /> }
);
const SettingsPage: any = loadable(
	() => import(/* webpackPrefetch: true */ './pages/SettingsPage'),
	{
		fallback: <Loading />
	}
);
const LocationManagementPage: any = loadable(() => import('./pages/LocationManagementPage'), {
	fallback: <Loading />
});
const CourseRegistrationPage: any = loadable(() => import('./pages/CourseRegistrationPage'), {
	fallback: <Loading />
});
const PaymentManagementPage: any = loadable(() => import('./pages/PaymentManagementPage'), {
	fallback: <Loading />
});

export const routes = [
	{ path: '/', Component: DashboardPage },
	{ path: '/userpage', Component: UserPage },
	{ path: '/user-management', Component: UserManagementPage },
	{ path: '/course-management', Component: CourseManagementPage },
	{ path: '/class-arrangement', Component: ClassArrangementPage },
	{ path: '/location-management', Component: LocationManagementPage },
	{ path: '/settings', Component: SettingsPage },
	{ path: '/course-registration', Component: CourseRegistrationPage },
	{ path: '/payment-management', Component: PaymentManagementPage }
];

interface RoutesProps {}

const Routes: React.FC<RoutesProps> = () => {
	const isMaster = useAtomValue(isMasterAtom);
	const location = useLocation();
	const navigate = useNavigate();
	const state = location.state as { backgroundLocation?: Location };

	useEffect(() => {
		if (location.pathname.startsWith('/users') || location.pathname.startsWith('/lessons')) {
			navigate(location.pathname.startsWith('/users') ? '/user-management' : '/class-arrangement');
		}
	}, []);

	return (
		<>
			<RouterRoutes location={state?.backgroundLocation || location}>
				<Route path="/" element={<App />}>
					<Route index element={<DashboardPage />} />
					<Route path="userpage" element={<ProfilePage />} />
					<Route path="user-management" element={<UserManagementPage />}>
						<Route index element={<Users />} />
						<Route path="student" element={<Students />} />
						<Route path="lecturer" element={<Lecturers />} />
						{isMaster && <Route path="admin" element={<Admins />} />}
						{/*<Route path="users/:id" element={<UserDetailsPage />} />*/}
						<Route path="*" element={<Navigate to=".." />} />
					</Route>
					<Route path="course-management" element={<CourseManagementPage />}>
						<Route index element={<Categories />} />
						<Route path="categories/:id/:name" element={<CategoryPage />} />
					</Route>
					<Route path="class-arrangement" element={<ClassArrangementPage />} />
					<Route path="location-management/*" element={<LocationManagementPage />} />
					<Route path="settings" element={<SettingsPage />} />
					<Route path="course-registration" element={<CourseRegistrationPage />} />
					<Route path="payment-management" element={<PaymentManagementPage />}>
						<Route path="invoices">
							<Route index element={<Invoices />} />
							<Route path="create" element={<InvoiceCreatePage />} />
							<Route path=":id">
								<Route index element={<InvoiceDetailsPage />} />
								<Route path="edit" element={<InvoiceEditPage />} />
								<Route path="receipt" element={<ReceiptDetailsPage />} />
								<Route path="payment" element={<PaymentCreatePage />} />
							</Route>
						</Route>
						{/*<Route path="payments">*/}
						{/*	<Route*/}
						{/*		index*/}
						{/*		element={*/}
						{/*			<PaymentManagementLayout>*/}
						{/*				<Payments />*/}
						{/*			</PaymentManagementLayout>*/}
						{/*		}*/}
						{/*	/>*/}
						{/*	<Route path=":id" element={<PaymentDetailsPage />} />*/}
						{/*</Route>*/}
						{/*<Route path="refunds">*/}
						{/*	<Route*/}
						{/*		index*/}
						{/*		element={*/}
						{/*			<PaymentManagementLayout>*/}
						{/*				<Refunds />*/}
						{/*			</PaymentManagementLayout>*/}
						{/*		}*/}
						{/*	/>*/}
						{/*</Route>*/}
						<Route index element={<Navigate to="invoices" />} />
					</Route>
					<Route path="*" element={<Navigate to="/" />} />
				</Route>
			</RouterRoutes>
			{state?.backgroundLocation && (
				<RouterRoutes>
					<Route path="/users/:id" element={<UserDetailsPage />} />
					<Route path="/users/create" element={<UserCreateDialogRoute />} />
					<Route path="/users/roles/create" element={<UserRoleCreateRoute />} />
					<Route path="/categories/create" element={<CategoryCreateRoute />} />
					<Route path="/courses/create" element={<CourseCreateDialogRoute />} />
					<Route path="/courses/:id" element={<CourseUpdateDialogRoute />} />
					<Route path="/categories/:id/classes/create" element={<ClassCreateDialogRoute />} />
					<Route path="/classes/:id" element={<ClassDetailsDialogRoute />} />
					<Route path="/lessons/create" element={<LessonCreateRoute />} />
					<Route path="/lessons/:id" element={<LessonUpdateRoute />} />
					{/*<Route path="/classes/:id/update" element={<ClassUpdateRoute />} />*/}
					{/*<Route path="/payments/:id" element={<PaymentDetailsRoute />} />*/}
				</RouterRoutes>
			)}
			<UserDetailsDialog />
		</>
	);
};

export default Routes;
