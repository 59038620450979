import { ObservableQuery } from '@apollo/client';
import { useRefetchFunctionsStore } from '../stores/useRefetchFunctionsStore';
import { useUserPreferenceStore } from '../stores/useUserPreferenceStore';
import { useEffect } from 'react';

export const useRefetchHandler = (
	refetch: ObservableQuery['refetch'],
	startPooling: ObservableQuery['startPolling'],
	stopPooling: ObservableQuery['stopPolling']
) => {
	useEffect(() => {
		if (!refetch) {
			return;
		}

		useRefetchFunctionsStore.getState().addFunction(refetch);

		return () => {
			useRefetchFunctionsStore.getState().removeFunction(refetch);
		};
	}, [refetch]);

	useEffect(() => {
		if (!startPooling || !stopPooling) {
			return;
		}

		const pollingInterval = useUserPreferenceStore.getState().pollingInterval;

		if (!pollingInterval) {
			return;
		}

		startPooling(pollingInterval);

		return stopPooling;
	}, [startPooling, stopPooling]);
};
