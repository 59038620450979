import React, { useEffect } from 'react';
import { categoryCreateDialogOpenAtom } from '../../../../atoms';
import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useCategoryCreateStore } from '../../../../stores/useCategoryCreateStore';
import { Language, useCreateCategoryMutation } from '../../../../generated/api';
import { createCategoryUpdate } from '../../update/createCategoryUpdate';
import { v4 } from 'uuid';
import { SlideUp } from '../../../../share-components/Transitions/SlideUp';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useUserPreferenceStore } from '../../../../stores/useUserPreferenceStore';
import { Relay } from '../../../../utils/relay';
import CategoryCreateBasicInfo from './CategoryCreateBasicInfo';
import { useNavigate } from 'react-router-dom';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { toast } from 'react-toastify';

interface CategoryCreateDialogProps {}

const CategoryCreateDialog: React.FC<CategoryCreateDialogProps> = () => {
	const open = useAtomValue(categoryCreateDialogOpenAtom);
	const reset = useCategoryCreateStore(state => state.reset);
	const [createCategoryMutation] = useCreateCategoryMutation({
		update: createCategoryUpdate
	});
	const theme = useTheme();
	const isSM = useMediaQuery(theme.breakpoints.down('sm'));
	const navigate = useNavigate();

	const handleClose = () => {
		navigate(-1);
	};

	const handleReset = () => {
		reset();
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		const { translations } = useCategoryCreateStore.getState();

		const id = v4();
		const language = useUserPreferenceStore.getState().language;

		const input = {
			id,
			translations: [
				{
					...translations.ZH,
					language: Language.Zh
				},
				{
					...translations.EN,
					language: Language.En
				}
			]
		};

		try {
			await createCategoryMutation({
				variables: {
					input,
					language
				},
				optimisticResponse: {
					createCategory: {
						__typename: 'Category',
						id: Relay.marshalId('Category', id),
						translation: {
							...translations[language],
							__typename: 'CategoryTranslation'
						}
					}
				}
			});
			handleClose();
			reset();
			toast.success('创建课程种类成功！');
		} catch (err) {
			toast.error(`创建课程种类失败！${err}`);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth
			fullScreen={isSM}
			TransitionComponent={isSM ? SlideUp : undefined}
		>
			<Box
				component="form"
				onSubmit={handleSubmit}
				autoComplete="off"
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					overflowY: 'hidden'
				}}
			>
				{!isSM ? (
					<AppBar position="static" color="inherit" elevation={0} component="div">
						<DialogTitle>创建课程种类</DialogTitle>
					</AppBar>
				) : (
					<AppBar position="static" color="inherit" component="div">
						<Toolbar>
							<IconButton onClick={handleClose} size="large">
								<Close />
							</IconButton>
							<Typography variant="h6" sx={{ ml: '10px' }}>
								创建课程种类
							</Typography>
							<Box sx={{ ml: 'auto', mr: 0 }}>
								<Button color="primary" onClick={handleReset}>
									重置
								</Button>
							</Box>
						</Toolbar>
					</AppBar>
				)}
				<StyledDialogContent>
					<CategoryCreateBasicInfo />
				</StyledDialogContent>
				{!isSM && (
					<AppBar position="static" color="inherit" elevation={0} component="div">
						<Toolbar sx={{ justifyContent: 'flex-end' }}>
							<DialogActions sx={{ p: 0 }}>
								<Button color="primary" onClick={handleClose}>
									取消
								</Button>
								<Button color="primary" onClick={handleReset}>
									重置
								</Button>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									sx={{ color: theme => (theme.palette.mode === 'light' ? '#fff' : '#000') }}
								>
									创建
								</Button>
							</DialogActions>
						</Toolbar>
					</AppBar>
				)}
			</Box>
		</Dialog>
	);
};

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	// paddingBottom: 14,
	// paddingTop: '14',
	maxHeight: 'calc(100vh - 108px)',
	[theme.breakpoints.down('sm')]: {
		paddingTop: 30
	}
}));

interface CategoryCreateRouteProps {}

export const CategoryCreateRoute: React.FC<CategoryCreateRouteProps> = () => {
	const setCategoryCreateDialogOpen = useUpdateAtom(categoryCreateDialogOpenAtom);

	useEffect(() => {
		setCategoryCreateDialogOpen(true);
		return () => {
			setCategoryCreateDialogOpen(false);
		};
	}, []);

	return <></>;
};

export default CategoryCreateDialog;
