import { useUpdateAtom } from 'jotai/utils';
import { pageAtom } from '../atoms';
import { useEffect } from 'react';

export enum Page {
	DASHBOARD,
	USERPAGE,
	USER_MANAGEMENT,
	COURSE_MANAGEMENT,
	COURSE_REGISTRATION,
	CLASS_ARRANGEMENT,
	PAYMENT_MANAGEMENT,
	LOCATION_MANAGEMENT,
	SETTINGS
}

export default function usePage(page: Page) {
	const setPage = useUpdateAtom(pageAtom);

	useEffect(() => {
		setPage(page);
	}, [page, setPage]);
}
