import React from 'react';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	FormControlLabel,
	Grid,
	MenuItem,
	Paper,
	TextField,
	Typography
} from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { StudentStatus, StudentPhone, UserStatus } from '../../../../../generated/api';
import StudentPhones from '../../../../../share-components/User/StudentPhones';
import { useUserCreateStore } from '../../../../../stores/useUserCreateStore';
import shallow from 'zustand/shallow';

interface UserCreateRolesStudentProps {
	handleChange: (
		event: React.ChangeEvent<{
			name: string;
			value: any;
			type: string;
			checked?: boolean;
		}>
	) => void;
}

const UserCreateRolesStudent: React.FC<UserCreateRolesStudentProps> = ({ handleChange }) => {
	const [student, studentExist, setUser, setRoles, userStatus] = useUserCreateStore(
		state => [
			state.user.student,
			state.roles.student,
			state.setUser,
			state.setRoles,
			state.user.status
		],
		shallow
	);

	const handleStudentPhonesChange = (phones: StudentPhone[]) => {
		setUser({ student: { ...student, phones } });
	};

	const handleExpandedChange = (event: React.ChangeEvent<{}>, expanded: boolean) => {
		setRoles({ student: expanded });
	};

	return (
		<Accordion variant="outlined" expanded={studentExist} onChange={handleExpandedChange}>
			<AccordionSummary expandIcon={<ExpandMore />}>
				<FormControlLabel
					onClick={event => event.stopPropagation()}
					onFocus={event => event.stopPropagation()}
					control={
						<Checkbox
							name="student"
							checked={studentExist}
							onChange={event => handleExpandedChange(event, event.target.checked)}
						/>
					}
					label="添加此用户为学生"
				/>
			</AccordionSummary>
			<AccordionDetails>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							label="学号"
							name="student.matricId"
							placeholder="YYMMXXX"
							value={student.matricId}
							onChange={handleChange}
							fullWidth
							disabled={!studentExist}
							InputLabelProps={{
								shrink: true
							}}
							inputProps={{
								pattern: '(^$|[0-9]{7}$)'
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<TextField
							variant="outlined"
							label="在籍状况"
							name="student.status"
							value={student.status}
							onChange={handleChange}
							select
							fullWidth
							disabled={!studentExist}
						>
							{userStatus === UserStatus.Active && (
								<MenuItem value={StudentStatus.Active}>在籍学生</MenuItem>
							)}
							<MenuItem value={StudentStatus.Temporary}>试课学生</MenuItem>
							<MenuItem value={StudentStatus.Inactive}>不在籍学生</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={12} sx={{ mt: '10px' }}>
						<Paper variant="outlined" sx={{ p: '10px' }}>
							<Typography variant="body2" paragraph>
								学生联系号码
							</Typography>
							<StudentPhones
								phones={student.phones}
								setPhones={handleStudentPhonesChange}
								disabled={!studentExist}
							/>
						</Paper>
					</Grid>
				</Grid>
			</AccordionDetails>
		</Accordion>
	);
};

export default UserCreateRolesStudent;
