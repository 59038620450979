import React, { useState } from 'react';
import {
	IconButton,
	ListItem,
	ListItemIcon,
	ListItemText,
	Popover,
	Tooltip
} from '@mui/material';
import { Feedback, MoreVert, Refresh } from '@mui/icons-material';
import { useRefetchFunctionsStore } from '../../stores/useRefetchFunctionsStore';
import { styled } from '@mui/material/styles';

interface HeaderActionsProps {}

const HeaderActions: React.FC<HeaderActionsProps> = () => {
	const refreshable = useRefetchFunctionsStore(
		state => state.functions.length > 0
	);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRefresh = async () => {
		await useRefetchFunctionsStore.getState().refresh();
	};

	return (
		<Root>
			{refreshable && (
				<Tooltip title="刷新">
					<HeaderActionButton onClick={handleRefresh} size="large">
						<Refresh />
					</HeaderActionButton>
				</Tooltip>
			)}
			<HeaderActionButton edge="end" onClick={handleClick} size="large">
				<MoreVert />
			</HeaderActionButton>
			<Popover
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right'
				}}
			>
				<ListItem onClick={handleClose} button>
					<ListItemIcon sx={{ minWidth: 46 }}>
						<Feedback />
					</ListItemIcon>
					<ListItemText primary="反映问题" />
				</ListItem>
			</Popover>
		</Root>
	);
};

const Root = styled('div')(({ theme }) => ({
	display: 'flex',
	marginLeft: 'auto',
	marginRight: 0,
	[theme.breakpoints.up('md')]: {
		marginBottom: 16
	}
}));

const HeaderActionButton = styled(IconButton)(({ theme }) => ({
	color: theme.palette.primary.main,
	[theme.breakpoints.down('md')]: {
		color: '#fff'
	}
}));

export default HeaderActions;
