import create from 'zustand';

type RefetchFunctionsStoreState = {
	functions: (() => Promise<any>)[];
	loading: boolean;
	addFunction: (fn: () => Promise<any>) => void;
	removeFunction: (fn: () => Promise<any>) => void;
	setLoading: (loading: boolean) => void;
	refresh: (delay?: number) => void;
};

export const useRefetchFunctionsStore = create<RefetchFunctionsStoreState>(
	(set, get) => ({
		functions: [],
		loading: false,
		addFunction: fn => set({ functions: [...get().functions, fn] }),
		removeFunction: fn =>
			set({ functions: get().functions.filter(f => f !== fn) }),
		setLoading: loading => set({ loading }),
		refresh: async (delay = 500) => {
			const { functions, setLoading } = get();
			if (functions.length > 0) {
				try {
					setLoading(true);
					functions.forEach(fn => fn());
				} catch {
				} finally {
					setTimeout(() => {
						setLoading(false);
					}, delay);
				}
			}
		}
	})
);
