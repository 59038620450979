import { MutationUpdaterFn } from '@apollo/client';
import {
	GetAdminsQuery,
	GetAdminsQueryVariables,
	UpdateUserMutation
} from '../../../generated/api';
import { GET_ADMINS } from '../query';

export const createAdminUpdate: MutationUpdaterFn<UpdateUserMutation> = (
	cache,
	mutationResults
) => {
	const updatedUser = mutationResults.data?.updateUser;

	if (!updatedUser || !updatedUser.admin) {
		return;
	}
	const variables: GetAdminsQueryVariables = {
		status: updatedUser.admin.status
	};

	const data = cache.readQuery<GetAdminsQuery>({
		query: GET_ADMINS,
		variables
	});

	if (data) {
		cache.writeQuery<GetAdminsQuery>({
			query: GET_ADMINS,
			variables,
			data: {
				admins: [
					...data.admins,
					{
						...updatedUser.admin,
						user: updatedUser
					}
				]
			}
		});
	}
};
