import { MutationUpdaterFn } from '@apollo/client';
import {
	GetStudentsQuery,
	GetStudentsQueryVariables,
	UpdateUserMutation
} from '../../../generated/api';
import { GET_STUDENTS } from '../query';

export const createStudentUpdate: MutationUpdaterFn<UpdateUserMutation> = (
	cache,
	mutationResults
) => {
	const updatedUser = mutationResults.data?.updateUser;

	if (!updatedUser || !updatedUser.student) {
		return;
	}

	const variables: GetStudentsQueryVariables = {
		status: updatedUser.student.status
	};

	const data = cache.readQuery<GetStudentsQuery>({
		query: GET_STUDENTS,
		variables
	});

	if (data) {
		cache.writeQuery<GetStudentsQuery>({
			query: GET_STUDENTS,
			variables,
			data: {
				students: [
					...data.students,
					{
						...updatedUser.student,
						user: updatedUser
					}
				]
			}
		});
	}
};
