import { DeleteUserMutation } from '../../../generated/api';
import { MutationUpdaterFn } from '@apollo/client';

export const deleteUserUpdate: MutationUpdaterFn<DeleteUserMutation> = (
	cache,
	mutationResult
) => {
	const id = mutationResult.data?.deleteUser?.id;

	if (!id) {
		return;
	}

	cache.evict({ id: cache.identify({ id, __typename: 'User' }) });
	cache.evict({ id: cache.identify({ user: { id }, __typename: 'Student' }) });
	cache.evict({ id: cache.identify({ user: { id }, __typename: 'Lecturer' }) });
	cache.evict({ id: cache.identify({ user: { id }, __typename: 'Admin' }) });
	cache.gc();
};
