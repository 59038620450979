import create from 'zustand';
import { ReceivableType, Receivable } from '../generated/api';
import { persistIndexedDB } from './IndexedDB';
import produce from 'immer';

type InvoiceCreateStoreState = {
	debtor: {
		id: string;
		cname: string;
		ename: string;
		address: string;
	} | null;
	createAt: Date | null;
	discount: number;
	tax: number;
	items: Array<{
		id: string;
		title: string;
		description: string;
		service: ReceivableType | null;
		quantity: number;
		price: number;
		receivable?: Omit<Receivable, 'debtor'>;
	}>;
	setDebtor: (debtor: InvoiceCreateStoreState['debtor']) => void;
	setCreateAt: (createAt: Date | null) => void;
	setDiscount: (discount: number) => void;
	setTax: (tax: number) => void;
	setItems: (items: InvoiceCreateStoreState['items']) => void;
	setItemTitle: (id: string, title: string) => void;
	setItemDescription: (id: string, description: string) => void;
	setItemService: (id: string, service: ReceivableType | null) => void;
	setItemQuantity: (id: string, quantity: number) => void;
	setItemPrice: (id: string, price: number) => void;
	addItems: (items: InvoiceCreateStoreState['items']) => void;
	removeItem: (id: string) => void;
	reset: () => void;
};

// const newItem = ({
// 	id = '',
// 	title = '',
// 	description = '',
// 	service = null,
// 	quantity = 1,
// 	price = 0
// }: {
// 	id?: string;
// 	title?: string;
// 	description?: string;
// 	service?: ReceivableType | null;
// 	quantity?: number;
// 	price?: number;
// }) => ({
// 	id,
// 	title,
// 	description,
// 	service,
// 	quantity,
// 	price,
// 	newReceivable: true
// });

export const useInvoiceCreateStore = create<InvoiceCreateStoreState>()(
	persistIndexedDB(
		(set, get) => ({
			debtor: null,
			createAt: null,
			discount: 0,
			tax: 0,
			items: [],
			setDebtor: debtor => set({ debtor }),
			setCreateAt: createAt => set({ createAt }),
			setDiscount: discount => set({ discount }),
			setTax: tax => set({ tax }),
			setItems: items => set({ items }),
			setItemTitle: (id, title) =>
				set(
					produce<InvoiceCreateStoreState>(draft => {
						const item = draft.items.find(item => item.id === id);
						if (!item) {
							return;
						}
						item.title = title;
					})
				),
			setItemDescription: (id, description) =>
				set(
					produce<InvoiceCreateStoreState>(draft => {
						const item = draft.items.find(item => item.id === id);
						if (!item) {
							return;
						}
						item.description = description;
					})
				),
			setItemService: (id, service) =>
				set(
					produce<InvoiceCreateStoreState>(draft => {
						const item = draft.items.find(item => item.id === id);
						if (!item) {
							return;
						}
						item.service = service;
					})
				),
			setItemQuantity: (id, quantity) =>
				set(
					produce<InvoiceCreateStoreState>(draft => {
						const item = draft.items.find(item => item.id === id);
						if (!item) {
							return;
						}
						item.quantity = quantity;
					})
				),
			setItemPrice: (id, price) =>
				set(
					produce<InvoiceCreateStoreState>(draft => {
						const item = draft.items.find(item => item.id === id);
						if (!item) {
							return;
						}
						item.price = price;
					})
				),
			addItems: items =>
				set(
					produce<InvoiceCreateStoreState>(draft => {
						draft.items.push(...items);
					})
				),
			removeItem: id =>
				set(
					produce<InvoiceCreateStoreState>(draft => {
						const index = draft.items.findIndex(item => item.id === id);
						if (index !== -1) {
							draft.items.splice(index, 1);
						}
					})
				),
			reset: () =>
				set({
					debtor: null,
					createAt: null,
					items: []
				})
		}),
		{
			name: 'invoice-create-state'
		}
	)
);
