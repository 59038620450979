import { Box, Theme, Typography } from '@mui/material';
import { useAtom } from 'jotai';
import React, { useEffect } from 'react';
import { isOnlineAtom } from './atoms';

interface NetworkIndicatorProps {}

const NetworkIndicator: React.FC<NetworkIndicatorProps> = () => {
	const [isOnline, setIsOnline] = useAtom(isOnlineAtom);

	const handleNetworkStateChange = () => {
		setIsOnline(navigator.onLine);
	};

	useEffect(() => {
		window.addEventListener('online', handleNetworkStateChange);
		window.addEventListener('offline', handleNetworkStateChange);

		return () => {
			window.removeEventListener('online', handleNetworkStateChange);
			window.removeEventListener('offline', handleNetworkStateChange);
		};
	}, []);

	return (
		<Box
			sx={{
				width: '100%',
				height: !isOnline ? 30 : 0,
				overflow: 'hidden',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				transition: 'height 250ms',
				position: 'relative',
				zIndex: 1101,
				backgroundColor: (theme: Theme) => theme.palette.primary.main,
				color: (theme: Theme) => (theme.palette.mode === 'light' ? '#fff' : '#000')
			}}
		>
			<Typography>Offline Mode</Typography>
		</Box>
	);
};

export default NetworkIndicator;
