import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material';
import { useAtom } from 'jotai';
import { invoiceOpenIdAtom } from '../../../atoms';
import useLastNotNullValue from '../../../hooks/useLastNotNullvalue';
import { Relay } from '../../../utils/relay';
import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';
import { InvoiceStatus, useUpdateInvoiceMutation } from '../../../generated/api';

interface InvoiceOpenDialogProps {}

const InvoiceOpenDialog: React.FC<InvoiceOpenDialogProps> = () => {
	const [invoiceIdRaw, setInvoiceId] = useAtom(invoiceOpenIdAtom);
	const invoiceId = useLastNotNullValue(invoiceIdRaw ? Relay.unmarshalId(invoiceIdRaw) : '');
	const [updateInvoiceMutation] = useUpdateInvoiceMutation();

	const handleClose = () => {
		setInvoiceId('');
	};

	const handleClick = async () => {
		try {
			await updateInvoiceMutation({
				variables: {
					id: invoiceIdRaw,
					input: {
						status: InvoiceStatus.Open
					}
				}
			});
			toast.success(`开启发票${invoiceId}成功!`);
			handleClose();
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(err.message);
			} else {
				toast.error(`${err}`);
			}
		}
	};

	return (
		<Dialog open={!!invoiceIdRaw} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>开启{invoiceId}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					确定要开启{invoiceId}
					吗？开启发票后系统将会通知付款着并且发票无法被删除，必须经过一定手续关闭。
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>取消</Button>
				<Button onClick={handleClick}>确定</Button>
			</DialogActions>
		</Dialog>
	);
};

export default InvoiceOpenDialog;
