import React, { useEffect } from 'react';
import { PaperProps, TableHeadProps, Theme } from '@mui/material';
import { SxProps } from '@mui/material/styles';
import { HeadCell, TableAction, TableListToolbar } from '../../types';
import { TableFilterHolder, useTableFilterStore } from '../../stores/useTableFilterStore';
import TableListContent, { desc } from './TableListContent';

interface TableListProps<T> {
	holder: TableFilterHolder;
	items: T[];
	headCells: HeadCell[];
	actions?: TableAction[];
	clickFunc?: (props: T) => void;
	tableStyle?: SxProps<Theme>;
	toolBar?: TableListToolbar;
	selectable?: boolean;
	contextMenu?: (params: { closeContextMenu: () => void; item: T }) => JSX.Element[];
	disableFirstAndLastPageButton?: boolean;
	sorter?: typeof desc;
	tabBar?: React.ReactNode;
	filter?: React.ReactNode;
	paperProps?: PaperProps;
	tableHeadProps?: TableHeadProps;
}

const TableList = <T,>(props: TableListProps<T>) => {
	useEffect(() => {
		useTableFilterStore.getState().setCurrentHolder(props.holder);
	}, []);

	return (
		<>
			{props.items && (
				<>
					{/*
			//@ts-ignore lazy to fix now */}
					<TableListContent {...props} />
				</>
			)}
		</>
	);
};

export default TableList;
