import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	Box,
	Breadcrumbs,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Link,
	MenuItem,
	Paper,
	SvgIconProps,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
	Typography,
	useTheme
} from '@mui/material';
import {
	Audiotrack,
	CheckCircle,
	Clear,
	CloudUpload,
	Description,
	Error,
	Image,
	Movie,
	NavigateNext
} from '@mui/icons-material';
import { Link as RouterLink, Navigate, useNavigate, useParams } from 'react-router-dom';
import {
	InvoiceStatus,
	PaymentMethod,
	PaymentStatus,
	useCreatePaymentMutation,
	useCreatePaymentProofMutation,
	useGetInvoiceQuery
} from '../../../generated/api';
import SimpleBar from 'simplebar-react';
import { Relay } from '../../../utils/relay';
import { useRefetchHandler } from '../../../hooks/useRefetchHandler';
import { v4 } from 'uuid';
import { PaymentProofFileOld } from '../../../stores/usePaymentCreateStoreOld';
import axios from 'axios';
import { PaymentProofFile, usePaymentCreateStore } from '../../../stores/usePaymentCreateStore';
import shallow from 'zustand/shallow';
import { ApolloError } from '@apollo/client';
import { toast } from 'react-toastify';

type ResolveReject = [(value: void | PromiseLike<void>) => void, (reason?: any) => void];

interface PaymentCreatePageProps {}

const PaymentCreatePage: React.FC<PaymentCreatePageProps> = () => {
	const params = useParams();
	const id = params['id'];

	if (!id) {
		return null;
	}

	const { data, refetch, startPolling, stopPolling } = useGetInvoiceQuery({
		variables: { id: Relay.marshalId('Invoice', id) }
	});
	const invoice = useMemo(() => (data?.node?.__typename === 'Invoice' ? data.node : null), [data]);

	if (invoice?.status === InvoiceStatus.Paid) {
		return <Navigate to={`/payment-management/invoices/${id}/receipt`} replace />;
	}

	const [
		paymentId,
		paymentMethod,
		paymentMethodDescription,
		paymentProofs,
		setPayment,
		setPaymentMethod,
		setPaymentMethodDescription,
		setPaymentProofs,
		setPaymentProofKey,
		setPaymentProofStatus,
		setPaymentProofError
	] = usePaymentCreateStore(
		state => [
			state.invoices[id]?.paymentId,
			state.invoices[id]?.method,
			state.invoices[id]?.methodDescription,
			state.invoices[id]?.proofs || [],
			state.setPayment,
			state.setPaymentMethod,
			state.setPaymentMethodDescription,
			state.setPaymentProofs,
			state.setPaymentProofKey,
			state.setPaymentProofStatus,
			state.setPaymentProofError
		],
		shallow
	);
	const containerElem = useRef<HTMLDivElement | null>(null);
	const inputElem = useRef<HTMLInputElement | null>(null);
	const theme = useTheme();
	const [createPaymentMutation, { loading }] = useCreatePaymentMutation();
	const [uploadFailedResolveReject, setUploadFailedResolveReject] = useState<ResolveReject | null>(
		null
	);
	const navigate = useNavigate();

	useRefetchHandler(refetch, startPolling, stopPolling);

	if (invoice?.__typename !== 'Invoice') {
		return null;
	}

	const handlePaymentMethodChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPaymentMethod(id, event.target.value as PaymentMethod);
	};

	const handlePaymentMethodDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPaymentMethodDescription(id, event.target.value);
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newPaymentProofs = Array.from(event.target.files || []).map(file => ({
			id: v4(),
			key: '',
			file,
			status: 'READY' as const
		}));
		setPaymentProofs(id, [
			...paymentProofs.filter(
				proof => !newPaymentProofs.find(p => p.file.name === proof.file.name)
			),
			...newPaymentProofs
		]);
		if (inputElem.current) {
			inputElem.current.value = '';
		}
	};

	const handleMouseEnter = (event: React.MouseEvent<{}>) => {
		event.preventDefault();
	};

	const handleDragEnter = () => {
		if (containerElem.current) {
			containerElem.current.style.borderColor = theme.palette.primary.main;
		}
	};

	const handleDragEnd = () => {
		containerElem.current?.style.removeProperty('border-color');
	};

	const handleClear = (index: number) => () => {
		let newPaymentProofs = Array.from(paymentProofs);
		newPaymentProofs.splice(index, 1);
		setPaymentProofs(id, newPaymentProofs);
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		for (const proof of paymentProofs) {
			if (proof.status === 'UPLOADING') {
				toast.error('还有尚未上传完毕的文件凭证，请稍候！');
				return;
			}
			if (proof.status === 'FAILED') {
				try {
					await new Promise<void>((resolve, reject) => {
						setUploadFailedResolveReject([resolve, reject]);
					});
				} catch {
					return;
				} finally {
					setUploadFailedResolveReject(null);
				}
				break;
			}
		}

		// if (paymentProofs.filter(proof => proof.status === 'COMPLETED').length === 0) {
		// 	// Cash is 1, this is immutable behavior
		// 	if (paymentMethod !== 1) {
		// 		toast.error('非Cash支付需要上传至少一个凭证文件');
		// 		return;
		// 	}
		// }

		try {
			await createPaymentMutation({
				variables: {
					input: {
						id: paymentId,
						invoiceId: invoice.id,
						userId: invoice.user.id,
						method: paymentMethod!,
						methodDescription: paymentMethodDescription,
						amount: invoice.amount,
						proofs: paymentProofs
							.filter(proof => proof.status === 'COMPLETED')
							.map(proof => proof.key),
						status: PaymentStatus.Completed
					}
				}
			});

			toast.success('付费成功!');
			navigate(`/payment-management/invoices/${id}/receipt`, { replace: true });
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(err.message);
			} else {
				toast.error(`${err}`);
			}
		}
	};

	useEffect(() => {
		setPayment(id, {});
	}, []);

	return (
		<Box sx={{ mt: 2, mb: 8 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
					<Link
						to="/"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						主页
					</Link>
					<Link
						to="/payment-management/invoices"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						发票
					</Link>
					<Link
						to={`/payment-management/invoices/${id}`}
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						{id}
					</Link>
					<Typography variant="body2" sx={{ fontSize: '1.1rem', opacity: 0.7 }}>
						付款
					</Typography>
				</Breadcrumbs>
			</Box>
			<form onSubmit={handleSubmit}>
				<Paper
					sx={{
						mt: 6,
						minHeight: 600,
						borderRadius: '16px',
						p: 4,
						boxShadow: theme =>
							theme.palette.mode === 'light'
								? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
								: 'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px'
					}}
				>
					<Box sx={{ display: 'flex' }}>
						<Box
							sx={theme => ({
								display: 'flex',
								flexWrap: 'nowrap',
								[theme.breakpoints.down('md')]: {
									flexWrap: 'wrap'
								},
								flex: 1
							})}
						>
							<img
								src="/images/ekids_logo.webp"
								alt=""
								css={{
									maxWidth: 200,
									height: 'max-content',
									marginRight: 16,
									marginBottom: 16,
									'@media(max-width:900px)': {
										maxWidth: 160,
										marginBottom: 8
									}
								}}
							/>
							<Box sx={{ mb: 2 }}>
								<Typography variant="h6">
									ELEMENTE EDUCATION SDN. BHD.{' '}
									<span css={{ fontSize: '0.875rem', fontWeight: 'normal' }}>
										(Co. No.1280836-M)
									</span>
								</Typography>
								<Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
									Level 2, L2.27 KL GATEWAY MALL.
									<br />
									2, Jalan Kerinchi, Kampung Kerinchi, 59200 Kuala Lumpur, Wilayah Persekutuan Kuala
									Lumpur, Malaysia.
								</Typography>
								<Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
									H/P: 012-336 6124
								</Typography>
							</Box>
						</Box>
						{/*<Box>*/}
						{/*	<InvoiceStatusChip status={invoice.status} />*/}
						{/*</Box>*/}
					</Box>
					<Box
						sx={theme => ({
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							my: 1,
							[theme.breakpoints.down('md')]: {
								justifyContent: 'flex-start',
								my: 2
							}
						})}
					>
						<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
							OFFICIAL RECEIPT
						</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '100%',
							flexWrap: 'wrap'
						}}
					>
						<Box sx={{ mt: 1, width: 360, maxWidth: '100%' }}>
							<Typography
								variant="h6"
								sx={{ opacity: 0.5, fontSize: '0.875rem !important', mb: 1 }}
							>
								TO
							</Typography>
							<Typography variant="subtitle1" sx={{ my: 1 }}>
								{invoice.user.cname}
								{invoice.user.cname !== '' ? ' ' : ''}
								{invoice.user.ename}
							</Typography>
							<Typography variant="subtitle1">{invoice.user.address}</Typography>
						</Box>
						<Box
							sx={theme => ({
								display: 'flex',
								flexDirection: 'column',
								mt: 2,
								[theme.breakpoints.down('md')]: {
									width: '100%',
									mt: 4
								}
							})}
						>
							<Typography
								variant="h6"
								sx={{ opacity: 0.5, fontSize: '0.875rem !important', mb: 1 }}
							>
								RECEIPT NO
							</Typography>
							<Typography variant="body1">EED22001</Typography>
							<Typography
								variant="h6"
								sx={{ opacity: 0.5, fontSize: '0.875rem !important', mb: 1, mt: 4 }}
							>
								DATE CREATE
							</Typography>
							<Typography variant="body1">{new Date().toLocaleDateString('en-MY')}</Typography>
						</Box>
					</Box>
					<SimpleBar>
						<Table sx={{ mt: 6 }}>
							<TableHead>
								<TableRow
									sx={{
										'& > .MuiTableCell-root': {
											opacity: 0.7
										}
									}}
								>
									<TableCell>#</TableCell>
									<TableCell>Description</TableCell>
									<TableCell align="right">Quantity</TableCell>
									<TableCell align="right">Unit Price</TableCell>
									<TableCell align="right">Total</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{invoice.items.map((item, index) => (
									<TableRow key={item.receivable.id}>
										<TableCell>{index + 1}</TableCell>
										<TableCell>
											<Box sx={{ maxWidth: 560 }}>
												<Typography variant="h6" sx={{ fontSize: '1rem !important' }}>
													{item.receivable.name}
												</Typography>
												<Typography
													variant="body2"
													sx={{
														opacity: 0.6,
														textOverflow: 'ellipsis',
														whiteSpace: 'nowrap',
														overflow: 'hidden'
													}}
												>
													{item.description}
												</Typography>
											</Box>
										</TableCell>
										<TableCell align="right">{item.receivable.quantity}</TableCell>
										<TableCell align="right">{item.receivable.price}</TableCell>
										<TableCell align="right">
											{item.receivable.quantity * item.receivable.price}
										</TableCell>
									</TableRow>
								))}
								<TableRow
									sx={{
										'& > td': {
											borderBottom: 'none'
										}
									}}
								>
									<TableCell colSpan={3} />
									<TableCell align="right">
										<Box sx={{ mt: 4 }} />
										<Typography variant="body1">Subtotal</Typography>
									</TableCell>
									<TableCell align="right">
										<Box sx={{ mt: 4 }} />
										<Typography variant="body1">
											RM
											{invoice.items
												.reduce(
													(total, item) => total + item.receivable.price * item.receivable.quantity,
													0
												)
												.toFixed(2)}
										</Typography>
									</TableCell>
								</TableRow>
								<TableRow
									sx={{
										'& > td': {
											borderBottom: 'none'
										}
									}}
								>
									<TableCell colSpan={3} />
									<TableCell align="right">
										<Typography variant="body1">Discount</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="body1" color={invoice.discount ? 'error' : 'inherit'}>
											{invoice.discount ? `- RM${invoice.discount.toFixed(2)}` : '-'}
										</Typography>
									</TableCell>
								</TableRow>
								<TableRow
									sx={{
										'& > td': {
											borderBottom: 'none'
										}
									}}
								>
									<TableCell colSpan={3} />
									<TableCell align="right">
										<Typography variant="body1">Taxes</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="body1">
											{invoice.tax ? `RM${invoice.tax.toFixed(2)}` : '-'}
										</Typography>
									</TableCell>
								</TableRow>
								<TableRow
									sx={{
										'& > td': {
											borderBottom: 'none'
										}
									}}
								>
									<TableCell colSpan={3}>
										<Box sx={{ display: 'flex' }}>
											<Box sx={{ minWidth: 140, mr: 1 }}>
												<TextField
													value={paymentMethod}
													onChange={handlePaymentMethodChange}
													select
													fullWidth
													label="支付方式"
													required
												>
													{Object.keys(PaymentMethod).map(method => (
														<MenuItem key={method} value={method}>
															{method}
														</MenuItem>
													))}
												</TextField>
											</Box>
											<Box sx={{ flex: 1, minWidth: 250 }}>
												<TextField
													value={paymentMethodDescription}
													onChange={handlePaymentMethodDescriptionChange}
													fullWidth
													label="支付信息"
												/>
											</Box>
										</Box>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h6">Total</Typography>
									</TableCell>
									<TableCell align="right">
										<Typography variant="h6">
											{(() => {
												if (invoice.amount < 0) {
													return `- RM${(-invoice.amount).toFixed(2)}`;
												} else {
													return `RM${invoice.amount.toFixed(2)}`;
												}
											})()}
										</Typography>
									</TableCell>
								</TableRow>
							</TableBody>
						</Table>
					</SimpleBar>
					<Divider sx={{ mt: 4, mb: 2 }} />
					<Typography variant="body1" sx={{ mb: 3 }}>
						支付凭证
					</Typography>
					<Paper
						ref={containerElem}
						variant="outlined"
						sx={{
							borderStyle: 'dashed',
							height: 200,
							width: '100%',
							mt: 2,
							backgroundColor: theme => theme.palette.background.default,
							transition: 'border-color 300ms',
							'&:hover': {
								borderColor: theme => theme.palette.primary.main
							}
						}}
						elevation={0}
						square
					>
						<Box
							component="label"
							htmlFor="files-input"
							sx={{ position: 'relative', display: 'block', height: 200, marginTop: '2px' }}
							onDragEnter={handleDragEnter}
							onDragOver={handleDragEnter}
							onDragEnd={handleDragEnd}
							onDragExit={handleDragEnd}
							onDragLeave={handleDragEnd}
						>
							<Box
								component="input"
								ref={inputElem}
								type="file"
								id="files-input"
								aria-label="Upload Files Input"
								title=" "
								sx={{
									position: 'absolute',
									top: 0,
									left: 0,
									opacity: 0,
									width: '100%',
									height: '100%',
									display: 'block',
									cursor: 'pointer',
									zIndex: 10
								}}
								multiple
								onChange={handleFileChange}
								onDrop={handleDragEnd}
								onMouseEnter={handleMouseEnter}
							/>
							<Grid
								container
								justifyContent="center"
								alignItems="center"
								alignContent="center"
								spacing={1}
								sx={{
									width: '100%',
									height: '100%'
								}}
							>
								<Grid
									container
									item
									xs={12}
									justifyContent="center"
									style={{ marginBottom: '4px' }}
								>
									<CloudUpload color="primary" style={{ fontSize: '3rem' }} />
								</Grid>
								<Grid container item xs={12} justifyContent="center">
									<Typography variant="body2" align="center">
										点击或拖动文件至此区域以上传凭证
									</Typography>
								</Grid>
								<Grid container item xs={12} justifyContent="center">
									<Typography
										variant="body2"
										align="center"
										sx={{ opacity: 0.7, fontSize: '0.9em' }}
									>
										支持单个或多个文件同时上传
									</Typography>
								</Grid>
							</Grid>
						</Box>
					</Paper>
					<Box
						sx={{
							marginTop: '16px',
							overflowY: 'auto',
							'& > .MuiPaper-root:last-of-type': {
								mb: 0
							}
						}}
					>
						{paymentProofs.map((paymentProof, index) => (
							<PaymentProofItem
								key={paymentProof.id}
								invoiceId={id}
								paymentId={paymentId}
								paymentProof={paymentProof}
								handleClear={handleClear(index)}
								setPaymentProofKey={setPaymentProofKey}
								setPaymentProofStatus={setPaymentProofStatus}
								setPaymentProofError={setPaymentProofError}
							/>
						))}
					</Box>
				</Paper>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
					<Button
						type="submit"
						variant="contained"
						size="large"
						disabled={loading}
						sx={{
							borderRadius: '8px',
							color: theme => (theme.palette.mode === 'light' ? '#fff' : '#000')
						}}
					>
						创建并发送
					</Button>
				</Box>
			</form>
			<PaymentCreateFailedProofWarningDialog
				resolveReject={uploadFailedResolveReject}
				paymentProofs={paymentProofs}
			/>
		</Box>
	);
};

const ContentIcon = ({ type }: { type: File['type'] }) => {
	const contentIconProps: SvgIconProps = {
		fontSize: 'small',
		color: 'inherit'
	};

	if (type.includes('video')) {
		return <Movie {...contentIconProps} />;
	} else if (type.includes('audio')) {
		return <Audiotrack {...contentIconProps} />;
	} else if (type.includes('image')) {
		return <Image {...contentIconProps} />;
	} else {
		return <Description {...contentIconProps} />;
	}
};

const PaymentProofItem: React.FC<{
	invoiceId: string;
	paymentId: string;
	paymentProof: PaymentProofFileOld;
	handleClear: () => void;
	setPaymentProofKey: (invoiceId: string, proofId: string, key: PaymentProofFileOld['key']) => void;
	setPaymentProofStatus: (
		invoiceId: string,
		proofId: string,
		key: PaymentProofFileOld['status']
	) => void;
	setPaymentProofError: (
		invoiceId: string,
		proofId: string,
		key: PaymentProofFileOld['error']
	) => void;
}> = ({
	invoiceId,
	paymentId,
	paymentProof,
	handleClear,
	setPaymentProofKey,
	setPaymentProofStatus,
	setPaymentProofError
}) => {
	const executedRef = useRef(false);
	const [isHover, setIsHover] = useState(false);
	const [progress, setProgress] = useState(0);
	const controllerRef = useRef<AbortController | null>(null);
	const [createPaymentProof, { data }] = useCreatePaymentProofMutation({
		variables: {
			input: {
				id: paymentId,
				proof: {
					name: paymentProof.file.name,
					contentLength: paymentProof.file.size
				}
			}
		}
	});
	const isFailed = useMemo(() => paymentProof.status === 'FAILED', [paymentProof]);

	const handleMouseEnter = () => {
		setIsHover(true);
	};

	const handleMouseLeave = () => {
		setIsHover(false);
	};

	const handleCreatePaymentProof = async () => {
		try {
			await createPaymentProof();
		} catch (err) {
			setPaymentProofStatus(invoiceId, paymentProof.id, 'FAILED');
			setPaymentProofError(invoiceId, paymentProof.id, `${err}`);
			return Promise.reject();
		}
	};

	const handleRetry = async () => {
		setPaymentProofStatus(invoiceId, paymentProof.id, 'READY');
		setPaymentProofError(invoiceId, paymentProof.id, undefined);

		try {
			await handleCreatePaymentProof();
			// this is not optimal since it only catch first api error,
			// second api call with error will have the hover state exit which do not show up the retry and clear button.
			// for now, I will put it as it is, since there should be rare to have error on second api, hopefully.
			// TODO: please revisit the hover state on second api error.
			setIsHover(false);
		} catch {}
	};

	useEffect(() => {
		if (executedRef.current || paymentProof.status !== 'READY') {
			return;
		}

		handleCreatePaymentProof().catch(() => {});

		executedRef.current = true;
	}, []);

	useEffect(() => {
		return () => {
			controllerRef.current?.abort();
		};
	}, []);

	useEffect(() => {
		(async () => {
			if (
				(paymentProof.status !== 'READY' && paymentProof.status !== 'FAILED') ||
				!data?.createPaymentProof
			) {
				return;
			}

			setPaymentProofStatus(invoiceId, paymentProof.id, 'UPLOADING');
			setPaymentProofKey(invoiceId, paymentProof.id, data.createPaymentProof.key);

			controllerRef.current = new AbortController();

			try {
				await axios.put(data.createPaymentProof.url, paymentProof.file, {
					headers: {
						'Cache-Control': 'private,max-age=31536000,immutable'
					},
					signal: controllerRef.current.signal,
					onUploadProgress: progressEvent => {
						const progress = (progressEvent.loaded / (progressEvent.total || 0)) * 100;
						setProgress(progress);
					}
				});

				setPaymentProofStatus(invoiceId, paymentProof.id, 'COMPLETED');
			} catch (err) {
				setPaymentProofStatus(invoiceId, paymentProof.id, 'FAILED');
				setPaymentProofError(invoiceId, paymentProof.id, `${err}`);
			}
		})();
	}, [data]);

	return (
		<Paper
			variant="outlined"
			square
			sx={{
				backgroundColor: theme => theme.palette.background.default,
				mb: 1,
				padding: 1,
				display: 'flex',
				alignItems: 'center'
			}}
			onMouseEnter={isFailed ? handleMouseEnter : undefined}
			onMouseLeave={isFailed ? handleMouseLeave : undefined}
		>
			<ContentIcon type={paymentProof.file.type} />
			<Typography variant="caption" sx={{ marginLeft: '9px', wordBreak: 'break-word' }}>
				{isHover && isFailed ? paymentProof.error || '' : paymentProof.file.name}
			</Typography>
			<Box
				sx={{ mr: 0, ml: 'auto', display: 'flex', alignItems: 'center', height: 30 }}
				onMouseEnter={!isFailed ? handleMouseEnter : undefined}
				onMouseLeave={!isFailed ? handleMouseLeave : undefined}
			>
				{!isHover ? (
					<Box sx={{ margin: '5px', height: '20px', width: '20px' }}>
						{paymentProof.status === 'COMPLETED' ? (
							<CheckCircle color="success" sx={{ height: '20px', width: '20px' }} />
						) : paymentProof.status === 'FAILED' ? (
							<Error color="error" sx={{ height: '20px', width: '20px' }} />
						) : (
							<CircularProgress
								size={20}
								thickness={5}
								variant={progress <= 20 ? 'indeterminate' : 'determinate'}
								value={progress}
							/>
						)}
					</Box>
				) : (
					<>
						{isHover && isFailed && (
							<Button size="small" onClick={handleRetry}>
								Retry
							</Button>
						)}
						<IconButton size="small" onClick={handleClear}>
							<Clear
								fontSize="small"
								sx={{
									opacity: 0.7,
									transition: 'opacity 100ms',
									marginLeft: 'auto',
									cursor: 'pointer',
									'&:hover': {
										opacity: 1
									}
								}}
							/>
						</IconButton>
					</>
				)}
			</Box>
		</Paper>
	);
};

const PaymentCreateFailedProofWarningDialog: React.FC<{
	resolveReject: ResolveReject | null;
	paymentProofs: PaymentProofFile[];
}> = ({ resolveReject, paymentProofs }) => {
	const handleClose = () => {
		resolveReject?.[1]();
	};

	const handleIgnore = () => {
		resolveReject?.[0]();
	};

	return (
		<Dialog open={!!resolveReject} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>上传失败的文件凭证</DialogTitle>
			<DialogContent>
				<DialogContentText>
					拥有上传失败的文件凭证，请问要无视它们吗？
					<br />
					<br />
					以下是上传失败的文件凭证：
					{paymentProofs
						.filter(proof => proof.status === 'FAILED')
						.map(proof => (
							<span key={proof.id}>
								<br />- {proof.file.name}
								<br />
								<i>&nbsp;&nbsp;{proof.error}</i>
							</span>
						))}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>取消</Button>
				<Button onClick={handleIgnore}>无视</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PaymentCreatePage;
