import React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material';
import { useAtom } from 'jotai';
import { classStudentDeleteIdsAtom } from '../../../../atoms';
import {
	DeleteClassStudentsMutationOptions,
	Student,
	useDeleteClassStudentsMutation,
	User
} from '../../../../generated/api';

interface ClassStudentDeleteDialogProps {
	classId: string;
	classStudents: (Pick<Student, 'matricId' | 'phones'> & {
		user: Pick<
			User,
			| 'id'
			| 'cname'
			| 'ename'
			| 'email'
			| 'gender'
			| 'ic'
			| 'phone'
			| 'status'
			| 'student'
			| 'lecturer'
			| 'admin'
		>;
	})[];
}

const ClassStudentDeleteDialog: React.FC<ClassStudentDeleteDialogProps> = ({
	classId,
	classStudents
}) => {
	const [userIds, setUserIds] = useAtom(classStudentDeleteIdsAtom);
	const [deleteClassStudentsMutation] = useDeleteClassStudentsMutation();

	const handleClose = () => {
		setUserIds([]);
	};

	const handleDelete = async () => {
		const deletingIds = [...userIds];
		handleClose();

		let optimisticResponse: DeleteClassStudentsMutationOptions['optimisticResponse'] = {
			deleteClassStudents: {
				__typename: 'Class',
				id: classId,
				students: []
			}
		};

		for (const student of classStudents.filter(s => deletingIds.indexOf(s.user.id) === -1)) {
			delete student.user['student'];
			delete student.user['lecturer'];
			delete student.user['admin'];
			optimisticResponse.deleteClassStudents?.students.push(student);
		}

		try {
			await deleteClassStudentsMutation({
				variables: {
					input: {
						classId: classId,
						students: deletingIds
					}
				},
				optimisticResponse
			});
		} catch {
			setUserIds(deletingIds);
		}
	};

	return (
		<Dialog open={userIds.length > 0} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>删除课程学生</DialogTitle>
			<DialogContent>
				<DialogContentText>确定要从此课程中删除共计{userIds.length}位学生吗？</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>取消</Button>
				<Button onClick={handleDelete}>删除</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ClassStudentDeleteDialog;
