import React, { useEffect, useMemo, useState } from 'react';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import {
	classTermCreateDialogIdAtom,
	lessonCreateDialogOpenAtom,
	lessonDeleteDialogItemAtom,
	lessonUpdateDialogSelectedIdAtom
} from '../../atoms';
import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
	Tab,
	Tooltip
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import { GET_LESSONS } from './query';
import { useUserPreferenceStore } from '../../stores/useUserPreferenceStore';
import {
	GetLessonsQuery,
	LessonStatus,
	UpdateLessonInput,
	useGetMinimalClassStudentsQuery,
	useUpdateLessonAttendancesMutation,
	useUpdateLessonMutation
} from '../../generated/api';
import { useLessonUpdateStore } from '../../stores/useLessonUpdateStore';
import LessonForm from '../../share-components/Lesson/LessonForm';
import { ContentCopy, Delete, Done } from '@mui/icons-material';
import LessonDeleteDialog from './LessonDeleteDialog';
import { useLessonCreateStore } from '../../stores/useLessonCreateStore';
import ClassColorsRadio from '../../share-components/ClassColorsRadio';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import ClassInheritenceStudentList from '../../share-components/Class/ClassInheritStudentList';
import LessonAttendances from '../../share-components/Lesson/LessonAttendances';
import SimpleBar from 'simplebar-react';
import { toast } from 'react-toastify';

interface LessonUpdateDialogProps {}

const LessonUpdateDialog: React.FC<LessonUpdateDialogProps> = () => {
	const lessonId = useAtomValue(lessonUpdateDialogSelectedIdAtom);
	const navigate = useNavigate();
	const location = useLocation();
	const language = useUserPreferenceStore(state => state.language);
	const { cache } = useApolloClient();
	const [lesson, setLesson] = useState<Exclude<GetLessonsQuery, undefined>['lessons'][0] | null>(
		null
	);
	const {
		startTime,
		endTime,
		color,
		classId,
		termId,
		spaceId,
		status,
		lecturers,
		students,
		attendances,
		setStartTime,
		setEndTime,
		setColor,
		setClassId,
		setTermId,
		setSpaceId,
		setStatus,
		setLecturers,
		setStudents,
		setAttendances,
		setAll
	} = useLessonUpdateStore();
	const [updateLesson, { loading: lessonLoading, error: lessonError, reset: resetLessonMutation }] =
		useUpdateLessonMutation();
	const [
		updateLessonAttendances,
		{ loading: attendancesLoading, error: attendancesError, reset: resetAttendancesMutation }
	] = useUpdateLessonAttendancesMutation({});
	const loading = useMemo(
		() => lessonLoading || attendancesLoading,
		[lessonLoading, attendancesLoading]
	);
	const error = useMemo(() => lessonError || attendancesError, [lessonError, attendancesError]);
	const setDeletingLesson = useUpdateAtom(lessonDeleteDialogItemAtom);
	const setLessonCreateDialogOpen = useUpdateAtom(lessonCreateDialogOpenAtom);
	const setClassTermCreateId = useUpdateAtom(classTermCreateDialogIdAtom);
	const [tabIndex, setTabIndex] = useState('1');
	const { data: classStudentsData } = useGetMinimalClassStudentsQuery({
		variables: { id: classId },
		skip: !classId
	});
	const classStudents = useMemo(
		() => (classStudentsData?.node?.__typename === 'Class' ? classStudentsData.node.students : []),
		[classStudentsData]
	);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	const getLesson = () => {
		return (
			cache
				.readQuery<GetLessonsQuery>({
					query: GET_LESSONS,
					variables: {
						language
					}
				})
				?.lessons.find(lesson => lesson.id === lessonId) ?? null
		);
	};

	useEffect(() => {
		setLesson(lessonId ? getLesson() : null);
	}, [cache, lessonId]);

	useEffect(() => {
		if (!lesson) {
			return;
		}

		setAll({
			startTime: lesson.startTime,
			endTime: lesson.endTime,
			classId: lesson.term.class.id,
			termId: lesson.term.id,
			spaceId: lesson.space?.id || '',
			status: lesson.status,
			lecturers: lesson.lecturers.map(lecturer => lecturer.user.id),
			color: lesson.color,
			students: lesson.students.map(student => student.student.user.id),
			attendances: lesson.students.map(({ student, status }) => ({
				studentId: student.user.id,
				status
			}))
		});
	}, [lesson]);

	const handleStatusClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleStatusClose = () => {
		setAnchorEl(null);
	};

	const handleStatusChange = (status: LessonStatus) => () => {
		setStatus(status);
		handleStatusClose();
	};

	const handleTermCreateClick = (classId: string) => {
		setClassTermCreateId(classId);
	};

	const handleClose = () => {
		resetLessonMutation();
		resetAttendancesMutation();
		navigate(-1);
	};

	const handleDelete = () => {
		if (!lesson) {
			return;
		}

		setDeletingLesson({
			id: lessonId,
			name: lesson.term.class.translation.name
		});
	};

	const handleDuplicate = () => {
		if (!lesson) {
			return;
		}

		const { setClassId, setTermId, setStartTime, setEndTime, setLecturers, setSpaceId } =
			useLessonCreateStore.getState();

		setClassId(lesson.term.class.id);
		setTermId(lesson.term.id);
		setStartTime(new Date(lesson.startTime));
		setEndTime(new Date(lesson.endTime));
		setLecturers(lesson.lecturers.map(lecturer => lecturer.user.id));
		setSpaceId(lesson.space?.id || '');
		setColor(lesson.color);

		setLessonCreateDialogOpen(true);

		navigate('/lessons/create', { state: { backgroundLocation: location }, replace: true });
	};

	const handleTabIndexChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabIndex(newValue);
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!lesson) {
			return;
		}

		let input: UpdateLessonInput = {};

		if (startTime !== lesson.startTime || endTime !== lesson.endTime) {
			input.startTime = startTime;
			input.endTime = endTime;
		}

		if (termId !== lesson.term.id) {
			input.termId = termId;
		}

		const inputSpaceId = !spaceId || spaceId === '0' ? null : spaceId;

		if (inputSpaceId ?? undefined !== lesson.space?.id) {
			input.spaceId = inputSpaceId;
		}

		if (
			[...lecturers].sort().toString() !==
			lesson.lecturers
				.map(lecturer => lecturer.user.id)
				.sort()
				.toString()
		) {
			input.lecturers = lecturers;
		}

		if (color !== lesson.color) {
			input.color = color;
		}

		if (status !== lesson.status) {
			input.status = status;
		}

		if (
			[...students].sort().toString() !==
			lesson.students
				.map(student => student.student.user.id)
				.sort()
				.toString()
		) {
			const createStudents: string[] = [];
			const deleteStudents: string[] = [];

			const lessonStudents = lesson.students.map(student => student.student.user.id);

			students.forEach(studentId => {
				if (!lessonStudents.includes(studentId)) {
					createStudents.push(studentId);
				}
			});

			lessonStudents.forEach(studentId => {
				if (!students.includes(studentId)) {
					deleteStudents.push(studentId);
				}
			});

			input.students = {};

			if (createStudents.length > 0) {
				input.students.createStudents = createStudents;
			}

			if (deleteStudents.length > 0) {
				input.students.deleteStudents = deleteStudents;
			}
		}

		try {
			if (
				attendances
					.map(attendance => `${attendance.studentId}:${attendance.status}`)
					.sort()
					.join(',') !==
				lesson.students
					.map(({ student, status }) => `${student.user.id}:${status}`)
					.sort()
					.join(',')
			) {
				await updateLessonAttendances({
					variables: {
						id: lessonId,
						input: {
							attendances
						}
					}
				});
			}

			if (Object.keys(input).length === 0) {
				toast.info('什么都没改到哦');
				return;
			}

			await updateLesson({
				variables: {
					id: lessonId,
					input
				}
			});

			setLesson(getLesson());
			toast.success('保存更改课次成功');
		} catch {}
	};

	return (
		<>
			<Dialog open={!!lesson} onClose={handleClose} maxWidth="xs" fullWidth>
				{lesson && (
					<Box
						component="form"
						onSubmit={handleSubmit}
						sx={{ overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}
					>
						<DialogTitle sx={{ display: 'flex', alignItems: 'center' }}>
							{lesson.term.class.translation.name}
							<Chip
								label={
									status === LessonStatus.Coming
										? '未完成'
										: status === LessonStatus.Conducted
										? '已完成'
										: '已取消'
								}
								variant="outlined"
								size="small"
								color={
									status === LessonStatus.Coming
										? 'default'
										: status === LessonStatus.Conducted
										? 'success'
										: 'error'
								}
								sx={{ ml: 1 }}
								onClick={handleStatusClick}
								component="button"
							/>
							<Menu open={open} onClose={handleStatusClose} anchorEl={anchorEl}>
								<MenuItem onClick={handleStatusChange(LessonStatus.Coming)}>
									未完成{status === LessonStatus.Coming && <Done fontSize="small" sx={{ ml: 1 }} />}
								</MenuItem>
								<MenuItem onClick={handleStatusChange(LessonStatus.Conducted)}>
									已完成
									{status === LessonStatus.Conducted && <Done fontSize="small" sx={{ ml: 1 }} />}
								</MenuItem>
								<MenuItem onClick={handleStatusChange(LessonStatus.Cancelled)}>
									已取消
									{status === LessonStatus.Cancelled && <Done fontSize="small" sx={{ ml: 1 }} />}
								</MenuItem>
							</Menu>
							<Tooltip title="复制">
								<IconButton sx={{ mr: 0, ml: 'auto' }} onClick={handleDuplicate}>
									<ContentCopy />
								</IconButton>
							</Tooltip>
						</DialogTitle>
						<DialogContent
							sx={{
								maxHeight: '100%',
								display: 'flex',
								flexDirection: 'column',
								pb: 0,
								px: 0
							}}
						>
							<TabContext value={tabIndex}>
								<Box sx={{ px: 4 }}>
									<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
										<TabList onChange={handleTabIndexChange} aria-label="班级资料类别" centered>
											<Tab label="基本" value="1" />
											<Tab label="学生" value="2" />
											<Tab label="点名" value="3" />
										</TabList>
									</Box>
								</Box>
								<SimpleBar style={{ maxHeight: '65vh' }}>
									<Box sx={{ px: 4 }}>
										<StyledTabPanel value="1" sx={{ pb: '20px' }}>
											<LessonForm
												loading={loading}
												error={error}
												startTime={startTime}
												endTime={endTime}
												classId={classId}
												termId={termId}
												spaceId={spaceId}
												lecturers={lecturers}
												setStartTime={setStartTime}
												setEndTime={setEndTime}
												setClassId={setClassId}
												setTermId={setTermId}
												setSpaceId={setSpaceId}
												setLecturers={setLecturers}
												onTermCreateClick={handleTermCreateClick}
											/>
											<ClassColorsRadio
												selectedColor={color}
												setSelectedColor={setColor}
												sx={{ mt: 2 }}
											/>
										</StyledTabPanel>
										<StyledTabPanel value="2">
											<ClassInheritenceStudentList
												classStudents={classStudents}
												selectedStudents={students}
												setSelectedStudents={setStudents}
											/>
										</StyledTabPanel>
										<StyledTabPanel value="3">
											<LessonAttendances
												students={lesson.students}
												attendances={attendances}
												setAttendances={setAttendances}
											/>
										</StyledTabPanel>
									</Box>
								</SimpleBar>
							</TabContext>
						</DialogContent>
						<DialogActions>
							<Tooltip title="删除">
								<IconButton onClick={handleDelete} color="error" sx={{ ml: 1, mr: 'auto' }}>
									<Delete />
								</IconButton>
							</Tooltip>
							<Button onClick={handleClose}>取消</Button>
							<Button type="submit">保存</Button>
						</DialogActions>
					</Box>
				)}
			</Dialog>
			<LessonDeleteDialog onDeleted={handleClose} />
		</>
	);
};

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
	paddingLeft: 0,
	paddingRight: 0,
	paddingTop: 12,
	paddingBottom: 12,
	// [theme.breakpoints.down('sm')]: {
	// 	paddingTop: 0,
	// 	paddingBottom: 0
	// },
	minHeight: '50vh',
	overflowY: 'auto'
}));

export const LessonUpdateRoute = () => {
	const { id } = useParams();
	const setLessonId = useUpdateAtom(lessonUpdateDialogSelectedIdAtom);

	useEffect(() => {
		setLessonId(id || '');

		return () => {
			setLessonId('');
		};
	}, [id]);

	return <></>;
};

export default LessonUpdateDialog;
