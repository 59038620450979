import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
	ApolloClient,
	ApolloLink,
	ApolloProvider,
	FieldFunctionOptions,
	from,
	HttpLink,
	InMemoryCache,
	NormalizedCacheObject,
	split
} from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import { useTokenStore } from '../stores/useTokenStore';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { IndexedDB } from '../stores/IndexedDB';
import { SafeReadonly } from '@apollo/client/cache/core/types/common';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { useUserPreferenceStore } from '../stores/useUserPreferenceStore';

interface ApolloClientProviderProps {}

const httpLink = new HttpLink({
	uri: import.meta.env.VITE_GRAPHQL_URI as string,
	credentials: 'include'
});

const wsLink = new GraphQLWsLink(
	createClient({
		url: import.meta.env.VITE_GRAPHQL_WS_URI as string
	})
);

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
	},
	wsLink,
	httpLink
);

const authMiddleware = new ApolloLink((operation, forward) => {
	// add the authorization to the headers
	const token = useTokenStore.getState().token;

	if (token) {
		operation.setContext({
			headers: {
				authorization: `Bearer ${token}`
			},
			mode: 'cors'
		});
	}

	return forward(operation);
});

const persistedQueriesLink = createPersistedQueryLink({
	sha256,
	useGETForHashedQueries: true
});

const removeDanglingReferences: (
	existing: SafeReadonly<any> | undefined,
	options: FieldFunctionOptions
) => any | undefined = (existingItems, { canRead }) => {
	const itemsRef = existingItems
		? existingItems.filter(canRead).map((s: { __ref: any }) => s.__ref)
		: undefined;
	if (!itemsRef) {
		return undefined;
	}
	return Array.from(new Set(itemsRef)).map(ref => ({
		__ref: ref
	}));
};

const cache = new InMemoryCache({
	typePolicies: {
		Query: {
			fields: {
				users: {
					keyArgs: ['status', 'roleFilter', ['roles', 'includeNoRole']],
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				students: {
					keyArgs: ['status'],
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				lecturers: {
					keyArgs: ['status'],
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				admins: {
					keyArgs: ['status'],
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				categories: {
					merge: false
				},
				courses: {
					merge: false
				},
				lessons: {
					keyArgs: ['filter', ['status', 'date']],
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				classArrangementWarnings: {
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				classArrangementErrors: {
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				invoices: {
					keyArgs: ['filter', ['status']],
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				receivables: {
					keyArgs: ['filter', ['status']],
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				payments: {
					keyArgs: ['filter', ['status']],
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				},
				refunds: {
					keyArgs: ['filter', ['status']],
					merge: false,
					read(...params) {
						return removeDanglingReferences(...params);
					}
				}
			}
		},
		User: {
			merge: true
		},
		Student: {
			keyFields: ['user', ['id']],
			merge: true
		},
		Lecturer: {
			keyFields: ['user', ['id']],
			merge: true
		},
		Admin: {
			keyFields: ['user', ['id']],
			merge: true
		},
		Category: {
			merge: true
		},
		CategoryTranslation: {
			merge: true
		},
		Course: {
			merge: true
			// fields: {
			// 	classes: {
			// 		merge: false
			// 	}
			// }
		},
		CourseTranslation: {
			merge: true
		},
		Class: {
			merge: true,
			fields: {
				students: {
					merge: false
				}
			}
		},
		ClassTranslation: {
			merge: true
		},
		Lesson: {
			merge: true
		},
		Location: {
			merge: true
		},
		Space: {
			merge: true
		},
		Invoice: {
			merge: true
		},
		InvoiceItem: {
			keyFields: ['receivable', ['id']],
			merge: true
		},
		Receivable: {
			merge: true
		},
		Payment: {
			merge: true
		},
		PaymentProof: {
			keyFields: ['key']
		},
		Receipt: {
			merge: true
		},
		Dashboards: {
			merge: true
		}
	}
});

const ApolloClientProvider: React.FC<PropsWithChildren<ApolloClientProviderProps>> = ({
	children
}) => {
	const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
	const fetchPolicy = useUserPreferenceStore(state => state.fetchPolicy);

	useEffect(() => {
		(async () => {
			try {
				await persistCache({
					//@ts-ignore
					cache,
					//@ts-ignore
					storage: new LocalStorageWrapper(IndexedDB),
					debounce: 0,
					//@ts-ignore
					serialize: false,
					maxSize: false
				});
				setClient(
					new ApolloClient({
						cache,
						link: from([authMiddleware, splitLink]),
						defaultOptions: {
							watchQuery: {
								fetchPolicy
							}
						}
					})
				);
			} catch (err) {
				console.log(err);
			}
		})();
	}, []);

	useEffect(() => {
		if (!client) {
			return;
		}
		if (!client.defaultOptions.watchQuery) {
			client.defaultOptions.watchQuery = {
				fetchPolicy
			};
		} else {
			client.defaultOptions.watchQuery.fetchPolicy = fetchPolicy;
		}
	}, [fetchPolicy]);

	if (!client) {
		return <></>;
	}

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
