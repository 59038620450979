import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Map: any;
  Time: any;
};

export type ActiveUserDashboardFilter = {
  basic: BasicDashboardFilter;
};

export type Admin = {
  __typename?: 'Admin';
  isMaster: Scalars['Boolean'];
  matricId: Scalars['String'];
  status: AdminStatus;
  user: User;
};

export enum AdminStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export enum AttendanceStatus {
  Absence = 'ABSENCE',
  Presence = 'PRESENCE'
}

export type BasicDashboardFilter = {
  every?: InputMaybe<Scalars['String']>;
  start: Scalars['String'];
  stop?: InputMaybe<Scalars['String']>;
};

export type Booking = Node & {
  __typename?: 'Booking';
  endTime: Scalars['DateTime'];
  finished: Scalars['Boolean'];
  id: Scalars['ID'];
  object: BookingObject;
  startTime: Scalars['DateTime'];
};

export type BookingObject = {
  id: Scalars['ID'];
};

export type BrowserLoginPayload = {
  __typename?: 'BrowserLoginPayload';
  accessToken: Scalars['String'];
  device: UserDevice;
  expiry: Scalars['Int'];
};

export type CalendarCompanyEvent = {
  __typename?: 'CalendarCompanyEvent';
  location?: Maybe<Location>;
  shouldPreventLessonCreation: Scalars['Boolean'];
};

export type CalendarCompanyEventInput = {
  locationId?: InputMaybe<Scalars['ID']>;
  shouldPreventLessonCreation: Scalars['Boolean'];
};

export type CalendarEvent = Node & {
  __typename?: 'CalendarEvent';
  color: Scalars['String'];
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  endTime: Scalars['DateTime'];
  id: Scalars['ID'];
  item: CalendarEventItem;
  startTime: Scalars['DateTime'];
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type CalendarEventItem = CalendarCompanyEvent | CalendarHolidayEvent | CalendarLessonEvent | CalendarUserEvent;

export type CalendarEventItemInput = {
  company?: InputMaybe<CalendarCompanyEventInput>;
  holiday?: InputMaybe<CalendarHolidayEventInput>;
  lesson?: InputMaybe<CalendarLessonEventInput>;
  user?: InputMaybe<CalendarUserEventInput>;
};

export enum CalendarEventType {
  Company = 'COMPANY',
  Holiday = 'HOLIDAY',
  Lesson = 'LESSON',
  User = 'USER'
}

export type CalendarEventsFilterInput = {
  time?: InputMaybe<TimeRangeFilter>;
  type?: InputMaybe<CalendarEventType>;
};

export type CalendarHolidayEvent = {
  __typename?: 'CalendarHolidayEvent';
  shouldPreventLessonCreation: Scalars['Boolean'];
};

export type CalendarHolidayEventInput = {
  shouldPreventLessonCreation: Scalars['Boolean'];
};

export type CalendarLessonEvent = {
  __typename?: 'CalendarLessonEvent';
  lesson: Lesson;
};

export type CalendarLessonEventInput = {
  lessonId: Scalars['ID'];
};

export type CalendarUserEvent = {
  __typename?: 'CalendarUserEvent';
  user: User;
};

export type CalendarUserEventInput = {
  userId: Scalars['ID'];
};

export type Category = Node & {
  __typename?: 'Category';
  courses: Array<Course>;
  id: Scalars['ID'];
  metadata: CategoryMetadata;
  translation: CategoryTranslation;
};


export type CategoryTranslationArgs = {
  language: Language;
};

export type CategoryMetadata = {
  __typename?: 'CategoryMetadata';
  classCount: Scalars['Int'];
  studentCount: Scalars['Int'];
};

export type CategoryTranslation = {
  __typename?: 'CategoryTranslation';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type Class = Node & {
  __typename?: 'Class';
  color: Scalars['String'];
  course: Course;
  hasOngoingTerm: Scalars['Boolean'];
  id: Scalars['ID'];
  lecturerCommission: Scalars['Float'];
  lecturers: Array<Lecturer>;
  rawId: Scalars['String'];
  space?: Maybe<Space>;
  studentFeePerLesson: Scalars['Float'];
  students: Array<Student>;
  termAutoGeneration?: Maybe<TermAutoGeneration>;
  terms: Array<Term>;
  translation: ClassTranslation;
};


export type ClassTranslationArgs = {
  language: Language;
};

export type ClassArrangementError = {
  __typename?: 'ClassArrangementError';
  id: Scalars['String'];
  message: Scalars['String'];
  reason: ClassArrangementErrorReason;
  term: Term;
};

export enum ClassArrangementErrorReason {
  OutOfDuration = 'OutOfDuration'
}

export type ClassArrangementWarning = {
  __typename?: 'ClassArrangementWarning';
  id: Scalars['String'];
  message: Scalars['String'];
  reason: ClassArrangementWarningReason;
  term: Term;
};

export enum ClassArrangementWarningReason {
  NotCompleted = 'NotCompleted'
}

export type ClassStudent = {
  __typename?: 'ClassStudent';
  class: Class;
  student: Student;
};

export type ClassTermAutoGenerationByMonthInput = {
  day: Day;
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type ClassTermAutoGenerationInput = {
  byMonth?: InputMaybe<ClassTermAutoGenerationByMonthInput>;
};

export type ClassTranslation = {
  __typename?: 'ClassTranslation';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type ClassTranslationInput = {
  description: Scalars['String'];
  language: Language;
  name: Scalars['String'];
};

export type CompletePaymentSessionInput = {
  id: Scalars['String'];
  receivables: Array<Scalars['String']>;
};

export type CompletePaymentSessionPayload = {
  __typename?: 'CompletePaymentSessionPayload';
  status: Scalars['String'];
};

export type Course = Node & {
  __typename?: 'Course';
  category: Category;
  classes: Array<Class>;
  id: Scalars['ID'];
  lecturers: Array<Lecturer>;
  translation: CourseTranslation;
};


export type CourseTranslationArgs = {
  language: Language;
};

export type CourseRegistration = Node & {
  __typename?: 'CourseRegistration';
  birthYear: Scalars['Int'];
  categories: Array<Category>;
  cname: Scalars['String'];
  completed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  ename: Scalars['String'];
  gender: Gender;
  guardianCname: Scalars['String'];
  guardianEname: Scalars['String'];
  ic: Scalars['String'];
  id: Scalars['ID'];
  location: Location;
  otherCategory: Scalars['String'];
  otherTime: Scalars['String'];
  phone: Scalars['String'];
  times: Array<CourseRegistrationTime>;
};

export type CourseRegistrationDashboardFilter = {
  basic: BasicDashboardFilter;
};

export type CourseRegistrationFilterInput = {
  completed?: InputMaybe<Scalars['Boolean']>;
};

export type CourseRegistrationTime = {
  __typename?: 'CourseRegistrationTime';
  day: Day;
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type CourseRegistrationTimeInput = {
  day: Day;
  time: TimeRange;
};

export type CourseTranslation = {
  __typename?: 'CourseTranslation';
  description: Scalars['String'];
  name: Scalars['String'];
};

export type CreateAdminInput = {
  isMaster: Scalars['Boolean'];
  matricId: Scalars['String'];
  status: AdminStatus;
  userId: Scalars['ID'];
};

export type CreateCalendarEventInput = {
  color: Scalars['String'];
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  item: CalendarEventItemInput;
  time: TimeRange;
  title: Scalars['String'];
};

export type CreateCategoryInput = {
  id?: InputMaybe<Scalars['String']>;
  translations: Array<CreateCategoryTranslationInput>;
};

export type CreateCategoryTranslationInput = {
  description: Scalars['String'];
  language: Language;
  name: Scalars['String'];
};

export type CreateClassInput = {
  color: Scalars['String'];
  courseId: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  lecturerCommission: Scalars['Float'];
  lecturers: Array<Scalars['ID']>;
  spaceId?: InputMaybe<Scalars['ID']>;
  studentFeePerLesson: Scalars['Float'];
  termAutoGeneration?: InputMaybe<ClassTermAutoGenerationInput>;
  translations: Array<ClassTranslationInput>;
};

export type CreateClassStudentInput = {
  classId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type CreateClassStudentsInput = {
  classId: Scalars['ID'];
  students: Array<Scalars['ID']>;
};

export type CreateCourseInput = {
  categoryId: Scalars['ID'];
  lecturers: Array<Scalars['String']>;
  translations: Array<CreateCourseTranslationInput>;
};

export type CreateCourseRegistrationInput = {
  birthYear: Scalars['Int'];
  categories: Array<Scalars['ID']>;
  cname: Scalars['String'];
  email: Scalars['String'];
  ename: Scalars['String'];
  gender: Gender;
  guardianCname: Scalars['String'];
  guardianEname: Scalars['String'];
  ic: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  locationId: Scalars['ID'];
  otherCategory: Scalars['String'];
  otherTime: Scalars['String'];
  phone: Scalars['String'];
  times: Array<CourseRegistrationTimeInput>;
};

export type CreateCourseTranslationInput = {
  description: Scalars['String'];
  language: Language;
  name: Scalars['String'];
};

export type CreateInvoiceInput = {
  createdAt: Scalars['DateTime'];
  discount: Scalars['Float'];
  items: Array<InvoiceItemInput>;
  status: InvoiceStatus;
  tax: Scalars['Float'];
  userId: Scalars['ID'];
};

export type CreateLecturerInput = {
  matricId: Scalars['String'];
  status: LecturerStatus;
  userId: Scalars['ID'];
};

export type CreateLessonInput = {
  color: Scalars['String'];
  endTime: Scalars['Time'];
  id?: InputMaybe<Scalars['ID']>;
  lecturers: Array<Scalars['ID']>;
  spaceId?: InputMaybe<Scalars['ID']>;
  startTime: Scalars['Time'];
  students: Array<Scalars['ID']>;
  termId: Scalars['ID'];
};

export type CreateLessonStudentsInput = {
  students: Array<Scalars['ID']>;
};

export type CreateLocationInput = {
  address: Scalars['String'];
  contacts: Array<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreatePaymentInput = {
  amount: Scalars['Float'];
  id?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
  method: PaymentMethod;
  methodDescription: Scalars['String'];
  proofs: Array<Scalars['String']>;
  status: PaymentStatus;
  userId: Scalars['ID'];
};

export type CreatePaymentProofInput = {
  id: Scalars['ID'];
  proof: PaymentProofInput;
};

export type CreatePaymentProofPayload = {
  __typename?: 'CreatePaymentProofPayload';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type CreatePaymentProofsInput = {
  id: Scalars['ID'];
  proofs: Array<PaymentProofInput>;
};

export type CreatePostInput = {
  authorId: Scalars['ID'];
  commentAllowed: Scalars['Boolean'];
  content: Scalars['String'];
  coverImage: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
  metaDescription: Scalars['String'];
  metaTitle: Scalars['String'];
  published: Scalars['Boolean'];
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type CreatePostTemporaryCoverImageInput = {
  contentLength: Scalars['Int'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type CreatePostTemporaryCoverImagePayload = {
  __typename?: 'CreatePostTemporaryCoverImagePayload';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type CreateReceivableInput = {
  debtor: Scalars['ID'];
  id?: InputMaybe<Scalars['ID']>;
  itemId?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  type: ReceivableType;
};

export type CreateRefundInput = {
  amount: Scalars['Float'];
  id?: InputMaybe<Scalars['String']>;
  invoiceId: Scalars['ID'];
  paymentId: Scalars['ID'];
  status: RefundStatus;
};

export type CreateSpaceInput = {
  id?: InputMaybe<Scalars['String']>;
  locationId: Scalars['ID'];
  translations: Array<CreateSpaceTranslationInput>;
};

export type CreateSpaceTranslationInput = {
  language: Language;
  name: Scalars['String'];
};

export type CreateStudentInput = {
  matricId: Scalars['String'];
  phones: Array<StudentPhoneInput>;
  status: StudentStatus;
  userId: Scalars['ID'];
};

export type CreateTermInput = {
  classId: Scalars['ID'];
  durationPerLesson: Scalars['Int'];
  id?: InputMaybe<Scalars['String']>;
  lecturerCommission: Scalars['Float'];
  lessonCount: Scalars['Int'];
  name: Scalars['String'];
  studentFeePerLesson: Scalars['Float'];
  students: Array<Scalars['String']>;
};

export type CreateUserAdminInput = {
  isMaster: Scalars['Boolean'];
  matricId: Scalars['String'];
  status: AdminStatus;
};

export type CreateUserInput = {
  address: Scalars['String'];
  admin?: InputMaybe<CreateUserAdminInput>;
  cname: Scalars['String'];
  email: Scalars['String'];
  ename: Scalars['String'];
  gender: Gender;
  ic: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lecturer?: InputMaybe<CreateUserLecturerInput>;
  password: Scalars['String'];
  phone: Scalars['String'];
  status: UserStatus;
  student?: InputMaybe<CreateUserStudentInput>;
};

export type CreateUserLecturerInput = {
  matricId: Scalars['String'];
  status: LecturerStatus;
};

export type CreateUserStudentInput = {
  matricId: Scalars['String'];
  phones: Array<StudentPhoneInput>;
  status: StudentStatus;
};

export type Dashboards = {
  __typename?: 'Dashboards';
  activeUser?: Maybe<Array<Scalars['Map']>>;
  courseRegistration?: Maybe<Array<Scalars['Map']>>;
  income?: Maybe<Array<Scalars['Map']>>;
  userCount?: Maybe<Array<Scalars['Map']>>;
};


export type DashboardsActiveUserArgs = {
  filter: ActiveUserDashboardFilter;
};


export type DashboardsCourseRegistrationArgs = {
  filter: CourseRegistrationDashboardFilter;
};


export type DashboardsIncomeArgs = {
  filter: IncomeDashboardFilter;
};


export type DashboardsUserCountArgs = {
  filter: UserCountDashboardFilter;
};

export enum Day {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY'
}

export type DeleteClassStudentPayload = {
  __typename?: 'DeleteClassStudentPayload';
  classId: Scalars['ID'];
  studentId: Scalars['ID'];
};

export type DeleteClassStudentsInput = {
  classId: Scalars['ID'];
  students: Array<Scalars['ID']>;
};

export type DeleteInvoiceInput = {
  id: Scalars['ID'];
};

export type DeleteLessonStudentsInput = {
  students: Array<Scalars['ID']>;
};

export type DeletePayload = {
  __typename?: 'DeletePayload';
  id: Scalars['ID'];
};

export type DeletePaymentInput = {
  id: Scalars['ID'];
};

export type DeleteTermInput = {
  id: Scalars['ID'];
};

export type File = {
  __typename?: 'File';
  bucket: Scalars['String'];
  key: Scalars['String'];
  lastModified: Scalars['DateTime'];
  name: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE'
}

export type IncomeDashboardFilter = {
  basic: BasicDashboardFilter;
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  discount: Scalars['Float'];
  id: Scalars['ID'];
  items: Array<InvoiceItem>;
  payment?: Maybe<Payment>;
  pdf?: Maybe<File>;
  status: InvoiceStatus;
  tax: Scalars['Float'];
  user: User;
};

export type InvoiceItem = {
  __typename?: 'InvoiceItem';
  description: Scalars['String'];
  receivable: Receivable;
};

export type InvoiceItemInput = {
  description: Scalars['String'];
  id: Scalars['ID'];
};

export enum InvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Void = 'VOID'
}

export type InvoicesFilterInput = {
  status?: InputMaybe<InvoiceStatus>;
  users?: InputMaybe<Array<Scalars['ID']>>;
};

export enum Language {
  En = 'EN',
  Zh = 'ZH'
}

export type Lecturer = {
  __typename?: 'Lecturer';
  classes: Array<Class>;
  courses: Array<Course>;
  matricId: Scalars['String'];
  status: LecturerStatus;
  user: User;
};

export enum LecturerStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type Lesson = Node & {
  __typename?: 'Lesson';
  booking?: Maybe<Booking>;
  color: Scalars['String'];
  endTime: Scalars['Time'];
  id: Scalars['ID'];
  lecturers: Array<Lecturer>;
  space?: Maybe<Space>;
  startTime: Scalars['Time'];
  status: LessonStatus;
  students: Array<LessonStudent>;
  term: Term;
};

export type LessonFilterDate = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type LessonFilterInput = {
  date?: InputMaybe<LessonFilterDate>;
  status?: InputMaybe<LessonFilterStatus>;
};

export enum LessonFilterStatus {
  All = 'ALL',
  Cancelled = 'CANCELLED',
  Coming = 'COMING',
  Conducted = 'CONDUCTED'
}

export enum LessonStatus {
  Cancelled = 'CANCELLED',
  Coming = 'COMING',
  Conducted = 'CONDUCTED'
}

export type LessonStudent = {
  __typename?: 'LessonStudent';
  status: AttendanceStatus;
  student: Student;
};

export type Location = Node & {
  __typename?: 'Location';
  address: Scalars['String'];
  contacts: Array<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  spaces: Array<Space>;
};

export type LoginInput = {
  id: Scalars['String'];
  maxAge?: InputMaybe<Scalars['Int']>;
  password: Scalars['String'];
};

export type LoginPayload = {
  __typename?: 'LoginPayload';
  accessToken: Scalars['String'];
  device: UserDevice;
  expiry: Scalars['Int'];
  refreshToken: Scalars['String'];
};

/** LogoutInput requires one of the fields to logout */
export type LogoutInput = {
  deviceId?: InputMaybe<Scalars['ID']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  id: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addClassLecturers?: Maybe<Class>;
  addCourseLecturers?: Maybe<Course>;
  browserLogin?: Maybe<BrowserLoginPayload>;
  browserLogout?: Maybe<LogoutPayload>;
  browserRefreshToken?: Maybe<RefreshTokenPayload>;
  completePaymentSession?: Maybe<CompletePaymentSessionPayload>;
  createAdmin?: Maybe<Admin>;
  createCalendar?: Maybe<CalendarEvent>;
  createCall?: Maybe<Scalars['Boolean']>;
  createCategory?: Maybe<Category>;
  createClass?: Maybe<Class>;
  createClassStudents?: Maybe<Class>;
  createCourse?: Maybe<Course>;
  createCourseRegistration?: Maybe<CourseRegistration>;
  createInvoice?: Maybe<Invoice>;
  createLecturer?: Maybe<Lecturer>;
  createLesson?: Maybe<Lesson>;
  createLessonStudents?: Maybe<Lesson>;
  createLessons?: Maybe<Array<Lesson>>;
  createLocation?: Maybe<Location>;
  createPayment?: Maybe<Payment>;
  createPaymentProof?: Maybe<CreatePaymentProofPayload>;
  createPaymentProofs?: Maybe<Array<CreatePaymentProofPayload>>;
  createPost?: Maybe<Post>;
  createPostTemporaryCoverImage?: Maybe<CreatePostTemporaryCoverImagePayload>;
  createReceivable?: Maybe<Receivable>;
  createReceivables?: Maybe<Array<Receivable>>;
  createRefund?: Maybe<Refund>;
  createSpace?: Maybe<Space>;
  createStudent?: Maybe<Student>;
  createTerm?: Maybe<Term>;
  createUser?: Maybe<User>;
  deleteAdmin?: Maybe<DeletePayload>;
  deleteCalendar?: Maybe<DeletePayload>;
  deleteCategory?: Maybe<DeletePayload>;
  deleteClass?: Maybe<DeletePayload>;
  deleteClassStudents?: Maybe<Class>;
  deleteCourse?: Maybe<DeletePayload>;
  deleteCourseRegistration?: Maybe<DeletePayload>;
  deleteInvoice?: Maybe<DeletePayload>;
  deleteLecturer?: Maybe<DeletePayload>;
  deleteLesson?: Maybe<DeletePayload>;
  deleteLessonStudents?: Maybe<Lesson>;
  deleteLocation?: Maybe<DeletePayload>;
  deletePayment?: Maybe<DeletePayload>;
  deletePost?: Maybe<DeletePayload>;
  deleteReceivable?: Maybe<DeletePayload>;
  deleteRefund?: Maybe<DeletePayload>;
  deleteSpace?: Maybe<DeletePayload>;
  deleteStudent?: Maybe<DeletePayload>;
  deleteTerm?: Maybe<DeletePayload>;
  deleteUser?: Maybe<DeletePayload>;
  login?: Maybe<LoginPayload>;
  logout?: Maybe<LogoutPayload>;
  refreshToken?: Maybe<RefreshTokenPayload>;
  removeClassLecturers?: Maybe<Class>;
  removeCourseLecturers?: Maybe<Course>;
  updateAdmin?: Maybe<Admin>;
  updateCalendar?: Maybe<CalendarEvent>;
  updateCategories?: Maybe<Array<Category>>;
  updateCategory?: Maybe<Category>;
  updateClass?: Maybe<Class>;
  updateCourse?: Maybe<Course>;
  updateCourseRegistration?: Maybe<CourseRegistration>;
  updateInvoice?: Maybe<Invoice>;
  updateLecturer?: Maybe<Lecturer>;
  updateLesson?: Maybe<Lesson>;
  updateLessonAttendances?: Maybe<Lesson>;
  updateLocation?: Maybe<Location>;
  updateMyPassword?: Maybe<UpdateMyPasswordPayload>;
  updateMyProfile?: Maybe<User>;
  updatePayment?: Maybe<Payment>;
  updatePost?: Maybe<Post>;
  updateReceivable?: Maybe<Receivable>;
  updateRefund?: Maybe<Refund>;
  updateSpace?: Maybe<Space>;
  updateStudent?: Maybe<Student>;
  updateTerm?: Maybe<Term>;
  updateUser?: Maybe<User>;
};


export type MutationAddClassLecturersArgs = {
  id: Scalars['ID'];
  lecturers?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationAddCourseLecturersArgs = {
  id: Scalars['ID'];
  lecturers: Array<Scalars['ID']>;
};


export type MutationBrowserLoginArgs = {
  input: LoginInput;
};


export type MutationCompletePaymentSessionArgs = {
  input: CompletePaymentSessionInput;
};


export type MutationCreateAdminArgs = {
  input: CreateAdminInput;
};


export type MutationCreateCalendarArgs = {
  input: CreateCalendarEventInput;
};


export type MutationCreateCallArgs = {
  roomId: Scalars['String'];
  users: Array<Scalars['String']>;
};


export type MutationCreateCategoryArgs = {
  input: CreateCategoryInput;
};


export type MutationCreateClassArgs = {
  input: CreateClassInput;
};


export type MutationCreateClassStudentsArgs = {
  input: CreateClassStudentsInput;
};


export type MutationCreateCourseArgs = {
  input: CreateCourseInput;
};


export type MutationCreateCourseRegistrationArgs = {
  input: CreateCourseRegistrationInput;
};


export type MutationCreateInvoiceArgs = {
  input: CreateInvoiceInput;
};


export type MutationCreateLecturerArgs = {
  input: CreateLecturerInput;
};


export type MutationCreateLessonArgs = {
  input: CreateLessonInput;
};


export type MutationCreateLessonStudentsArgs = {
  id: Scalars['ID'];
  input: CreateLessonStudentsInput;
};


export type MutationCreateLessonsArgs = {
  input: Array<CreateLessonInput>;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreatePaymentArgs = {
  input: CreatePaymentInput;
};


export type MutationCreatePaymentProofArgs = {
  input: CreatePaymentProofInput;
};


export type MutationCreatePaymentProofsArgs = {
  input: CreatePaymentProofsInput;
};


export type MutationCreatePostArgs = {
  input: CreatePostInput;
};


export type MutationCreatePostTemporaryCoverImageArgs = {
  input: CreatePostTemporaryCoverImageInput;
};


export type MutationCreateReceivableArgs = {
  input: CreateReceivableInput;
};


export type MutationCreateReceivablesArgs = {
  input: Array<CreateReceivableInput>;
};


export type MutationCreateRefundArgs = {
  input: CreateRefundInput;
};


export type MutationCreateSpaceArgs = {
  input: CreateSpaceInput;
};


export type MutationCreateStudentArgs = {
  input: CreateStudentInput;
};


export type MutationCreateTermArgs = {
  input: CreateTermInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteAdminArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteCalendarArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteClassArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteClassStudentsArgs = {
  input: DeleteClassStudentsInput;
};


export type MutationDeleteCourseArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteCourseRegistrationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteInvoiceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLecturerArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteLessonArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLessonStudentsArgs = {
  id: Scalars['ID'];
  input: DeleteLessonStudentsInput;
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePaymentArgs = {
  id: Scalars['ID'];
};


export type MutationDeletePostArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteReceivableArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRefundArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSpaceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteStudentArgs = {
  userId: Scalars['ID'];
};


export type MutationDeleteTermArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLogoutArgs = {
  input: LogoutInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationRemoveClassLecturersArgs = {
  id: Scalars['ID'];
  lecturers?: InputMaybe<Array<Scalars['ID']>>;
};


export type MutationRemoveCourseLecturersArgs = {
  id: Scalars['ID'];
  lecturers: Array<Scalars['ID']>;
};


export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
  userId: Scalars['ID'];
};


export type MutationUpdateCalendarArgs = {
  id: Scalars['ID'];
  input: UpdateCalendarEventInput;
};


export type MutationUpdateCategoriesArgs = {
  input: UpdateCategoriesInput;
};


export type MutationUpdateCategoryArgs = {
  id: Scalars['ID'];
  input: UpdateCategoryInput;
};


export type MutationUpdateClassArgs = {
  id: Scalars['ID'];
  input: UpdateClassInput;
};


export type MutationUpdateCourseArgs = {
  id: Scalars['ID'];
  input: UpdateCourseInput;
};


export type MutationUpdateCourseRegistrationArgs = {
  id: Scalars['ID'];
  input: UpdateCourseRegistrationInput;
};


export type MutationUpdateInvoiceArgs = {
  id: Scalars['ID'];
  input: UpdateInvoiceInput;
};


export type MutationUpdateLecturerArgs = {
  input: UpdateLecturerInput;
  userId: Scalars['ID'];
};


export type MutationUpdateLessonArgs = {
  id: Scalars['ID'];
  input: UpdateLessonInput;
};


export type MutationUpdateLessonAttendancesArgs = {
  id: Scalars['ID'];
  input: UpdateLessonAttendancesInput;
};


export type MutationUpdateLocationArgs = {
  id: Scalars['ID'];
  input: UpdateLocationInput;
};


export type MutationUpdateMyPasswordArgs = {
  input: UpdateMyPasswordInput;
};


export type MutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput;
};


export type MutationUpdatePaymentArgs = {
  id: Scalars['ID'];
  input: UpdatePaymentInput;
};


export type MutationUpdatePostArgs = {
  id: Scalars['ID'];
  input: UpdatePostInput;
};


export type MutationUpdateReceivableArgs = {
  id: Scalars['ID'];
  input: UpdateReceivableInput;
};


export type MutationUpdateRefundArgs = {
  id: Scalars['ID'];
  input: UpdateRefundInput;
};


export type MutationUpdateSpaceArgs = {
  id: Scalars['ID'];
  input: UpdateSpaceInput;
};


export type MutationUpdateStudentArgs = {
  input: UpdateStudentInput;
  userId: Scalars['ID'];
};


export type MutationUpdateTermArgs = {
  id: Scalars['ID'];
  input: UpdateTermInput;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};

export type Node = {
  id: Scalars['ID'];
};

export type Payment = Node & {
  __typename?: 'Payment';
  amount: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  details: Scalars['Map'];
  error?: Maybe<PaymentError>;
  id: Scalars['ID'];
  invoice: Invoice;
  method: PaymentMethod;
  methodDescription: Scalars['String'];
  proofs: Array<File>;
  receipt?: Maybe<Receipt>;
  status: PaymentStatus;
  user: User;
};

export type PaymentError = {
  __typename?: 'PaymentError';
  code: Scalars['String'];
  declineCode: Scalars['String'];
  message: Scalars['String'];
};

export enum PaymentMethod {
  AliPay = 'ALI_PAY',
  ApplePay = 'APPLE_PAY',
  Card = 'CARD',
  Cash = 'CASH',
  Fpx = 'FPX',
  GooglePay = 'GOOGLE_PAY',
  GrabPay = 'GRAB_PAY',
  OnlineTransfer = 'ONLINE_TRANSFER',
  Tng = 'TNG'
}

export type PaymentProofInput = {
  contentLength: Scalars['Int'];
  name: Scalars['String'];
};

export type PaymentSession = {
  __typename?: 'PaymentSession';
  clientSecret: Scalars['String'];
  id: Scalars['String'];
  paymentId: Scalars['ID'];
};

export enum PaymentStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED'
}

export type PaymentsFilterInput = {
  status?: InputMaybe<PaymentStatus>;
};

export type Post = Node & {
  __typename?: 'Post';
  author: User;
  commentAllowed: Scalars['Boolean'];
  content: Scalars['String'];
  coverImage: File;
  createdAt: Scalars['DateTime'];
  description: Scalars['String'];
  id: Scalars['ID'];
  metaDescription: Scalars['String'];
  metaTitle: Scalars['String'];
  published: Scalars['Boolean'];
  tags: Array<Scalars['String']>;
  title: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  admins: Array<Admin>;
  bookings: Array<Booking>;
  calendarEvents: Array<CalendarEvent>;
  categories: Array<Category>;
  classArrangementErrors: Array<ClassArrangementError>;
  classArrangementWarnings: Array<ClassArrangementWarning>;
  classes: Array<Class>;
  courseRegistrations: Array<CourseRegistration>;
  courses: Array<Course>;
  currentUser: User;
  dashboards: Dashboards;
  invoices: Array<Invoice>;
  lecturers: Array<Lecturer>;
  lessons: Array<Lesson>;
  locations: Array<Location>;
  node?: Maybe<Node>;
  payments: Array<Payment>;
  posts: Array<Post>;
  receivables: Array<Receivable>;
  refunds: Array<Refund>;
  spaces: Array<Space>;
  students: Array<Student>;
  terms: Array<Term>;
  users: Array<User>;
};


export type QueryAdminsArgs = {
  status?: InputMaybe<AdminStatus>;
};


export type QueryCalendarEventsArgs = {
  filter?: InputMaybe<CalendarEventsFilterInput>;
};


export type QueryCourseRegistrationsArgs = {
  filter?: InputMaybe<CourseRegistrationFilterInput>;
};


export type QueryInvoicesArgs = {
  filter?: InputMaybe<InvoicesFilterInput>;
};


export type QueryLecturersArgs = {
  status?: InputMaybe<LecturerStatus>;
};


export type QueryLessonsArgs = {
  filter?: InputMaybe<LessonFilterInput>;
};


export type QueryNodeArgs = {
  id: Scalars['ID'];
};


export type QueryPaymentsArgs = {
  filter?: InputMaybe<PaymentsFilterInput>;
};


export type QueryReceivablesArgs = {
  filter?: InputMaybe<ReceivablesFilterInput>;
};


export type QueryRefundsArgs = {
  filter?: InputMaybe<RefundsFilterInput>;
};


export type QuerySpacesArgs = {
  filter?: InputMaybe<SpacesFilterInput>;
};


export type QueryStudentsArgs = {
  status?: InputMaybe<StudentStatus>;
};


export type QueryUsersArgs = {
  roleFilter?: InputMaybe<UserRoleFilterInput>;
  status?: InputMaybe<UserStatus>;
};

export type Receipt = Node & {
  __typename?: 'Receipt';
  id: Scalars['ID'];
  payment: Payment;
  pdf?: Maybe<File>;
};

export type Receivable = Node & {
  __typename?: 'Receivable';
  debtor: User;
  id: Scalars['ID'];
  item?: Maybe<ReceivableItem>;
  name: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Int'];
  status: ReceivableStatus;
  type: ReceivableType;
};

export type ReceivableItem = {
  id: Scalars['ID'];
};

export enum ReceivableStatus {
  Draft = 'DRAFT',
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export enum ReceivableType {
  Lesson = 'LESSON'
}

export type ReceivablesFilterInput = {
  status?: InputMaybe<ReceivableStatus>;
};

export type RefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  accessToken: Scalars['String'];
  expiry: Scalars['Int'];
};

export type Refund = Node & {
  __typename?: 'Refund';
  amount: Scalars['Float'];
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  invoice: Invoice;
  payment: Payment;
  refundedAt?: Maybe<Scalars['Time']>;
  status: RefundStatus;
};

export enum RefundStatus {
  Complete = 'COMPLETE',
  Draft = 'DRAFT'
}

export type RefundsFilterInput = {
  status?: InputMaybe<RefundStatus>;
};

export type Space = BookingObject & Node & {
  __typename?: 'Space';
  available: Scalars['Boolean'];
  id: Scalars['ID'];
  location: Location;
  translation: SpaceTranslation;
};


export type SpaceTranslationArgs = {
  language: Language;
};

export type SpaceTranslation = {
  __typename?: 'SpaceTranslation';
  name: Scalars['String'];
};

export type SpacesFilterInput = {
  locationId?: InputMaybe<Scalars['ID']>;
};

export type StartPaymentSessionInput = {
  receivables: Array<Scalars['String']>;
};

export type Student = {
  __typename?: 'Student';
  classes: Array<Class>;
  lessons: Array<Lesson>;
  matricId: Scalars['String'];
  /** A list of extra phones that different to user's phone */
  phones: Array<StudentPhone>;
  status: StudentStatus;
  user: User;
};


export type StudentLessonsArgs = {
  filter?: InputMaybe<LessonFilterInput>;
};

export type StudentPhone = {
  __typename?: 'StudentPhone';
  name: Scalars['String'];
  number: Scalars['String'];
};

export type StudentPhoneInput = {
  name: Scalars['String'];
  number: Scalars['String'];
};

export enum StudentStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Temporary = 'TEMPORARY'
}

export type Subscription = {
  __typename?: 'Subscription';
  classArrangementErrorCreated: ClassArrangementError;
  classArrangementErrorDeleted: DeletePayload;
  classArrangementErrorUpdated: ClassArrangementError;
  classArrangementWarningCreated: ClassArrangementWarning;
  classArrangementWarningDeleted: DeletePayload;
  classArrangementWarningUpdated: ClassArrangementWarning;
  lessonCreated: Lesson;
  lessonDeleted: DeletePayload;
  lessonUpdated: Lesson;
  startPaymentSession: PaymentSession;
};


export type SubscriptionLessonCreatedArgs = {
  filter?: InputMaybe<LessonFilterInput>;
};


export type SubscriptionStartPaymentSessionArgs = {
  input: StartPaymentSessionInput;
};

export type Term = Node & ReceivableItem & {
  __typename?: 'Term';
  class: Class;
  createdAt: Scalars['DateTime'];
  durationPerLesson: Scalars['Int'];
  id: Scalars['ID'];
  lecturerCommission: Scalars['Float'];
  lessonCount: Scalars['Int'];
  lessons: Array<Lesson>;
  name: Scalars['String'];
  studentFeePerLesson: Scalars['Float'];
  students: Array<Student>;
};


export type TermLessonsArgs = {
  filter?: InputMaybe<LessonFilterInput>;
};

export type TermAutoGeneration = {
  classId: Scalars['ID'];
};

export type TermAutoGenerationByMonth = TermAutoGeneration & {
  __typename?: 'TermAutoGenerationByMonth';
  classId: Scalars['ID'];
  day: Day;
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type TimeRange = {
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
};

export type TimeRangeFilter = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};

export type UpdateAdminInput = {
  isMaster?: InputMaybe<Scalars['Boolean']>;
  matricId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AdminStatus>;
};

export type UpdateCalendarEventInput = {
  color?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  item?: InputMaybe<CalendarEventItemInput>;
  time?: InputMaybe<TimeRange>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateCategoriesInput = {
  categories: Array<UpdateCategoryWithIdInput>;
};

export type UpdateCategoryInput = {
  order?: InputMaybe<Scalars['Int']>;
  translations?: InputMaybe<Array<UpdateCategoryTranslationInput>>;
};

export type UpdateCategoryTranslationInput = {
  description?: InputMaybe<Scalars['String']>;
  language: Language;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateCategoryWithIdInput = {
  id: Scalars['String'];
  order?: InputMaybe<Scalars['Int']>;
  translations?: InputMaybe<Array<UpdateCategoryTranslationInput>>;
};

export type UpdateClassInput = {
  color?: InputMaybe<Scalars['String']>;
  lecturerCommission?: InputMaybe<Scalars['Float']>;
  lecturers?: InputMaybe<Array<Scalars['ID']>>;
  spaceId?: InputMaybe<Scalars['ID']>;
  studentFeePerLesson?: InputMaybe<Scalars['Float']>;
  termAutoGeneration?: InputMaybe<UpdateClassTermAutoGenerationInput>;
  translations?: InputMaybe<Array<ClassTranslationInput>>;
};

export type UpdateClassTermAutoGenerationInput = {
  byMonth?: InputMaybe<ClassTermAutoGenerationByMonthInput>;
};

export type UpdateCourseInput = {
  lecturers?: InputMaybe<Array<Scalars['ID']>>;
  translations?: InputMaybe<Array<UpdateCourseTranslationInput>>;
};

export type UpdateCourseRegistrationInput = {
  birthYear?: InputMaybe<Scalars['Int']>;
  categories?: InputMaybe<Array<Scalars['ID']>>;
  cname?: InputMaybe<Scalars['String']>;
  completed?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  ename?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  guardianCname?: InputMaybe<Scalars['String']>;
  guardianEname?: InputMaybe<Scalars['String']>;
  ic?: InputMaybe<Scalars['String']>;
  locationId?: InputMaybe<Scalars['ID']>;
  otherCategory?: InputMaybe<Scalars['String']>;
  otherTime?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  times?: InputMaybe<Array<CourseRegistrationTimeInput>>;
};

export type UpdateCourseTranslationInput = {
  description?: InputMaybe<Scalars['String']>;
  language: Language;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateInvoiceInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  discount?: InputMaybe<Scalars['Float']>;
  items?: InputMaybe<Array<InvoiceItemInput>>;
  status?: InputMaybe<InvoiceStatus>;
  tax?: InputMaybe<Scalars['Float']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UpdateLecturerInput = {
  matricId?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<LecturerStatus>;
};

export type UpdateLessonAttendanceInput = {
  status: AttendanceStatus;
  studentId: Scalars['ID'];
};

export type UpdateLessonAttendancesInput = {
  attendances: Array<UpdateLessonAttendanceInput>;
};

export type UpdateLessonInput = {
  color?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['Time']>;
  lecturers?: InputMaybe<Array<Scalars['ID']>>;
  spaceId?: InputMaybe<Scalars['ID']>;
  startTime?: InputMaybe<Scalars['Time']>;
  status?: InputMaybe<LessonStatus>;
  students?: InputMaybe<UpdateLessonStudentsInput>;
  termId?: InputMaybe<Scalars['ID']>;
};

export type UpdateLessonStudentsInput = {
  createStudents?: InputMaybe<Array<Scalars['ID']>>;
  deleteStudents?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateLocationInput = {
  address?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateMyPasswordInput = {
  newPassword: Scalars['String'];
  password: Scalars['String'];
};

export type UpdateMyPasswordPayload = {
  __typename?: 'UpdateMyPasswordPayload';
  id: Scalars['ID'];
};

export type UpdateMyProfileInput = {
  address?: InputMaybe<Scalars['String']>;
  cname?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  ename?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  ic?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdatePaymentInput = {
  amount?: InputMaybe<Scalars['Float']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
  method?: InputMaybe<PaymentMethod>;
  methodDescription?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PaymentStatus>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UpdatePostInput = {
  authorId?: InputMaybe<Scalars['ID']>;
  commentAllowed?: InputMaybe<Scalars['Boolean']>;
  content?: InputMaybe<Scalars['String']>;
  coverImage?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  metaDescription?: InputMaybe<Scalars['String']>;
  metaTitle?: InputMaybe<Scalars['String']>;
  published?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<Scalars['String']>>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpdateReceivableInput = {
  debtor?: InputMaybe<Scalars['ID']>;
  itemId?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['Float']>;
  quantity?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ReceivableStatus>;
  type?: InputMaybe<ReceivableType>;
};

export type UpdateRefundInput = {
  amount?: InputMaybe<Scalars['Float']>;
  invoiceId?: InputMaybe<Scalars['ID']>;
  paymentId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<RefundStatus>;
};

export type UpdateSpaceInput = {
  available?: InputMaybe<Scalars['Boolean']>;
  locationId?: InputMaybe<Scalars['ID']>;
  translations?: InputMaybe<Array<UpdateSpaceTranslationInput>>;
};

export type UpdateSpaceTranslationInput = {
  language: Language;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateStudentInput = {
  matricId?: InputMaybe<Scalars['String']>;
  phones?: InputMaybe<Array<StudentPhoneInput>>;
  status?: InputMaybe<StudentStatus>;
};

export type UpdateTermInput = {
  durationPerLesson?: InputMaybe<Scalars['Int']>;
  lecturerCommission?: InputMaybe<Scalars['Float']>;
  lessonCount?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  studentFeePerLesson?: InputMaybe<Scalars['Float']>;
  students?: InputMaybe<UpdateTermStudentsInput>;
};

export type UpdateTermStudentsInput = {
  createStudents?: InputMaybe<Array<Scalars['ID']>>;
  deleteStudents?: InputMaybe<Array<Scalars['ID']>>;
};

export type UpdateUserInput = {
  address?: InputMaybe<Scalars['String']>;
  admin?: InputMaybe<UpdateAdminInput>;
  cname?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  ename?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  ic?: InputMaybe<Scalars['String']>;
  lecturer?: InputMaybe<UpdateLecturerInput>;
  password?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<UserStatus>;
  student?: InputMaybe<UpdateStudentInput>;
};

export type User = Node & {
  __typename?: 'User';
  address: Scalars['String'];
  admin?: Maybe<Admin>;
  cname: Scalars['String'];
  devices?: Maybe<Array<UserDevice>>;
  email: Scalars['String'];
  ename: Scalars['String'];
  gender: Gender;
  ic: Scalars['String'];
  id: Scalars['ID'];
  invoices: Array<Invoice>;
  lecturer?: Maybe<Lecturer>;
  payables?: Maybe<Array<Receivable>>;
  payments: Array<Payment>;
  phone: Scalars['String'];
  registerDate: Scalars['DateTime'];
  status: UserStatus;
  student?: Maybe<Student>;
};


export type UserPayablesArgs = {
  filter?: InputMaybe<UserPayablesFilter>;
};

export type UserCountDashboardFilter = {
  basic: BasicDashboardFilter;
  role?: InputMaybe<UserCountDashboardUserRole>;
  status?: InputMaybe<Scalars['String']>;
};

export enum UserCountDashboardUserRole {
  Admin = 'ADMIN',
  Basic = 'BASIC',
  Guardian = 'GUARDIAN',
  Lecturer = 'LECTURER',
  Student = 'STUDENT'
}

export type UserDevice = Node & {
  __typename?: 'UserDevice';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  ipAddress: Scalars['String'];
  lastLoginAt: Scalars['Time'];
  name: Scalars['String'];
  user: User;
};

export type UserPayablesFilter = {
  type?: InputMaybe<ReceivableType>;
};

export enum UserRole {
  Admin = 'ADMIN',
  Lecturer = 'LECTURER',
  Master = 'MASTER',
  Student = 'STUDENT'
}

export type UserRoleFilterInput = {
  includeNoRole?: InputMaybe<Scalars['Boolean']>;
  roles?: InputMaybe<Array<UserRole>>;
};

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type LessonFieldsFragment = { __typename?: 'Lesson', id: string, startTime: any, endTime: any, status: LessonStatus, color: string };

export type GetLessonsQueryVariables = Exact<{
  filter?: InputMaybe<LessonFilterInput>;
  language: Language;
}>;


export type GetLessonsQuery = { __typename?: 'Query', lessons: Array<{ __typename?: 'Lesson', id: string, startTime: any, endTime: any, status: LessonStatus, color: string, term: { __typename?: 'Term', id: string, class: { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string } } }, space?: { __typename?: 'Space', id: string, translation: { __typename?: 'SpaceTranslation', name: string } } | null, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string } }>, students: Array<{ __typename?: 'LessonStudent', status: AttendanceStatus, student: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } } }> }> };

export type SubscribeLessonCreatedSubscriptionVariables = Exact<{
  filter?: InputMaybe<LessonFilterInput>;
  language: Language;
}>;


export type SubscribeLessonCreatedSubscription = { __typename?: 'Subscription', lessonCreated: { __typename?: 'Lesson', id: string, startTime: any, endTime: any, status: LessonStatus, color: string, term: { __typename?: 'Term', id: string, class: { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string } } }, space?: { __typename?: 'Space', id: string, translation: { __typename?: 'SpaceTranslation', name: string } } | null, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string } }>, students: Array<{ __typename?: 'LessonStudent', status: AttendanceStatus, student: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } } }> } };

export type SubscribeLessonUpdatedSubscriptionVariables = Exact<{
  language: Language;
}>;


export type SubscribeLessonUpdatedSubscription = { __typename?: 'Subscription', lessonUpdated: { __typename?: 'Lesson', id: string, startTime: any, endTime: any, status: LessonStatus, color: string, term: { __typename?: 'Term', id: string, class: { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string } } }, space?: { __typename?: 'Space', id: string, translation: { __typename?: 'SpaceTranslation', name: string } } | null, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string } }>, students: Array<{ __typename?: 'LessonStudent', status: AttendanceStatus, student: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } } }> } };

export type SubscribeLessonDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeLessonDeletedSubscription = { __typename?: 'Subscription', lessonDeleted: { __typename?: 'DeletePayload', id: string } };

export type GetClassesQueryVariables = Exact<{
  language: Language;
}>;


export type GetClassesQuery = { __typename?: 'Query', classes: Array<{ __typename?: 'Class', id: string, studentFeePerLesson: number, lecturerCommission: number, color: string, hasOngoingTerm: boolean, course: { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string } }, translation: { __typename?: 'ClassTranslation', name: string, description: string }, termAutoGeneration?: { __typename?: 'TermAutoGenerationByMonth', day: Day, startTime: any, endTime: any } | null }> };

export type CreateLessonMutationVariables = Exact<{
  input: CreateLessonInput;
}>;


export type CreateLessonMutation = { __typename?: 'Mutation', createLesson?: { __typename?: 'Lesson', id: string } | null };

export type CreateLessonsMutationVariables = Exact<{
  input: Array<CreateLessonInput> | CreateLessonInput;
}>;


export type CreateLessonsMutation = { __typename?: 'Mutation', createLessons?: Array<{ __typename?: 'Lesson', id: string }> | null };

export type UpdateLessonMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateLessonInput;
}>;


export type UpdateLessonMutation = { __typename?: 'Mutation', updateLesson?: { __typename?: 'Lesson', id: string } | null };

export type DeleteLessonMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLessonMutation = { __typename?: 'Mutation', deleteLesson?: { __typename?: 'DeletePayload', id: string } | null };

export type GetClassTermsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClassTermsQuery = { __typename?: 'Query', node?: { __typename?: 'Booking', id: string } | { __typename?: 'CalendarEvent', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'Class', id: string, terms: Array<{ __typename?: 'Term', id: string, name: string, durationPerLesson: number, studentFeePerLesson: number, lecturerCommission: number, lessonCount: number, createdAt: any }> } | { __typename?: 'Course', id: string } | { __typename?: 'CourseRegistration', id: string } | { __typename?: 'Invoice', id: string } | { __typename?: 'Lesson', id: string } | { __typename?: 'Location', id: string } | { __typename?: 'Payment', id: string } | { __typename?: 'Post', id: string } | { __typename?: 'Receipt', id: string } | { __typename?: 'Receivable', id: string } | { __typename?: 'Refund', id: string } | { __typename?: 'Space', id: string } | { __typename?: 'Term', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserDevice', id: string } | null };

export type ClassArrangementWarningFieldsFragment = { __typename?: 'ClassArrangementWarning', id: string, reason: ClassArrangementWarningReason, message: string };

export type ClassArrangementErrorFieldsFragment = { __typename?: 'ClassArrangementError', id: string, reason: ClassArrangementErrorReason, message: string };

export type GetClassArrangementWarningsQueryVariables = Exact<{
  language: Language;
}>;


export type GetClassArrangementWarningsQuery = { __typename?: 'Query', classArrangementWarnings: Array<{ __typename?: 'ClassArrangementWarning', id: string, reason: ClassArrangementWarningReason, message: string, term: { __typename?: 'Term', id: string, name: string, class: { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string }, course: { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string } } } } }> };

export type GetClassArrangementErrorsQueryVariables = Exact<{
  language: Language;
}>;


export type GetClassArrangementErrorsQuery = { __typename?: 'Query', classArrangementErrors: Array<{ __typename?: 'ClassArrangementError', id: string, reason: ClassArrangementErrorReason, message: string, term: { __typename?: 'Term', id: string, name: string, class: { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string }, course: { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string } } } } }> };

export type SubscribeClassArrangementWarningCreatedSubscriptionVariables = Exact<{
  language: Language;
}>;


export type SubscribeClassArrangementWarningCreatedSubscription = { __typename?: 'Subscription', classArrangementWarningCreated: { __typename?: 'ClassArrangementWarning', id: string, reason: ClassArrangementWarningReason, message: string, term: { __typename?: 'Term', id: string, name: string, class: { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string }, course: { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string } } } } } };

export type SubscribeClassArrangementWarningUpdatedSubscriptionVariables = Exact<{
  language: Language;
}>;


export type SubscribeClassArrangementWarningUpdatedSubscription = { __typename?: 'Subscription', classArrangementWarningUpdated: { __typename?: 'ClassArrangementWarning', id: string, reason: ClassArrangementWarningReason, message: string, term: { __typename?: 'Term', id: string, name: string, class: { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string }, course: { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string } } } } } };

export type SubscribeClassArrangementWarningDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeClassArrangementWarningDeletedSubscription = { __typename?: 'Subscription', classArrangementWarningDeleted: { __typename?: 'DeletePayload', id: string } };

export type SubscribeClassArrangementErrorCreatedSubscriptionVariables = Exact<{
  language: Language;
}>;


export type SubscribeClassArrangementErrorCreatedSubscription = { __typename?: 'Subscription', classArrangementErrorCreated: { __typename?: 'ClassArrangementError', id: string, reason: ClassArrangementErrorReason, message: string, term: { __typename?: 'Term', id: string, name: string, class: { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string }, course: { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string } } } } } };

export type SubscribeClassArrangementErrorUpdatedSubscriptionVariables = Exact<{
  language: Language;
}>;


export type SubscribeClassArrangementErrorUpdatedSubscription = { __typename?: 'Subscription', classArrangementErrorUpdated: { __typename?: 'ClassArrangementError', id: string, reason: ClassArrangementErrorReason, message: string, term: { __typename?: 'Term', id: string, name: string, class: { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string }, course: { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string } } } } } };

export type SubscribeClassArrangementErrorDeletedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type SubscribeClassArrangementErrorDeletedSubscription = { __typename?: 'Subscription', classArrangementErrorDeleted: { __typename?: 'DeletePayload', id: string } };

export type GetMinimalClassStudentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetMinimalClassStudentsQuery = { __typename?: 'Query', node?: { __typename?: 'Booking', id: string } | { __typename?: 'CalendarEvent', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'Class', id: string, students: Array<{ __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } }> } | { __typename?: 'Course', id: string } | { __typename?: 'CourseRegistration', id: string } | { __typename?: 'Invoice', id: string } | { __typename?: 'Lesson', id: string } | { __typename?: 'Location', id: string } | { __typename?: 'Payment', id: string } | { __typename?: 'Post', id: string } | { __typename?: 'Receipt', id: string } | { __typename?: 'Receivable', id: string } | { __typename?: 'Refund', id: string } | { __typename?: 'Space', id: string } | { __typename?: 'Term', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserDevice', id: string } | null };

export type UpdateLessonAttendancesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateLessonAttendancesInput;
}>;


export type UpdateLessonAttendancesMutation = { __typename?: 'Mutation', updateLessonAttendances?: { __typename?: 'Lesson', id: string } | null };

export type GetLessonStudentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLessonStudentsQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category' } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice' } | { __typename?: 'Lesson', students: Array<{ __typename?: 'LessonStudent', status: AttendanceStatus, student: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } } }> } | { __typename?: 'Location' } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type CategoryFieldsFragment = { __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string, description: string } };

export type GetCategoriesQueryVariables = Exact<{
  language: Language;
}>;


export type GetCategoriesQuery = { __typename?: 'Query', categories: Array<{ __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string, description: string } }> };

export type GetCategoryClassesQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Language;
}>;


export type GetCategoryClassesQuery = { __typename?: 'Query', node?: { __typename?: 'Booking', id: string } | { __typename?: 'CalendarEvent', id: string } | { __typename?: 'Category', id: string, courses: Array<{ __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string }, classes: Array<{ __typename?: 'Class', id: string, hasOngoingTerm: boolean, translation: { __typename?: 'ClassTranslation', name: string } }> }> } | { __typename?: 'Class', id: string } | { __typename?: 'Course', id: string } | { __typename?: 'CourseRegistration', id: string } | { __typename?: 'Invoice', id: string } | { __typename?: 'Lesson', id: string } | { __typename?: 'Location', id: string } | { __typename?: 'Payment', id: string } | { __typename?: 'Post', id: string } | { __typename?: 'Receipt', id: string } | { __typename?: 'Receivable', id: string } | { __typename?: 'Refund', id: string } | { __typename?: 'Space', id: string } | { __typename?: 'Term', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserDevice', id: string } | null };

export type CreateCategoryMutationVariables = Exact<{
  input: CreateCategoryInput;
  language: Language;
}>;


export type CreateCategoryMutation = { __typename?: 'Mutation', createCategory?: { __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string, description: string } } | null };

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateCategoryInput;
  language: Language;
}>;


export type UpdateCategoryMutation = { __typename?: 'Mutation', updateCategory?: { __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string, description: string } } | null };

export type UpdateCategoriesMutationVariables = Exact<{
  input: UpdateCategoriesInput;
  language: Language;
}>;


export type UpdateCategoriesMutation = { __typename?: 'Mutation', updateCategories?: Array<{ __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string, description: string } }> | null };

export type DeleteCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCategoryMutation = { __typename?: 'Mutation', deleteCategory?: { __typename?: 'DeletePayload', id: string } | null };

export type GetCategoryTranslationQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Language;
}>;


export type GetCategoryTranslationQuery = { __typename?: 'Query', node?: { __typename?: 'Booking', id: string } | { __typename?: 'CalendarEvent', id: string } | { __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string, description: string } } | { __typename?: 'Class', id: string } | { __typename?: 'Course', id: string } | { __typename?: 'CourseRegistration', id: string } | { __typename?: 'Invoice', id: string } | { __typename?: 'Lesson', id: string } | { __typename?: 'Location', id: string } | { __typename?: 'Payment', id: string } | { __typename?: 'Post', id: string } | { __typename?: 'Receipt', id: string } | { __typename?: 'Receivable', id: string } | { __typename?: 'Refund', id: string } | { __typename?: 'Space', id: string } | { __typename?: 'Term', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserDevice', id: string } | null };

export type GetCategoryInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetCategoryInfoQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category', translationZH: { __typename?: 'CategoryTranslation', name: string, description: string }, translationEN: { __typename?: 'CategoryTranslation', name: string, description: string } } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice' } | { __typename?: 'Lesson' } | { __typename?: 'Location' } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type CourseFieldsFragment = { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string, description: string }, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string, cname: string } }> };

export type GetCategoryCoursesQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Language;
}>;


export type GetCategoryCoursesQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category', id: string, courses: Array<{ __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string, description: string }, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string, cname: string } }> }> } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice' } | { __typename?: 'Lesson' } | { __typename?: 'Location' } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type GetCourseTranslationQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Language;
}>;


export type GetCourseTranslationQuery = { __typename?: 'Query', node?: { __typename?: 'Booking', id: string } | { __typename?: 'CalendarEvent', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'Class', id: string } | { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string, description: string } } | { __typename?: 'CourseRegistration', id: string } | { __typename?: 'Invoice', id: string } | { __typename?: 'Lesson', id: string } | { __typename?: 'Location', id: string } | { __typename?: 'Payment', id: string } | { __typename?: 'Post', id: string } | { __typename?: 'Receipt', id: string } | { __typename?: 'Receivable', id: string } | { __typename?: 'Refund', id: string } | { __typename?: 'Space', id: string } | { __typename?: 'Term', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserDevice', id: string } | null };

export type CreateCourseMutationVariables = Exact<{
  input: CreateCourseInput;
  language: Language;
}>;


export type CreateCourseMutation = { __typename?: 'Mutation', createCourse?: { __typename?: 'Course', id: string, category: { __typename?: 'Category', id: string }, translation: { __typename?: 'CourseTranslation', name: string, description: string }, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string, cname: string } }> } | null };

export type UpdateCourseMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateCourseInput;
  language: Language;
}>;


export type UpdateCourseMutation = { __typename?: 'Mutation', updateCourse?: { __typename?: 'Course', id: string, translation: { __typename?: 'CourseTranslation', name: string, description: string }, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string, cname: string } }> } | null };

export type AddLecturersMutationVariables = Exact<{
  id: Scalars['ID'];
  lecturers: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type AddLecturersMutation = { __typename?: 'Mutation', addCourseLecturers?: { __typename?: 'Course', lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string, cname: string } }> } | null };

export type DeleteCourseMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCourseMutation = { __typename?: 'Mutation', deleteCourse?: { __typename?: 'DeletePayload', id: string } | null };

export type ClassFieldsFragment = { __typename?: 'Class', id: string, studentFeePerLesson: number, lecturerCommission: number, color: string, hasOngoingTerm: boolean, translation: { __typename?: 'ClassTranslation', name: string, description: string }, termAutoGeneration?: { __typename?: 'TermAutoGenerationByMonth', day: Day, startTime: any, endTime: any } | null };

export type TermFieldsFragment = { __typename?: 'Term', id: string, name: string, durationPerLesson: number, studentFeePerLesson: number, lecturerCommission: number, lessonCount: number, createdAt: any };

export type GetCourseClassesQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Language;
}>;


export type GetCourseClassesQuery = { __typename?: 'Query', node?: { __typename?: 'Booking', id: string } | { __typename?: 'CalendarEvent', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'Class', id: string } | { __typename?: 'Course', id: string, classes: Array<{ __typename?: 'Class', id: string, studentFeePerLesson: number, lecturerCommission: number, color: string, hasOngoingTerm: boolean, space?: { __typename?: 'Space', id: string, available: boolean, translation: { __typename?: 'SpaceTranslation', name: string } } | null, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } }>, translation: { __typename?: 'ClassTranslation', name: string, description: string }, termAutoGeneration?: { __typename?: 'TermAutoGenerationByMonth', day: Day, startTime: any, endTime: any } | null }> } | { __typename?: 'CourseRegistration', id: string } | { __typename?: 'Invoice', id: string } | { __typename?: 'Lesson', id: string } | { __typename?: 'Location', id: string } | { __typename?: 'Payment', id: string } | { __typename?: 'Post', id: string } | { __typename?: 'Receipt', id: string } | { __typename?: 'Receivable', id: string } | { __typename?: 'Refund', id: string } | { __typename?: 'Space', id: string } | { __typename?: 'Term', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserDevice', id: string } | null };

export type GetClassQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Language;
}>;


export type GetClassQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category' } | { __typename?: 'Class', id: string, studentFeePerLesson: number, lecturerCommission: number, color: string, hasOngoingTerm: boolean, space?: { __typename?: 'Space', id: string, available: boolean, translation: { __typename?: 'SpaceTranslation', name: string } } | null, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } }>, terms: Array<{ __typename?: 'Term', id: string, name: string, durationPerLesson: number, studentFeePerLesson: number, lecturerCommission: number, lessonCount: number, createdAt: any }>, translation: { __typename?: 'ClassTranslation', name: string, description: string }, termAutoGeneration?: { __typename?: 'TermAutoGenerationByMonth', day: Day, startTime: any, endTime: any } | null } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice' } | { __typename?: 'Lesson' } | { __typename?: 'Location' } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type GetClassTranslationQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Language;
}>;


export type GetClassTranslationQuery = { __typename?: 'Query', node?: { __typename?: 'Booking', id: string } | { __typename?: 'CalendarEvent', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'Class', id: string, translation: { __typename?: 'ClassTranslation', name: string, description: string } } | { __typename?: 'Course', id: string } | { __typename?: 'CourseRegistration', id: string } | { __typename?: 'Invoice', id: string } | { __typename?: 'Lesson', id: string } | { __typename?: 'Location', id: string } | { __typename?: 'Payment', id: string } | { __typename?: 'Post', id: string } | { __typename?: 'Receipt', id: string } | { __typename?: 'Receivable', id: string } | { __typename?: 'Refund', id: string } | { __typename?: 'Space', id: string } | { __typename?: 'Term', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserDevice', id: string } | null };

export type CreateClassMutationVariables = Exact<{
  input: CreateClassInput;
  language: Language;
}>;


export type CreateClassMutation = { __typename?: 'Mutation', createClass?: { __typename?: 'Class', id: string, studentFeePerLesson: number, lecturerCommission: number, color: string, hasOngoingTerm: boolean, space?: { __typename?: 'Space', id: string, available: boolean, translation: { __typename?: 'SpaceTranslation', name: string } } | null, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } }>, translation: { __typename?: 'ClassTranslation', name: string, description: string }, termAutoGeneration?: { __typename?: 'TermAutoGenerationByMonth', day: Day, startTime: any, endTime: any } | null } | null };

export type UpdateClassMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateClassInput;
  language: Language;
}>;


export type UpdateClassMutation = { __typename?: 'Mutation', updateClass?: { __typename?: 'Class', id: string, studentFeePerLesson: number, lecturerCommission: number, color: string, hasOngoingTerm: boolean, space?: { __typename?: 'Space', id: string, available: boolean, translation: { __typename?: 'SpaceTranslation', name: string } } | null, lecturers: Array<{ __typename?: 'Lecturer', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } }>, translation: { __typename?: 'ClassTranslation', name: string, description: string }, termAutoGeneration?: { __typename?: 'TermAutoGenerationByMonth', day: Day, startTime: any, endTime: any } | null } | null };

export type DeleteClassMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteClassMutation = { __typename?: 'Mutation', deleteClass?: { __typename?: 'DeletePayload', id: string } | null };

export type CreateTermMutationVariables = Exact<{
  input: CreateTermInput;
}>;


export type CreateTermMutation = { __typename?: 'Mutation', createTerm?: { __typename?: 'Term', id: string, name: string, durationPerLesson: number, studentFeePerLesson: number, lecturerCommission: number, lessonCount: number, createdAt: any } | null };

export type UpdateTermMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTermInput;
}>;


export type UpdateTermMutation = { __typename?: 'Mutation', updateTerm?: { __typename?: 'Term', id: string, name: string, durationPerLesson: number, studentFeePerLesson: number, lecturerCommission: number, lessonCount: number, createdAt: any } | null };

export type UpdateTermStudentsMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTermInput;
}>;


export type UpdateTermStudentsMutation = { __typename?: 'Mutation', updateTerm?: { __typename?: 'Term', id: string, students: Array<{ __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } }> } | null };

export type DeleteTermMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTermMutation = { __typename?: 'Mutation', deleteTerm?: { __typename?: 'DeletePayload', id: string } | null };

export type ClassStudentFieldsFragment = { __typename?: 'Student', matricId: string, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }>, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null } };

export type GetClassStudentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetClassStudentsQuery = { __typename?: 'Query', node?: { __typename?: 'Booking', id: string } | { __typename?: 'CalendarEvent', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'Class', id: string, students: Array<{ __typename?: 'Student', matricId: string, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }>, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null } }> } | { __typename?: 'Course', id: string } | { __typename?: 'CourseRegistration', id: string } | { __typename?: 'Invoice', id: string } | { __typename?: 'Lesson', id: string } | { __typename?: 'Location', id: string } | { __typename?: 'Payment', id: string } | { __typename?: 'Post', id: string } | { __typename?: 'Receipt', id: string } | { __typename?: 'Receivable', id: string } | { __typename?: 'Refund', id: string } | { __typename?: 'Space', id: string } | { __typename?: 'Term', id: string } | { __typename?: 'User', id: string } | { __typename?: 'UserDevice', id: string } | null };

export type CreateClassStudentsMutationVariables = Exact<{
  input: CreateClassStudentsInput;
}>;


export type CreateClassStudentsMutation = { __typename?: 'Mutation', createClassStudents?: { __typename?: 'Class', id: string, students: Array<{ __typename?: 'Student', matricId: string, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }>, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null } }> } | null };

export type DeleteClassStudentsMutationVariables = Exact<{
  input: DeleteClassStudentsInput;
}>;


export type DeleteClassStudentsMutation = { __typename?: 'Mutation', deleteClassStudents?: { __typename?: 'Class', id: string, students: Array<{ __typename?: 'Student', matricId: string, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }>, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null } }> } | null };

export type GetTermStudentsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTermStudentsQuery = { __typename?: 'Query', node?: { __typename?: 'Booking', id: string } | { __typename?: 'CalendarEvent', id: string } | { __typename?: 'Category', id: string } | { __typename?: 'Class', id: string } | { __typename?: 'Course', id: string } | { __typename?: 'CourseRegistration', id: string } | { __typename?: 'Invoice', id: string } | { __typename?: 'Lesson', id: string } | { __typename?: 'Location', id: string } | { __typename?: 'Payment', id: string } | { __typename?: 'Post', id: string } | { __typename?: 'Receipt', id: string } | { __typename?: 'Receivable', id: string } | { __typename?: 'Refund', id: string } | { __typename?: 'Space', id: string } | { __typename?: 'Term', id: string, students: Array<{ __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string, cname: string, ename: string } }> } | { __typename?: 'User', id: string } | { __typename?: 'UserDevice', id: string } | null };

export type CourseRegistrationFieldsFragment = { __typename?: 'CourseRegistration', id: string, cname: string, ename: string, email: string, ic: string, gender: Gender, phone: string, birthYear: number, otherCategory: string, otherTime: string, guardianCname: string, guardianEname: string, completed: boolean, createdAt: any, location: { __typename?: 'Location', id: string, name: string }, times: Array<{ __typename?: 'CourseRegistrationTime', day: Day, startTime: any, endTime: any }> };

export type GetCourseRegistrationsQueryVariables = Exact<{
  filter?: InputMaybe<CourseRegistrationFilterInput>;
  language: Language;
}>;


export type GetCourseRegistrationsQuery = { __typename?: 'Query', courseRegistrations: Array<{ __typename?: 'CourseRegistration', id: string, cname: string, ename: string, otherCategory: string, createdAt: any, categories: Array<{ __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string } }> }> };

export type GetCourseRegistrationQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Language;
}>;


export type GetCourseRegistrationQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category' } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration', id: string, cname: string, ename: string, email: string, ic: string, gender: Gender, phone: string, birthYear: number, otherCategory: string, otherTime: string, guardianCname: string, guardianEname: string, completed: boolean, createdAt: any, categories: Array<{ __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string } }>, location: { __typename?: 'Location', id: string, name: string }, times: Array<{ __typename?: 'CourseRegistrationTime', day: Day, startTime: any, endTime: any }> } | { __typename?: 'Invoice' } | { __typename?: 'Lesson' } | { __typename?: 'Location' } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type UpdateCourseRegistrationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateCourseRegistrationInput;
  language: Language;
}>;


export type UpdateCourseRegistrationMutation = { __typename?: 'Mutation', updateCourseRegistration?: { __typename?: 'CourseRegistration', id: string, cname: string, ename: string, email: string, ic: string, gender: Gender, phone: string, birthYear: number, otherCategory: string, otherTime: string, guardianCname: string, guardianEname: string, completed: boolean, createdAt: any, categories: Array<{ __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string } }>, location: { __typename?: 'Location', id: string, name: string }, times: Array<{ __typename?: 'CourseRegistrationTime', day: Day, startTime: any, endTime: any }> } | null };

export type DeleteCourseRegistrationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCourseRegistrationMutation = { __typename?: 'Mutation', deleteCourseRegistration?: { __typename?: 'DeletePayload', id: string } | null };

export type GetUserCountDashboardQueryVariables = Exact<{
  filter: UserCountDashboardFilter;
}>;


export type GetUserCountDashboardQuery = { __typename?: 'Query', dashboards: { __typename?: 'Dashboards', userCount?: Array<any> | null } };

export type GetIncomeDashboardQueryVariables = Exact<{
  filter: IncomeDashboardFilter;
}>;


export type GetIncomeDashboardQuery = { __typename?: 'Query', dashboards: { __typename?: 'Dashboards', income?: Array<any> | null } };

export type GetActiveUserDashboardQueryVariables = Exact<{
  filter: ActiveUserDashboardFilter;
}>;


export type GetActiveUserDashboardQuery = { __typename?: 'Query', dashboards: { __typename?: 'Dashboards', activeUser?: Array<any> | null } };

export type GetCourseRegistrationDashboardQueryVariables = Exact<{
  filter: CourseRegistrationDashboardFilter;
  language: Language;
}>;


export type GetCourseRegistrationDashboardQuery = { __typename?: 'Query', dashboards: { __typename?: 'Dashboards', courseRegistration?: Array<any> | null }, categories: Array<{ __typename?: 'Category', id: string, translation: { __typename?: 'CategoryTranslation', name: string } }> };

export type LocationFieldsFragment = { __typename?: 'Location', id: string, name: string, address: string, contacts: Array<string> };

export type GetLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLocationsQuery = { __typename?: 'Query', locations: Array<{ __typename?: 'Location', id: string, name: string, address: string, contacts: Array<string> }> };

export type GetLocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLocationQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category' } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice' } | { __typename?: 'Lesson' } | { __typename?: 'Location', id: string, name: string, address: string, contacts: Array<string> } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type SpaceFieldsFragment = { __typename?: 'Space', id: string, available: boolean, translation: { __typename?: 'SpaceTranslation', name: string } };

export type GetSpacesQueryVariables = Exact<{
  filter?: InputMaybe<SpacesFilterInput>;
  language: Language;
}>;


export type GetSpacesQuery = { __typename?: 'Query', spaces: Array<{ __typename?: 'Space', id: string, available: boolean, location: { __typename?: 'Location', id: string, name: string }, translation: { __typename?: 'SpaceTranslation', name: string } }> };

export type GetSpaceQueryVariables = Exact<{
  id: Scalars['ID'];
  language: Language;
}>;


export type GetSpaceQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category' } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice' } | { __typename?: 'Lesson' } | { __typename?: 'Location' } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space', id: string, available: boolean, location: { __typename?: 'Location', id: string, name: string, address: string, contacts: Array<string> }, translation: { __typename?: 'SpaceTranslation', name: string } } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type CreateLocationMutationVariables = Exact<{
  input: CreateLocationInput;
}>;


export type CreateLocationMutation = { __typename?: 'Mutation', createLocation?: { __typename?: 'Location', id: string, name: string, address: string, contacts: Array<string> } | null };

export type CreateSpaceMutationVariables = Exact<{
  input: CreateSpaceInput;
  language: Language;
}>;


export type CreateSpaceMutation = { __typename?: 'Mutation', createSpace?: { __typename?: 'Space', id: string, available: boolean, location: { __typename?: 'Location', id: string, name: string, address: string, contacts: Array<string> }, translation: { __typename?: 'SpaceTranslation', name: string } } | null };

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLocationMutation = { __typename?: 'Mutation', deleteLocation?: { __typename?: 'DeletePayload', id: string } | null };

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateLocationInput;
}>;


export type UpdateLocationMutation = { __typename?: 'Mutation', updateLocation?: { __typename?: 'Location', id: string, name: string, address: string, contacts: Array<string> } | null };

export type DeleteSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSpaceMutation = { __typename?: 'Mutation', deleteSpace?: { __typename?: 'DeletePayload', id: string } | null };

export type UpdateSpaceMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateSpaceInput;
  language: Language;
}>;


export type UpdateSpaceMutation = { __typename?: 'Mutation', updateSpace?: { __typename?: 'Space', id: string, available: boolean, location: { __typename?: 'Location', id: string, name: string, address: string, contacts: Array<string> }, translation: { __typename?: 'SpaceTranslation', name: string } } | null };

export type ReceivableFieldsFragment = { __typename?: 'Receivable', id: string, name: string, type: ReceivableType, price: number, quantity: number, status: ReceivableStatus, item?: { __typename?: 'Term', name: string, id: string } | null, debtor: { __typename?: 'User', id: string, cname: string, ename: string } };

export type GetReceivablesQueryVariables = Exact<{
  filter?: InputMaybe<ReceivablesFilterInput>;
}>;


export type GetReceivablesQuery = { __typename?: 'Query', receivables: Array<{ __typename?: 'Receivable', id: string, name: string, type: ReceivableType, price: number, quantity: number, status: ReceivableStatus, item?: { __typename?: 'Term', name: string, id: string } | null, debtor: { __typename?: 'User', id: string, cname: string, ename: string } }> };

export type CreateReceivablesMutationVariables = Exact<{
  input: Array<CreateReceivableInput> | CreateReceivableInput;
}>;


export type CreateReceivablesMutation = { __typename?: 'Mutation', createReceivables?: Array<{ __typename?: 'Receivable', id: string, name: string, type: ReceivableType, price: number, quantity: number, status: ReceivableStatus, item?: { __typename?: 'Term', name: string, id: string } | null, debtor: { __typename?: 'User', id: string, cname: string, ename: string } }> | null };

export type GetReceivableQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetReceivableQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category' } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice' } | { __typename?: 'Lesson' } | { __typename?: 'Location' } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable', id: string, name: string, type: ReceivableType, price: number, quantity: number, status: ReceivableStatus, item?: { __typename?: 'Term', name: string, id: string } | null, debtor: { __typename?: 'User', id: string, cname: string, ename: string } } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type InvoiceFieldsFragment = { __typename?: 'Invoice', id: string, amount: number, discount: number, tax: number, createdAt: any, status: InvoiceStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, address: string, student?: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string } } | null } };

export type PaymentFieldsFragment = { __typename?: 'Payment', id: string, amount: number, createdAt: any, status: PaymentStatus, method: PaymentMethod, methodDescription: string, user: { __typename?: 'User', id: string, cname: string, ename: string }, receipt?: { __typename?: 'Receipt', id: string, payment: { __typename?: 'Payment', id: string } } | null, proofs: Array<{ __typename?: 'File', key: string, name: string, size: number, lastModified: any, url: string }> };

export type GetInvoicesQueryVariables = Exact<{
  filter?: InputMaybe<InvoicesFilterInput>;
}>;


export type GetInvoicesQuery = { __typename?: 'Query', invoices: Array<{ __typename?: 'Invoice', id: string, amount: number, discount: number, tax: number, createdAt: any, status: InvoiceStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, address: string, student?: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string } } | null } }> };

export type GetInvoiceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetInvoiceQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category' } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice', id: string, amount: number, discount: number, tax: number, createdAt: any, status: InvoiceStatus, items: Array<{ __typename?: 'InvoiceItem', description: string, receivable: { __typename?: 'Receivable', id: string, name: string, type: ReceivableType, price: number, quantity: number, status: ReceivableStatus, item?: { __typename?: 'Term', name: string, id: string } | null, debtor: { __typename?: 'User', id: string, cname: string, ename: string } } }>, payment?: { __typename?: 'Payment', id: string, amount: number, createdAt: any, status: PaymentStatus, method: PaymentMethod, methodDescription: string, user: { __typename?: 'User', id: string, cname: string, ename: string }, receipt?: { __typename?: 'Receipt', id: string, payment: { __typename?: 'Payment', id: string } } | null, proofs: Array<{ __typename?: 'File', key: string, name: string, size: number, lastModified: any, url: string }> } | null, user: { __typename?: 'User', id: string, cname: string, ename: string, address: string, student?: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string } } | null } } | { __typename?: 'Lesson' } | { __typename?: 'Location' } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type CreateInvoiceMutationVariables = Exact<{
  input: CreateInvoiceInput;
}>;


export type CreateInvoiceMutation = { __typename?: 'Mutation', createInvoice?: { __typename?: 'Invoice', id: string, amount: number, discount: number, tax: number, createdAt: any, status: InvoiceStatus, items: Array<{ __typename?: 'InvoiceItem', description: string, receivable: { __typename?: 'Receivable', id: string, name: string, type: ReceivableType, price: number, quantity: number, status: ReceivableStatus, item?: { __typename?: 'Term', name: string, id: string } | null, debtor: { __typename?: 'User', id: string, cname: string, ename: string } } }>, user: { __typename?: 'User', id: string, cname: string, ename: string, address: string, student?: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string } } | null } } | null };

export type UpdateInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateInvoiceInput;
}>;


export type UpdateInvoiceMutation = { __typename?: 'Mutation', updateInvoice?: { __typename?: 'Invoice', id: string, amount: number, discount: number, tax: number, createdAt: any, status: InvoiceStatus, items: Array<{ __typename?: 'InvoiceItem', description: string, receivable: { __typename?: 'Receivable', id: string, name: string, type: ReceivableType, price: number, quantity: number, status: ReceivableStatus, item?: { __typename?: 'Term', name: string, id: string } | null, debtor: { __typename?: 'User', id: string, cname: string, ename: string } } }>, user: { __typename?: 'User', id: string, cname: string, ename: string, address: string, student?: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string } } | null } } | null };

export type DeleteInvoiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteInvoiceMutation = { __typename?: 'Mutation', deleteInvoice?: { __typename?: 'DeletePayload', id: string } | null };

export type GetPaymentsQueryVariables = Exact<{
  filter?: InputMaybe<PaymentsFilterInput>;
}>;


export type GetPaymentsQuery = { __typename?: 'Query', payments: Array<{ __typename?: 'Payment', id: string, amount: number, createdAt: any, status: PaymentStatus, method: PaymentMethod, methodDescription: string, invoice: { __typename?: 'Invoice', id: string, amount: number, discount: number, tax: number, createdAt: any, status: InvoiceStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, address: string, student?: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string } } | null } }, user: { __typename?: 'User', id: string, cname: string, ename: string }, receipt?: { __typename?: 'Receipt', id: string, payment: { __typename?: 'Payment', id: string } } | null, proofs: Array<{ __typename?: 'File', key: string, name: string, size: number, lastModified: any, url: string }> }> };

export type GetRefundsQueryVariables = Exact<{
  filter?: InputMaybe<RefundsFilterInput>;
}>;


export type GetRefundsQuery = { __typename?: 'Query', refunds: Array<{ __typename?: 'Refund', id: string, amount: number, createdAt: any, refundedAt?: any | null, status: RefundStatus, invoice: { __typename?: 'Invoice', id: string, amount: number, discount: number, tax: number, createdAt: any, status: InvoiceStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, address: string, student?: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string } } | null } }, payment: { __typename?: 'Payment', id: string, amount: number, createdAt: any, status: PaymentStatus, method: PaymentMethod, methodDescription: string, user: { __typename?: 'User', id: string, cname: string, ename: string }, receipt?: { __typename?: 'Receipt', id: string, payment: { __typename?: 'Payment', id: string } } | null, proofs: Array<{ __typename?: 'File', key: string, name: string, size: number, lastModified: any, url: string }> } }> };

export type CreatePaymentProofMutationVariables = Exact<{
  input: CreatePaymentProofInput;
}>;


export type CreatePaymentProofMutation = { __typename?: 'Mutation', createPaymentProof?: { __typename?: 'CreatePaymentProofPayload', key: string, url: string } | null };

export type CreatePaymentMutationVariables = Exact<{
  input: CreatePaymentInput;
}>;


export type CreatePaymentMutation = { __typename?: 'Mutation', createPayment?: { __typename?: 'Payment', id: string, amount: number, createdAt: any, status: PaymentStatus, method: PaymentMethod, methodDescription: string, invoice: { __typename?: 'Invoice', id: string, amount: number, discount: number, tax: number, createdAt: any, status: InvoiceStatus, payment?: { __typename?: 'Payment', id: string, amount: number, createdAt: any, status: PaymentStatus, method: PaymentMethod, methodDescription: string, user: { __typename?: 'User', id: string, cname: string, ename: string }, receipt?: { __typename?: 'Receipt', id: string, payment: { __typename?: 'Payment', id: string } } | null, proofs: Array<{ __typename?: 'File', key: string, name: string, size: number, lastModified: any, url: string }> } | null, user: { __typename?: 'User', id: string, cname: string, ename: string, address: string, student?: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string } } | null } }, user: { __typename?: 'User', id: string, cname: string, ename: string }, receipt?: { __typename?: 'Receipt', id: string, payment: { __typename?: 'Payment', id: string } } | null, proofs: Array<{ __typename?: 'File', key: string, name: string, size: number, lastModified: any, url: string }> } | null };

export type GetPaymentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPaymentQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category' } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice' } | { __typename?: 'Lesson' } | { __typename?: 'Location' } | { __typename?: 'Payment', id: string, amount: number, createdAt: any, status: PaymentStatus, method: PaymentMethod, methodDescription: string, invoice: { __typename?: 'Invoice', id: string, amount: number, discount: number, tax: number, createdAt: any, status: InvoiceStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, address: string, student?: { __typename?: 'Student', matricId: string, user: { __typename?: 'User', id: string } } | null } }, user: { __typename?: 'User', id: string, cname: string, ename: string }, receipt?: { __typename?: 'Receipt', id: string, payment: { __typename?: 'Payment', id: string } } | null, proofs: Array<{ __typename?: 'File', key: string, name: string, size: number, lastModified: any, url: string }> } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User' } | { __typename?: 'UserDevice' } | null };

export type GetUsersWithAddressQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersWithAddressQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, cname: string, ename: string, address: string }> };

export type UpdateMyProfileMutationVariables = Exact<{
  input: UpdateMyProfileInput;
}>;


export type UpdateMyProfileMutation = { __typename?: 'Mutation', updateMyProfile?: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, address: string, registerDate: any, status: UserStatus } | null };

export type UpdateMyPasswordMutationVariables = Exact<{
  input: UpdateMyPasswordInput;
}>;


export type UpdateMyPasswordMutation = { __typename?: 'Mutation', updateMyPassword?: { __typename?: 'UpdateMyPasswordPayload', id: string } | null };

export type UserTableFieldsFragment = { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null };

export type GetUsersQueryVariables = Exact<{
  status?: InputMaybe<UserStatus>;
  roleFilter?: InputMaybe<UserRoleFilterInput>;
}>;


export type GetUsersQuery = { __typename?: 'Query', users: Array<{ __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null }> };

export type GetStudentsQueryVariables = Exact<{
  status?: InputMaybe<StudentStatus>;
}>;


export type GetStudentsQuery = { __typename?: 'Query', students: Array<{ __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> }> };

export type GetLecturersQueryVariables = Exact<{
  status?: InputMaybe<LecturerStatus>;
}>;


export type GetLecturersQuery = { __typename?: 'Query', lecturers: Array<{ __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null } }> };

export type GetAdminsQueryVariables = Exact<{
  status?: InputMaybe<AdminStatus>;
}>;


export type GetAdminsQuery = { __typename?: 'Query', admins: Array<{ __typename?: 'Admin', matricId: string, isMaster: boolean, status: AdminStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null } }> };

export type GetUserQueryVariables = Exact<{
  id: Scalars['ID'];
  isMaster: Scalars['Boolean'];
}>;


export type GetUserQuery = { __typename?: 'Query', node?: { __typename?: 'Booking' } | { __typename?: 'CalendarEvent' } | { __typename?: 'Category' } | { __typename?: 'Class' } | { __typename?: 'Course' } | { __typename?: 'CourseRegistration' } | { __typename?: 'Invoice' } | { __typename?: 'Lesson' } | { __typename?: 'Location' } | { __typename?: 'Payment' } | { __typename?: 'Post' } | { __typename?: 'Receipt' } | { __typename?: 'Receivable' } | { __typename?: 'Refund' } | { __typename?: 'Space' } | { __typename?: 'Term' } | { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, address: string, registerDate: any, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null, admin?: { __typename?: 'Admin', matricId: string, isMaster: boolean, status: AdminStatus, user: { __typename?: 'User', id: string } } | null } | { __typename?: 'UserDevice' } | null };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
  isMaster: Scalars['Boolean'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser?: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, address: string, registerDate: any, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null, admin?: { __typename?: 'Admin', matricId: string, isMaster: boolean, status: AdminStatus, user: { __typename?: 'User', id: string } } | null } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateUserInput;
  isMaster: Scalars['Boolean'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser?: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, address: string, registerDate: any, status: UserStatus, student?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null, lecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string } } | null, admin?: { __typename?: 'Admin', matricId: string, isMaster: boolean, status: AdminStatus, user: { __typename?: 'User', id: string } } | null } | null };

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser?: { __typename?: 'DeletePayload', id: string } | null };

export type CreateStudentMutationVariables = Exact<{
  input: CreateStudentInput;
}>;


export type CreateStudentMutation = { __typename?: 'Mutation', createStudent?: { __typename?: 'Student', matricId: string, status: StudentStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, address: string, registerDate: any, status: UserStatus }, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> } | null };

export type CreateLecturerMutationVariables = Exact<{
  input: CreateLecturerInput;
}>;


export type CreateLecturerMutation = { __typename?: 'Mutation', createLecturer?: { __typename?: 'Lecturer', matricId: string, status: LecturerStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, address: string, registerDate: any, status: UserStatus } } | null };

export type CreateAdminMutationVariables = Exact<{
  input: CreateAdminInput;
}>;


export type CreateAdminMutation = { __typename?: 'Mutation', createAdmin?: { __typename?: 'Admin', matricId: string, isMaster: boolean, status: AdminStatus, user: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, address: string, registerDate: any, status: UserStatus } } | null };

export type UserFieldsFragment = { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, address: string, registerDate: any, status: UserStatus };

export type StudentFieldsFragment = { __typename?: 'Student', matricId: string, status: StudentStatus, phones: Array<{ __typename?: 'StudentPhone', name: string, number: string }> };

export type LecturerFieldsFragment = { __typename?: 'Lecturer', matricId: string, status: LecturerStatus };

export type AdminFieldsFragment = { __typename?: 'Admin', matricId: string, isMaster: boolean, status: AdminStatus };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, cname: string, ename: string, email: string, gender: Gender, ic: string, phone: string, address: string, registerDate: any, status: UserStatus, admin?: { __typename?: 'Admin', matricId: string, isMaster: boolean, status: AdminStatus, user: { __typename?: 'User', id: string } } | null } };

export const LessonFieldsFragmentDoc = gql`
    fragment LessonFields on Lesson {
  id
  startTime
  endTime
  status
  color
}
    `;
export const ClassArrangementWarningFieldsFragmentDoc = gql`
    fragment ClassArrangementWarningFields on ClassArrangementWarning {
  id
  reason
  message
}
    `;
export const ClassArrangementErrorFieldsFragmentDoc = gql`
    fragment ClassArrangementErrorFields on ClassArrangementError {
  id
  reason
  message
}
    `;
export const CategoryFieldsFragmentDoc = gql`
    fragment CategoryFields on Category {
  id
  translation(language: $language) {
    name
    description
  }
}
    `;
export const CourseFieldsFragmentDoc = gql`
    fragment CourseFields on Course {
  id
  translation(language: $language) {
    name
    description
  }
  lecturers {
    matricId
    user {
      id
      cname
    }
  }
}
    `;
export const ClassFieldsFragmentDoc = gql`
    fragment ClassFields on Class {
  id
  translation(language: $language) {
    name
    description
  }
  studentFeePerLesson
  lecturerCommission
  color
  termAutoGeneration {
    ... on TermAutoGenerationByMonth {
      day
      startTime
      endTime
    }
  }
  hasOngoingTerm
}
    `;
export const TermFieldsFragmentDoc = gql`
    fragment TermFields on Term {
  id
  name
  durationPerLesson
  studentFeePerLesson
  lecturerCommission
  lessonCount
  createdAt
}
    `;
export const StudentFieldsFragmentDoc = gql`
    fragment StudentFields on Student {
  matricId
  phones {
    name
    number
  }
  status
}
    `;
export const LecturerFieldsFragmentDoc = gql`
    fragment LecturerFields on Lecturer {
  matricId
  status
}
    `;
export const UserTableFieldsFragmentDoc = gql`
    fragment UserTableFields on User {
  id
  cname
  ename
  email
  gender
  ic
  phone
  status
  student {
    user {
      id
    }
    ...StudentFields
  }
  lecturer {
    user {
      id
    }
    ...LecturerFields
  }
}
    ${StudentFieldsFragmentDoc}
${LecturerFieldsFragmentDoc}`;
export const ClassStudentFieldsFragmentDoc = gql`
    fragment ClassStudentFields on Student {
  matricId
  phones {
    name
    number
  }
  user {
    ...UserTableFields
  }
}
    ${UserTableFieldsFragmentDoc}`;
export const CourseRegistrationFieldsFragmentDoc = gql`
    fragment CourseRegistrationFields on CourseRegistration {
  id
  cname
  ename
  email
  ic
  gender
  phone
  birthYear
  otherCategory
  otherTime
  location {
    id
    name
  }
  guardianCname
  guardianEname
  completed
  createdAt
  times {
    day
    startTime
    endTime
  }
}
    `;
export const LocationFieldsFragmentDoc = gql`
    fragment LocationFields on Location {
  id
  name
  address
  contacts
}
    `;
export const SpaceFieldsFragmentDoc = gql`
    fragment SpaceFields on Space {
  id
  translation(language: $language) {
    name
  }
  available
}
    `;
export const ReceivableFieldsFragmentDoc = gql`
    fragment ReceivableFields on Receivable {
  id
  name
  type
  price
  quantity
  status
  item {
    id
    ... on Term {
      name
    }
  }
  debtor {
    id
    cname
    ename
  }
}
    `;
export const InvoiceFieldsFragmentDoc = gql`
    fragment InvoiceFields on Invoice {
  id
  amount
  discount
  tax
  user {
    id
    cname
    ename
    address
    student {
      matricId
      user {
        id
      }
    }
  }
  createdAt
  status
}
    `;
export const PaymentFieldsFragmentDoc = gql`
    fragment PaymentFields on Payment {
  id
  amount
  createdAt
  user {
    id
    cname
    ename
  }
  status
  method
  methodDescription
  receipt {
    id
    payment {
      id
    }
  }
  proofs {
    key
    name
    size
    lastModified
    url
  }
}
    `;
export const UserFieldsFragmentDoc = gql`
    fragment UserFields on User {
  id
  cname
  ename
  email
  gender
  ic
  phone
  address
  registerDate
  status
}
    `;
export const AdminFieldsFragmentDoc = gql`
    fragment AdminFields on Admin {
  matricId
  isMaster
  status
}
    `;
export const GetLessonsDocument = gql`
    query GetLessons($filter: LessonFilterInput, $language: Language!) {
  lessons(filter: $filter) {
    ...LessonFields
    term {
      id
      class {
        id
        translation(language: $language) {
          name
        }
      }
    }
    space {
      id
      translation(language: $language) {
        name
      }
    }
    lecturers {
      matricId
      user {
        id
      }
    }
    students {
      student {
        matricId
        user {
          id
          cname
          ename
        }
      }
      status
    }
  }
}
    ${LessonFieldsFragmentDoc}`;

/**
 * __useGetLessonsQuery__
 *
 * To run a query within a React component, call `useGetLessonsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetLessonsQuery(baseOptions: Apollo.QueryHookOptions<GetLessonsQuery, GetLessonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonsQuery, GetLessonsQueryVariables>(GetLessonsDocument, options);
      }
export function useGetLessonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonsQuery, GetLessonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonsQuery, GetLessonsQueryVariables>(GetLessonsDocument, options);
        }
export type GetLessonsQueryHookResult = ReturnType<typeof useGetLessonsQuery>;
export type GetLessonsLazyQueryHookResult = ReturnType<typeof useGetLessonsLazyQuery>;
export type GetLessonsQueryResult = Apollo.QueryResult<GetLessonsQuery, GetLessonsQueryVariables>;
export const SubscribeLessonCreatedDocument = gql`
    subscription SubscribeLessonCreated($filter: LessonFilterInput, $language: Language!) {
  lessonCreated(filter: $filter) {
    ...LessonFields
    term {
      id
      class {
        id
        translation(language: $language) {
          name
        }
      }
    }
    space {
      id
      translation(language: $language) {
        name
      }
    }
    lecturers {
      matricId
      user {
        id
      }
    }
    students {
      student {
        matricId
        user {
          id
          cname
          ename
        }
      }
      status
    }
  }
}
    ${LessonFieldsFragmentDoc}`;

/**
 * __useSubscribeLessonCreatedSubscription__
 *
 * To run a query within a React component, call `useSubscribeLessonCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeLessonCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeLessonCreatedSubscription({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubscribeLessonCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeLessonCreatedSubscription, SubscribeLessonCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeLessonCreatedSubscription, SubscribeLessonCreatedSubscriptionVariables>(SubscribeLessonCreatedDocument, options);
      }
export type SubscribeLessonCreatedSubscriptionHookResult = ReturnType<typeof useSubscribeLessonCreatedSubscription>;
export type SubscribeLessonCreatedSubscriptionResult = Apollo.SubscriptionResult<SubscribeLessonCreatedSubscription>;
export const SubscribeLessonUpdatedDocument = gql`
    subscription SubscribeLessonUpdated($language: Language!) {
  lessonUpdated {
    ...LessonFields
    term {
      id
      class {
        id
        translation(language: $language) {
          name
        }
      }
    }
    space {
      id
      translation(language: $language) {
        name
      }
    }
    lecturers {
      matricId
      user {
        id
      }
    }
    students {
      student {
        matricId
        user {
          id
          cname
          ename
        }
      }
      status
    }
  }
}
    ${LessonFieldsFragmentDoc}`;

/**
 * __useSubscribeLessonUpdatedSubscription__
 *
 * To run a query within a React component, call `useSubscribeLessonUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeLessonUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeLessonUpdatedSubscription({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubscribeLessonUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeLessonUpdatedSubscription, SubscribeLessonUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeLessonUpdatedSubscription, SubscribeLessonUpdatedSubscriptionVariables>(SubscribeLessonUpdatedDocument, options);
      }
export type SubscribeLessonUpdatedSubscriptionHookResult = ReturnType<typeof useSubscribeLessonUpdatedSubscription>;
export type SubscribeLessonUpdatedSubscriptionResult = Apollo.SubscriptionResult<SubscribeLessonUpdatedSubscription>;
export const SubscribeLessonDeletedDocument = gql`
    subscription SubscribeLessonDeleted {
  lessonDeleted {
    id
  }
}
    `;

/**
 * __useSubscribeLessonDeletedSubscription__
 *
 * To run a query within a React component, call `useSubscribeLessonDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeLessonDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeLessonDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeLessonDeletedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeLessonDeletedSubscription, SubscribeLessonDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeLessonDeletedSubscription, SubscribeLessonDeletedSubscriptionVariables>(SubscribeLessonDeletedDocument, options);
      }
export type SubscribeLessonDeletedSubscriptionHookResult = ReturnType<typeof useSubscribeLessonDeletedSubscription>;
export type SubscribeLessonDeletedSubscriptionResult = Apollo.SubscriptionResult<SubscribeLessonDeletedSubscription>;
export const GetClassesDocument = gql`
    query GetClasses($language: Language!) {
  classes {
    ...ClassFields
    course {
      id
      translation(language: $language) {
        name
      }
    }
  }
}
    ${ClassFieldsFragmentDoc}`;

/**
 * __useGetClassesQuery__
 *
 * To run a query within a React component, call `useGetClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassesQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetClassesQuery(baseOptions: Apollo.QueryHookOptions<GetClassesQuery, GetClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassesQuery, GetClassesQueryVariables>(GetClassesDocument, options);
      }
export function useGetClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassesQuery, GetClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassesQuery, GetClassesQueryVariables>(GetClassesDocument, options);
        }
export type GetClassesQueryHookResult = ReturnType<typeof useGetClassesQuery>;
export type GetClassesLazyQueryHookResult = ReturnType<typeof useGetClassesLazyQuery>;
export type GetClassesQueryResult = Apollo.QueryResult<GetClassesQuery, GetClassesQueryVariables>;
export const CreateLessonDocument = gql`
    mutation CreateLesson($input: CreateLessonInput!) {
  createLesson(input: $input) {
    id
  }
}
    `;
export type CreateLessonMutationFn = Apollo.MutationFunction<CreateLessonMutation, CreateLessonMutationVariables>;

/**
 * __useCreateLessonMutation__
 *
 * To run a mutation, you first call `useCreateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLessonMutation, { data, loading, error }] = useCreateLessonMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLessonMutation(baseOptions?: Apollo.MutationHookOptions<CreateLessonMutation, CreateLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLessonMutation, CreateLessonMutationVariables>(CreateLessonDocument, options);
      }
export type CreateLessonMutationHookResult = ReturnType<typeof useCreateLessonMutation>;
export type CreateLessonMutationResult = Apollo.MutationResult<CreateLessonMutation>;
export type CreateLessonMutationOptions = Apollo.BaseMutationOptions<CreateLessonMutation, CreateLessonMutationVariables>;
export const CreateLessonsDocument = gql`
    mutation CreateLessons($input: [CreateLessonInput!]!) {
  createLessons(input: $input) {
    id
  }
}
    `;
export type CreateLessonsMutationFn = Apollo.MutationFunction<CreateLessonsMutation, CreateLessonsMutationVariables>;

/**
 * __useCreateLessonsMutation__
 *
 * To run a mutation, you first call `useCreateLessonsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLessonsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLessonsMutation, { data, loading, error }] = useCreateLessonsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLessonsMutation(baseOptions?: Apollo.MutationHookOptions<CreateLessonsMutation, CreateLessonsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLessonsMutation, CreateLessonsMutationVariables>(CreateLessonsDocument, options);
      }
export type CreateLessonsMutationHookResult = ReturnType<typeof useCreateLessonsMutation>;
export type CreateLessonsMutationResult = Apollo.MutationResult<CreateLessonsMutation>;
export type CreateLessonsMutationOptions = Apollo.BaseMutationOptions<CreateLessonsMutation, CreateLessonsMutationVariables>;
export const UpdateLessonDocument = gql`
    mutation UpdateLesson($id: ID!, $input: UpdateLessonInput!) {
  updateLesson(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateLessonMutationFn = Apollo.MutationFunction<UpdateLessonMutation, UpdateLessonMutationVariables>;

/**
 * __useUpdateLessonMutation__
 *
 * To run a mutation, you first call `useUpdateLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonMutation, { data, loading, error }] = useUpdateLessonMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLessonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLessonMutation, UpdateLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLessonMutation, UpdateLessonMutationVariables>(UpdateLessonDocument, options);
      }
export type UpdateLessonMutationHookResult = ReturnType<typeof useUpdateLessonMutation>;
export type UpdateLessonMutationResult = Apollo.MutationResult<UpdateLessonMutation>;
export type UpdateLessonMutationOptions = Apollo.BaseMutationOptions<UpdateLessonMutation, UpdateLessonMutationVariables>;
export const DeleteLessonDocument = gql`
    mutation DeleteLesson($id: ID!) {
  deleteLesson(id: $id) {
    id
  }
}
    `;
export type DeleteLessonMutationFn = Apollo.MutationFunction<DeleteLessonMutation, DeleteLessonMutationVariables>;

/**
 * __useDeleteLessonMutation__
 *
 * To run a mutation, you first call `useDeleteLessonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLessonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLessonMutation, { data, loading, error }] = useDeleteLessonMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLessonMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLessonMutation, DeleteLessonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLessonMutation, DeleteLessonMutationVariables>(DeleteLessonDocument, options);
      }
export type DeleteLessonMutationHookResult = ReturnType<typeof useDeleteLessonMutation>;
export type DeleteLessonMutationResult = Apollo.MutationResult<DeleteLessonMutation>;
export type DeleteLessonMutationOptions = Apollo.BaseMutationOptions<DeleteLessonMutation, DeleteLessonMutationVariables>;
export const GetClassTermsDocument = gql`
    query GetClassTerms($id: ID!) {
  node(id: $id) {
    id
    ... on Class {
      terms {
        ...TermFields
      }
    }
  }
}
    ${TermFieldsFragmentDoc}`;

/**
 * __useGetClassTermsQuery__
 *
 * To run a query within a React component, call `useGetClassTermsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassTermsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassTermsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassTermsQuery(baseOptions: Apollo.QueryHookOptions<GetClassTermsQuery, GetClassTermsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassTermsQuery, GetClassTermsQueryVariables>(GetClassTermsDocument, options);
      }
export function useGetClassTermsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassTermsQuery, GetClassTermsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassTermsQuery, GetClassTermsQueryVariables>(GetClassTermsDocument, options);
        }
export type GetClassTermsQueryHookResult = ReturnType<typeof useGetClassTermsQuery>;
export type GetClassTermsLazyQueryHookResult = ReturnType<typeof useGetClassTermsLazyQuery>;
export type GetClassTermsQueryResult = Apollo.QueryResult<GetClassTermsQuery, GetClassTermsQueryVariables>;
export const GetClassArrangementWarningsDocument = gql`
    query GetClassArrangementWarnings($language: Language!) {
  classArrangementWarnings {
    ...ClassArrangementWarningFields
    term {
      id
      name
      class {
        id
        translation(language: $language) {
          name
        }
        course {
          id
          translation(language: $language) {
            name
          }
        }
      }
    }
  }
}
    ${ClassArrangementWarningFieldsFragmentDoc}`;

/**
 * __useGetClassArrangementWarningsQuery__
 *
 * To run a query within a React component, call `useGetClassArrangementWarningsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassArrangementWarningsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassArrangementWarningsQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetClassArrangementWarningsQuery(baseOptions: Apollo.QueryHookOptions<GetClassArrangementWarningsQuery, GetClassArrangementWarningsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassArrangementWarningsQuery, GetClassArrangementWarningsQueryVariables>(GetClassArrangementWarningsDocument, options);
      }
export function useGetClassArrangementWarningsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassArrangementWarningsQuery, GetClassArrangementWarningsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassArrangementWarningsQuery, GetClassArrangementWarningsQueryVariables>(GetClassArrangementWarningsDocument, options);
        }
export type GetClassArrangementWarningsQueryHookResult = ReturnType<typeof useGetClassArrangementWarningsQuery>;
export type GetClassArrangementWarningsLazyQueryHookResult = ReturnType<typeof useGetClassArrangementWarningsLazyQuery>;
export type GetClassArrangementWarningsQueryResult = Apollo.QueryResult<GetClassArrangementWarningsQuery, GetClassArrangementWarningsQueryVariables>;
export const GetClassArrangementErrorsDocument = gql`
    query GetClassArrangementErrors($language: Language!) {
  classArrangementErrors {
    ...ClassArrangementErrorFields
    term {
      id
      name
      class {
        id
        translation(language: $language) {
          name
        }
        course {
          id
          translation(language: $language) {
            name
          }
        }
      }
    }
  }
}
    ${ClassArrangementErrorFieldsFragmentDoc}`;

/**
 * __useGetClassArrangementErrorsQuery__
 *
 * To run a query within a React component, call `useGetClassArrangementErrorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassArrangementErrorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassArrangementErrorsQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetClassArrangementErrorsQuery(baseOptions: Apollo.QueryHookOptions<GetClassArrangementErrorsQuery, GetClassArrangementErrorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassArrangementErrorsQuery, GetClassArrangementErrorsQueryVariables>(GetClassArrangementErrorsDocument, options);
      }
export function useGetClassArrangementErrorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassArrangementErrorsQuery, GetClassArrangementErrorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassArrangementErrorsQuery, GetClassArrangementErrorsQueryVariables>(GetClassArrangementErrorsDocument, options);
        }
export type GetClassArrangementErrorsQueryHookResult = ReturnType<typeof useGetClassArrangementErrorsQuery>;
export type GetClassArrangementErrorsLazyQueryHookResult = ReturnType<typeof useGetClassArrangementErrorsLazyQuery>;
export type GetClassArrangementErrorsQueryResult = Apollo.QueryResult<GetClassArrangementErrorsQuery, GetClassArrangementErrorsQueryVariables>;
export const SubscribeClassArrangementWarningCreatedDocument = gql`
    subscription SubscribeClassArrangementWarningCreated($language: Language!) {
  classArrangementWarningCreated {
    ...ClassArrangementWarningFields
    term {
      id
      name
      class {
        id
        translation(language: $language) {
          name
        }
        course {
          id
          translation(language: $language) {
            name
          }
        }
      }
    }
  }
}
    ${ClassArrangementWarningFieldsFragmentDoc}`;

/**
 * __useSubscribeClassArrangementWarningCreatedSubscription__
 *
 * To run a query within a React component, call `useSubscribeClassArrangementWarningCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeClassArrangementWarningCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeClassArrangementWarningCreatedSubscription({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubscribeClassArrangementWarningCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeClassArrangementWarningCreatedSubscription, SubscribeClassArrangementWarningCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeClassArrangementWarningCreatedSubscription, SubscribeClassArrangementWarningCreatedSubscriptionVariables>(SubscribeClassArrangementWarningCreatedDocument, options);
      }
export type SubscribeClassArrangementWarningCreatedSubscriptionHookResult = ReturnType<typeof useSubscribeClassArrangementWarningCreatedSubscription>;
export type SubscribeClassArrangementWarningCreatedSubscriptionResult = Apollo.SubscriptionResult<SubscribeClassArrangementWarningCreatedSubscription>;
export const SubscribeClassArrangementWarningUpdatedDocument = gql`
    subscription SubscribeClassArrangementWarningUpdated($language: Language!) {
  classArrangementWarningUpdated {
    ...ClassArrangementWarningFields
    term {
      id
      name
      class {
        id
        translation(language: $language) {
          name
        }
        course {
          id
          translation(language: $language) {
            name
          }
        }
      }
    }
  }
}
    ${ClassArrangementWarningFieldsFragmentDoc}`;

/**
 * __useSubscribeClassArrangementWarningUpdatedSubscription__
 *
 * To run a query within a React component, call `useSubscribeClassArrangementWarningUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeClassArrangementWarningUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeClassArrangementWarningUpdatedSubscription({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubscribeClassArrangementWarningUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeClassArrangementWarningUpdatedSubscription, SubscribeClassArrangementWarningUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeClassArrangementWarningUpdatedSubscription, SubscribeClassArrangementWarningUpdatedSubscriptionVariables>(SubscribeClassArrangementWarningUpdatedDocument, options);
      }
export type SubscribeClassArrangementWarningUpdatedSubscriptionHookResult = ReturnType<typeof useSubscribeClassArrangementWarningUpdatedSubscription>;
export type SubscribeClassArrangementWarningUpdatedSubscriptionResult = Apollo.SubscriptionResult<SubscribeClassArrangementWarningUpdatedSubscription>;
export const SubscribeClassArrangementWarningDeletedDocument = gql`
    subscription SubscribeClassArrangementWarningDeleted {
  classArrangementWarningDeleted {
    id
  }
}
    `;

/**
 * __useSubscribeClassArrangementWarningDeletedSubscription__
 *
 * To run a query within a React component, call `useSubscribeClassArrangementWarningDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeClassArrangementWarningDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeClassArrangementWarningDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeClassArrangementWarningDeletedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeClassArrangementWarningDeletedSubscription, SubscribeClassArrangementWarningDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeClassArrangementWarningDeletedSubscription, SubscribeClassArrangementWarningDeletedSubscriptionVariables>(SubscribeClassArrangementWarningDeletedDocument, options);
      }
export type SubscribeClassArrangementWarningDeletedSubscriptionHookResult = ReturnType<typeof useSubscribeClassArrangementWarningDeletedSubscription>;
export type SubscribeClassArrangementWarningDeletedSubscriptionResult = Apollo.SubscriptionResult<SubscribeClassArrangementWarningDeletedSubscription>;
export const SubscribeClassArrangementErrorCreatedDocument = gql`
    subscription SubscribeClassArrangementErrorCreated($language: Language!) {
  classArrangementErrorCreated {
    ...ClassArrangementErrorFields
    term {
      id
      name
      class {
        id
        translation(language: $language) {
          name
        }
        course {
          id
          translation(language: $language) {
            name
          }
        }
      }
    }
  }
}
    ${ClassArrangementErrorFieldsFragmentDoc}`;

/**
 * __useSubscribeClassArrangementErrorCreatedSubscription__
 *
 * To run a query within a React component, call `useSubscribeClassArrangementErrorCreatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeClassArrangementErrorCreatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeClassArrangementErrorCreatedSubscription({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubscribeClassArrangementErrorCreatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeClassArrangementErrorCreatedSubscription, SubscribeClassArrangementErrorCreatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeClassArrangementErrorCreatedSubscription, SubscribeClassArrangementErrorCreatedSubscriptionVariables>(SubscribeClassArrangementErrorCreatedDocument, options);
      }
export type SubscribeClassArrangementErrorCreatedSubscriptionHookResult = ReturnType<typeof useSubscribeClassArrangementErrorCreatedSubscription>;
export type SubscribeClassArrangementErrorCreatedSubscriptionResult = Apollo.SubscriptionResult<SubscribeClassArrangementErrorCreatedSubscription>;
export const SubscribeClassArrangementErrorUpdatedDocument = gql`
    subscription SubscribeClassArrangementErrorUpdated($language: Language!) {
  classArrangementErrorUpdated {
    ...ClassArrangementErrorFields
    term {
      id
      name
      class {
        id
        translation(language: $language) {
          name
        }
        course {
          id
          translation(language: $language) {
            name
          }
        }
      }
    }
  }
}
    ${ClassArrangementErrorFieldsFragmentDoc}`;

/**
 * __useSubscribeClassArrangementErrorUpdatedSubscription__
 *
 * To run a query within a React component, call `useSubscribeClassArrangementErrorUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeClassArrangementErrorUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeClassArrangementErrorUpdatedSubscription({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubscribeClassArrangementErrorUpdatedSubscription(baseOptions: Apollo.SubscriptionHookOptions<SubscribeClassArrangementErrorUpdatedSubscription, SubscribeClassArrangementErrorUpdatedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeClassArrangementErrorUpdatedSubscription, SubscribeClassArrangementErrorUpdatedSubscriptionVariables>(SubscribeClassArrangementErrorUpdatedDocument, options);
      }
export type SubscribeClassArrangementErrorUpdatedSubscriptionHookResult = ReturnType<typeof useSubscribeClassArrangementErrorUpdatedSubscription>;
export type SubscribeClassArrangementErrorUpdatedSubscriptionResult = Apollo.SubscriptionResult<SubscribeClassArrangementErrorUpdatedSubscription>;
export const SubscribeClassArrangementErrorDeletedDocument = gql`
    subscription SubscribeClassArrangementErrorDeleted {
  classArrangementErrorDeleted {
    id
  }
}
    `;

/**
 * __useSubscribeClassArrangementErrorDeletedSubscription__
 *
 * To run a query within a React component, call `useSubscribeClassArrangementErrorDeletedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSubscribeClassArrangementErrorDeletedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscribeClassArrangementErrorDeletedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useSubscribeClassArrangementErrorDeletedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<SubscribeClassArrangementErrorDeletedSubscription, SubscribeClassArrangementErrorDeletedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SubscribeClassArrangementErrorDeletedSubscription, SubscribeClassArrangementErrorDeletedSubscriptionVariables>(SubscribeClassArrangementErrorDeletedDocument, options);
      }
export type SubscribeClassArrangementErrorDeletedSubscriptionHookResult = ReturnType<typeof useSubscribeClassArrangementErrorDeletedSubscription>;
export type SubscribeClassArrangementErrorDeletedSubscriptionResult = Apollo.SubscriptionResult<SubscribeClassArrangementErrorDeletedSubscription>;
export const GetMinimalClassStudentsDocument = gql`
    query GetMinimalClassStudents($id: ID!) {
  node(id: $id) {
    id
    ... on Class {
      students {
        matricId
        user {
          id
          cname
          ename
        }
      }
    }
  }
}
    `;

/**
 * __useGetMinimalClassStudentsQuery__
 *
 * To run a query within a React component, call `useGetMinimalClassStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMinimalClassStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMinimalClassStudentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMinimalClassStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetMinimalClassStudentsQuery, GetMinimalClassStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMinimalClassStudentsQuery, GetMinimalClassStudentsQueryVariables>(GetMinimalClassStudentsDocument, options);
      }
export function useGetMinimalClassStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMinimalClassStudentsQuery, GetMinimalClassStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMinimalClassStudentsQuery, GetMinimalClassStudentsQueryVariables>(GetMinimalClassStudentsDocument, options);
        }
export type GetMinimalClassStudentsQueryHookResult = ReturnType<typeof useGetMinimalClassStudentsQuery>;
export type GetMinimalClassStudentsLazyQueryHookResult = ReturnType<typeof useGetMinimalClassStudentsLazyQuery>;
export type GetMinimalClassStudentsQueryResult = Apollo.QueryResult<GetMinimalClassStudentsQuery, GetMinimalClassStudentsQueryVariables>;
export const UpdateLessonAttendancesDocument = gql`
    mutation UpdateLessonAttendances($id: ID!, $input: UpdateLessonAttendancesInput!) {
  updateLessonAttendances(id: $id, input: $input) {
    id
  }
}
    `;
export type UpdateLessonAttendancesMutationFn = Apollo.MutationFunction<UpdateLessonAttendancesMutation, UpdateLessonAttendancesMutationVariables>;

/**
 * __useUpdateLessonAttendancesMutation__
 *
 * To run a mutation, you first call `useUpdateLessonAttendancesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLessonAttendancesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLessonAttendancesMutation, { data, loading, error }] = useUpdateLessonAttendancesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLessonAttendancesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLessonAttendancesMutation, UpdateLessonAttendancesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLessonAttendancesMutation, UpdateLessonAttendancesMutationVariables>(UpdateLessonAttendancesDocument, options);
      }
export type UpdateLessonAttendancesMutationHookResult = ReturnType<typeof useUpdateLessonAttendancesMutation>;
export type UpdateLessonAttendancesMutationResult = Apollo.MutationResult<UpdateLessonAttendancesMutation>;
export type UpdateLessonAttendancesMutationOptions = Apollo.BaseMutationOptions<UpdateLessonAttendancesMutation, UpdateLessonAttendancesMutationVariables>;
export const GetLessonStudentsDocument = gql`
    query GetLessonStudents($id: ID!) {
  node(id: $id) {
    ... on Lesson {
      students {
        student {
          matricId
          user {
            id
            cname
            ename
          }
        }
        status
      }
    }
  }
}
    `;

/**
 * __useGetLessonStudentsQuery__
 *
 * To run a query within a React component, call `useGetLessonStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLessonStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLessonStudentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLessonStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetLessonStudentsQuery, GetLessonStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLessonStudentsQuery, GetLessonStudentsQueryVariables>(GetLessonStudentsDocument, options);
      }
export function useGetLessonStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLessonStudentsQuery, GetLessonStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLessonStudentsQuery, GetLessonStudentsQueryVariables>(GetLessonStudentsDocument, options);
        }
export type GetLessonStudentsQueryHookResult = ReturnType<typeof useGetLessonStudentsQuery>;
export type GetLessonStudentsLazyQueryHookResult = ReturnType<typeof useGetLessonStudentsLazyQuery>;
export type GetLessonStudentsQueryResult = Apollo.QueryResult<GetLessonStudentsQuery, GetLessonStudentsQueryVariables>;
export const GetCategoriesDocument = gql`
    query GetCategories($language: Language!) {
  categories {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;

/**
 * __useGetCategoriesQuery__
 *
 * To run a query within a React component, call `useGetCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesQuery({
 *   variables: {
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
      }
export function useGetCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesQuery, GetCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesQuery, GetCategoriesQueryVariables>(GetCategoriesDocument, options);
        }
export type GetCategoriesQueryHookResult = ReturnType<typeof useGetCategoriesQuery>;
export type GetCategoriesLazyQueryHookResult = ReturnType<typeof useGetCategoriesLazyQuery>;
export type GetCategoriesQueryResult = Apollo.QueryResult<GetCategoriesQuery, GetCategoriesQueryVariables>;
export const GetCategoryClassesDocument = gql`
    query GetCategoryClasses($id: ID!, $language: Language!) {
  node(id: $id) {
    id
    ... on Category {
      courses {
        id
        translation(language: $language) {
          name
        }
        classes {
          id
          translation(language: $language) {
            name
          }
          hasOngoingTerm
        }
      }
    }
  }
}
    `;

/**
 * __useGetCategoryClassesQuery__
 *
 * To run a query within a React component, call `useGetCategoryClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryClassesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCategoryClassesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryClassesQuery, GetCategoryClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryClassesQuery, GetCategoryClassesQueryVariables>(GetCategoryClassesDocument, options);
      }
export function useGetCategoryClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryClassesQuery, GetCategoryClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryClassesQuery, GetCategoryClassesQueryVariables>(GetCategoryClassesDocument, options);
        }
export type GetCategoryClassesQueryHookResult = ReturnType<typeof useGetCategoryClassesQuery>;
export type GetCategoryClassesLazyQueryHookResult = ReturnType<typeof useGetCategoryClassesLazyQuery>;
export type GetCategoryClassesQueryResult = Apollo.QueryResult<GetCategoryClassesQuery, GetCategoryClassesQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation CreateCategory($input: CreateCategoryInput!, $language: Language!) {
  createCategory(input: $input) {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;
export type CreateCategoryMutationFn = Apollo.MutationFunction<CreateCategoryMutation, CreateCategoryMutationVariables>;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, options);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = Apollo.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = Apollo.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($id: ID!, $input: UpdateCategoryInput!, $language: Language!) {
  updateCategory(id: $id, input: $input) {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;
export type UpdateCategoryMutationFn = Apollo.MutationFunction<UpdateCategoryMutation, UpdateCategoryMutationVariables>;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = Apollo.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const UpdateCategoriesDocument = gql`
    mutation UpdateCategories($input: UpdateCategoriesInput!, $language: Language!) {
  updateCategories(input: $input) {
    ...CategoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;
export type UpdateCategoriesMutationFn = Apollo.MutationFunction<UpdateCategoriesMutation, UpdateCategoriesMutationVariables>;

/**
 * __useUpdateCategoriesMutation__
 *
 * To run a mutation, you first call `useUpdateCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoriesMutation, { data, loading, error }] = useUpdateCategoriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoriesMutation, UpdateCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoriesMutation, UpdateCategoriesMutationVariables>(UpdateCategoriesDocument, options);
      }
export type UpdateCategoriesMutationHookResult = ReturnType<typeof useUpdateCategoriesMutation>;
export type UpdateCategoriesMutationResult = Apollo.MutationResult<UpdateCategoriesMutation>;
export type UpdateCategoriesMutationOptions = Apollo.BaseMutationOptions<UpdateCategoriesMutation, UpdateCategoriesMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($id: ID!) {
  deleteCategory(id: $id) {
    id
  }
}
    `;
export type DeleteCategoryMutationFn = Apollo.MutationFunction<DeleteCategoryMutation, DeleteCategoryMutationVariables>;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = Apollo.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const GetCategoryTranslationDocument = gql`
    query GetCategoryTranslation($id: ID!, $language: Language!) {
  node(id: $id) {
    id
    ... on Category {
      translation(language: $language) {
        name
        description
      }
    }
  }
}
    `;

/**
 * __useGetCategoryTranslationQuery__
 *
 * To run a query within a React component, call `useGetCategoryTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryTranslationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCategoryTranslationQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryTranslationQuery, GetCategoryTranslationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryTranslationQuery, GetCategoryTranslationQueryVariables>(GetCategoryTranslationDocument, options);
      }
export function useGetCategoryTranslationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryTranslationQuery, GetCategoryTranslationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryTranslationQuery, GetCategoryTranslationQueryVariables>(GetCategoryTranslationDocument, options);
        }
export type GetCategoryTranslationQueryHookResult = ReturnType<typeof useGetCategoryTranslationQuery>;
export type GetCategoryTranslationLazyQueryHookResult = ReturnType<typeof useGetCategoryTranslationLazyQuery>;
export type GetCategoryTranslationQueryResult = Apollo.QueryResult<GetCategoryTranslationQuery, GetCategoryTranslationQueryVariables>;
export const GetCategoryInfoDocument = gql`
    query GetCategoryInfo($id: ID!) {
  node(id: $id) {
    ... on Category {
      translationZH: translation(language: ZH) {
        name
        description
      }
      translationEN: translation(language: EN) {
        name
        description
      }
    }
  }
}
    `;

/**
 * __useGetCategoryInfoQuery__
 *
 * To run a query within a React component, call `useGetCategoryInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryInfoQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>(GetCategoryInfoDocument, options);
      }
export function useGetCategoryInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>(GetCategoryInfoDocument, options);
        }
export type GetCategoryInfoQueryHookResult = ReturnType<typeof useGetCategoryInfoQuery>;
export type GetCategoryInfoLazyQueryHookResult = ReturnType<typeof useGetCategoryInfoLazyQuery>;
export type GetCategoryInfoQueryResult = Apollo.QueryResult<GetCategoryInfoQuery, GetCategoryInfoQueryVariables>;
export const GetCategoryCoursesDocument = gql`
    query GetCategoryCourses($id: ID!, $language: Language!) {
  node(id: $id) {
    ... on Category {
      id
      courses {
        ...CourseFields
      }
    }
  }
}
    ${CourseFieldsFragmentDoc}`;

/**
 * __useGetCategoryCoursesQuery__
 *
 * To run a query within a React component, call `useGetCategoryCoursesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryCoursesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryCoursesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCategoryCoursesQuery(baseOptions: Apollo.QueryHookOptions<GetCategoryCoursesQuery, GetCategoryCoursesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoryCoursesQuery, GetCategoryCoursesQueryVariables>(GetCategoryCoursesDocument, options);
      }
export function useGetCategoryCoursesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoryCoursesQuery, GetCategoryCoursesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoryCoursesQuery, GetCategoryCoursesQueryVariables>(GetCategoryCoursesDocument, options);
        }
export type GetCategoryCoursesQueryHookResult = ReturnType<typeof useGetCategoryCoursesQuery>;
export type GetCategoryCoursesLazyQueryHookResult = ReturnType<typeof useGetCategoryCoursesLazyQuery>;
export type GetCategoryCoursesQueryResult = Apollo.QueryResult<GetCategoryCoursesQuery, GetCategoryCoursesQueryVariables>;
export const GetCourseTranslationDocument = gql`
    query GetCourseTranslation($id: ID!, $language: Language!) {
  node(id: $id) {
    id
    ... on Course {
      translation(language: $language) {
        name
        description
      }
    }
  }
}
    `;

/**
 * __useGetCourseTranslationQuery__
 *
 * To run a query within a React component, call `useGetCourseTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseTranslationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCourseTranslationQuery(baseOptions: Apollo.QueryHookOptions<GetCourseTranslationQuery, GetCourseTranslationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseTranslationQuery, GetCourseTranslationQueryVariables>(GetCourseTranslationDocument, options);
      }
export function useGetCourseTranslationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseTranslationQuery, GetCourseTranslationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseTranslationQuery, GetCourseTranslationQueryVariables>(GetCourseTranslationDocument, options);
        }
export type GetCourseTranslationQueryHookResult = ReturnType<typeof useGetCourseTranslationQuery>;
export type GetCourseTranslationLazyQueryHookResult = ReturnType<typeof useGetCourseTranslationLazyQuery>;
export type GetCourseTranslationQueryResult = Apollo.QueryResult<GetCourseTranslationQuery, GetCourseTranslationQueryVariables>;
export const CreateCourseDocument = gql`
    mutation CreateCourse($input: CreateCourseInput!, $language: Language!) {
  createCourse(input: $input) {
    ...CourseFields
    category {
      id
    }
  }
}
    ${CourseFieldsFragmentDoc}`;
export type CreateCourseMutationFn = Apollo.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateCourseMutation(baseOptions?: Apollo.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options);
      }
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<CreateCourseMutation, CreateCourseMutationVariables>;
export const UpdateCourseDocument = gql`
    mutation UpdateCourse($id: ID!, $input: UpdateCourseInput!, $language: Language!) {
  updateCourse(id: $id, input: $input) {
    ...CourseFields
  }
}
    ${CourseFieldsFragmentDoc}`;
export type UpdateCourseMutationFn = Apollo.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateCourseMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, options);
      }
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<UpdateCourseMutation, UpdateCourseMutationVariables>;
export const AddLecturersDocument = gql`
    mutation AddLecturers($id: ID!, $lecturers: [ID!]!) {
  addCourseLecturers(id: $id, lecturers: $lecturers) {
    lecturers {
      matricId
      user {
        id
        cname
      }
    }
  }
}
    `;
export type AddLecturersMutationFn = Apollo.MutationFunction<AddLecturersMutation, AddLecturersMutationVariables>;

/**
 * __useAddLecturersMutation__
 *
 * To run a mutation, you first call `useAddLecturersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLecturersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLecturersMutation, { data, loading, error }] = useAddLecturersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      lecturers: // value for 'lecturers'
 *   },
 * });
 */
export function useAddLecturersMutation(baseOptions?: Apollo.MutationHookOptions<AddLecturersMutation, AddLecturersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLecturersMutation, AddLecturersMutationVariables>(AddLecturersDocument, options);
      }
export type AddLecturersMutationHookResult = ReturnType<typeof useAddLecturersMutation>;
export type AddLecturersMutationResult = Apollo.MutationResult<AddLecturersMutation>;
export type AddLecturersMutationOptions = Apollo.BaseMutationOptions<AddLecturersMutation, AddLecturersMutationVariables>;
export const DeleteCourseDocument = gql`
    mutation DeleteCourse($id: ID!) {
  deleteCourse(id: $id) {
    id
  }
}
    `;
export type DeleteCourseMutationFn = Apollo.MutationFunction<DeleteCourseMutation, DeleteCourseMutationVariables>;

/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourseMutation, DeleteCourseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(DeleteCourseDocument, options);
      }
export type DeleteCourseMutationHookResult = ReturnType<typeof useDeleteCourseMutation>;
export type DeleteCourseMutationResult = Apollo.MutationResult<DeleteCourseMutation>;
export type DeleteCourseMutationOptions = Apollo.BaseMutationOptions<DeleteCourseMutation, DeleteCourseMutationVariables>;
export const GetCourseClassesDocument = gql`
    query GetCourseClasses($id: ID!, $language: Language!) {
  node(id: $id) {
    id
    ... on Course {
      classes {
        ...ClassFields
        space {
          ...SpaceFields
        }
        lecturers {
          matricId
          user {
            id
            cname
            ename
          }
        }
      }
    }
  }
}
    ${ClassFieldsFragmentDoc}
${SpaceFieldsFragmentDoc}`;

/**
 * __useGetCourseClassesQuery__
 *
 * To run a query within a React component, call `useGetCourseClassesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseClassesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseClassesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCourseClassesQuery(baseOptions: Apollo.QueryHookOptions<GetCourseClassesQuery, GetCourseClassesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseClassesQuery, GetCourseClassesQueryVariables>(GetCourseClassesDocument, options);
      }
export function useGetCourseClassesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseClassesQuery, GetCourseClassesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseClassesQuery, GetCourseClassesQueryVariables>(GetCourseClassesDocument, options);
        }
export type GetCourseClassesQueryHookResult = ReturnType<typeof useGetCourseClassesQuery>;
export type GetCourseClassesLazyQueryHookResult = ReturnType<typeof useGetCourseClassesLazyQuery>;
export type GetCourseClassesQueryResult = Apollo.QueryResult<GetCourseClassesQuery, GetCourseClassesQueryVariables>;
export const GetClassDocument = gql`
    query GetClass($id: ID!, $language: Language!) {
  node(id: $id) {
    ... on Class {
      ...ClassFields
      space {
        ...SpaceFields
      }
      lecturers {
        matricId
        user {
          id
          cname
          ename
        }
      }
      terms {
        ...TermFields
      }
    }
  }
}
    ${ClassFieldsFragmentDoc}
${SpaceFieldsFragmentDoc}
${TermFieldsFragmentDoc}`;

/**
 * __useGetClassQuery__
 *
 * To run a query within a React component, call `useGetClassQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetClassQuery(baseOptions: Apollo.QueryHookOptions<GetClassQuery, GetClassQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassQuery, GetClassQueryVariables>(GetClassDocument, options);
      }
export function useGetClassLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassQuery, GetClassQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassQuery, GetClassQueryVariables>(GetClassDocument, options);
        }
export type GetClassQueryHookResult = ReturnType<typeof useGetClassQuery>;
export type GetClassLazyQueryHookResult = ReturnType<typeof useGetClassLazyQuery>;
export type GetClassQueryResult = Apollo.QueryResult<GetClassQuery, GetClassQueryVariables>;
export const GetClassTranslationDocument = gql`
    query GetClassTranslation($id: ID!, $language: Language!) {
  node(id: $id) {
    id
    ... on Class {
      translation(language: $language) {
        name
        description
      }
    }
  }
}
    `;

/**
 * __useGetClassTranslationQuery__
 *
 * To run a query within a React component, call `useGetClassTranslationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassTranslationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassTranslationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetClassTranslationQuery(baseOptions: Apollo.QueryHookOptions<GetClassTranslationQuery, GetClassTranslationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassTranslationQuery, GetClassTranslationQueryVariables>(GetClassTranslationDocument, options);
      }
export function useGetClassTranslationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassTranslationQuery, GetClassTranslationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassTranslationQuery, GetClassTranslationQueryVariables>(GetClassTranslationDocument, options);
        }
export type GetClassTranslationQueryHookResult = ReturnType<typeof useGetClassTranslationQuery>;
export type GetClassTranslationLazyQueryHookResult = ReturnType<typeof useGetClassTranslationLazyQuery>;
export type GetClassTranslationQueryResult = Apollo.QueryResult<GetClassTranslationQuery, GetClassTranslationQueryVariables>;
export const CreateClassDocument = gql`
    mutation CreateClass($input: CreateClassInput!, $language: Language!) {
  createClass(input: $input) {
    ...ClassFields
    space {
      ...SpaceFields
    }
    lecturers {
      matricId
      user {
        id
        cname
        ename
      }
    }
  }
}
    ${ClassFieldsFragmentDoc}
${SpaceFieldsFragmentDoc}`;
export type CreateClassMutationFn = Apollo.MutationFunction<CreateClassMutation, CreateClassMutationVariables>;

/**
 * __useCreateClassMutation__
 *
 * To run a mutation, you first call `useCreateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassMutation, { data, loading, error }] = useCreateClassMutation({
 *   variables: {
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateClassMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassMutation, CreateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassMutation, CreateClassMutationVariables>(CreateClassDocument, options);
      }
export type CreateClassMutationHookResult = ReturnType<typeof useCreateClassMutation>;
export type CreateClassMutationResult = Apollo.MutationResult<CreateClassMutation>;
export type CreateClassMutationOptions = Apollo.BaseMutationOptions<CreateClassMutation, CreateClassMutationVariables>;
export const UpdateClassDocument = gql`
    mutation UpdateClass($id: ID!, $input: UpdateClassInput!, $language: Language!) {
  updateClass(id: $id, input: $input) {
    ...ClassFields
    space {
      ...SpaceFields
    }
    lecturers {
      matricId
      user {
        id
        cname
        ename
      }
    }
  }
}
    ${ClassFieldsFragmentDoc}
${SpaceFieldsFragmentDoc}`;
export type UpdateClassMutationFn = Apollo.MutationFunction<UpdateClassMutation, UpdateClassMutationVariables>;

/**
 * __useUpdateClassMutation__
 *
 * To run a mutation, you first call `useUpdateClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateClassMutation, { data, loading, error }] = useUpdateClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateClassMutation(baseOptions?: Apollo.MutationHookOptions<UpdateClassMutation, UpdateClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateClassMutation, UpdateClassMutationVariables>(UpdateClassDocument, options);
      }
export type UpdateClassMutationHookResult = ReturnType<typeof useUpdateClassMutation>;
export type UpdateClassMutationResult = Apollo.MutationResult<UpdateClassMutation>;
export type UpdateClassMutationOptions = Apollo.BaseMutationOptions<UpdateClassMutation, UpdateClassMutationVariables>;
export const DeleteClassDocument = gql`
    mutation DeleteClass($id: ID!) {
  deleteClass(id: $id) {
    id
  }
}
    `;
export type DeleteClassMutationFn = Apollo.MutationFunction<DeleteClassMutation, DeleteClassMutationVariables>;

/**
 * __useDeleteClassMutation__
 *
 * To run a mutation, you first call `useDeleteClassMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassMutation, { data, loading, error }] = useDeleteClassMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteClassMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassMutation, DeleteClassMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassMutation, DeleteClassMutationVariables>(DeleteClassDocument, options);
      }
export type DeleteClassMutationHookResult = ReturnType<typeof useDeleteClassMutation>;
export type DeleteClassMutationResult = Apollo.MutationResult<DeleteClassMutation>;
export type DeleteClassMutationOptions = Apollo.BaseMutationOptions<DeleteClassMutation, DeleteClassMutationVariables>;
export const CreateTermDocument = gql`
    mutation CreateTerm($input: CreateTermInput!) {
  createTerm(input: $input) {
    ...TermFields
  }
}
    ${TermFieldsFragmentDoc}`;
export type CreateTermMutationFn = Apollo.MutationFunction<CreateTermMutation, CreateTermMutationVariables>;

/**
 * __useCreateTermMutation__
 *
 * To run a mutation, you first call `useCreateTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTermMutation, { data, loading, error }] = useCreateTermMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTermMutation(baseOptions?: Apollo.MutationHookOptions<CreateTermMutation, CreateTermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTermMutation, CreateTermMutationVariables>(CreateTermDocument, options);
      }
export type CreateTermMutationHookResult = ReturnType<typeof useCreateTermMutation>;
export type CreateTermMutationResult = Apollo.MutationResult<CreateTermMutation>;
export type CreateTermMutationOptions = Apollo.BaseMutationOptions<CreateTermMutation, CreateTermMutationVariables>;
export const UpdateTermDocument = gql`
    mutation UpdateTerm($id: ID!, $input: UpdateTermInput!) {
  updateTerm(id: $id, input: $input) {
    ...TermFields
  }
}
    ${TermFieldsFragmentDoc}`;
export type UpdateTermMutationFn = Apollo.MutationFunction<UpdateTermMutation, UpdateTermMutationVariables>;

/**
 * __useUpdateTermMutation__
 *
 * To run a mutation, you first call `useUpdateTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTermMutation, { data, loading, error }] = useUpdateTermMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTermMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTermMutation, UpdateTermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTermMutation, UpdateTermMutationVariables>(UpdateTermDocument, options);
      }
export type UpdateTermMutationHookResult = ReturnType<typeof useUpdateTermMutation>;
export type UpdateTermMutationResult = Apollo.MutationResult<UpdateTermMutation>;
export type UpdateTermMutationOptions = Apollo.BaseMutationOptions<UpdateTermMutation, UpdateTermMutationVariables>;
export const UpdateTermStudentsDocument = gql`
    mutation UpdateTermStudents($id: ID!, $input: UpdateTermInput!) {
  updateTerm(id: $id, input: $input) {
    id
    students {
      matricId
      user {
        id
        cname
        ename
      }
    }
  }
}
    `;
export type UpdateTermStudentsMutationFn = Apollo.MutationFunction<UpdateTermStudentsMutation, UpdateTermStudentsMutationVariables>;

/**
 * __useUpdateTermStudentsMutation__
 *
 * To run a mutation, you first call `useUpdateTermStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTermStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTermStudentsMutation, { data, loading, error }] = useUpdateTermStudentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTermStudentsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTermStudentsMutation, UpdateTermStudentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTermStudentsMutation, UpdateTermStudentsMutationVariables>(UpdateTermStudentsDocument, options);
      }
export type UpdateTermStudentsMutationHookResult = ReturnType<typeof useUpdateTermStudentsMutation>;
export type UpdateTermStudentsMutationResult = Apollo.MutationResult<UpdateTermStudentsMutation>;
export type UpdateTermStudentsMutationOptions = Apollo.BaseMutationOptions<UpdateTermStudentsMutation, UpdateTermStudentsMutationVariables>;
export const DeleteTermDocument = gql`
    mutation DeleteTerm($id: ID!) {
  deleteTerm(id: $id) {
    id
  }
}
    `;
export type DeleteTermMutationFn = Apollo.MutationFunction<DeleteTermMutation, DeleteTermMutationVariables>;

/**
 * __useDeleteTermMutation__
 *
 * To run a mutation, you first call `useDeleteTermMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTermMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTermMutation, { data, loading, error }] = useDeleteTermMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTermMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTermMutation, DeleteTermMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTermMutation, DeleteTermMutationVariables>(DeleteTermDocument, options);
      }
export type DeleteTermMutationHookResult = ReturnType<typeof useDeleteTermMutation>;
export type DeleteTermMutationResult = Apollo.MutationResult<DeleteTermMutation>;
export type DeleteTermMutationOptions = Apollo.BaseMutationOptions<DeleteTermMutation, DeleteTermMutationVariables>;
export const GetClassStudentsDocument = gql`
    query GetClassStudents($id: ID!) {
  node(id: $id) {
    id
    ... on Class {
      students {
        ...ClassStudentFields
      }
    }
  }
}
    ${ClassStudentFieldsFragmentDoc}`;

/**
 * __useGetClassStudentsQuery__
 *
 * To run a query within a React component, call `useGetClassStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClassStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClassStudentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetClassStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetClassStudentsQuery, GetClassStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClassStudentsQuery, GetClassStudentsQueryVariables>(GetClassStudentsDocument, options);
      }
export function useGetClassStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClassStudentsQuery, GetClassStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClassStudentsQuery, GetClassStudentsQueryVariables>(GetClassStudentsDocument, options);
        }
export type GetClassStudentsQueryHookResult = ReturnType<typeof useGetClassStudentsQuery>;
export type GetClassStudentsLazyQueryHookResult = ReturnType<typeof useGetClassStudentsLazyQuery>;
export type GetClassStudentsQueryResult = Apollo.QueryResult<GetClassStudentsQuery, GetClassStudentsQueryVariables>;
export const CreateClassStudentsDocument = gql`
    mutation CreateClassStudents($input: CreateClassStudentsInput!) {
  createClassStudents(input: $input) {
    id
    students {
      ...ClassStudentFields
    }
  }
}
    ${ClassStudentFieldsFragmentDoc}`;
export type CreateClassStudentsMutationFn = Apollo.MutationFunction<CreateClassStudentsMutation, CreateClassStudentsMutationVariables>;

/**
 * __useCreateClassStudentsMutation__
 *
 * To run a mutation, you first call `useCreateClassStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClassStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClassStudentsMutation, { data, loading, error }] = useCreateClassStudentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateClassStudentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateClassStudentsMutation, CreateClassStudentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClassStudentsMutation, CreateClassStudentsMutationVariables>(CreateClassStudentsDocument, options);
      }
export type CreateClassStudentsMutationHookResult = ReturnType<typeof useCreateClassStudentsMutation>;
export type CreateClassStudentsMutationResult = Apollo.MutationResult<CreateClassStudentsMutation>;
export type CreateClassStudentsMutationOptions = Apollo.BaseMutationOptions<CreateClassStudentsMutation, CreateClassStudentsMutationVariables>;
export const DeleteClassStudentsDocument = gql`
    mutation DeleteClassStudents($input: DeleteClassStudentsInput!) {
  deleteClassStudents(input: $input) {
    id
    students {
      ...ClassStudentFields
    }
  }
}
    ${ClassStudentFieldsFragmentDoc}`;
export type DeleteClassStudentsMutationFn = Apollo.MutationFunction<DeleteClassStudentsMutation, DeleteClassStudentsMutationVariables>;

/**
 * __useDeleteClassStudentsMutation__
 *
 * To run a mutation, you first call `useDeleteClassStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteClassStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteClassStudentsMutation, { data, loading, error }] = useDeleteClassStudentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteClassStudentsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteClassStudentsMutation, DeleteClassStudentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteClassStudentsMutation, DeleteClassStudentsMutationVariables>(DeleteClassStudentsDocument, options);
      }
export type DeleteClassStudentsMutationHookResult = ReturnType<typeof useDeleteClassStudentsMutation>;
export type DeleteClassStudentsMutationResult = Apollo.MutationResult<DeleteClassStudentsMutation>;
export type DeleteClassStudentsMutationOptions = Apollo.BaseMutationOptions<DeleteClassStudentsMutation, DeleteClassStudentsMutationVariables>;
export const GetTermStudentsDocument = gql`
    query GetTermStudents($id: ID!) {
  node(id: $id) {
    id
    ... on Term {
      students {
        matricId
        user {
          id
          cname
          ename
        }
      }
    }
  }
}
    `;

/**
 * __useGetTermStudentsQuery__
 *
 * To run a query within a React component, call `useGetTermStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermStudentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTermStudentsQuery(baseOptions: Apollo.QueryHookOptions<GetTermStudentsQuery, GetTermStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTermStudentsQuery, GetTermStudentsQueryVariables>(GetTermStudentsDocument, options);
      }
export function useGetTermStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTermStudentsQuery, GetTermStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTermStudentsQuery, GetTermStudentsQueryVariables>(GetTermStudentsDocument, options);
        }
export type GetTermStudentsQueryHookResult = ReturnType<typeof useGetTermStudentsQuery>;
export type GetTermStudentsLazyQueryHookResult = ReturnType<typeof useGetTermStudentsLazyQuery>;
export type GetTermStudentsQueryResult = Apollo.QueryResult<GetTermStudentsQuery, GetTermStudentsQueryVariables>;
export const GetCourseRegistrationsDocument = gql`
    query GetCourseRegistrations($filter: CourseRegistrationFilterInput, $language: Language!) {
  courseRegistrations(filter: $filter) {
    id
    cname
    ename
    otherCategory
    createdAt
    categories {
      id
      translation(language: $language) {
        name
      }
    }
  }
}
    `;

/**
 * __useGetCourseRegistrationsQuery__
 *
 * To run a query within a React component, call `useGetCourseRegistrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseRegistrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseRegistrationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCourseRegistrationsQuery(baseOptions: Apollo.QueryHookOptions<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>(GetCourseRegistrationsDocument, options);
      }
export function useGetCourseRegistrationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>(GetCourseRegistrationsDocument, options);
        }
export type GetCourseRegistrationsQueryHookResult = ReturnType<typeof useGetCourseRegistrationsQuery>;
export type GetCourseRegistrationsLazyQueryHookResult = ReturnType<typeof useGetCourseRegistrationsLazyQuery>;
export type GetCourseRegistrationsQueryResult = Apollo.QueryResult<GetCourseRegistrationsQuery, GetCourseRegistrationsQueryVariables>;
export const GetCourseRegistrationDocument = gql`
    query GetCourseRegistration($id: ID!, $language: Language!) {
  node(id: $id) {
    ... on CourseRegistration {
      ...CourseRegistrationFields
      categories {
        id
        translation(language: $language) {
          name
        }
      }
    }
  }
}
    ${CourseRegistrationFieldsFragmentDoc}`;

/**
 * __useGetCourseRegistrationQuery__
 *
 * To run a query within a React component, call `useGetCourseRegistrationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseRegistrationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseRegistrationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCourseRegistrationQuery(baseOptions: Apollo.QueryHookOptions<GetCourseRegistrationQuery, GetCourseRegistrationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseRegistrationQuery, GetCourseRegistrationQueryVariables>(GetCourseRegistrationDocument, options);
      }
export function useGetCourseRegistrationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseRegistrationQuery, GetCourseRegistrationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseRegistrationQuery, GetCourseRegistrationQueryVariables>(GetCourseRegistrationDocument, options);
        }
export type GetCourseRegistrationQueryHookResult = ReturnType<typeof useGetCourseRegistrationQuery>;
export type GetCourseRegistrationLazyQueryHookResult = ReturnType<typeof useGetCourseRegistrationLazyQuery>;
export type GetCourseRegistrationQueryResult = Apollo.QueryResult<GetCourseRegistrationQuery, GetCourseRegistrationQueryVariables>;
export const UpdateCourseRegistrationDocument = gql`
    mutation UpdateCourseRegistration($id: ID!, $input: UpdateCourseRegistrationInput!, $language: Language!) {
  updateCourseRegistration(id: $id, input: $input) {
    ...CourseRegistrationFields
    categories {
      id
      translation(language: $language) {
        name
      }
    }
  }
}
    ${CourseRegistrationFieldsFragmentDoc}`;
export type UpdateCourseRegistrationMutationFn = Apollo.MutationFunction<UpdateCourseRegistrationMutation, UpdateCourseRegistrationMutationVariables>;

/**
 * __useUpdateCourseRegistrationMutation__
 *
 * To run a mutation, you first call `useUpdateCourseRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseRegistrationMutation, { data, loading, error }] = useUpdateCourseRegistrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateCourseRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCourseRegistrationMutation, UpdateCourseRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCourseRegistrationMutation, UpdateCourseRegistrationMutationVariables>(UpdateCourseRegistrationDocument, options);
      }
export type UpdateCourseRegistrationMutationHookResult = ReturnType<typeof useUpdateCourseRegistrationMutation>;
export type UpdateCourseRegistrationMutationResult = Apollo.MutationResult<UpdateCourseRegistrationMutation>;
export type UpdateCourseRegistrationMutationOptions = Apollo.BaseMutationOptions<UpdateCourseRegistrationMutation, UpdateCourseRegistrationMutationVariables>;
export const DeleteCourseRegistrationDocument = gql`
    mutation DeleteCourseRegistration($id: ID!) {
  deleteCourseRegistration(id: $id) {
    id
  }
}
    `;
export type DeleteCourseRegistrationMutationFn = Apollo.MutationFunction<DeleteCourseRegistrationMutation, DeleteCourseRegistrationMutationVariables>;

/**
 * __useDeleteCourseRegistrationMutation__
 *
 * To run a mutation, you first call `useDeleteCourseRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseRegistrationMutation, { data, loading, error }] = useDeleteCourseRegistrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCourseRegistrationMutation, DeleteCourseRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCourseRegistrationMutation, DeleteCourseRegistrationMutationVariables>(DeleteCourseRegistrationDocument, options);
      }
export type DeleteCourseRegistrationMutationHookResult = ReturnType<typeof useDeleteCourseRegistrationMutation>;
export type DeleteCourseRegistrationMutationResult = Apollo.MutationResult<DeleteCourseRegistrationMutation>;
export type DeleteCourseRegistrationMutationOptions = Apollo.BaseMutationOptions<DeleteCourseRegistrationMutation, DeleteCourseRegistrationMutationVariables>;
export const GetUserCountDashboardDocument = gql`
    query GetUserCountDashboard($filter: UserCountDashboardFilter!) {
  dashboards {
    userCount(filter: $filter)
  }
}
    `;

/**
 * __useGetUserCountDashboardQuery__
 *
 * To run a query within a React component, call `useGetUserCountDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserCountDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserCountDashboardQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetUserCountDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetUserCountDashboardQuery, GetUserCountDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserCountDashboardQuery, GetUserCountDashboardQueryVariables>(GetUserCountDashboardDocument, options);
      }
export function useGetUserCountDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserCountDashboardQuery, GetUserCountDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserCountDashboardQuery, GetUserCountDashboardQueryVariables>(GetUserCountDashboardDocument, options);
        }
export type GetUserCountDashboardQueryHookResult = ReturnType<typeof useGetUserCountDashboardQuery>;
export type GetUserCountDashboardLazyQueryHookResult = ReturnType<typeof useGetUserCountDashboardLazyQuery>;
export type GetUserCountDashboardQueryResult = Apollo.QueryResult<GetUserCountDashboardQuery, GetUserCountDashboardQueryVariables>;
export const GetIncomeDashboardDocument = gql`
    query GetIncomeDashboard($filter: IncomeDashboardFilter!) {
  dashboards {
    income(filter: $filter)
  }
}
    `;

/**
 * __useGetIncomeDashboardQuery__
 *
 * To run a query within a React component, call `useGetIncomeDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIncomeDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIncomeDashboardQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetIncomeDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetIncomeDashboardQuery, GetIncomeDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIncomeDashboardQuery, GetIncomeDashboardQueryVariables>(GetIncomeDashboardDocument, options);
      }
export function useGetIncomeDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIncomeDashboardQuery, GetIncomeDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIncomeDashboardQuery, GetIncomeDashboardQueryVariables>(GetIncomeDashboardDocument, options);
        }
export type GetIncomeDashboardQueryHookResult = ReturnType<typeof useGetIncomeDashboardQuery>;
export type GetIncomeDashboardLazyQueryHookResult = ReturnType<typeof useGetIncomeDashboardLazyQuery>;
export type GetIncomeDashboardQueryResult = Apollo.QueryResult<GetIncomeDashboardQuery, GetIncomeDashboardQueryVariables>;
export const GetActiveUserDashboardDocument = gql`
    query GetActiveUserDashboard($filter: ActiveUserDashboardFilter!) {
  dashboards {
    activeUser(filter: $filter)
  }
}
    `;

/**
 * __useGetActiveUserDashboardQuery__
 *
 * To run a query within a React component, call `useGetActiveUserDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveUserDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveUserDashboardQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetActiveUserDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetActiveUserDashboardQuery, GetActiveUserDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveUserDashboardQuery, GetActiveUserDashboardQueryVariables>(GetActiveUserDashboardDocument, options);
      }
export function useGetActiveUserDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveUserDashboardQuery, GetActiveUserDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveUserDashboardQuery, GetActiveUserDashboardQueryVariables>(GetActiveUserDashboardDocument, options);
        }
export type GetActiveUserDashboardQueryHookResult = ReturnType<typeof useGetActiveUserDashboardQuery>;
export type GetActiveUserDashboardLazyQueryHookResult = ReturnType<typeof useGetActiveUserDashboardLazyQuery>;
export type GetActiveUserDashboardQueryResult = Apollo.QueryResult<GetActiveUserDashboardQuery, GetActiveUserDashboardQueryVariables>;
export const GetCourseRegistrationDashboardDocument = gql`
    query GetCourseRegistrationDashboard($filter: CourseRegistrationDashboardFilter!, $language: Language!) {
  dashboards {
    courseRegistration(filter: $filter)
  }
  categories {
    id
    translation(language: $language) {
      name
    }
  }
}
    `;

/**
 * __useGetCourseRegistrationDashboardQuery__
 *
 * To run a query within a React component, call `useGetCourseRegistrationDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseRegistrationDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseRegistrationDashboardQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetCourseRegistrationDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetCourseRegistrationDashboardQuery, GetCourseRegistrationDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCourseRegistrationDashboardQuery, GetCourseRegistrationDashboardQueryVariables>(GetCourseRegistrationDashboardDocument, options);
      }
export function useGetCourseRegistrationDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCourseRegistrationDashboardQuery, GetCourseRegistrationDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCourseRegistrationDashboardQuery, GetCourseRegistrationDashboardQueryVariables>(GetCourseRegistrationDashboardDocument, options);
        }
export type GetCourseRegistrationDashboardQueryHookResult = ReturnType<typeof useGetCourseRegistrationDashboardQuery>;
export type GetCourseRegistrationDashboardLazyQueryHookResult = ReturnType<typeof useGetCourseRegistrationDashboardLazyQuery>;
export type GetCourseRegistrationDashboardQueryResult = Apollo.QueryResult<GetCourseRegistrationDashboardQuery, GetCourseRegistrationDashboardQueryVariables>;
export const GetLocationsDocument = gql`
    query GetLocations {
  locations {
    ...LocationFields
  }
}
    ${LocationFieldsFragmentDoc}`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetLocationDocument = gql`
    query GetLocation($id: ID!) {
  node(id: $id) {
    ... on Location {
      ...LocationFields
    }
  }
}
    ${LocationFieldsFragmentDoc}`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const GetSpacesDocument = gql`
    query GetSpaces($filter: SpacesFilterInput, $language: Language!) {
  spaces(filter: $filter) {
    ...SpaceFields
    location {
      id
      name
    }
  }
}
    ${SpaceFieldsFragmentDoc}`;

/**
 * __useGetSpacesQuery__
 *
 * To run a query within a React component, call `useGetSpacesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpacesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpacesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetSpacesQuery(baseOptions: Apollo.QueryHookOptions<GetSpacesQuery, GetSpacesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpacesQuery, GetSpacesQueryVariables>(GetSpacesDocument, options);
      }
export function useGetSpacesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpacesQuery, GetSpacesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpacesQuery, GetSpacesQueryVariables>(GetSpacesDocument, options);
        }
export type GetSpacesQueryHookResult = ReturnType<typeof useGetSpacesQuery>;
export type GetSpacesLazyQueryHookResult = ReturnType<typeof useGetSpacesLazyQuery>;
export type GetSpacesQueryResult = Apollo.QueryResult<GetSpacesQuery, GetSpacesQueryVariables>;
export const GetSpaceDocument = gql`
    query GetSpace($id: ID!, $language: Language!) {
  node(id: $id) {
    ... on Space {
      ...SpaceFields
      location {
        ...LocationFields
      }
    }
  }
}
    ${SpaceFieldsFragmentDoc}
${LocationFieldsFragmentDoc}`;

/**
 * __useGetSpaceQuery__
 *
 * To run a query within a React component, call `useGetSpaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpaceQuery({
 *   variables: {
 *      id: // value for 'id'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useGetSpaceQuery(baseOptions: Apollo.QueryHookOptions<GetSpaceQuery, GetSpaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpaceQuery, GetSpaceQueryVariables>(GetSpaceDocument, options);
      }
export function useGetSpaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpaceQuery, GetSpaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpaceQuery, GetSpaceQueryVariables>(GetSpaceDocument, options);
        }
export type GetSpaceQueryHookResult = ReturnType<typeof useGetSpaceQuery>;
export type GetSpaceLazyQueryHookResult = ReturnType<typeof useGetSpaceLazyQuery>;
export type GetSpaceQueryResult = Apollo.QueryResult<GetSpaceQuery, GetSpaceQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    ...LocationFields
  }
}
    ${LocationFieldsFragmentDoc}`;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const CreateSpaceDocument = gql`
    mutation CreateSpace($input: CreateSpaceInput!, $language: Language!) {
  createSpace(input: $input) {
    ...SpaceFields
    location {
      ...LocationFields
    }
  }
}
    ${SpaceFieldsFragmentDoc}
${LocationFieldsFragmentDoc}`;
export type CreateSpaceMutationFn = Apollo.MutationFunction<CreateSpaceMutation, CreateSpaceMutationVariables>;

/**
 * __useCreateSpaceMutation__
 *
 * To run a mutation, you first call `useCreateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSpaceMutation, { data, loading, error }] = useCreateSpaceMutation({
 *   variables: {
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useCreateSpaceMutation(baseOptions?: Apollo.MutationHookOptions<CreateSpaceMutation, CreateSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSpaceMutation, CreateSpaceMutationVariables>(CreateSpaceDocument, options);
      }
export type CreateSpaceMutationHookResult = ReturnType<typeof useCreateSpaceMutation>;
export type CreateSpaceMutationResult = Apollo.MutationResult<CreateSpaceMutation>;
export type CreateSpaceMutationOptions = Apollo.BaseMutationOptions<CreateSpaceMutation, CreateSpaceMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: ID!) {
  deleteLocation(id: $id) {
    id
  }
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($id: ID!, $input: UpdateLocationInput!) {
  updateLocation(id: $id, input: $input) {
    ...LocationFields
  }
}
    ${LocationFieldsFragmentDoc}`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const DeleteSpaceDocument = gql`
    mutation DeleteSpace($id: ID!) {
  deleteSpace(id: $id) {
    id
  }
}
    `;
export type DeleteSpaceMutationFn = Apollo.MutationFunction<DeleteSpaceMutation, DeleteSpaceMutationVariables>;

/**
 * __useDeleteSpaceMutation__
 *
 * To run a mutation, you first call `useDeleteSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSpaceMutation, { data, loading, error }] = useDeleteSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSpaceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSpaceMutation, DeleteSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSpaceMutation, DeleteSpaceMutationVariables>(DeleteSpaceDocument, options);
      }
export type DeleteSpaceMutationHookResult = ReturnType<typeof useDeleteSpaceMutation>;
export type DeleteSpaceMutationResult = Apollo.MutationResult<DeleteSpaceMutation>;
export type DeleteSpaceMutationOptions = Apollo.BaseMutationOptions<DeleteSpaceMutation, DeleteSpaceMutationVariables>;
export const UpdateSpaceDocument = gql`
    mutation UpdateSpace($id: ID!, $input: UpdateSpaceInput!, $language: Language!) {
  updateSpace(id: $id, input: $input) {
    ...SpaceFields
    location {
      ...LocationFields
    }
  }
}
    ${SpaceFieldsFragmentDoc}
${LocationFieldsFragmentDoc}`;
export type UpdateSpaceMutationFn = Apollo.MutationFunction<UpdateSpaceMutation, UpdateSpaceMutationVariables>;

/**
 * __useUpdateSpaceMutation__
 *
 * To run a mutation, you first call `useUpdateSpaceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpaceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpaceMutation, { data, loading, error }] = useUpdateSpaceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useUpdateSpaceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpaceMutation, UpdateSpaceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpaceMutation, UpdateSpaceMutationVariables>(UpdateSpaceDocument, options);
      }
export type UpdateSpaceMutationHookResult = ReturnType<typeof useUpdateSpaceMutation>;
export type UpdateSpaceMutationResult = Apollo.MutationResult<UpdateSpaceMutation>;
export type UpdateSpaceMutationOptions = Apollo.BaseMutationOptions<UpdateSpaceMutation, UpdateSpaceMutationVariables>;
export const GetReceivablesDocument = gql`
    query GetReceivables($filter: ReceivablesFilterInput) {
  receivables(filter: $filter) {
    ...ReceivableFields
  }
}
    ${ReceivableFieldsFragmentDoc}`;

/**
 * __useGetReceivablesQuery__
 *
 * To run a query within a React component, call `useGetReceivablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivablesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetReceivablesQuery(baseOptions?: Apollo.QueryHookOptions<GetReceivablesQuery, GetReceivablesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceivablesQuery, GetReceivablesQueryVariables>(GetReceivablesDocument, options);
      }
export function useGetReceivablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivablesQuery, GetReceivablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceivablesQuery, GetReceivablesQueryVariables>(GetReceivablesDocument, options);
        }
export type GetReceivablesQueryHookResult = ReturnType<typeof useGetReceivablesQuery>;
export type GetReceivablesLazyQueryHookResult = ReturnType<typeof useGetReceivablesLazyQuery>;
export type GetReceivablesQueryResult = Apollo.QueryResult<GetReceivablesQuery, GetReceivablesQueryVariables>;
export const CreateReceivablesDocument = gql`
    mutation CreateReceivables($input: [CreateReceivableInput!]!) {
  createReceivables(input: $input) {
    ...ReceivableFields
  }
}
    ${ReceivableFieldsFragmentDoc}`;
export type CreateReceivablesMutationFn = Apollo.MutationFunction<CreateReceivablesMutation, CreateReceivablesMutationVariables>;

/**
 * __useCreateReceivablesMutation__
 *
 * To run a mutation, you first call `useCreateReceivablesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReceivablesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReceivablesMutation, { data, loading, error }] = useCreateReceivablesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReceivablesMutation(baseOptions?: Apollo.MutationHookOptions<CreateReceivablesMutation, CreateReceivablesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReceivablesMutation, CreateReceivablesMutationVariables>(CreateReceivablesDocument, options);
      }
export type CreateReceivablesMutationHookResult = ReturnType<typeof useCreateReceivablesMutation>;
export type CreateReceivablesMutationResult = Apollo.MutationResult<CreateReceivablesMutation>;
export type CreateReceivablesMutationOptions = Apollo.BaseMutationOptions<CreateReceivablesMutation, CreateReceivablesMutationVariables>;
export const GetReceivableDocument = gql`
    query GetReceivable($id: ID!) {
  node(id: $id) {
    ...ReceivableFields
  }
}
    ${ReceivableFieldsFragmentDoc}`;

/**
 * __useGetReceivableQuery__
 *
 * To run a query within a React component, call `useGetReceivableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReceivableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReceivableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReceivableQuery(baseOptions: Apollo.QueryHookOptions<GetReceivableQuery, GetReceivableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReceivableQuery, GetReceivableQueryVariables>(GetReceivableDocument, options);
      }
export function useGetReceivableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReceivableQuery, GetReceivableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReceivableQuery, GetReceivableQueryVariables>(GetReceivableDocument, options);
        }
export type GetReceivableQueryHookResult = ReturnType<typeof useGetReceivableQuery>;
export type GetReceivableLazyQueryHookResult = ReturnType<typeof useGetReceivableLazyQuery>;
export type GetReceivableQueryResult = Apollo.QueryResult<GetReceivableQuery, GetReceivableQueryVariables>;
export const GetInvoicesDocument = gql`
    query GetInvoices($filter: InvoicesFilterInput) {
  invoices(filter: $filter) {
    ...InvoiceFields
  }
}
    ${InvoiceFieldsFragmentDoc}`;

/**
 * __useGetInvoicesQuery__
 *
 * To run a query within a React component, call `useGetInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
      }
export function useGetInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesQuery, GetInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesQuery, GetInvoicesQueryVariables>(GetInvoicesDocument, options);
        }
export type GetInvoicesQueryHookResult = ReturnType<typeof useGetInvoicesQuery>;
export type GetInvoicesLazyQueryHookResult = ReturnType<typeof useGetInvoicesLazyQuery>;
export type GetInvoicesQueryResult = Apollo.QueryResult<GetInvoicesQuery, GetInvoicesQueryVariables>;
export const GetInvoiceDocument = gql`
    query GetInvoice($id: ID!) {
  node(id: $id) {
    ... on Invoice {
      ...InvoiceFields
      items {
        receivable {
          ...ReceivableFields
        }
        description
      }
      payment {
        ...PaymentFields
      }
    }
  }
}
    ${InvoiceFieldsFragmentDoc}
${ReceivableFieldsFragmentDoc}
${PaymentFieldsFragmentDoc}`;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, options);
      }
export function useGetInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, options);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceQueryResult = Apollo.QueryResult<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const CreateInvoiceDocument = gql`
    mutation CreateInvoice($input: CreateInvoiceInput!) {
  createInvoice(input: $input) {
    ...InvoiceFields
    items {
      receivable {
        ...ReceivableFields
      }
      description
    }
  }
}
    ${InvoiceFieldsFragmentDoc}
${ReceivableFieldsFragmentDoc}`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
      }
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>;
export const UpdateInvoiceDocument = gql`
    mutation UpdateInvoice($id: ID!, $input: UpdateInvoiceInput!) {
  updateInvoice(id: $id, input: $input) {
    ...InvoiceFields
    items {
      receivable {
        ...ReceivableFields
      }
      description
    }
  }
}
    ${InvoiceFieldsFragmentDoc}
${ReceivableFieldsFragmentDoc}`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
      }
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;
export const DeleteInvoiceDocument = gql`
    mutation DeleteInvoice($id: ID!) {
  deleteInvoice(id: $id) {
    id
  }
}
    `;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, options);
      }
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;
export const GetPaymentsDocument = gql`
    query GetPayments($filter: PaymentsFilterInput) {
  payments(filter: $filter) {
    ...PaymentFields
    invoice {
      ...InvoiceFields
    }
  }
}
    ${PaymentFieldsFragmentDoc}
${InvoiceFieldsFragmentDoc}`;

/**
 * __useGetPaymentsQuery__
 *
 * To run a query within a React component, call `useGetPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
      }
export function useGetPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsQuery, GetPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsQuery, GetPaymentsQueryVariables>(GetPaymentsDocument, options);
        }
export type GetPaymentsQueryHookResult = ReturnType<typeof useGetPaymentsQuery>;
export type GetPaymentsLazyQueryHookResult = ReturnType<typeof useGetPaymentsLazyQuery>;
export type GetPaymentsQueryResult = Apollo.QueryResult<GetPaymentsQuery, GetPaymentsQueryVariables>;
export const GetRefundsDocument = gql`
    query GetRefunds($filter: RefundsFilterInput) {
  refunds(filter: $filter) {
    id
    amount
    createdAt
    refundedAt
    status
    invoice {
      ...InvoiceFields
    }
    payment {
      ...PaymentFields
    }
  }
}
    ${InvoiceFieldsFragmentDoc}
${PaymentFieldsFragmentDoc}`;

/**
 * __useGetRefundsQuery__
 *
 * To run a query within a React component, call `useGetRefundsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRefundsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRefundsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetRefundsQuery(baseOptions?: Apollo.QueryHookOptions<GetRefundsQuery, GetRefundsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRefundsQuery, GetRefundsQueryVariables>(GetRefundsDocument, options);
      }
export function useGetRefundsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRefundsQuery, GetRefundsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRefundsQuery, GetRefundsQueryVariables>(GetRefundsDocument, options);
        }
export type GetRefundsQueryHookResult = ReturnType<typeof useGetRefundsQuery>;
export type GetRefundsLazyQueryHookResult = ReturnType<typeof useGetRefundsLazyQuery>;
export type GetRefundsQueryResult = Apollo.QueryResult<GetRefundsQuery, GetRefundsQueryVariables>;
export const CreatePaymentProofDocument = gql`
    mutation CreatePaymentProof($input: CreatePaymentProofInput!) {
  createPaymentProof(input: $input) {
    key
    url
  }
}
    `;
export type CreatePaymentProofMutationFn = Apollo.MutationFunction<CreatePaymentProofMutation, CreatePaymentProofMutationVariables>;

/**
 * __useCreatePaymentProofMutation__
 *
 * To run a mutation, you first call `useCreatePaymentProofMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentProofMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentProofMutation, { data, loading, error }] = useCreatePaymentProofMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentProofMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentProofMutation, CreatePaymentProofMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentProofMutation, CreatePaymentProofMutationVariables>(CreatePaymentProofDocument, options);
      }
export type CreatePaymentProofMutationHookResult = ReturnType<typeof useCreatePaymentProofMutation>;
export type CreatePaymentProofMutationResult = Apollo.MutationResult<CreatePaymentProofMutation>;
export type CreatePaymentProofMutationOptions = Apollo.BaseMutationOptions<CreatePaymentProofMutation, CreatePaymentProofMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation CreatePayment($input: CreatePaymentInput!) {
  createPayment(input: $input) {
    ...PaymentFields
    invoice {
      ...InvoiceFields
      payment {
        ...PaymentFields
      }
    }
  }
}
    ${PaymentFieldsFragmentDoc}
${InvoiceFieldsFragmentDoc}`;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const GetPaymentDocument = gql`
    query GetPayment($id: ID!) {
  node(id: $id) {
    ... on Payment {
      ...PaymentFields
      invoice {
        ...InvoiceFields
      }
    }
  }
}
    ${PaymentFieldsFragmentDoc}
${InvoiceFieldsFragmentDoc}`;

/**
 * __useGetPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
      }
export function useGetPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
        }
export type GetPaymentQueryHookResult = ReturnType<typeof useGetPaymentQuery>;
export type GetPaymentLazyQueryHookResult = ReturnType<typeof useGetPaymentLazyQuery>;
export type GetPaymentQueryResult = Apollo.QueryResult<GetPaymentQuery, GetPaymentQueryVariables>;
export const GetUsersWithAddressDocument = gql`
    query GetUsersWithAddress {
  users {
    id
    cname
    ename
    address
  }
}
    `;

/**
 * __useGetUsersWithAddressQuery__
 *
 * To run a query within a React component, call `useGetUsersWithAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersWithAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersWithAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersWithAddressQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersWithAddressQuery, GetUsersWithAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersWithAddressQuery, GetUsersWithAddressQueryVariables>(GetUsersWithAddressDocument, options);
      }
export function useGetUsersWithAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersWithAddressQuery, GetUsersWithAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersWithAddressQuery, GetUsersWithAddressQueryVariables>(GetUsersWithAddressDocument, options);
        }
export type GetUsersWithAddressQueryHookResult = ReturnType<typeof useGetUsersWithAddressQuery>;
export type GetUsersWithAddressLazyQueryHookResult = ReturnType<typeof useGetUsersWithAddressLazyQuery>;
export type GetUsersWithAddressQueryResult = Apollo.QueryResult<GetUsersWithAddressQuery, GetUsersWithAddressQueryVariables>;
export const UpdateMyProfileDocument = gql`
    mutation UpdateMyProfile($input: UpdateMyProfileInput!) {
  updateMyProfile(input: $input) {
    ...UserFields
  }
}
    ${UserFieldsFragmentDoc}`;
export type UpdateMyProfileMutationFn = Apollo.MutationFunction<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>;

/**
 * __useUpdateMyProfileMutation__
 *
 * To run a mutation, you first call `useUpdateMyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyProfileMutation, { data, loading, error }] = useUpdateMyProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>(UpdateMyProfileDocument, options);
      }
export type UpdateMyProfileMutationHookResult = ReturnType<typeof useUpdateMyProfileMutation>;
export type UpdateMyProfileMutationResult = Apollo.MutationResult<UpdateMyProfileMutation>;
export type UpdateMyProfileMutationOptions = Apollo.BaseMutationOptions<UpdateMyProfileMutation, UpdateMyProfileMutationVariables>;
export const UpdateMyPasswordDocument = gql`
    mutation UpdateMyPassword($input: UpdateMyPasswordInput!) {
  updateMyPassword(input: $input) {
    id
  }
}
    `;
export type UpdateMyPasswordMutationFn = Apollo.MutationFunction<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>;

/**
 * __useUpdateMyPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateMyPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyPasswordMutation, { data, loading, error }] = useUpdateMyPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>(UpdateMyPasswordDocument, options);
      }
export type UpdateMyPasswordMutationHookResult = ReturnType<typeof useUpdateMyPasswordMutation>;
export type UpdateMyPasswordMutationResult = Apollo.MutationResult<UpdateMyPasswordMutation>;
export type UpdateMyPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateMyPasswordMutation, UpdateMyPasswordMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers($status: UserStatus, $roleFilter: UserRoleFilterInput) {
  users(status: $status, roleFilter: $roleFilter) {
    ...UserTableFields
  }
}
    ${UserTableFieldsFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      status: // value for 'status'
 *      roleFilter: // value for 'roleFilter'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetStudentsDocument = gql`
    query GetStudents($status: StudentStatus) {
  students(status: $status) {
    ...StudentFields
    user {
      ...UserTableFields
    }
  }
}
    ${StudentFieldsFragmentDoc}
${UserTableFieldsFragmentDoc}`;

/**
 * __useGetStudentsQuery__
 *
 * To run a query within a React component, call `useGetStudentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetStudentsQuery(baseOptions?: Apollo.QueryHookOptions<GetStudentsQuery, GetStudentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudentsDocument, options);
      }
export function useGetStudentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStudentsQuery, GetStudentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStudentsQuery, GetStudentsQueryVariables>(GetStudentsDocument, options);
        }
export type GetStudentsQueryHookResult = ReturnType<typeof useGetStudentsQuery>;
export type GetStudentsLazyQueryHookResult = ReturnType<typeof useGetStudentsLazyQuery>;
export type GetStudentsQueryResult = Apollo.QueryResult<GetStudentsQuery, GetStudentsQueryVariables>;
export const GetLecturersDocument = gql`
    query GetLecturers($status: LecturerStatus) {
  lecturers(status: $status) {
    ...LecturerFields
    user {
      ...UserTableFields
    }
  }
}
    ${LecturerFieldsFragmentDoc}
${UserTableFieldsFragmentDoc}`;

/**
 * __useGetLecturersQuery__
 *
 * To run a query within a React component, call `useGetLecturersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLecturersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLecturersQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetLecturersQuery(baseOptions?: Apollo.QueryHookOptions<GetLecturersQuery, GetLecturersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLecturersQuery, GetLecturersQueryVariables>(GetLecturersDocument, options);
      }
export function useGetLecturersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLecturersQuery, GetLecturersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLecturersQuery, GetLecturersQueryVariables>(GetLecturersDocument, options);
        }
export type GetLecturersQueryHookResult = ReturnType<typeof useGetLecturersQuery>;
export type GetLecturersLazyQueryHookResult = ReturnType<typeof useGetLecturersLazyQuery>;
export type GetLecturersQueryResult = Apollo.QueryResult<GetLecturersQuery, GetLecturersQueryVariables>;
export const GetAdminsDocument = gql`
    query GetAdmins($status: AdminStatus) {
  admins(status: $status) {
    ...AdminFields
    user {
      ...UserTableFields
    }
  }
}
    ${AdminFieldsFragmentDoc}
${UserTableFieldsFragmentDoc}`;

/**
 * __useGetAdminsQuery__
 *
 * To run a query within a React component, call `useGetAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminsQuery({
 *   variables: {
 *      status: // value for 'status'
 *   },
 * });
 */
export function useGetAdminsQuery(baseOptions?: Apollo.QueryHookOptions<GetAdminsQuery, GetAdminsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdminsQuery, GetAdminsQueryVariables>(GetAdminsDocument, options);
      }
export function useGetAdminsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdminsQuery, GetAdminsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdminsQuery, GetAdminsQueryVariables>(GetAdminsDocument, options);
        }
export type GetAdminsQueryHookResult = ReturnType<typeof useGetAdminsQuery>;
export type GetAdminsLazyQueryHookResult = ReturnType<typeof useGetAdminsLazyQuery>;
export type GetAdminsQueryResult = Apollo.QueryResult<GetAdminsQuery, GetAdminsQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: ID!, $isMaster: Boolean!) {
  node(id: $id) {
    ... on User {
      ...UserFields
      student {
        user {
          id
        }
        ...StudentFields
      }
      lecturer {
        user {
          id
        }
        ...LecturerFields
      }
      admin @include(if: $isMaster) {
        user {
          id
        }
        ...AdminFields
      }
    }
  }
}
    ${UserFieldsFragmentDoc}
${StudentFieldsFragmentDoc}
${LecturerFieldsFragmentDoc}
${AdminFieldsFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isMaster: // value for 'isMaster'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!, $isMaster: Boolean!) {
  createUser(input: $input) {
    ...UserFields
    student {
      user {
        id
      }
      ...StudentFields
    }
    lecturer {
      user {
        id
      }
      ...LecturerFields
    }
    admin @include(if: $isMaster) {
      user {
        id
      }
      ...AdminFields
    }
  }
}
    ${UserFieldsFragmentDoc}
${StudentFieldsFragmentDoc}
${LecturerFieldsFragmentDoc}
${AdminFieldsFragmentDoc}`;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *      isMaster: // value for 'isMaster'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $input: UpdateUserInput!, $isMaster: Boolean!) {
  updateUser(id: $id, input: $input) {
    ...UserFields
    student {
      user {
        id
      }
      ...StudentFields
    }
    lecturer {
      user {
        id
      }
      ...LecturerFields
    }
    admin @include(if: $isMaster) {
      user {
        id
      }
      ...AdminFields
    }
  }
}
    ${UserFieldsFragmentDoc}
${StudentFieldsFragmentDoc}
${LecturerFieldsFragmentDoc}
${AdminFieldsFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *      isMaster: // value for 'isMaster'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    id
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const CreateStudentDocument = gql`
    mutation CreateStudent($input: CreateStudentInput!) {
  createStudent(input: $input) {
    ...StudentFields
    user {
      ...UserFields
    }
  }
}
    ${StudentFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export type CreateStudentMutationFn = Apollo.MutationFunction<CreateStudentMutation, CreateStudentMutationVariables>;

/**
 * __useCreateStudentMutation__
 *
 * To run a mutation, you first call `useCreateStudentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStudentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStudentMutation, { data, loading, error }] = useCreateStudentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateStudentMutation(baseOptions?: Apollo.MutationHookOptions<CreateStudentMutation, CreateStudentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStudentMutation, CreateStudentMutationVariables>(CreateStudentDocument, options);
      }
export type CreateStudentMutationHookResult = ReturnType<typeof useCreateStudentMutation>;
export type CreateStudentMutationResult = Apollo.MutationResult<CreateStudentMutation>;
export type CreateStudentMutationOptions = Apollo.BaseMutationOptions<CreateStudentMutation, CreateStudentMutationVariables>;
export const CreateLecturerDocument = gql`
    mutation CreateLecturer($input: CreateLecturerInput!) {
  createLecturer(input: $input) {
    ...LecturerFields
    user {
      ...UserFields
    }
  }
}
    ${LecturerFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export type CreateLecturerMutationFn = Apollo.MutationFunction<CreateLecturerMutation, CreateLecturerMutationVariables>;

/**
 * __useCreateLecturerMutation__
 *
 * To run a mutation, you first call `useCreateLecturerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLecturerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLecturerMutation, { data, loading, error }] = useCreateLecturerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLecturerMutation(baseOptions?: Apollo.MutationHookOptions<CreateLecturerMutation, CreateLecturerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLecturerMutation, CreateLecturerMutationVariables>(CreateLecturerDocument, options);
      }
export type CreateLecturerMutationHookResult = ReturnType<typeof useCreateLecturerMutation>;
export type CreateLecturerMutationResult = Apollo.MutationResult<CreateLecturerMutation>;
export type CreateLecturerMutationOptions = Apollo.BaseMutationOptions<CreateLecturerMutation, CreateLecturerMutationVariables>;
export const CreateAdminDocument = gql`
    mutation CreateAdmin($input: CreateAdminInput!) {
  createAdmin(input: $input) {
    ...AdminFields
    user {
      ...UserFields
    }
  }
}
    ${AdminFieldsFragmentDoc}
${UserFieldsFragmentDoc}`;
export type CreateAdminMutationFn = Apollo.MutationFunction<CreateAdminMutation, CreateAdminMutationVariables>;

/**
 * __useCreateAdminMutation__
 *
 * To run a mutation, you first call `useCreateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdminMutation, { data, loading, error }] = useCreateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAdminMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdminMutation, CreateAdminMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdminMutation, CreateAdminMutationVariables>(CreateAdminDocument, options);
      }
export type CreateAdminMutationHookResult = ReturnType<typeof useCreateAdminMutation>;
export type CreateAdminMutationResult = Apollo.MutationResult<CreateAdminMutation>;
export type CreateAdminMutationOptions = Apollo.BaseMutationOptions<CreateAdminMutation, CreateAdminMutationVariables>;
export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    ...UserFields
    admin {
      user {
        id
      }
      ...AdminFields
    }
  }
}
    ${UserFieldsFragmentDoc}
${AdminFieldsFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;