import React, { useEffect, useMemo } from 'react';
import { classDeleteIdAtom, classDetailsIdAtom } from '../../../../atoms';
import {
	ClassTranslationInput,
	Day,
	Language,
	UpdateClassMutationVariables,
	useGetClassQuery,
	useGetClassTranslationQuery,
	useUpdateClassMutation
} from 'generated/api';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	IconButton,
	Theme,
	Toolbar,
	useMediaQuery
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useUserPreferenceStore } from '../../../../stores/useUserPreferenceStore';
import {
	ClassTermAutoGenerateMethod,
	useClassUpdateStore
} from '../../../../stores/useClassUpdateStore';
import ClassDetailsDialogContent from './ClassDetailsDialogContent';
import { getDiff } from '../../../../utils/getDiff';
import { SlideUp } from '../../../../share-components/Transitions/SlideUp';
import ClassDetailsDialogHeader from './ClassDetailsDialogHeader';
import { TabContext } from '@mui/lab';
import TermCreateDialog from '../../Term/TermCreateDialog';
import TermDetailsDialog from '../../Term/TermDetailsDialog';
import { Delete } from '@mui/icons-material';
import shallow from 'zustand/shallow';
import { toast } from 'react-toastify';
import ClassDeleteDialog from '../ClassDeleteDialog';

interface ClassDetailsDialogProps {}

const ClassDetailsDialog: React.FC<ClassDetailsDialogProps> = () => {
	const classId = useAtomValue(classDetailsIdAtom);
	const language = useUserPreferenceStore(state => state.language);
	const { data: classData } = useGetClassQuery({
		variables: {
			id: classId,
			language
		},
		skip: !classId
	});
	const classItem = useMemo(() => {
		if (classData?.node?.__typename !== 'Class') {
			return null;
		}
		return classData.node;
	}, [classData]);
	const { data: transData, refetch: refetchTrans } = useGetClassTranslationQuery({
		variables: {
			id: classId,
			language: language === Language.Zh ? Language.En : Language.Zh
		},
		skip: !classId,
		fetchPolicy: 'cache-and-network'
	});
	const otherTrans = useMemo(() => {
		let t = transData?.node?.__typename === 'Class' && transData.node.translation;

		if (t) {
			let { __typename, ...tr } = { ...t };
			t = tr;
		}

		return (
			t || {
				name: '',
				description: ''
			}
		);
	}, [transData]);
	const [tabIndex, setTabIndex, setAll] = useClassUpdateStore(
		state => [state.tabIndex, state.setTabIndex, state.setAll],
		shallow
	);
	const [updateClassMutation, { loading }] = useUpdateClassMutation();
	const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const setDeleteId = useUpdateAtom(classDeleteIdAtom);
	const navigate = useNavigate();

	useEffect(() => {
		if (!classItem) {
			return;
		}

		handleReset();
	}, [classItem, setAll, otherTrans]);

	const handleClose = () => {
		navigate(-1);
	};

	const handleReset = () => {
		if (!classItem) {
			return;
		}

		const termAutoGenerateMethod =
			classItem.termAutoGeneration?.__typename === 'TermAutoGenerationByMonth'
				? ClassTermAutoGenerateMethod.BY_MONTH
				: ClassTermAutoGenerateMethod.BY_MONTH;

		let termAutoGeneration = {
			[ClassTermAutoGenerateMethod.BY_MONTH]: {
				day: Day.Saturday,
				startTime: new Date(),
				endTime: new Date(new Date().getTime() + 3_600_000)
			}
		};

		if (classItem.termAutoGeneration) {
			if (classItem.termAutoGeneration.__typename === 'TermAutoGenerationByMonth') {
				termAutoGeneration[ClassTermAutoGenerateMethod.BY_MONTH] = {
					day: classItem.termAutoGeneration.day,
					startTime: classItem.termAutoGeneration.startTime,
					endTime: classItem.termAutoGeneration.endTime
				};
			}
		}

		setAll({
			translations: {
				[language as Language.Zh]: {
					name: classItem.translation.name,
					description: classItem.translation.description
				},
				[(language === Language.Zh ? Language.En : Language.Zh) as Language.En]: {
					name: otherTrans.name,
					description: otherTrans.description
				}
			},
			lecturerCommission: classItem.lecturerCommission * 100,
			studentFeePerLesson: classItem.studentFeePerLesson,
			spaceId: classItem.space?.id,
			lecturers: classItem.lecturers.map(lecturer => lecturer.user.id),
			color: classItem.color,
			termAutoGenerationAvailability: !!classItem.termAutoGeneration,
			termAutoGenerateMethod: termAutoGenerateMethod,
			termAutoGeneration: termAutoGeneration
		});
	};

	const handleDelete = () => {
		setDeleteId(classId);
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!classItem || !transData) {
			return;
		}

		const {
			spaceId,
			termAutoGenerationAvailability,
			termAutoGenerateMethod,
			termAutoGeneration,
			...data
		} = useClassUpdateStore.getState().getValues();

		const {
			id,
			translation,
			hasOngoingTerm,
			lecturers,
			space,
			terms,
			termAutoGeneration: classTermAutoGeneration,
			__typename,
			...prev
		} = classItem;

		const input = getDiff(
			Object.assign(
				{},
				{
					...prev,
					translations: {
						[language]: {
							name: translation.name,
							description: translation.description
						},
						[language === Language.Zh ? Language.En : Language.Zh]: {
							name: otherTrans.name,
							description: otherTrans.description
						}
					},
					lecturers: lecturers.map(lecturer => lecturer.user.id),
					spaceId: space?.id ?? null,
					termAutoGeneration:
						classTermAutoGeneration?.__typename === 'TermAutoGenerationByMonth'
							? {
									byMonth: {
										startTime: classTermAutoGeneration.startTime,
										endTime: classTermAutoGeneration.endTime,
										day: classTermAutoGeneration.day
									}
							  }
							: null
					// template: prev.template ?? null
				}
			),
			{
				...data,
				// template: templateAvailability ? data.template : null,
				spaceId: spaceId === '0' ? null : spaceId ?? null,
				lecturerCommission: data.lecturerCommission / 100,
				termAutoGeneration:
					termAutoGenerationAvailability && termAutoGeneration[ClassTermAutoGenerateMethod.BY_MONTH]
						? {
								byMonth: termAutoGeneration[ClassTermAutoGenerateMethod.BY_MONTH]!
						  }
						: null
			}
		);

		if (Object.keys(input).length === 0) {
			toast('你没有改到什么东西哦!');
			return;
		}

		const { translations, ...restOfInput } = input;

		const variables: UpdateClassMutationVariables = {
			id: classId,
			input: {
				...restOfInput,
				...(translations
					? {
							translations: (Object.keys(translations) as Language[]).map(language => ({
								...(translations![language] as Omit<ClassTranslationInput, 'language'>),
								language
							}))
					  }
					: {})
				// template: template
				// 	? {
				// 			recurrence: template.recurrence,
				// 			lessonCount: template.lessonCount,
				// 			time: {
				// 				startTime: template.startTime,
				// 				endTime: template.endTime
				// 			}
				// 	  }
				// 	: template
			},
			language: useUserPreferenceStore.getState().language
		};

		try {
			await updateClassMutation({
				variables
			});
			toast.success('更改班级成功!');
			if (variables.input.translations) {
				await refetchTrans();
			}
		} catch (err) {
			toast.error(`更改班级失败! ${err}`);
		}
	};

	return (
		<Dialog
			open={!!classItem}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth
			fullScreen={isXS}
			TransitionComponent={isXS ? SlideUp : undefined}
			PaperProps={{
				sx: { overflowY: 'hidden' }
			}}
		>
			<Box
				component="form"
				onSubmit={handleSubmit}
				sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
			>
				<TabContext value={tabIndex}>
					<ClassDetailsDialogHeader
						title={classItem?.translation.name || ''}
						isXS={isXS}
						handleReset={handleReset}
						handleClose={handleClose}
						setTabIndex={setTabIndex}
						loading={loading}
					/>
					<DialogContent
						sx={{
							maxHeight: {
								xs: 'calc(100vh - 112px)',
								sm: 'calc(100vh - 200px)'
							}
						}}
					>
						<ClassDetailsDialogContent
							classId={classId}
							terms={classItem?.terms || []}
							isXS={isXS}
							setTabIndex={setTabIndex}
						/>
					</DialogContent>
				</TabContext>
				{!isXS && (
					<Toolbar>
						<DialogActions sx={{ width: '100%', p: 0 }}>
							<IconButton onClick={handleDelete} sx={{ ml: -1, mr: 'auto' }} color="error">
								<Delete />
							</IconButton>
							<Button color="primary" onClick={handleClose}>
								取消
							</Button>
							<Button color="primary" onClick={handleReset}>
								重置
							</Button>
							<Button
								variant="contained"
								color="primary"
								type="submit"
								disabled={loading}
								sx={{ color: theme => (theme.palette.mode === 'light' ? '#fff' : '#000') }}
							>
								保存
							</Button>
						</DialogActions>
					</Toolbar>
				)}
			</Box>
			<ClassDeleteDialog />
			<TermCreateDialog />
			<TermDetailsDialog />
		</Dialog>
	);
};

export const ClassDetailsDialogRoute = () => {
	const { id } = useParams();
	const setClassId = useUpdateAtom(classDetailsIdAtom);

	useEffect(() => {
		if (!id) {
			return;
		}
		setClassId(id);
		return () => {
			setClassId('');
		};
	}, [id]);

	return <></>;
};

export default ClassDetailsDialog;
