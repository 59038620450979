import create from 'zustand';
import { persist } from 'zustand/middleware';

type DrawerMinimizedStoreState = {
	minimized: boolean;
	setMinimized: (minimized: boolean) => void;
};

export const useDrawerMinimizedStore = create<DrawerMinimizedStoreState>()(
	persist(
		(set, _) => ({
			minimized: false,
			setMinimized: minimized => set({ minimized })
		}),
		{
			name: 'drawer-minimized'
		}
	)
);
