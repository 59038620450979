import React, { useMemo, useRef, useState } from 'react';
import { Box, Breadcrumbs, Button, Link, Typography } from '@mui/material';
import { NavigateNext } from '@mui/icons-material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
	GetInvoicesDocument,
	GetInvoicesQuery,
	GetReceivablesDocument,
	GetReceivablesQuery,
	InvoiceItemInput,
	InvoiceStatus,
	useCreateInvoiceMutation,
	useCreateReceivablesMutation,
	useGetInvoicesQuery
} from '../../../generated/api';
import { useInvoiceCreateStore } from '../../../stores/useInvoiceCreateStore';
import shallow from 'zustand/shallow';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import { Relay } from '../../../utils/relay';
import InvoiceForm from '../../../share-components/Invoice/InvoiceForm';

interface InvoiceCreatePageProps {}

const InvoiceCreatePage: React.FC<InvoiceCreatePageProps> = () => {
	const [
		debtor,
		createAt,
		discount,
		tax,
		items,
		setDebtor,
		setCreateAt,
		setDiscount,
		setTax,
		addItems,
		setItemTitle,
		setItemDescription,
		setItemService,
		setItemQuantity,
		setItemPrice,
		removeItem,
		reset
	] = useInvoiceCreateStore(
		state => [
			state.debtor,
			state.createAt,
			state.discount,
			state.tax,
			state.items,
			state.setDebtor,
			state.setCreateAt,
			state.setDiscount,
			state.setTax,
			state.addItems,
			state.setItemTitle,
			state.setItemDescription,
			state.setItemService,
			state.setItemQuantity,
			state.setItemPrice,
			state.removeItem,
			state.reset
		],
		shallow
	);
	const { data } = useGetInvoicesQuery();
	const latestInvoice = useMemo(
		() =>
			data
				? data.invoices
						.map(invoice => ({ ...invoice, id: Relay.unmarshalId(invoice.id) }))
						.sort((a, b) => (a.id > b.id ? 1 : -1))[data.invoices.length - 1]
				: null,
		[data]
	);
	const submitEvent = useRef('');
	const [loading, setLoading] = useState(false);
	const [createReceivablesMutation] = useCreateReceivablesMutation({
		update: (cache, result) => {
			const createdReceivables = result.data?.createReceivables;
			if (!createdReceivables) {
				return;
			}
			const data = cache.readQuery<GetReceivablesQuery>({ query: GetReceivablesDocument });
			if (data) {
				cache.writeQuery<GetReceivablesQuery>({
					query: GetReceivablesDocument,
					data: {
						receivables: [...data.receivables, ...createdReceivables]
					}
				});
			}
		}
	});
	const [createInvoiceMutation] = useCreateInvoiceMutation({
		update: (cache, result) => {
			const createdInvoice = result.data?.createInvoice;
			if (!createdInvoice) {
				return;
			}
			const data = cache.readQuery<GetInvoicesQuery>({ query: GetInvoicesDocument });
			if (data) {
				cache.writeQuery<GetInvoicesQuery>({
					query: GetInvoicesDocument,
					data: {
						invoices: [...data.invoices, createdInvoice]
					}
				});
			}
		}
	});
	const navigate = useNavigate();

	const handleSubmitClick = (name: string) => () => {
		submitEvent.current = name;
	};

	const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();

		if (!debtor) {
			toast.error('请选择一个付款者');
			return;
		}

		if (items.length === 0) {
			toast.error('请至少添加一个ITEM');
			return;
		}

		if (submitEvent.current !== 'draft' && submitEvent.current !== 'create') {
			return;
		}

		setLoading(true);

		try {
			const invoiceItems: InvoiceItemInput[] = [];
			const newReceivables: typeof items = [];

			items.forEach(item => {
				if (!item.receivable) {
					newReceivables.push(item);
				} else {
					invoiceItems.push({
						id: item.id,
						description: item.description
					});
				}
			});

			if (newReceivables.length > 0) {
				const res = await createReceivablesMutation({
					variables: {
						input: newReceivables.map(receivable => ({
							id: receivable.id,
							name: receivable.title,
							type: receivable.service!,
							price: receivable.price,
							quantity: receivable.quantity,
							debtor: debtor.id
						}))
					}
				});
				if (res.data?.createReceivables) {
					res.data.createReceivables.forEach(receivable => {
						invoiceItems.push({
							id: receivable.id,
							description:
								items.find(item => Relay.unmarshalId(receivable.id) === item.id)?.description || ''
						});
					});
				}
			}

			const res = await createInvoiceMutation({
				variables: {
					input: {
						userId: debtor.id,
						items: invoiceItems,
						discount: 0,
						tax: 0,
						createdAt: createAt,
						status: submitEvent.current === 'create' ? InvoiceStatus.Open : InvoiceStatus.Draft
					}
				}
			});

			reset();

			const invoice = res.data?.createInvoice;

			if (invoice) {
				navigate(`../${Relay.unmarshalId(invoice.id)}`, { replace: true });
			}
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(err.message);
			} else {
				toast.error(`${err}`);
			}
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box sx={{ mt: 2, mb: 8 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
					<Link
						to="/"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						主页
					</Link>
					<Link
						to=".."
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						发票
					</Link>
					<Typography variant="body2" sx={{ fontSize: '1.1rem', opacity: 0.7 }}>
						创建
					</Typography>
				</Breadcrumbs>
			</Box>
			<form onSubmit={handleSubmit}>
				<InvoiceForm
					id={getNewInvoiceId(latestInvoice?.id || '')}
					debtor={debtor}
					createAt={createAt}
					discount={discount}
					tax={tax}
					items={items}
					setDebtor={setDebtor}
					setCreateAt={setCreateAt}
					setDiscount={setDiscount}
					setTax={setTax}
					setItemTitle={setItemTitle}
					setItemDescription={setItemDescription}
					setItemService={setItemService}
					setItemQuantity={setItemQuantity}
					setItemPrice={setItemPrice}
					addItems={addItems}
					removeItem={removeItem}
				/>
				<Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
					<Button
						type="submit"
						name="draft"
						variant="contained"
						color="inherit"
						size="large"
						onClick={handleSubmitClick('draft')}
						disabled={loading}
						sx={{ mr: 2, color: '#000', borderRadius: '8px' }}
					>
						保存为草稿
					</Button>
					<Button
						type="submit"
						name="create"
						variant="contained"
						size="large"
						onClick={handleSubmitClick('create')}
						disabled={loading}
						sx={{
							borderRadius: '8px',
							color: theme => (theme.palette.mode === 'light' ? '#fff' : '#000')
						}}
					>
						创建并发送
					</Button>
				</Box>
			</form>
		</Box>
	);
};

const getNewInvoiceId = (id: string): string => {
	if (id.length < 8) {
		return `EED${new Date().getFullYear().toString().slice(2)}001`;
	}

	const yearNow = new Date().getFullYear();

	let newId = 'INV' + yearNow.toString().slice(2);

	const year = id.slice(3, 5);
	let count = id.slice(5);

	if (yearNow.toString().slice(2) !== year) {
		newId += '001';
	} else {
		newId += (Number(count) + 1).toString().padStart(3, '0');
	}

	return newId;
};

export default InvoiceCreatePage;
