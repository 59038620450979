import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Dialog, DialogActions, MenuItem, TextField } from '@mui/material';
import { getDaysInMonth } from '../../utils/getDaysInMonth';
import { StaticDatePicker } from '@mui/x-date-pickers';
import {
	ClassTermAutoGenerateMethod,
	ClassTermAutoGeneration
} from '../../stores/useClassUpdateStore';
import { dayToNumber } from '../../utils/daytoNumber';

interface ClassTermAutoGenerationCreateTermAndLessonsProps {
	termAutoGeneration: ClassTermAutoGeneration;
	setCreateTerm: (createTerm: { startFrom: Date; numberOfLessons: number }) => void;
}

const ClassTermAutoGenerationCreateTermAndLessons: React.FC<
	ClassTermAutoGenerationCreateTermAndLessonsProps
> = ({ termAutoGeneration, setCreateTerm }) => {
	const [startingFrom, setStartingFrom] = useState(0);
	const [displayText, setDisplayText] = useState(<></>);
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const [datePickerOpen, setDatePickerOpen] = useState(false);
	const today = useMemo(() => new Date(), []);
	const dayText = termAutoGeneration?.[ClassTermAutoGenerateMethod.BY_MONTH]?.day;
	const day = useMemo(() => (dayText ? dayToNumber(dayText) : null), [dayText]);

	const nextMonth = useMemo(() => new Date(today.getFullYear(), today.getMonth() + 1, 1), [today]);
	const monthAfterNextMonth = useMemo(
		() => new Date(today.getFullYear(), today.getMonth() + 2, 1),
		[today]
	);

	const handleStartingFromChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setStartingFrom(Number(event.target.value));
	};

	const handleDatePickerClose = useCallback(() => {
		setDatePickerOpen(false);
	}, [setDatePickerOpen]);

	useEffect(() => {
		if (day === null) {
			return;
		}
		let numberOfLessons = 0;
		switch (startingFrom) {
			case 0:
				numberOfLessons = getDaysInMonth(day, today.getMonth(), today.getFullYear());
				setDisplayText(
					<>
						从这个月开始 ({numberOfLessons}
						堂课) ({`${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`})
					</>
				);
				setCreateTerm({
					startFrom: new Date(today.getFullYear(), today.getMonth(), 1),
					numberOfLessons
				});
				break;
			case 1:
				numberOfLessons = getDaysInMonth(day, nextMonth.getMonth(), nextMonth.getFullYear());
				setDisplayText(
					<>
						从下个月开始 ({numberOfLessons}
						堂课) (
						{`${(nextMonth.getMonth() + 1).toString().padStart(2, '0')}/${nextMonth.getFullYear()}`}
						)
					</>
				);
				setCreateTerm({
					startFrom: nextMonth,
					numberOfLessons
				});
				break;
			case 2:
				numberOfLessons = getDaysInMonth(
					day,
					monthAfterNextMonth.getMonth(),
					monthAfterNextMonth.getFullYear()
				);
				setDisplayText(
					<>
						从后个月开始 ({numberOfLessons}
						堂课) (
						{`${(monthAfterNextMonth.getMonth() + 1)
							.toString()
							.padStart(2, '0')}/${monthAfterNextMonth.getFullYear()}`}
						)
					</>
				);
				setCreateTerm({
					startFrom: monthAfterNextMonth,
					numberOfLessons
				});
				break;
			case 3:
				numberOfLessons = getDaysInMonth(day, selectedDate.getMonth(), selectedDate.getFullYear());
				setDisplayText(
					<>
						从
						{`${(selectedDate.getMonth() + 1)
							.toString()
							.padStart(2, '0')}/${selectedDate.getFullYear()}`}
						开始 ({numberOfLessons}
						堂课)
					</>
				);
				setCreateTerm({
					startFrom: selectedDate,
					numberOfLessons
				});
				break;
		}
	}, [startingFrom, selectedDate, day]);

	if (day === null) {
		return null;
	}

	return (
		<Box>
			<TextField
				select
				label="同时创建学期与课次"
				fullWidth
				value={startingFrom}
				onChange={handleStartingFromChange}
				required
				SelectProps={{
					renderValue: () => displayText
				}}
				sx={{ mt: 2 }}
			>
				<MenuItem value={0}>
					从这个月开始 ({getDaysInMonth(day, today.getMonth(), today.getFullYear())}堂课) (
					{`${(today.getMonth() + 1).toString().padStart(2, '0')}/${today.getFullYear()}`})
				</MenuItem>
				<MenuItem value={1}>
					从下个月开始 ({getDaysInMonth(day, nextMonth.getMonth(), nextMonth.getFullYear())}
					堂课) (
					{`${(nextMonth.getMonth() + 1).toString().padStart(2, '0')}/${nextMonth.getFullYear()}`})
				</MenuItem>
				<MenuItem value={2}>
					从后个月开始 (
					{getDaysInMonth(day, monthAfterNextMonth.getMonth(), monthAfterNextMonth.getFullYear())}
					堂课) (
					{`${(monthAfterNextMonth.getMonth() + 1)
						.toString()
						.padStart(2, '0')}/${monthAfterNextMonth.getFullYear()}`}
					)
				</MenuItem>
				<MenuItem value={3} onClick={() => setDatePickerOpen(true)}>
					其它
				</MenuItem>
			</TextField>
			<Dialog open={datePickerOpen} onClose={handleDatePickerClose}>
				<StaticDatePicker
					views={['year', 'month']}
					openTo="month"
					value={selectedDate}
					onChange={date =>
						date ? setSelectedDate(new Date(date.getFullYear(), date.getMonth(), 1)) : undefined
					}
					renderInput={params => <TextField {...params} />}
					componentsProps={{
						actionBar: {
							actions: []
						}
					}}
					minDate={today}
				/>
				<DialogActions>
					<Button onClick={handleDatePickerClose}>关闭</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};

export default ClassTermAutoGenerationCreateTermAndLessons;
