import { TabList, TabPanel } from '@mui/lab';
import React from 'react';
import { Box, Button, Tab } from '@mui/material';
import ClassForm from '../../../../share-components/Class/ClassForm';
import { useClassUpdateStore } from '../../../../stores/useClassUpdateStore';
import { styled } from '@mui/material/styles';
import ClassDetailsDialogTerms from './ClassDetailsDialogTerms';
import { TermFieldsFragment } from '../../../../generated/api';
import ClassDetailsDialogStudents from './ClassDetailsDialogStudents';
import ClassTermAutoGenerationForm from '../../../../share-components/Class/ClassTermAutoGenerationForm';

interface ClassUpdateDialogContentProps {
	classId: string;
	terms: TermFieldsFragment[];
	isXS: boolean;
	setTabIndex: (tabIndex: string) => void;
}

const ClassDetailsDialogContent: React.FC<ClassUpdateDialogContentProps> = ({
	classId,
	terms,
	isXS,
	setTabIndex
}) => {
	const {
		translations,
		studentFeePerLesson,
		lecturerCommission,
		spaceId,
		lecturers,
		color,
		termAutoGenerationAvailability,
		termAutoGenerateMethod,
		termAutoGeneration,
		setName,
		setDescription,
		setStudentFeePerLesson,
		setLecturerCommission,
		setSpaceId,
		setLecturers,
		setColor,
		setTermAutoGenerationAvailability,
		setTermAutoGenerateMethod,
		setTermAutoGeneration
	} = useClassUpdateStore();

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabIndex(newValue);
	};

	return (
		<>
			{!isXS && (
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<TabList onChange={handleChange} aria-label="班级资料类别" centered>
						<Tab label="基本资料" value="1" />
						<Tab label="学期生成" value="2" />
						<Tab label="班级学期" value="3" />
						<Tab label="班级学生" value="4" />
					</TabList>
				</Box>
			)}
			<StyledTabPanel value="1">
				<ClassForm
					translations={translations}
					studentFeePerLesson={studentFeePerLesson}
					lecturerCommission={lecturerCommission}
					spaceId={spaceId}
					lecturers={lecturers}
					color={color}
					setName={setName}
					setDescription={setDescription}
					setStudentFeePerLesson={setStudentFeePerLesson}
					setLecturerCommission={setLecturerCommission}
					setSpaceId={setSpaceId}
					setLecturers={setLecturers}
					setColor={setColor}
				/>
			</StyledTabPanel>
			<StyledTabPanel value="2">
				<ClassTermAutoGenerationForm
					termAutoGenerationAvailability={termAutoGenerationAvailability}
					setTermAutoGenerationAvailability={setTermAutoGenerationAvailability}
					termAutoGenerateMethod={termAutoGenerateMethod}
					setTermAutoGenerateMethod={setTermAutoGenerateMethod}
					termAutoGeneration={termAutoGeneration}
					setTermAutoGeneration={setTermAutoGeneration}
				/>
				{/*<ClassTemplateForm*/}
				{/*	templateAvailability={templateAvailability}*/}
				{/*	template={template}*/}
				{/*	setTemplateAvailability={setTemplateAvailability}*/}
				{/*	setTemplateRecurrence={setTemplateRecurrence}*/}
				{/*	setTemplateLessonCount={setTemplateLessonCount}*/}
				{/*	setTemplateStartTime={setTemplateStartTime}*/}
				{/*	setTemplateEndTime={setTemplateEndTime}*/}
				{/*/>*/}
			</StyledTabPanel>
			<StyledTabPanel value="3">
				<ClassDetailsDialogTerms classId={classId} terms={terms} />
			</StyledTabPanel>
			<StyledTabPanel value="4">
				<ClassDetailsDialogStudents classId={classId} />
			</StyledTabPanel>
		</>
	);
};

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
	paddingLeft: 0,
	paddingRight: 0,
	[theme.breakpoints.down('sm')]: {
		paddingTop: 0,
		paddingBottom: 0
	},
	// height: 'calc(100vh - 152px)',
	// [theme.breakpoints.up('sm')]: {
	// 	height: 'calc(100vh - 270px)'
	// },
	minHeight: '50vh',
	overflowY: 'auto'
}));

export default ClassDetailsDialogContent;
