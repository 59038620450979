import create from 'zustand';

type TokenStoreState = {
	token: string;
	expiry: number;
	setToken: (token: string) => void;
	setExpiry: (expiry: number) => void;
};

export const useTokenStore = create<TokenStoreState>(set => ({
	token: '',
	expiry: 0,
	setToken: token => set({ token }),
	setExpiry: expiry => set({ expiry })
}));
