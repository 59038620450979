import React, { useEffect, useState } from 'react';
import {
	AppBar,
	Box,
	CircularProgress,
	Dialog,
	DialogContent,
	IconButton,
	ListItemIcon,
	Menu,
	MenuItem,
	SxProps,
	Tab,
	Toolbar,
	Typography
} from '@mui/material';
import { ArrowBack, Delete, MoreVert, Refresh } from '@mui/icons-material';
import { useGetUserQuery, User } from 'generated/api';
import UserDetails from './UserDetails';
import UserStudentDetails from './UserStudentDetails';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import UserDetailsUpdateDialog from './UserDetailsUpdateDialog';
import UserLecturerDetails from './UserLecturerDetails';
import UserAdminDetails from './UserAdminDetails';
import UserDeleteDialog from '../UserDeleteDialog';
import { isMasterAtom, selectedUserIdAtom, userDeleteDialogOpenAtom } from '../../../atoms';
import { useUserDetailsRoleCreateStore } from '../../../stores/useUserDetailsRoleCreateStore';
import { useRefetchFunctionsStore } from '../../../stores/useRefetchFunctionsStore';
import { useUserPreferenceStore } from '../../../stores/useUserPreferenceStore';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { styled } from '@mui/material/styles';
import { SlideUp } from '../../../share-components/Transitions/SlideUp';
import shallow from 'zustand/shallow';
import { useNavigate } from 'react-router-dom';

function a11yProps(index: any) {
	return {
		id: `full-width-tab-${index}`,
		'aria-controls': `full-width-tabpanel-${index}`
	};
}

interface UserDetailsDialogProps {}

const UserDetailsDialog: React.FC<UserDetailsDialogProps> = () => {
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const selectedUserId = useAtomValue(selectedUserIdAtom);
	const isMaster = useAtomValue(isMasterAtom);
	const { data, loading, error, refetch, startPolling, stopPolling } = useGetUserQuery({
		variables: { id: selectedUserId, isMaster },
		skip: selectedUserId === ''
	});
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const user = data?.node as User;
	const [tabIndex, setTabIndex] = useState('1');
	const setUserDeleteDialogOpen = useUpdateAtom(userDeleteDialogOpenAtom);
	const [addRefetch, removeRefetch] = useRefetchFunctionsStore(
		state => [state.addFunction, state.removeFunction],
		shallow
	);

	useEffect(() => {
		setOpen(selectedUserId !== '');
	}, [selectedUserId]);

	useEffect(() => {
		if (open) {
			addRefetch(refetch);
		} else {
			removeRefetch(refetch);
		}
	}, [refetch, open]);

	useEffect(() => {
		return () => {
			useUserDetailsRoleCreateStore.getState().reset();
		};
	}, []);

	useEffect(() => {
		if (open) {
			const pollingInterval = useUserPreferenceStore.getState().pollingInterval;

			if (!pollingInterval) {
				return;
			}

			startPolling(pollingInterval);
		} else {
			stopPolling();
		}

		return () => {
			stopPolling();
		};
	}, [open, startPolling, stopPolling]);

	const handleClose = () => {
		navigate(-1);
	};

	const openMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const closeMenu = () => {
		setAnchorEl(null);
	};

	const clickDelete = () => {
		setUserDeleteDialogOpen(true);
		closeMenu();
		// setDeleteUser(selectedUser);
	};

	const handleChange = (event: React.ChangeEvent<{}>, value: string) => {
		setTabIndex(value);
	};

	const handleRefresh = async () => {
		await useRefetchFunctionsStore.getState().refresh();
	};

	return (
		<Dialog
			fullScreen
			open={open}
			onClose={handleClose}
			TransitionComponent={SlideUp}
			scroll="paper"
		>
			<TabContext value={tabIndex}>
				<StyledAppBar position="sticky">
					<Toolbar sx={childStyle}>
						<IconButton
							edge="end"
							color="inherit"
							onClick={handleClose}
							aria-label="back"
							size="large"
						>
							<ArrowBack />
						</IconButton>
						<StyledTitle>
							{user?.cname} {user?.ename}
						</StyledTitle>
						<Box sx={{ marginLeft: 'auto' }}>
							<IconButton color="inherit" aria-label="refresh" onClick={handleRefresh} size="large">
								<Refresh />
							</IconButton>
							<IconButton
								edge="end"
								color="inherit"
								onClick={openMenu}
								aria-label="menu"
								size="large"
							>
								<MoreVert />
							</IconButton>
							<Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
								<MenuItem onClick={clickDelete} sx={{ minHeight: 'unset' }}>
									<ListItemIcon sx={{ minWidth: '30px' }}>
										<Delete fontSize="small" />
									</ListItemIcon>
									<Typography variant="inherit">删除用户</Typography>
								</MenuItem>
							</Menu>
						</Box>
					</Toolbar>
					<Toolbar sx={{ alignItems: 'flex-end', marginTop: '-8px' }}>
						{/*
						//@ts-ignore */}
						<StyledTabs centered onChange={handleChange}>
							<StyledTab label="个人信息" value="1" {...a11yProps('1')} />
							<StyledTab label="学生信息" value="2" {...a11yProps('2')} />
							<StyledTab label="老师信息" value="3" {...a11yProps('3')} />
							<StyledTab label="管理信息" value="4" {...a11yProps('4')} />
						</StyledTabs>
					</Toolbar>
				</StyledAppBar>
				<StyledDialogContent>
					<Box sx={childStyle}>
						{user ? (
							<>
								<TabPanel value="1">
									<UserDetails user={user} />
								</TabPanel>
								<TabPanel value="2">
									<UserStudentDetails user={user} />
								</TabPanel>
								<TabPanel value="3">
									<UserLecturerDetails user={user} />
								</TabPanel>
								<TabPanel value="4">
									<UserAdminDetails user={user} />
								</TabPanel>
								<UserDetailsUpdateDialog user={user} />
							</>
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									height: '100%'
								}}
							>
								{loading ? <CircularProgress /> : error ? error.message : <></>}
							</div>
						)}
					</Box>
				</StyledDialogContent>
				<UserDeleteDialog user={user} />
			</TabContext>
		</Dialog>
	);
};

const childStyle: SxProps = { width: '100%', maxWidth: '800px' };

const StyledTabs = styled(TabList)({
	color: 'white',
	'& .MuiTabs-indicator': {
		backgroundColor: 'white'
	}
});

const StyledTab = styled(Tab)({
	fontSize: '1rem',
	color: '#fff',
	opacity: '0.8',
	'&.Mui-selected': {
		color: '#fff',
		opacity: 1
	}
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	background:
		theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.background.paper
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
	marginLeft: theme.spacing(2),
	flex: 1,
	fontSize: '1.1em'
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	display: 'flex',
	justifyContent: 'center',
	padding: 0,
	background:
		theme.palette.mode === 'light'
			? theme.palette.background.paper
			: theme.palette.background.default
}));

export default UserDetailsDialog;
