import gql from 'graphql-tag';

const LOCATION_FIELDS = gql`
	fragment LocationFields on Location {
		id
		name
		address
		contacts
	}
`;

export const GET_LOCATIONS = gql`
	query GetLocations {
		locations {
			...LocationFields
		}
	}
	${LOCATION_FIELDS}
`;

export const GET_LOCATION = gql`
	query GetLocation($id: ID!) {
		node(id: $id) {
			... on Location {
				...LocationFields
			}
		}
	}
`;

export const SPACE_FIELDS = gql`
	fragment SpaceFields on Space {
		id
		translation(language: $language) {
			name
		}
		available
	}
`;

export const GET_SPACES = gql`
	query GetSpaces($filter: SpacesFilterInput, $language: Language!) {
		spaces(filter: $filter) {
			...SpaceFields
			location {
				id
				name
			}
		}
	}
	${SPACE_FIELDS}
`;

export const GET_SPACE = gql`
	query GetSpace($id: ID!, $language: Language!) {
		node(id: $id) {
			... on Space {
				...SpaceFields
				location {
					...LocationFields
				}
			}
		}
	}
	${SPACE_FIELDS}
	${LOCATION_FIELDS}
`;

export const CREATE_LOCATION = gql`
	mutation CreateLocation($input: CreateLocationInput!) {
		createLocation(input: $input) {
			...LocationFields
		}
	}
	${LOCATION_FIELDS}
`;

export const CREATE_SPACE = gql`
	mutation CreateSpace($input: CreateSpaceInput!, $language: Language!) {
		createSpace(input: $input) {
			...SpaceFields
			location {
				...LocationFields
			}
		}
	}
	${SPACE_FIELDS}
`;

export const DELETE_LOCATION = gql`
	mutation DeleteLocation($id: ID!) {
		deleteLocation(id: $id) {
			id
		}
	}
`;

export const UPDATE_LOCATION = gql`
	mutation UpdateLocation($id: ID!, $input: UpdateLocationInput!) {
		updateLocation(id: $id, input: $input) {
			...LocationFields
		}
	}
	${LOCATION_FIELDS}
`;

export const DELETE_SPACE = gql`
	mutation DeleteSpace($id: ID!) {
		deleteSpace(id: $id) {
			id
		}
	}
`;

export const UPDATE_SPACE = gql`
	mutation UpdateSpace(
		$id: ID!
		$input: UpdateSpaceInput!
		$language: Language!
	) {
		updateSpace(id: $id, input: $input) {
			...SpaceFields
			location {
				...LocationFields
			}
		}
	}
`;
