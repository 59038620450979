import React, { PropsWithChildren } from 'react';
import { Provider } from 'jotai';
import ThemeProvider from './ThemeProvider';
import ApolloClientProvider from './ApolloClientProvider';
import { HeadProvider } from 'react-head';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import SnackbarProvider from './SnackbarProvider';
// import { locale } from 'dayjs';
// import 'dayjs/locale/en-sg';
import { enGB } from 'date-fns/locale';

interface ProviderProps {}

const Providers: React.FC<PropsWithChildren<ProviderProps>> = ({ children }) => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
			<ApolloClientProvider>
				<Provider>
					<HeadProvider>
						<ThemeProvider>
							<SnackbarProvider>{children}</SnackbarProvider>
						</ThemeProvider>
					</HeadProvider>
				</Provider>
			</ApolloClientProvider>
		</LocalizationProvider>
	);
};

export default Providers;
