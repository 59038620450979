// eslint-disable-next-line no-use-before-define
import React, { useEffect } from 'react';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { toast, ToastContentProps } from 'react-toastify';
import { Box, ButtonBase, Theme, Typography } from '@mui/material';
import { ToastProps } from 'react-toastify/dist/types';
import styles from './styles/toast.module.sass';

interface ReloadToastContentProps extends Omit<ToastContentProps, 'toastProps'> {
	updateServiceWorker: (reload: boolean) => void;
	close: () => void;
	toastProps?: ToastProps;
}

const ReloadToastContent: React.FC<ReloadToastContentProps> = ({
	closeToast,
	updateServiceWorker,
	close
}) => {
	return (
		<Box sx={{ width: '100%' }}>
			<Box
				sx={{
					width: '100%',
					textAlign: 'center',
					px: 1,
					py: 3,
					backgroundColor: (theme: Theme) => theme.palette.background.default
				}}
			>
				<Typography>有新的更新可用</Typography>
			</Box>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					borderTop: (theme: Theme) => `1px solid ${theme.palette.divider}`
				}}
			>
				<ButtonBase
					sx={{
						width: '50%',
						height: '100%',
						py: 1.5,
						backgroundColor: (theme: Theme) => theme.palette.background.paper,
						borderRight: (theme: Theme) => `1px solid ${theme.palette.divider}`
					}}
					onClick={() => {
						close();
						closeToast?.();
					}}
				>
					<Box sx={{ textAlign: 'center' }}>取消</Box>
				</ButtonBase>
				<ButtonBase
					sx={{
						width: '50%',
						py: 1.5,
						backgroundColor: (theme: Theme) => theme.palette.background.paper
					}}
					onClick={() => updateServiceWorker(true)}
				>
					<Box sx={{ textAlign: 'center' }}>更新</Box>
				</ButtonBase>
			</Box>
		</Box>
	);
};

function SWReloadPrompt() {
	const {
		offlineReady: [, setOfflineReady],
		needRefresh: [needRefresh, setNeedRefresh],
		updateServiceWorker
	} = useRegisterSW({
		onRegistered(r) {
			r &&
				setInterval(() => {
					r.update();
				}, 60000);
		},
		onRegisterError(error) {
			// eslint-disable-next-line no-console
			console.log('SW registration error', error);
		}
	});

	const close = () => {
		setOfflineReady(false);
		setNeedRefresh(false);
	};

	useEffect(() => {
		if (!needRefresh) {
			return;
		}

		toast(<ReloadToastContent updateServiceWorker={updateServiceWorker} close={close} />, {
			autoClose: false,
			closeButton: false,
			className: styles.toast,
			bodyClassName: styles.toast
		});
	}, [needRefresh]);

	return <></>;
}

export default SWReloadPrompt;
