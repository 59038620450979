import deepEqual from 'fast-deep-equal';

export function getDiff<T extends Record<string, any>>(obj1: T, obj2: T): Partial<T> {
	let res: Partial<T> = {};

	for (const key in obj1) {
		if (!deepEqual(obj1[key], obj2[key])) {
			res[key] = obj2[key];
		}
	}

	for (const key in obj2) {
		if (!(key in obj1)) {
			res[key] = obj2[key];
		}
	}

	return res;
}
