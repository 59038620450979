import { Outlet, useLocation } from 'react-router-dom';
import {
	GetCurrentUserDocument,
	GetCurrentUserQuery,
	useGetCurrentUserQuery
} from './generated/api';
import Loading from './pages/Loading';
import { useTokenStore } from './stores/useTokenStore';
import React, { lazy, Suspense, useEffect, useMemo, useRef, useState } from 'react';
import SideDrawer from './layout/SideDrawer';
import { useUpdateAtom } from 'jotai/utils';
import { isMasterAtom, isOnlineAtom } from './atoms';
import LoadingIndicator from './layout/LoadingIndicator';
import { LoginResponse } from './types';
import Auth from './components/Auth';
import { useIsMasterStore } from './stores/useIsMasterStore';
import Header from './layout/Header';
import { Box, Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import SWReloadPrompt from './SWReloadPrompt';
import NetworkIndicator from './NetworkIndicator';
import { useAtom } from 'jotai';
import { useApolloClient } from '@apollo/client';

const LoginPage = lazy(() => import('./pages/LoginPage'));

const App = () => {
	const token = useTokenStore(state => state.token);
	const { data, loading, error } = useGetCurrentUserQuery({
		context: { headers: { authorization: token } },
		skip: token === '',
		nextFetchPolicy: 'cache-first'
	});
	const setIsMaster = useUpdateAtom(isMasterAtom);
	const setIsMasterStore = useIsMasterStore(state => state.setIsMaster);
	const [refreshLoading, setRefreshLoading] = useState(true);
	const mainElem = useRef<HTMLDivElement | null>(null);
	const [isOnline, setIsOnline] = useAtom(isOnlineAtom);
	const { cache } = useApolloClient();
	const cachedCurrentUser = useMemo(
		() => cache.readQuery<GetCurrentUserQuery>({ query: GetCurrentUserDocument }),
		[cache]
	);

	useEffect(() => {
		const isMaster =
			!!data?.currentUser?.admin?.isMaster || !!cachedCurrentUser?.currentUser.admin?.isMaster;
		setIsMaster(isMaster);
		setIsMasterStore(isMaster);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);

	useEffect(() => {
		(async () => {
			try {
				if (!navigator.onLine) {
					return;
				}
				const controller = new AbortController();
				const t = setTimeout(() => {
					controller.abort();
				}, 10000);
				const response = await fetch(`${import.meta.env.VITE_REST_API_URI}/refresh_token`, {
					credentials: 'include',
					signal: controller.signal
				});
				clearTimeout(t);
				if (response.ok) {
					const data = (await response.json()) as LoginResponse;
					const { setToken, setExpiry } = useTokenStore.getState();
					setToken(data.access_token);
					setExpiry(data.expiry);
				}
			} catch {
				setIsOnline(false);
			}
			setRefreshLoading(false);
		})();
	}, []);

	useEffect(() => {
		if (!error) {
			return;
		}
		if (error.networkError) {
			setIsOnline(false);
		}
	}, [error]);

	if (loading || refreshLoading) {
		return <Loading />;
	}

	if (((!data || error) && isOnline) || (!isOnline && !cachedCurrentUser)) {
		return (
			<Suspense fallback={<Loading />}>
				<LoginPage />
			</Suspense>
		);
	}

	return (
		<>
			<NetworkIndicator />
			<LoadingIndicator />
			<Box sx={{ display: 'flex', width: '100%' }}>
				<SideDrawer />
				<Main ref={mainElem}>
					{/*<TopAppBar />*/}
					<Container maxWidth="xl">
						<Header />
						<Outlet />
					</Container>
				</Main>
			</Box>
			{/*{!mdUp && <BottomNavigationBar />}*/}
			<Auth />
			<ScrollToTop elem={mainElem} />
			<SWReloadPrompt />
		</>
	);
};

const Main = styled('main')(({ theme }) => ({
	background:
		theme.palette.mode === 'light'
			? // ? 'rgb(243, 237, 237)'
			  'rgb(247, 249, 255)'
			: theme.palette.background.default,
	color: theme.palette.text.primary,
	flex: 1,
	paddingBottom: 40,
	paddingLeft: 16,
	paddingRight: 16,
	maxWidth: '100%',
	overflowY: 'scroll',
	overflowX: 'hidden',
	maxHeight: '100vh',
	display: 'flex',
	justifyContent: 'flex-start',
	alignItems: 'center',
	flexDirection: 'column',
	position: 'relative',
	[theme.breakpoints.down('md')]: {
		paddingBottom: 80
	},
	[theme.breakpoints.down('sm')]: {
		paddingLeft: 0,
		paddingRight: 0
	}
}));

// const Container = styled(props => {
// 	const location = useLocation();
// 	const maxWidth =
// 		location.pathname === '/class-arrangement' ||
// 		location.pathname.startsWith('/lessons') ||
// 		location.pathname === '/course-management' ||
// 		location.pathname.startsWith('/categories')
// 			? 'none'
// 			: '1400px';
//
// 	return <Box {...props} sx={{ maxWidth: `${maxWidth} !important` }} />;
// })<BoxProps>({
// 	width: '100%',
// 	padding: '0 5%'
// });

// const Container = styled(Box)({
// 	width: '100%',
// 	padding: '0 24px'
// });

const ScrollToTop = ({ elem }: { elem: React.MutableRefObject<HTMLDivElement | null> }) => {
	const location = useLocation();
	const isModalRef = useRef(false);

	useEffect(() => {
		//@ts-ignore
		if (location.state?.backgroundLocation) {
			isModalRef.current = true;
			return;
		}
		if (isModalRef.current) {
			isModalRef.current = false;
			return;
		}
		elem.current?.scrollTo(0, 0);
	}, [location.pathname]);

	return null;
};

export default App;
