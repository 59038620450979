import { headerTitleAtom } from '../atoms';
import { useUpdateAtom } from 'jotai/utils';
import { useEffect } from 'react';

export const useTitle = (title: string) => {
	const setHeaderTitle = useUpdateAtom(headerTitleAtom);

	useEffect(() => {
		setHeaderTitle(title);
	}, [title]);
};
