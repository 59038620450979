import React, { useEffect } from 'react';
import { courseCreateDialogOpenAtom } from '../../../../atoms';
import {
	AppBar,
	Box,
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme
} from '@mui/material';
import { useCourseCreateStore } from '../../../../stores/useCourseCreateStore';
import {
	GetCategoryCoursesDocument,
	GetCategoryCoursesQuery,
	Language,
	useCreateCourseMutation
} from '../../../../generated/api';
import { createCourseUpdate } from '../../update/createCourseUpdate';
import { useUserPreferenceStore } from '../../../../stores/useUserPreferenceStore';
import CourseCreateDialogActions from './CourseCreateDialogActions';
import CourseCreateDialogContent from './CourseCreateDialogContent';
import { SlideUp } from '../../../../share-components/Transitions/SlideUp';
import { Close } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface CourseCreateDialogProps {
	categoryId: string;
}

const CourseCreateDialog: React.FC<CourseCreateDialogProps> = ({ categoryId }) => {
	const navigate = useNavigate();
	const open = useAtomValue(courseCreateDialogOpenAtom);
	const [createCourseMutation] = useCreateCourseMutation({
		update: createCourseUpdate
	});
	const theme = useTheme();
	const isSM = useMediaQuery(theme.breakpoints.down('sm'));
	const reset = useCourseCreateStore(state => state.reset);

	const handleClose = () => {
		navigate(-1);
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		const { translations, lecturers } = useCourseCreateStore.getState();

		const language = useUserPreferenceStore.getState().language;

		// user = user as any;

		try {
			console.log({
				input: {
					translations: (Object.keys(translations) as Language[]).map(language => ({
						...translations[language],
						language
					})),
					categoryId,
					lecturers
				},
				language
			});
			await createCourseMutation({
				variables: {
					input: {
						translations: (Object.keys(translations) as Language[]).map(language => ({
							...translations[language],
							language
						})),
						categoryId,
						lecturers
					},
					language
				},
				update: (cache, result) => {
					if (!result.data?.createCourse) {
						return;
					}

					const res = cache.readQuery<GetCategoryCoursesQuery>({
						query: GetCategoryCoursesDocument,
						variables: {
							id: result.data.createCourse.category.id,
							language: useUserPreferenceStore.getState().language
						}
					});

					if (res?.node?.__typename === 'Category') {
						cache.writeQuery<GetCategoryCoursesQuery>({
							query: GetCategoryCoursesDocument,
							variables: {
								id: result.data.createCourse.category.id,
								language: useUserPreferenceStore.getState().language
							},
							data: {
								node: {
									...res.node,
									courses: [...res.node.courses, result.data.createCourse]
								}
							}
						});
					}
				}
			});
			toast.success(`创建课程成功!`);
			reset();
			handleClose();
		} catch (err) {
			console.trace(err);
			toast.error(`创建课程失败! ${err}`);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth
			fullScreen={isSM}
			TransitionComponent={isSM ? SlideUp : undefined}
		>
			<Box
				component="form"
				onSubmit={handleSubmit}
				sx={{
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					overflowY: 'hidden'
				}}
			>
				{!isSM ? (
					<AppBar position="static" color="inherit" elevation={0} component="div">
						<DialogTitle>创建课程</DialogTitle>
					</AppBar>
				) : (
					<AppBar position="static" color="inherit" component="div">
						<Toolbar>
							<IconButton onClick={handleClose} size="large">
								<Close />
							</IconButton>
							<Typography variant="h6" sx={{ ml: '10px' }}>
								创建课程
							</Typography>
							<Box sx={{ ml: 'auto', mr: 0 }}>
								<Button color="primary" onClick={reset}>
									重置
								</Button>
							</Box>
						</Toolbar>
					</AppBar>
				)}
				<StyledDialogContent dividers={isSM}>
					<CourseCreateDialogContent />
				</StyledDialogContent>
				<CourseCreateDialogActions />
			</Box>
		</Dialog>
	);
};

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
	maxHeight: 'calc(100vh - 108px)',
	[theme.breakpoints.down('sm')]: {
		paddingTop: 30
	}
}));

export const CourseCreateDialogRoute: React.FC = () => {
	const setOpen = useUpdateAtom(courseCreateDialogOpenAtom);

	useEffect(() => {
		setOpen(true);
		return () => {
			setOpen(false);
		};
	}, []);

	return <></>;
};

export default CourseCreateDialog;
