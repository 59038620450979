import React from 'react';
import { AppBar, Button, DialogActions, Toolbar } from '@mui/material';
import { useClassCreateStore } from '../../../../stores/useClassCreateStore';
import shallow from 'zustand/shallow';

interface ClassCreateDialogActionsProps {
	handleClose: () => void;
	handleReset: () => void;
	isXS: boolean;
}

const ClassCreateDialogActions: React.FC<ClassCreateDialogActionsProps> = ({
	handleClose,
	handleReset,
	isXS
}) => {
	const [step, setStep] = useClassCreateStore(state => [state.step, state.setStep], shallow);

	const handlePrevStep = () => {
		setStep((step - 1) as 0 | 1);
	};

	return (
		<AppBar position="static" color="inherit" elevation={0} component="div">
			<Toolbar>
				<DialogActions sx={{ p: 0, width: '100%' }}>
					<Button
						color="primary"
						onClick={handlePrevStep}
						disabled={step === 0}
						sx={isXS ? { ml: 0, mr: 'auto' } : {}}
					>
						上一步
					</Button>
					{!isXS && (
						<>
							<Button color="primary" onClick={handleClose}>
								取消
							</Button>
							<Button color="primary" onClick={handleReset}>
								重置
							</Button>
						</>
					)}
					<Button
						variant="contained"
						color="primary"
						type="submit"
						sx={{ color: theme => (theme.palette.mode === 'dark' ? '#000' : '#fff') }}
					>
						{step !== 2 ? '下一步' : '创建'}
					</Button>
				</DialogActions>
			</Toolbar>
		</AppBar>
	);
};

export default ClassCreateDialogActions;
