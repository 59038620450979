import React from 'react';
import {
	Box,
	Checkbox,
	TableCell,
	TableHead,
	TableHeadProps,
	TableRow,
	TableSortLabel
} from '@mui/material';
import { HeadCell } from '../../types';
import { TableFilterHolder, useTableFilterStore } from '../../stores/useTableFilterStore';
import shallow from 'zustand/shallow';

interface TableListHeadProps {
	holder: TableFilterHolder;
	headCells: HeadCell[];
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	rowCount: number;
	hasActions: boolean;
	selectable: boolean;
	tableHeadProps?: TableHeadProps;
}

const TableListHead: React.FC<TableListHeadProps> = ({
	holder,
	headCells,
	onSelectAllClick,
	rowCount,
	hasActions,
	selectable,
	tableHeadProps
}) => {
	const [numberOfSelected, order, orderBy, setOrder, setOrderBy] = useTableFilterStore(
		state => [
			state.filters[holder].selected.length,
			state.filters[holder].order,
			state.filters[holder].orderBy,
			state.setOrder(holder),
			state.setOrderBy(holder)
		],
		shallow
	);

	const handleRequestSort = (event: unknown, property: string) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const createSortHandler = (property: string) => (event: unknown) => {
		handleRequestSort(event, property);
	};

	return (
		<TableHead {...tableHeadProps}>
			<TableRow>
				{selectable && (
					<TableCell
						padding="checkbox"
						// classes={{ stickyHeader: classes.stickyHeader }}
						sx={theme => ({
							...(theme.palette.mode === 'light' ? { backgroundColor: 'rgb(247, 249, 255)' } : {})
						})}
					>
						<Checkbox
							indeterminate={numberOfSelected > 0 && numberOfSelected < rowCount}
							checked={rowCount > 0 && numberOfSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{ 'aria-label': 'select all rows' }}
						/>
					</TableCell>
				)}
				{headCells.map(headCell => (
					<TableCell
						key={headCell.id as string}
						align="left"
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
						sx={theme => ({
							...(theme.palette.mode === 'light' ? { backgroundColor: 'rgb(247, 249, 255)' } : {})
						})}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id as string)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box
									component="span"
									sx={{
										border: 0,
										clip: 'rect(0 0 0 0)',
										height: 1,
										margin: -1,
										overflow: 'hidden',
										padding: 0,
										position: 'absolute',
										top: 20,
										width: 1
									}}
								>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
				{hasActions && (
					<TableCell
						key="actions"
						align="left"
						sx={theme => ({
							...(theme.palette.mode === 'light' ? { backgroundColor: 'rgb(247, 249, 255)' } : {})
						})}
					/>
				)}
			</TableRow>
		</TableHead>
	);
};

export default TableListHead;
