import React from 'react';
import { Grid, MenuItem, Paper, TextField, Typography } from '@mui/material';
import {
	CreateUserStudentInput,
	StudentStatus,
	StudentPhone
} from '../../generated/api';
import StudentPhones from './StudentPhones';

interface CreateStudentFormProps {
	student: CreateUserStudentInput;
	setStudent: (students: Partial<CreateUserStudentInput>) => void;
	disabled?: boolean;
}

const CreateStudentForm: React.FC<CreateStudentFormProps> = ({
	student,
	setStudent,
	disabled
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		const value = event.target.value;
		switch (name) {
			case 'matricId':
				if (value.length > 7) {
					return;
				}
				break;
			case 'phones':
				if (value.length > 10) {
					return;
				}
				break;
			case 'status':
				break;
			default:
				return;
		}

		setStudent({ [name]: value });
	};

	const handlePhonesChange = (phones: StudentPhone[]) => {
		setStudent({ phones });
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="学号"
					name="matricId"
					placeholder="YYMMXXX"
					value={student.matricId}
					onChange={handleChange}
					fullWidth
					disabled={disabled}
					InputLabelProps={{
						shrink: true
					}}
					inputProps={{
						pattern: '(^$|[0-9]{7}$)'
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="在籍状况"
					name="status"
					value={student.status}
					onChange={handleChange}
					select
					fullWidth
					disabled={disabled}
				>
					<MenuItem value={StudentStatus.Active}>在籍学生</MenuItem>
					<MenuItem value={StudentStatus.Temporary}>试课学生</MenuItem>
					<MenuItem value={StudentStatus.Inactive}>不在籍学生</MenuItem>
				</TextField>
			</Grid>
			<Grid item xs={12} style={{ marginTop: 10 }}>
				<Paper
					variant="outlined"
					style={{ padding: 10, background: 'transparent' }}
				>
					<Typography variant="body2" paragraph>
						学生联系号码
					</Typography>
					<StudentPhones
						phones={student.phones}
						setPhones={handlePhonesChange}
						disabled={disabled}
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default CreateStudentForm;
