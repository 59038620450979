import React from 'react';
import { Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { AdminStatus, CreateUserAdminInput } from '../../generated/api';

interface CreateAdminFormProps {
	admin: CreateUserAdminInput;
	setAdmin: (admin: Partial<CreateUserAdminInput>) => void;
	disabled?: boolean;
}

const CreateAdminForm: React.FC<CreateAdminFormProps> = ({
	admin,
	setAdmin,
	disabled
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		let value: CreateUserAdminInput[keyof CreateUserAdminInput] =
			event.target.value;

		switch (name) {
			case 'matricId':
				value = 'A' + value;
				break;
			case 'isMaster':
				value = Boolean(value);
				break;
			default:
				return;
		}

		setAdmin({ [name]: value });
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="编号"
					name="matricId"
					placeholder="XXX"
					value={admin.matricId.slice(1)}
					onChange={handleChange}
					fullWidth
					disabled={disabled}
					InputProps={{
						startAdornment: <InputAdornment position="start">A</InputAdornment>
					}}
					inputProps={{
						pattern: '(^[0-9]{3,}$)'
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="在籍状况"
					name="status"
					value={admin.status}
					onChange={handleChange}
					select
					fullWidth
					disabled={disabled}
				>
					<MenuItem value={AdminStatus.Active}>在籍管理</MenuItem>
					<MenuItem value={AdminStatus.Inactive}>不在籍管理</MenuItem>
				</TextField>
			</Grid>
			<Grid item xs={12}>
				<TextField
					variant="outlined"
					label="高级管理"
					name="isMaster"
					value={Number(admin.isMaster)}
					onChange={handleChange}
					select
					fullWidth
					disabled={disabled}
				>
					<MenuItem value={1}>是</MenuItem>
					<MenuItem value={0}>否</MenuItem>
				</TextField>
			</Grid>
		</Grid>
	);
};

export default CreateAdminForm;
