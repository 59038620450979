import { Box, Button, Grid, IconButton, Paper, TextField, Theme, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import {
	Language,
	useGetCategoryInfoQuery,
	useUpdateCategoryMutation
} from '../../../generated/api';
import { useForm } from 'react-hook-form';
import { useUserPreferenceStore } from '../../../stores/useUserPreferenceStore';
import { toast } from 'react-toastify';
import { useRefetchHandler } from '../../../hooks/useRefetchHandler';
import CategoryPageCourses from './CategoryPageCourses';
import { useUpdateAtom } from 'jotai/utils';
import { categoryDeleteItemAtom } from '../../../atoms';
import CategoryDeleteDialog from './CategoryDeleteDialog';

interface CategoryPageProps {}

const CategoryPage: React.FC<CategoryPageProps> = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { id, name } = useParams<{ id: string; name: string }>();
	const { data, refetch, startPolling, stopPolling } = useGetCategoryInfoQuery({
		variables: { id: id || '' },
		skip: !id
	});
	const category = useMemo(
		() => (data?.node?.__typename === 'Category' ? data.node : null),
		[data]
	);
	const { register, handleSubmit, setValue } = useForm({
		defaultValues: {
			zhName: category?.translationZH.name || '',
			enName: category?.translationEN.name || '',
			zhDescription: category?.translationZH.description || '',
			enDescription: category?.translationEN.description || ''
		}
	});
	const language = useUserPreferenceStore.getState().language;
	const [updateCategory, { loading: updateLoading }] = useUpdateCategoryMutation();
	const setDeleteItem = useUpdateAtom(categoryDeleteItemAtom);

	useRefetchHandler(refetch, startPolling, stopPolling);

	useEffect(() => {
		if (!category) {
			return;
		}
		setValue('zhName', category.translationZH.name);
		setValue('enName', category.translationEN.name);
		setValue('zhDescription', category.translationZH.description);
		setValue('enDescription', category.translationEN.description);
	}, [category]);

	const onSubmit = async ({
		zhName,
		enName,
		zhDescription,
		enDescription
	}: {
		zhName: string;
		enName: string;
		zhDescription: string;
		enDescription: string;
	}) => {
		try {
			await updateCategory({
				variables: {
					id: id!,
					input: {
						translations: [
							{
								language: Language.Zh,
								name: zhName,
								description: zhDescription
							},
							{
								language: Language.En,
								name: enName,
								description: enDescription
							}
						]
					},
					language
				}
			});
			toast.success('保存更改成功！');
		} catch (err) {
			toast.error(`保存更改失败！${err}`);
		}
	};

	const handleDelete = () => {
		if (!id || !name) {
			return;
		}

		setDeleteItem({ id, name });
	};

	return (
		<Box sx={theme => ({ py: 4, [theme.breakpoints.down('sm')]: { py: 2 } })}>
			<Box sx={{ display: 'flex', alignItems: 'center' }}>
				<IconButton
					onClick={() => {
						//@ts-ignore
						if (location.state?.jump) {
							navigate('/course-management');
						} else {
							navigate(-1);
						}
					}}
					sx={{ mr: 1 }}
				>
					<ArrowBack />
				</IconButton>
				<Typography variant="h5">
					{category
						? language === Language.Zh
							? category.translationZH.name
							: category.translationEN.name
						: name}
				</Typography>
			</Box>
			<Paper
				component="form"
				onSubmit={handleSubmit(onSubmit)}
				sx={theme => ({ p: 4, pb: 2, mt: 4, [theme.breakpoints.down('sm')]: { px: 2 } })}
			>
				<Grid container spacing={4}>
					<Grid item xs={6}>
						<TextField
							label="中文名字"
							fullWidth
							InputLabelProps={{ shrink: true }}
							required
							{...register('zhName')}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="英文名字"
							fullWidth
							InputLabelProps={{ shrink: true }}
							required
							{...register('enName')}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="中文简介"
							fullWidth
							InputLabelProps={{ shrink: true }}
							multiline
							{...register('zhDescription')}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							label="英文简介"
							fullWidth
							InputLabelProps={{ shrink: true }}
							multiline
							{...register('enDescription')}
						/>
					</Grid>
				</Grid>
				<Box sx={{ textAlign: 'right' }}>
					<Button
						type="submit"
						variant="contained"
						color="primary"
						disabled={updateLoading}
						sx={{
							mt: 3,
							color: (theme: Theme) => (theme.palette.mode === 'light' ? '#fff' : '#000')
						}}
					>
						保存更改
					</Button>
				</Box>
			</Paper>
			<CategoryPageCourses id={id!} />
			<Button
				variant="contained"
				color="error"
				size="large"
				sx={{ mt: 4 }}
				fullWidth
				onClick={handleDelete}
			>
				删除{category?.translationZH.name || ''}
			</Button>
			<CategoryDeleteDialog />
		</Box>
	);
};

export default CategoryPage;
