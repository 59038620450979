import React from 'react';
import {
	Autocomplete,
	InputAdornment,
	TextField,
	TextFieldProps
} from '@mui/material';
import { styled } from '@mui/material/styles';

export interface StudentPhoneItemProps {
	name: string;
	setName: (name: string) => void;
	number: string;
	setNumber: (number: string) => void;

	[x: string]: any;
}

const textFieldProps: TextFieldProps = {
	variant: 'outlined',
	type: 'tel',
	style: { flex: 1 },
	InputProps: {
		startAdornment: <InputAdornment position="start">+60</InputAdornment>
	}
};

const StudentPhoneItem: React.FC<StudentPhoneItemProps> = ({
	name,
	setName,
	number,
	setNumber,
	...props
}) => {
	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value);
	};

	const handleSelectNameChange = (
		event: React.ChangeEvent<{}>,
		value: string
	) => {
		setName(value);
	};

	const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNumber(event.target.value);
	};

	return (
		<Root {...props}>
			<Autocomplete
				renderInput={params => (
					<TextField
						{...params}
						label="名字"
						variant="outlined"
						onChange={handleNameChange}
						required
						InputLabelProps={{
							shrink: true
						}}
					/>
				)}
				options={['爸爸', '妈妈', '监护人']}
				value={name}
				style={{ minWidth: 120, marginRight: 12 }}
				onChange={handleSelectNameChange}
				freeSolo
				disableClearable
			/>
			<TextField
				label="号码"
				value={number}
				onChange={handleNumberChange}
				required
				inputProps={{
					pattern: '[0-9]{9,10}'
				}}
				{...textFieldProps}
			/>
		</Root>
	);
};

const Root = styled('div')({
	display: 'flex',
	flex: 1
});

export default StudentPhoneItem;
