import React from 'react';
import { styled } from '@mui/material/styles';
import { Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useClassCreateStore } from '../../../../stores/useClassCreateStore';

interface ClassCreateDialogStepperProps {}

const ClassCreateDialogStepper: React.FC<ClassCreateDialogStepperProps> = () => {
	const step = useClassCreateStore(state => state.step);

	return (
		<StyledStepper activeStep={step} alternativeLabel>
			<Step>
				<StepLabel>填写班级基本资料</StepLabel>
			</Step>
			<Step>
				<StepLabel>设置学期自动创建</StepLabel>
			</Step>
			<Step>
				<StepLabel>添加班级学生</StepLabel>
			</Step>
		</StyledStepper>
	);
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
	paddingTop: '20px',
	marginBottom: '20px',
	'& .MuiStepIcon-text': {
		fill: theme.palette.mode === 'light' ? '#fff' : '#000'
	}
}));

export default ClassCreateDialogStepper;
