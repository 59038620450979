import React, { Dispatch, MouseEvent, PropsWithChildren, SetStateAction } from 'react';
import { styled } from '@mui/material/styles';
import { Alert, Box, BoxProps, CircularProgress, Fab } from '@mui/material';

export const Controller = styled('div')({
	display: 'flex',
	flexFlow: 'row nowrap',
	alignItems: 'top',
	justifyContent: 'space-between'
});

export const SideController = styled('div')({
	display: 'flex',
	flexFlow: 'row nowrap',
	alignItems: 'center',
	justifyContent: 'flex-end'
});

interface FabButtonProps {
	onClick?(e: MouseEvent<HTMLElement>): void;

	color?: 'primary' | 'secondary';
	type?: 'button' | 'submit';
}

export const FabButton: React.FC<PropsWithChildren<FabButtonProps>> = ({
	children,
	color,
	type,
	onClick
}) => {
	// const [bottomNavigationShow] = useContext(BottomNavigationShowContext);

	return (
		<Fab
			sx={theme => ({
				position: 'fixed',
				bottom: '6vh',
				right: '6vh',
				[theme.breakpoints.down('xl')]: {
					bottom: '3vh',
					right: '3vh'
				}
			})}
			color={color}
			type={type}
			onClick={onClick}
		>
			{/*
      //@ts-ignore*/}
			{children}
		</Fab>
	);
};

export const Loader = ({
	loading = true,
	color
}: {
	loading?: boolean;
	color?: string;
}) => {
	return (
		<>
			{loading && (
				<Box
					sx={theme => ({
						position: 'absolute',
						background: color || theme.palette.background.paper,
						width: '100%',
						height: '100%',
						top: '0',
						left: '0',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						zIndex: 20
					})}
				>
					<CircularProgress />
				</Box>
			)}
		</>
	);
};

export const Loading = ({ sx, ...props }: BoxProps) => (
	<Box
		sx={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			width: '100%',
			height: '300px',
			...sx
		}}
		{...props}
	>
		<CircularProgress />
	</Box>
);

export const ErrorAlert = ({
	errors,
	setErrors
}: {
	errors: Array<string>;
	setErrors: Dispatch<SetStateAction<Array<string>>>;
}) => {
	return (
		<>
			{typeof errors === 'object' && errors.length !== 0 && (
				<Box sx={{ margin: '-5px 24px 10px 24px' }}>
					{errors.map((e, index: number) => (
						<Alert
							severity="error"
							onClose={() => {
								setErrors(errs => errs.filter((e, i: number) => i !== index));
							}}
							key={index}
							sx={{ marginBottom: '5px' }}
						>
							{e}
						</Alert>
					))}
				</Box>
			)}
		</>
	);
};
