export const getDaysInMonth = (day: number, month: number, year: number) => {
	const firstDate = new Date(year, month, 1);

	// % is mod, the first +7 is to turn negative remainder into positive
	const dayRange = (((day - firstDate.getDay()) % 7) + 7) % 7;

	let nextDateToDay = 1 + dayRange;

	const numberOfDate = new Date(year, month + 1, 0).getDate();

	return ((numberOfDate - nextDateToDay) / 7 + 1) | 0;
};
