import React, { useEffect } from 'react';
import { AdminStatus, useGetAdminsQuery } from 'generated/api';
import { useUserStatusStore } from '../../stores/useUserStatusStore';
import shallow from 'zustand/shallow';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserRole } from '../../types';
import { Typography } from '@mui/material';
import TableList from '../common/TableList';
import { useUserTableControllerStore } from '../../stores/useUserTableControllerStore';
import { useAtom } from 'jotai';
import { isMasterAtom, userManagementLastRouteAtom } from '../../atoms';
import { useRefetchFunctionsStore } from '../../stores/useRefetchFunctionsStore';
import TableSkeletonLoading from '../../share-components/Table/TableSkeletonLoading';
import { useUserPreferenceStore } from '../../stores/useUserPreferenceStore';
import { useAtomValue } from 'jotai/utils';
import { TableFilterHolder } from '../../stores/useTableFilterStore';

const options = [
	{
		label: '全部管理',
		value: '0' as const
	},
	{
		label: '在籍管理',
		value: AdminStatus.Active
	},
	{
		label: '不在籍管理',
		value: AdminStatus.Inactive
	}
];

interface AdminsProps {}

const Admins: React.FC<AdminsProps> = () => {
	const [status, setStatus] = useUserStatusStore(
		state => [state.adminStatus, state.setAdminStatus],
		shallow
	);
	const [selectedValue, setOptions, setSelectedValue, setRole] = useUserTableControllerStore(
		state => [state.selectedValue, state.setOptions, state.setSelectedValue, state.setRole],
		shallow
	);
	const isMaster = useAtomValue(isMasterAtom);
	const { data, loading, error, refetch, startPolling, stopPolling } = useGetAdminsQuery({
		variables: {
			status: status !== '0' ? status : undefined
		},
		skip: !isMaster
	});
	const navigate = useNavigate();
	const location = useLocation();
	const [, setUserManagementLastRoute] = useAtom(userManagementLastRouteAtom);
	const [addRefetch, removeRefetch] = useRefetchFunctionsStore(
		state => [state.addFunction, state.removeFunction],
		shallow
	);

	useEffect(() => {
		setOptions(options);
		setSelectedValue(status);
		setUserManagementLastRoute('/admin');
		setRole(UserRole.ADMIN);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const pollingInterval = useUserPreferenceStore.getState().pollingInterval;

		if (!pollingInterval) {
			return;
		}

		startPolling(pollingInterval);

		return () => {
			stopPolling();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (refetch) {
			addRefetch(refetch);
		}

		return () => {
			if (refetch) {
				removeRefetch(refetch);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetch]);

	useEffect(() => {
		if (
			selectedValue === AdminStatus.Active ||
			selectedValue === AdminStatus.Inactive ||
			selectedValue == '0'
		) {
			setStatus(selectedValue);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue]);

	if (!data) {
		if (loading) {
			return <TableSkeletonLoading />;
		}

		if (error) {
			return <>{error.message}</>;
		}

		return <></>;
	}

	const users = data.admins.map(admin => ({
		...admin,
		...admin.user
	}));

	const handleItemClick = (user: typeof users[0]) => {
		navigate(`/users/${user.id}`, { state: { backgroundLocation: location } });
	};

	return (
		<>
			{!users || users.length === 0 ? (
				<Typography variant="body1" align="center" style={{ marginTop: '3em' }}>
					没有{status !== '0' ? (status == AdminStatus.Active ? '在籍管理' : '不在籍管理') : '管理'}
				</Typography>
			) : (
				<TableList
					holder={TableFilterHolder.ADMIN}
					items={users}
					headCells={[
						{ id: 'matricId', disablePadding: false, label: '编号' },
						{ id: 'cname', disablePadding: false, label: '中文名' },
						{ id: 'ename', disablePadding: false, label: '英文名' },
						{ id: 'gender', disablePadding: false, label: '性别' },
						{ id: 'email', disablePadding: false, label: 'Email' },
						{ id: 'ic', disablePadding: false, label: 'I/C' },
						{ id: 'phone', disablePadding: false, label: '电话' }
					]}
					clickFunc={handleItemClick}
				/>
			)}
		</>
	);
};

export default Admins;
