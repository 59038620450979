import React from 'react';
import { useUserRoleCreateStore } from '../../../../stores/useUserRoleCreateStore';
import shallow from 'zustand/shallow';
import CreateStudentForm from '../../../../share-components/User/CreateStudentForm';
import {
	useGetStudentsQuery,
	useGetUsersQuery,
	UserRole
} from '../../../../generated/api';
import { useAtomValue } from 'jotai/utils';
import { isMasterAtom } from '../../../../atoms';
import UserRoleCreateUserSelection from './UserRoleCreateUserSelection';

interface UserRoleCreateStudentProps {}

const UserRoleCreateStudent: React.FC<UserRoleCreateStudentProps> = () => {
	const [student, setStudent] = useUserRoleCreateStore(
		state => [state.student, state.setStudent],
		shallow
	);
	const isMaster = useAtomValue(isMasterAtom);
	const { data: userData, loading: userLoading } = useGetUsersQuery({
		variables: {
			roleFilter: {
				roles: !isMaster ? [UserRole.Student, UserRole.Lecturer] : undefined,
				includeNoRole: true
			}
		}
	});
	const { data: studentData, loading: studentLoading } = useGetStudentsQuery();

	const users =
		(userData &&
			studentData &&
			userData.users.filter(
				user =>
					!studentData.students.find(student => student.user.id === user.id)
			)) ||
		[];

	return (
		<>
			<UserRoleCreateUserSelection
				users={users}
				loading={userLoading || studentLoading}
				selectedUserId={student.userId}
				setRoleState={setStudent}
			/>
			<CreateStudentForm
				student={student}
				setStudent={setStudent}
				disabled={student.userId === ''}
			/>
		</>
	);
};

export default UserRoleCreateStudent;
