import React, { useState } from 'react';
import { useAtom } from 'jotai';
import { invoiceCloseIdAtom } from '../../../atoms';
import useLastNotNullValue from '../../../hooks/useLastNotNullvalue';
import { Relay } from '../../../utils/relay';
import { InvoiceStatus, useUpdateInvoiceMutation } from '../../../generated/api';
import { toast } from 'react-toastify';
import { ApolloError } from '@apollo/client';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FilledInput,
	FormHelperText,
	Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';

interface InvoiceCloseDialogProps {}

const InvoiceCloseDialog: React.FC<InvoiceCloseDialogProps> = () => {
	const [invoiceIdRaw, setInvoiceId] = useAtom(invoiceCloseIdAtom);
	const invoiceId = useLastNotNullValue(invoiceIdRaw ? Relay.unmarshalId(invoiceIdRaw) : '');
	const [updateInvoiceMutation] = useUpdateInvoiceMutation();
	const [confirmationText, setConfirmationText] = useState('');
	const [isError, setIsError] = useState(false);

	const handleClose = () => {
		setInvoiceId('');
		setConfirmationText('');
		setIsError(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (isError) {
			setIsError(false);
		}
		setConfirmationText(event.target.value);
	};

	const handleClick = async () => {
		if (confirmationText !== invoiceId) {
			setIsError(true);
			return;
		}

		try {
			await updateInvoiceMutation({
				variables: {
					id: invoiceIdRaw,
					input: {
						status: InvoiceStatus.Void
					}
				}
			});
			toast.success(`关闭发票${invoiceId}成功!`);
			handleClose();
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(err.message);
			} else {
				toast.error(`${err}`);
			}
		}
	};

	return (
		<Dialog open={!!invoiceIdRaw} onClose={handleClose} maxWidth="xs" fullWidth>
			<DialogTitle>关闭{invoiceId}</DialogTitle>
			<DialogContent>
				<DialogContentText>确定要关闭{invoiceId}吗？</DialogContentText>
				<Typography variant="body2" sx={{ mt: 2, mb: 1 }}>
					输入
					<Typography variant="body2" color="primary" component="span" sx={{ margin: '0 2px' }}>
						{invoiceId}
					</Typography>
					以确认关闭
				</Typography>
				<StyledFilledInput
					value={confirmationText}
					onChange={handleChange}
					error={isError}
					fullWidth
					autoFocus
				/>
				<FormHelperText error={isError}>{isError ? `请输入${invoiceId}` : ''}</FormHelperText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose}>取消</Button>
				<Button onClick={handleClick}>确定</Button>
			</DialogActions>
		</Dialog>
	);
};

const StyledFilledInput = styled(FilledInput)({
	'& .MuiFilledInput-input': {
		paddingTop: '16px'
	}
});

export default InvoiceCloseDialog;
