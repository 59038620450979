import React, { useCallback, useMemo } from 'react';
import {
	Box,
	Chip,
	Grid,
	InputAdornment,
	ListSubheader,
	MenuItem,
	TextField,
	Typography
} from '@mui/material';
import { ClassCreateStoreStateValue } from '../../stores/useClassCreateStore';
import {
	CourseFieldsFragment,
	Language,
	useGetLecturersQuery,
	useGetSpacesQuery
} from '../../generated/api';
import { useUserPreferenceStore } from '../../stores/useUserPreferenceStore';
import ClassColorsRadio from '../ClassColorsRadio';

interface ClassFormProps {
	courses?: CourseFieldsFragment[];
	courseId?: string;
	translations: ClassCreateStoreStateValue['translations'];
	studentFeePerLesson: number;
	lecturerCommission: number;
	spaceId?: string;
	lecturers: string[];
	color: string;
	setCourseId?: (courseId: string) => void;
	setName: (name: string, language: Language) => void;
	setDescription: (description: string, language: Language) => void;
	setStudentFeePerLesson: (studentFeePerLesson: number) => void;
	setLecturerCommission: (lecturerCommission: number) => void;
	setSpaceId: (spaceId: string) => void;
	setLecturers: (lecturers: string[]) => void;
	setColor: (color: string) => void;
}

const ClassForm: React.FC<ClassFormProps> = ({
	courses,
	courseId,
	translations,
	studentFeePerLesson,
	lecturerCommission,
	spaceId,
	lecturers: selectedLecturers,
	color,
	setCourseId,
	setName,
	setDescription,
	setStudentFeePerLesson,
	setLecturerCommission,
	setSpaceId,
	setLecturers,
	setColor
}) => {
	const { data } = useGetSpacesQuery({
		variables: {
			language: useUserPreferenceStore.getState().language
		}
	});
	const { data: lecturersData } = useGetLecturersQuery({
		fetchPolicy: 'cache-and-network'
	});

	const spaces = useMemo(() => data?.spaces || [], [data]);
	const lecturers = useMemo(() => lecturersData?.lecturers || [], [lecturersData]);

	const getSpaceSelectContent = useCallback(() => {
		const locations = spaces.reduce(
			(locations, space) => ({
				...locations,
				[space.location.id]: [...(locations[space.location.id] || []), space]
			}),
			{} as Record<string, Array<typeof spaces[0]>>
		);

		return Object.keys(locations).map((location, index) => [
			<ListSubheader key={location + index} sx={{ ml: -0.3 }}>
				{locations[location][0].location.name}
			</ListSubheader>,
			...locations[location].map(space => (
				<MenuItem value={space.id}>{space.translation.name}</MenuItem>
			))
		]);
	}, [spaces]);

	const handleCourseChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (!setCourseId) {
				return;
			}
			setCourseId(event.target.value);
		},
		[setCourseId]
	);

	const handleNameChange = useCallback(
		(language: Language) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setName(event.target.value, language);
		},
		[setName]
	);

	const handleDescriptionChange = useCallback(
		(language: Language) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setDescription(event.target.value, language);
		},
		[setDescription]
	);

	const handleStudentFeePerLessonChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setStudentFeePerLesson(Number(event.target.value));
		},
		[setStudentFeePerLesson]
	);

	const handleLecturerCommissionChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setLecturerCommission(Number(event.target.value));
		},
		[setLecturerCommission]
	);

	const handleSpaceIdChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setSpaceId(event.target.value);
		},
		[setSpaceId]
	);

	const handleLecturersChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setLecturers(
				typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value
			);
		},
		[setLecturers]
	);

	const handleColorChange = useCallback(
		(color: string) => {
			setColor(color);
		},
		[setColor]
	);

	return (
		<Grid container spacing={2} sx={{ mt: { xs: 0, md: 0.5 } }}>
			{courses !== undefined && (
				<Grid item xs={12}>
					<TextField
						name="courseId"
						label="课程"
						value={courseId}
						onChange={handleCourseChange}
						variant="outlined"
						fullWidth
						required
						InputLabelProps={{ shrink: true }}
						select
					>
						{courses.map(course => (
							<MenuItem key={course.id} value={course.id}>
								{course.translation.name}
							</MenuItem>
						))}
					</TextField>
				</Grid>
			)}
			<Grid item xs={12} sm={6}>
				<TextField
					name="translations.ZH.name"
					label="中文名字"
					value={translations.ZH.name}
					onChange={handleNameChange(Language.Zh)}
					variant="outlined"
					fullWidth
					required
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					name="translations.EN.name"
					label="英文名字"
					value={translations.EN.name}
					onChange={handleNameChange(Language.En)}
					variant="outlined"
					fullWidth
					required
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					name="translations.ZH.description"
					label="中文简介"
					value={translations.ZH.description}
					onChange={handleDescriptionChange(Language.Zh)}
					variant="outlined"
					multiline
					fullWidth
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					name="translations.EN.description"
					label="英文简介"
					value={translations.EN.description}
					onChange={handleDescriptionChange(Language.En)}
					variant="outlined"
					multiline
					fullWidth
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					name="studentFeePerLesson"
					label="学费(每堂课)"
					value={studentFeePerLesson}
					onChange={handleStudentFeePerLessonChange}
					variant="outlined"
					fullWidth
					required
					type="number"
					InputLabelProps={{
						shrink: true
					}}
					InputProps={{
						startAdornment: <InputAdornment position="start">RM</InputAdornment>
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					name="lecturerCommission"
					label="导师分成"
					value={lecturerCommission}
					onChange={handleLecturerCommissionChange}
					variant="outlined"
					fullWidth
					required
					type="number"
					InputLabelProps={{
						shrink: true
					}}
					InputProps={{
						endAdornment: <InputAdornment position="end">%</InputAdornment>
					}}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				sx={{
					alignSelf: 'stretch',
					'& > .MuiFormControl-root': {
						height: '100%'
					},
					'& .MuiOutlinedInput-root': {
						height: '100%'
					}
				}}
			>
				<TextField
					name="spaceId"
					value={spaceId || '0'}
					onChange={handleSpaceIdChange}
					label="主要上课地点"
					select
					fullWidth
					required
					variant="outlined"
				>
					<MenuItem value="0">线上</MenuItem>
					{getSpaceSelectContent()}
				</TextField>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					name="lecturers"
					label="老师"
					value={selectedLecturers}
					onChange={handleLecturersChange}
					select
					SelectProps={{
						multiple: true,
						renderValue: selected => (
							<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
								{(selected as string[]).map(value => (
									<Chip
										key={value}
										label={
											lecturers?.find(lecturer => lecturer.user.id === value)?.user.cname || ''
										}
									/>
								))}
							</Box>
						)
					}}
					required
					fullWidth
					variant="outlined"
				>
					{lecturers.map(lecturer => (
						<MenuItem key={lecturer.user.id} value={lecturer.user.id}>
							{lecturer.matricId} {lecturer.user.cname} {lecturer.user.ename}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body2" sx={{ mb: 0.75, ml: 0.5, opacity: 0.8 }}>
					预设颜色
				</Typography>
				<ClassColorsRadio selectedColor={color} setSelectedColor={handleColorChange} />
			</Grid>
		</Grid>
	);
};

export default ClassForm;
