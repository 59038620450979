import React, { useEffect, useRef } from 'react';
import { useTokenStore } from '../../stores/useTokenStore';
import shallow from 'zustand/shallow';
import { LoginResponse } from '../../types';
import { isOnlineAtom } from '../../atoms';
import { useAtom } from 'jotai';

interface AuthProps {}

// helper component to eagerly check for login status
const Auth: React.FC<AuthProps> = () => {
	const [expiry, setToken, setExpiry] = useTokenStore(
		state => [state.expiry, state.setToken, state.setExpiry],
		shallow
	);
	const [isOnline, setIsOnline] = useAtom(isOnlineAtom);
	const tokenRefreshing = useRef(false);

	const invalidateToken = () => {
		setToken('');
		setExpiry(0);
	};

	const refreshToken = async () => {
		if (!navigator.onLine || tokenRefreshing.current) {
			return;
		}
		try {
			tokenRefreshing.current = true;
			const response = await fetch(`${import.meta.env.VITE_REST_API_URI}/refresh_token`, {
				credentials: 'include'
			});
			if (!response.ok) {
				invalidateToken();
				setIsOnline(true);
				return;
			}
			const data = (await response.json()) as LoginResponse;
			setToken(data.access_token);
			setExpiry(data.expiry);
			setIsOnline(true);
		} catch {
			setIsOnline(false);
		} finally {
			tokenRefreshing.current = false;
		}
	};

	useEffect(() => {
		let t: NodeJS.Timeout;
		if (!expiry) {
			return;
		}
		const now = new Date().getTime();
		const expire_date = new Date(expiry * 1000).getTime();
		if (expire_date < now) {
			invalidateToken();
			return;
		}
		const range = expire_date - now;

		t = setTimeout(refreshToken, range * 0.8);

		return () => {
			clearTimeout(t);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [expiry]);

	useEffect(() => {
		if (!expiry) {
			refreshToken().catch(() => {});
		}
	}, [isOnline]);

	useEffect(() => {
		if (!isOnline) {
			const t = setInterval(refreshToken, 1000);

			return () => {
				clearTimeout(t);
			};
		}
	}, [isOnline]);

	const handleVisibleChange = async () => {
		if (document.visibilityState === 'hidden') {
			return;
		}
		const now = new Date().getTime();
		const expire_date = new Date(expiry * 1000).getTime();
		if (expire_date < now) {
			await refreshToken();
		}
	};

	useEffect(() => {
		document.addEventListener('visibilitychange', handleVisibleChange, {
			passive: true
		});
		return () => {
			document.removeEventListener('visibilitychange', handleVisibleChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <></>;
};

export default Auth;
