import React, { useCallback, useEffect, useMemo } from 'react';
import { classCreateCategoryIdAtom } from '../../../../atoms';
import { Box, Dialog, DialogContent, Theme, useMediaQuery } from '@mui/material';
import { useClassCreateStore } from '../../../../stores/useClassCreateStore';
import {
	ClassFieldsFragment,
	ClassFieldsFragmentDoc,
	CreateTermMutationResult,
	Language,
	useCreateClassMutation,
	useCreateClassStudentsMutation,
	useCreateLessonsMutation,
	useCreateTermMutation,
	useGetCategoryCoursesQuery
} from '../../../../generated/api';
import { SlideUp } from '../../../../share-components/Transitions/SlideUp';
import { useUserPreferenceStore } from '../../../../stores/useUserPreferenceStore';
import ClassCreateDialogContent from './ClassCreateDialogContent';
import ClassCreateDialogStepper from './ClassCreateDialogStepper';
import ClassCreateDialogHeader from './ClassCreateDialogHeader';
import ClassCreateDialogActions from './ClassCreateDialogActions';
import shallow from 'zustand/shallow';
import { ApolloError, useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import { useNavigate, useParams } from 'react-router-dom';
import { CLASS_FIELDS } from '../../query';
import { monthToAbbreviations } from '../../../../utils/monthToAbbreviations';
import { getFirstDateOfDayInMonth } from '../../../../utils/getFirstDateOfDayInMonth';
import { dayToNumber } from '../../../../utils/daytoNumber';

interface ClassCreateDialogProps {}

const ClassCreateDialog: React.FC<ClassCreateDialogProps> = () => {
	const categoryId = useAtomValue(classCreateCategoryIdAtom);
	const language = useUserPreferenceStore(state => state.language);
	const { data } = useGetCategoryCoursesQuery({
		variables: { id: categoryId, language },
		skip: !categoryId
	});
	const courses = useMemo(
		() => (data?.node?.__typename === 'Category' ? data.node.courses : []),
		[data]
	);
	const [createClassMutation] = useCreateClassMutation({
		update: (cache, mutationResult) => {
			const createdClass = mutationResult.data?.createClass;
			if (!createdClass) {
				return;
			}
			cache.modify({
				id: cache.identify({
					id: courseId,
					__typename: 'Course'
				}),
				fields: {
					classes(existing = []) {
						const newClassRef = cache.writeFragment({
							data: createdClass,
							fragment: CLASS_FIELDS
						});
						return [...existing, newClassRef];
					}
				}
			});
		}
	});
	const [createClassStudentsMutation] = useCreateClassStudentsMutation();
	const [createTermMutation] = useCreateTermMutation();
	const [createLessonsMutation] = useCreateLessonsMutation();
	const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
	const [courseId, setCourseId, reset, setLecturers, setStep] = useClassCreateStore(
		state => [state.courseId, state.setCourseId, state.reset, state.setLecturers, state.setStep],
		shallow
	);
	const selectedCourse = useMemo(
		() => courses.find(course => course.id === courseId),
		[courseId, courses]
	);
	const navigate = useNavigate();
	const { cache } = useApolloClient();

	useEffect(() => {
		if (!selectedCourse) {
			return;
		}
		setLecturers(selectedCourse.lecturers.map(lecturer => lecturer.user.id));
	}, [selectedCourse]);

	useEffect(() => {
		if (courses.length === 0) {
			return;
		}
		if (!courses.find(course => course.id === courseId)) {
			setCourseId('');
			setLecturers([]);
			setStep(0);
		}
	}, [courses]);

	const handleClose = useCallback(() => {
		navigate(-1);
	}, [navigate]);

	const handleReset = useCallback(() => {
		reset();
	}, [reset]);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		const { step, setStep, ...data } = useClassCreateStore.getState();

		if (step < 2) {
			setStep((step + 1) as 1 | 2);
			return;
		}

		if (!data.spaceId) {
			delete data['spaceId'];
		}

		try {
			const res = await createClassMutation({
				variables: {
					input: {
						translations: (Object.keys(data.translations) as Language[]).map(language => ({
							...data.translations[language]!,
							language: language
						})),
						studentFeePerLesson: data.studentFeePerLesson,
						lecturerCommission: data.lecturerCommission / 100,
						courseId: courseId,
						spaceId: data.spaceId,
						lecturers: data.lecturers,
						color: data.color,
						termAutoGeneration: data.termAutoGenerationAvailability
							? data.termAutoGeneration
							: undefined
						// template: templateAvailability ? data.template : undefined
					},
					language: useUserPreferenceStore.getState().language
				}
			});
			if (res.data?.createClass) {
				const classId = res.data.createClass.id;
				const promises = [];
				let createTermPromiseIndex = -1;
				if (data.students.length > 0) {
					promises.push(
						createClassStudentsMutation({
							variables: { input: { classId, students: data.students } }
						})
					);
				}
				const byMonth = data.termAutoGeneration['byMonth'];
				if (data.createTerm && byMonth && data.termAutoGenerationAvailability) {
					createTermPromiseIndex = promises.length;
					promises.push(
						createTermMutation({
							variables: {
								input: {
									classId,
									name: `${monthToAbbreviations(
										data.createTerm.startFrom.getMonth()
									)} ${data.createTerm.startFrom.getFullYear()}`,
									studentFeePerLesson: data.studentFeePerLesson,
									lecturerCommission: data.lecturerCommission / 100,
									students: data.students,
									durationPerLesson: Math.ceil(
										(byMonth.endTime.getTime() - byMonth.startTime.getTime()) / 1000 / 60
									),
									lessonCount: data.createTerm.numberOfLessons
								}
							}
						})
					);
				}
				if (promises.length > 0) {
					const responses = await Promise.all(promises);

					cache.writeFragment<ClassFieldsFragment>({
						fragment: ClassFieldsFragmentDoc,
						variables: {
							language
						},
						id: cache.identify({ __ref: res.data.createClass.id, __typename: 'Class' }),
						data: {
							...res.data.createClass,
							hasOngoingTerm: true
						}
					});

					if (createTermPromiseIndex > -1) {
						const termId = (responses[createTermPromiseIndex] as CreateTermMutationResult).data
							?.createTerm?.id;

						if (termId && byMonth) {
							await createLessonsMutation({
								variables: {
									input: Array.from(new Array(data.createTerm.numberOfLessons)).map((_, index) => {
										const year = data.createTerm.startFrom.getFullYear();
										const month = data.createTerm.startFrom.getMonth();
										const date = getFirstDateOfDayInMonth(dayToNumber(byMonth.day), month, year);
										return {
											termId,
											startTime: new Date(
												year,
												month,
												date + 7 * index,
												byMonth.startTime.getHours(),
												byMonth.startTime.getMinutes(),
												byMonth.startTime.getSeconds()
											),
											endTime: new Date(
												year,
												month,
												date + 7 * index,
												byMonth.endTime.getHours(),
												byMonth.endTime.getMinutes(),
												byMonth.endTime.getSeconds()
											),
											spaceId: data.spaceId,
											students: data.students,
											lecturers: data.lecturers,
											color: data.color
										};
									})
								}
							});
						}
					}
				}
			}
			handleClose();
			handleReset();
			toast.success('班级创建成功!');
			// enqueueSnackbar('班级创建成功!', { variant: 'success' });
		} catch (err) {
			if (err instanceof ApolloError) {
				toast.error(`班级创建失败! ${err.message}`);
			} else {
				toast.error(`班级创建失败! ${err}`);
			}
		}
	};

	return (
		<Dialog
			open={!!categoryId}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth
			fullScreen={isXS}
			TransitionComponent={isXS ? SlideUp : undefined}
			PaperProps={{
				sx: { overflowY: 'hidden' }
			}}
		>
			<Box
				component="form"
				onSubmit={handleSubmit}
				sx={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'auto' }}
			>
				<ClassCreateDialogHeader handleClose={handleClose} handleReset={handleReset} isXS={isXS} />
				<DialogContent sx={{ pt: '30px', flex: 1 }} dividers={isXS}>
					<ClassCreateDialogStepper />
					<ClassCreateDialogContent courses={courses} />
				</DialogContent>
				<ClassCreateDialogActions handleClose={handleClose} handleReset={handleReset} isXS={isXS} />
			</Box>
		</Dialog>
	);
};

export const ClassCreateDialogRoute: React.FC = () => {
	const { id } = useParams();
	const setCategoryId = useUpdateAtom(classCreateCategoryIdAtom);

	useEffect(() => {
		if (!id) {
			return;
		}
		setCategoryId(id);
		return () => {
			setCategoryId('');
		};
	}, [id]);

	return <></>;
};

export default ClassCreateDialog;
