import React from 'react';
import { Box, BoxProps, Radio } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Done } from '@mui/icons-material';

interface ClassColorsRadioProps extends BoxProps {
	selectedColor: string;
	setSelectedColor: (selectedColor: string) => void;
}

const COLORS = [
	'#00ab55',
	'#188fff',
	'#54d62c',
	'#ffc107',
	'#ff4842',
	'#04297a',
	'#7a0c2f'
];

const ClassColorsRadio: React.FC<ClassColorsRadioProps> = ({
	selectedColor,
	setSelectedColor,
	...props
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedColor(event.target.value);
	};

	return (
		<Box {...props} sx={{ display: 'flex', ...props.sx }}>
			{COLORS.map(color => (
				<Radio
					key={color}
					checked={selectedColor === color}
					value={color}
					onChange={handleChange}
					name="color-radio-buttons"
					icon={
						<Box
							sx={{
								backgroundColor: color,
								fontSize: 'medium',
								width: '20px',
								height: '20px',
								display: 'flex',
								borderRadius: '50%',
								position: 'relative',
								alignItems: 'center',
								justifyContent: 'center',
								transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
							}}
						/>
					}
					checkedIcon={
						<Box
							sx={{
								color: '#fff',
								backgroundColor: color,
								fontSize: 'medium',
								width: '20px',
								height: '20px',
								display: 'flex',
								borderRadius: '50%',
								position: 'relative',
								alignItems: 'center',
								justifyContent: 'center',
								transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
								transform: 'scale(1.4)',
								'&:before': {
									opacity: '0.48',
									width: '100%',
									content: '""',
									height: '100%',
									borderRadius: '50%',
									position: 'absolute',
									boxShadow: `${color} 4px 4px 8px 0px`
								}
							}}
						>
							<Done sx={{ fontSize: '0.875em' }} />
						</Box>
					}
					sx={{
						'&, &.Mui-checked': {
							color: color
						}
					}}
				/>
			))}
		</Box>
	);
};

const ColorDot = styled(Radio)({
	// marginRight: 5
});

export default ClassColorsRadio;
