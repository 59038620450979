import { MutationUpdaterFn } from '@apollo/client';
import { GET_ADMINS, GET_LECTURERS, GET_STUDENTS, GET_USERS } from '../query';
import {
	GetAdminsQuery,
	GetAdminsQueryVariables,
	GetLecturersQuery,
	GetLecturersQueryVariables,
	GetStudentsQuery,
	GetStudentsQueryVariables,
	GetUsersQuery,
	UpdateUserInput,
	UpdateUserMutation,
	User,
	UserRole
} from '../../../generated/api';
import { useIsMasterStore } from '../../../stores/useIsMasterStore';

export const updateUserUpdate =
	(
		input: UpdateUserInput,
		oldUser: User
	): MutationUpdaterFn<UpdateUserMutation> =>
	(cache, mutationResult) => {
		const updatedUser = mutationResult.data?.updateUser;

		if (!updatedUser) {
			return;
		}

		// update queries when status is changed since status is a variable of query
		// ex: if status is changed,
		// a user has to remove from its old query list and insert into the new query list that corresponding to its status

		if (input.status) {
			const isMaster = useIsMasterStore.getState().isMaster;
			const roleFilter = {
				roles: !isMaster ? [UserRole.Student, UserRole.Lecturer] : undefined,
				includeNoRole: true
			};

			// remove user from old query list
			{
				const variables = {
					status: oldUser.status,
					roleFilter
				};

				// read all user with old status
				const data = cache.readQuery<GetUsersQuery>({
					query: GET_USERS,
					variables
				});

				// remove updated user from old status's user list
				if (data) {
					cache.writeQuery({
						query: GET_USERS,
						variables,
						data: {
							users: (data.users as User[]).filter(u => u.id !== updatedUser.id)
						}
					});
				}
			}

			// add updated user into new user list
			{
				const variables = {
					status: updatedUser.status,
					roleFilter
				};
				const data = cache.readQuery<GetUsersQuery>({
					query: GET_USERS,
					variables
				});

				if (data) {
					cache.writeQuery({
						query: GET_USERS,
						variables,
						data: {
							users: [...(data.users as User[]), updatedUser]
						}
					});
				}
			}
		}

		if (input.student?.status && oldUser.student && updatedUser.student) {
			// remove student from old status's student list
			{
				const variables: GetStudentsQueryVariables = {
					status: oldUser.student.status
				};

				const data = cache.readQuery<GetStudentsQuery>({
					query: GET_STUDENTS,
					variables
				});

				if (data) {
					cache.writeQuery<GetStudentsQuery>({
						query: GET_STUDENTS,
						variables,
						data: {
							students: data.students.filter(s => s.user.id !== updatedUser.id)
						}
					});
				}
			}

			// add student into new student list corresponding to new status
			{
				const variables: GetStudentsQueryVariables = {
					status: input.student.status
				};

				const data = cache.readQuery<GetStudentsQuery>({
					query: GET_STUDENTS,
					variables
				});

				if (data) {
					cache.writeQuery<GetStudentsQuery>({
						query: GET_STUDENTS,
						variables,
						data: {
							students: [
								...data.students,
								{
									...updatedUser.student,
									user: updatedUser
								}
							]
						}
					});
				}
			}
		}

		if (input.lecturer?.status && oldUser.lecturer && updatedUser.lecturer) {
			// remove lecturer from old status's lecturer list
			{
				const variables: GetLecturersQueryVariables = {
					status: oldUser.lecturer.status
				};

				const data = cache.readQuery<GetLecturersQuery>({
					query: GET_LECTURERS,
					variables
				});

				if (data) {
					cache.writeQuery<GetLecturersQuery>({
						query: GET_LECTURERS,
						variables,
						data: {
							lecturers: data.lecturers.filter(
								s => s.user.id !== updatedUser.id
							)
						}
					});
				}
			}

			// add lecturer into new lecturer list corresponding to new status
			{
				const variables: GetLecturersQueryVariables = {
					status: input.lecturer.status
				};

				const data = cache.readQuery<GetLecturersQuery>({
					query: GET_LECTURERS,
					variables
				});

				if (data) {
					cache.writeQuery<GetLecturersQuery>({
						query: GET_LECTURERS,
						variables,
						data: {
							lecturers: [
								...data.lecturers,
								{
									...updatedUser.lecturer,
									user: updatedUser
								}
							]
						}
					});
				}
			}
		}

		if (input.admin?.status && oldUser.admin && updatedUser.admin) {
			// remove admin from old status's admin list
			{
				const variables: GetAdminsQueryVariables = {
					status: oldUser.admin.status
				};

				const data = cache.readQuery<GetAdminsQuery>({
					query: GET_ADMINS,
					variables
				});

				if (data) {
					cache.writeQuery<GetAdminsQuery>({
						query: GET_ADMINS,
						variables,
						data: {
							admins: data.admins.filter(s => s.user.id !== updatedUser.id)
						}
					});
				}
			}

			// add admin into new admin list corresponding to new status
			{
				const variables: GetAdminsQueryVariables = {
					status: input.admin.status
				};

				const data = cache.readQuery<GetAdminsQuery>({
					query: GET_ADMINS,
					variables
				});

				if (data) {
					cache.writeQuery<GetAdminsQuery>({
						query: GET_ADMINS,
						variables,
						data: {
							admins: [
								...data.admins,
								{
									...updatedUser.admin,
									user: updatedUser
								}
							]
						}
					});
				}
			}
		}
	};
