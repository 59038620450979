import React from 'react';
import {
	AdminStatus,
	Gender,
	LecturerStatus,
	StudentPhone,
	StudentStatus,
	User,
	UserStatus
} from 'generated/api';
import { UserDetailsItemKey } from '../../../../types';
import {
	InputAdornment,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField
} from '@mui/material';
import { UserDetailsItemRecord } from '../../../../constants';
import { useUserUpdateStore } from '../../../../stores/useUserUpdateStore';
import shallow from 'zustand/shallow';
import StudentPhones from '../../../../share-components/User/StudentPhones';

interface UserDetailsUpdateDialogContentProps {
	user: User;
	itemKey: UserDetailsItemKey;
}

const UserDetailsUpdateDialogContent: React.FC<UserDetailsUpdateDialogContentProps> =
	({ user, itemKey }) => {
		const label = UserDetailsItemRecord[itemKey];
		const [variables, setVariables] = useUserUpdateStore(
			state => [state.variables, state.setVariables],
			shallow
		);

		const handleChange = (
			event:
				| React.ChangeEvent<{ name?: string | undefined; value: unknown }>
				| SelectChangeEvent<any>
		) => {
			const target = event.target;
			const name = target.name;
			if (!name) {
				return;
			}
			let value;
			switch (name) {
				case 'phone':
					if (
						isNaN(Number(target.value)) ||
						(target.value as string).length > 10
					) {
						return;
					}
					value =
						(target.value as string).length > 0 ? '+60' + target.value : '';
					break;
				case 'password':
					if ((target.value as string) === '') {
						setVariables({});
						return;
					}
					value = target.value;
					break;
				case 'gender':
				case 'email':
				case 'cname':
				case 'ename':
				case 'ic':
				case 'address':
				case 'status':
					value = target.value;
					break;
				default:
					return;
			}
			setVariables({ [name]: value });
		};

		const handleStudentChange = (
			event:
				| React.ChangeEvent<{ name?: string | undefined; value: unknown }>
				| SelectChangeEvent<any>
		) => {
			const name = event.target.name;
			if (!name) {
				return;
			}
			let value;
			switch (name) {
				case 'status':
				case 'matricId':
					value = event.target.value;
					break;
				default:
					return;
			}
			setVariables({ student: { [name]: value } });
		};

		const handleStudentPhonesChange = (phones: StudentPhone[]) => {
			setVariables({ student: { phones: phones } });
		};

		const handleLecturerChange = (
			event:
				| React.ChangeEvent<{ name?: string | undefined; value: unknown }>
				| SelectChangeEvent<any>
		) => {
			const name = event.target.name;
			if (!name) {
				return;
			}
			let value;
			switch (name) {
				case 'status':
				case 'matricId':
					value = event.target.value;
					break;
				default:
					return;
			}
			setVariables({ lecturer: { [name]: value } });
		};

		const handleAdminChange = (
			event:
				| React.ChangeEvent<{ name?: string | undefined; value: unknown }>
				| SelectChangeEvent<any>
		) => {
			const name = event.target.name;
			if (!name) {
				return;
			}
			let value;
			switch (name) {
				case 'isMaster':
					value = Boolean(event.target.value);
					break;
				case 'status':
				case 'matricId':
					value = event.target.value;
					break;
				default:
					return;
			}
			setVariables({ admin: { [name]: value } });
		};

		switch (itemKey) {
			case UserDetailsItemKey.CNAME:
				return (
					<TextField
						label={label}
						name="cname"
						value={variables.cname !== undefined ? variables.cname : user.cname}
						onChange={handleChange}
						variant="standard"
						fullWidth
					/>
				);
			case UserDetailsItemKey.ENAME:
				return (
					<TextField
						label={label}
						name="ename"
						value={variables.ename !== undefined ? variables.ename : user.ename}
						onChange={handleChange}
						variant="standard"
						fullWidth
					/>
				);
			case UserDetailsItemKey.GENDER:
				return (
					<Select
						value={variables.gender || user.gender}
						name="gender"
						onChange={handleChange}
						variant="standard"
						fullWidth
					>
						<MenuItem value={Gender.Female}>女</MenuItem>
						<MenuItem value={Gender.Male}>男</MenuItem>
					</Select>
				);
			case UserDetailsItemKey.IC:
				return (
					<TextField
						label={label}
						name="ic"
						value={variables.ic !== undefined ? variables.ic : user.ic}
						onChange={handleChange}
						variant="standard"
						fullWidth
					/>
				);
			case UserDetailsItemKey.PHONE:
				return (
					<TextField
						label={label}
						name="phone"
						type="tel"
						value={(variables.phone !== undefined && variables.phone !== null
							? variables.phone
							: user.phone
						).slice(3)}
						onChange={handleChange}
						fullWidth
						variant="standard"
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">+60</InputAdornment>
							)
						}}
					/>
				);
			case UserDetailsItemKey.ADDRESS:
				return (
					<TextField
						label={label}
						name="address"
						value={
							variables.address !== undefined ? variables.address : user.address
						}
						onChange={handleChange}
						multiline
						variant="standard"
						fullWidth
					/>
				);
			case UserDetailsItemKey.STATUS:
				return (
					<Select
						value={variables.status || user.status || ''}
						name="status"
						onChange={handleChange}
						variant="standard"
						fullWidth
					>
						<MenuItem value={UserStatus.Active}>在籍</MenuItem>
						<MenuItem value={UserStatus.Inactive}>不在籍</MenuItem>
					</Select>
				);
			case UserDetailsItemKey.EMAIL:
				return (
					<TextField
						label={label}
						name="email"
						value={variables.email !== undefined ? variables.email : user.email}
						onChange={handleChange}
						type="email"
						variant="standard"
						fullWidth
					/>
				);
			case UserDetailsItemKey.PASSWORD:
				return (
					<TextField
						label={label}
						name="password"
						value={variables.password || ''}
						onChange={handleChange}
						required
						variant="standard"
						fullWidth
					/>
				);
			case UserDetailsItemKey.STUDENT_MATRIC_ID:
				return (
					<TextField
						label={label}
						name="matricId"
						value={
							variables.student?.matricId !== undefined
								? variables.student.matricId
								: user.student?.matricId || ''
						}
						inputProps={{
							pattern: '^[0-9]{7}$'
						}}
						onChange={handleStudentChange}
						required
						variant="standard"
						fullWidth
					/>
				);
			case UserDetailsItemKey.STUDENT_PHONES:
				return (
					<StudentPhones
						phones={
							variables.student?.phones ||
							user.student?.phones?.map(phone => ({
								name: phone.name,
								number: phone.number
							})) ||
							[]
						}
						setPhones={handleStudentPhonesChange}
					/>
				);
			case UserDetailsItemKey.STUDENT_STATUS:
				return (
					<Select
						label={label}
						name="status"
						value={variables.student?.status || user.student?.status || ''}
						onChange={handleStudentChange}
						variant="standard"
						fullWidth
					>
						<MenuItem value={StudentStatus.Active}>在籍</MenuItem>
						<MenuItem value={StudentStatus.Temporary}>试课</MenuItem>
						<MenuItem value={StudentStatus.Inactive}>不在籍</MenuItem>
					</Select>
				);
			case UserDetailsItemKey.LECTURER_MATRIC_ID:
				return (
					<TextField
						label={label}
						name="matricId"
						value={
							variables.lecturer?.matricId !== undefined
								? variables.lecturer.matricId
								: user.lecturer?.matricId || ''
						}
						inputProps={{
							pattern: '^L[0-9]{3,}$'
						}}
						onChange={handleLecturerChange}
						required
						variant="standard"
						fullWidth
					/>
				);
			case UserDetailsItemKey.LECTURER_STATUS:
				return (
					<Select
						label={label}
						name="status"
						value={variables.lecturer?.status || user.lecturer?.status || ''}
						onChange={handleLecturerChange}
						variant="standard"
						fullWidth
					>
						<MenuItem value={LecturerStatus.Active}>在籍</MenuItem>
						<MenuItem value={LecturerStatus.Inactive}>不在籍</MenuItem>
					</Select>
				);
			case UserDetailsItemKey.ADMIN_MATRIC_ID:
				return (
					<TextField
						label={label}
						name="matricId"
						value={
							variables.admin?.matricId !== undefined
								? variables.admin.matricId
								: user.admin?.matricId || ''
						}
						inputProps={{
							pattern: '^A[0-9]{3,}$'
						}}
						onChange={handleAdminChange}
						required
						variant="standard"
						fullWidth
					/>
				);
			case UserDetailsItemKey.ADMIN_IS_MASTER:
				return (
					<Select
						label={label}
						name="isMaster"
						value={
							variables.admin?.isMaster !== undefined
								? Number(variables.admin.isMaster)
								: Number(user.admin?.isMaster)
						}
						onChange={handleAdminChange}
						variant="standard"
						fullWidth
					>
						<MenuItem value={1}>是</MenuItem>
						<MenuItem value={0}>否</MenuItem>
					</Select>
				);
			case UserDetailsItemKey.ADMIN_STATUS:
				return (
					<Select
						label={label}
						name="status"
						value={variables.admin?.status || user.admin?.status || ''}
						onChange={handleAdminChange}
						variant="standard"
						fullWidth
					>
						<MenuItem value={AdminStatus.Active}>在籍</MenuItem>
						<MenuItem value={AdminStatus.Inactive}>不在籍</MenuItem>
					</Select>
				);
			default:
				return <></>;
		}
	};

export default UserDetailsUpdateDialogContent;
