import React from 'react';
import { useClassCreateStore } from '../../../../stores/useClassCreateStore';
import ClassForm from '../../../../share-components/Class/ClassForm';
import ClassTermAutoGenerationForm from '../../../../share-components/Class/ClassTermAutoGenerationForm';
import { CourseFieldsFragment } from '../../../../generated/api';
import ClassStudentListSelector from '../../../../share-components/Class/ClassStudentListSelector';
import ClassTermAutoGenerationCreateTermAndLessons from '../../../../share-components/Class/ClassTermAutoGenerationCreateTermAndLessons';

interface ClassCreateDialogContentProps {
	courses: CourseFieldsFragment[];
}

const ClassCreateDialogContent: React.FC<ClassCreateDialogContentProps> = ({ courses }) => {
	const {
		step,
		courseId,
		translations,
		studentFeePerLesson,
		lecturerCommission,
		spaceId,
		students,
		lecturers,
		color,
		termAutoGenerationAvailability,
		termAutoGenerateMethod,
		termAutoGeneration,
		setCourseId,
		setName,
		setDescription,
		setStudentFeePerLesson,
		setLecturerCommission,
		setSpaceId,
		setStudents,
		setLecturers,
		setColor,
		setTermAutoGenerationAvailability,
		setTermAutoGenerateMethod,
		setTermAutoGeneration
	} = useClassCreateStore();

	switch (step) {
		case 0:
			return (
				<ClassForm
					courses={courses}
					courseId={courseId}
					translations={translations}
					studentFeePerLesson={studentFeePerLesson}
					lecturerCommission={lecturerCommission}
					spaceId={spaceId}
					lecturers={lecturers}
					color={color}
					setCourseId={setCourseId}
					setName={setName}
					setDescription={setDescription}
					setStudentFeePerLesson={setStudentFeePerLesson}
					setLecturerCommission={setLecturerCommission}
					setSpaceId={setSpaceId}
					setLecturers={setLecturers}
					setColor={setColor}
				/>
			);
		case 1:
			return (
				<ClassTermAutoGeneration
					termAutoGenerationAvailability={termAutoGenerationAvailability}
					setTermAutoGenerationAvailability={setTermAutoGenerationAvailability}
					termAutoGenerateMethod={termAutoGenerateMethod}
					setTermAutoGenerateMethod={setTermAutoGenerateMethod}
					termAutoGeneration={termAutoGeneration}
					setTermAutoGeneration={setTermAutoGeneration}
				/>
			);
		case 2:
			return (
				<ClassStudentListSelector selectedStudents={students} setSelectedStudents={setStudents} />
			);
	}
};

const ClassTermAutoGeneration: React.FC<
	React.ComponentProps<typeof ClassTermAutoGenerationForm>
> = props => {
	const setCreateTerm = useClassCreateStore(state => state.setCreateTerm);

	return (
		<>
			<ClassTermAutoGenerationForm {...props} />
			<ClassTermAutoGenerationCreateTermAndLessons
				termAutoGeneration={props.termAutoGeneration}
				setCreateTerm={setCreateTerm}
			/>
		</>
	);
};

export default ClassCreateDialogContent;
