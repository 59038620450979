import React from 'react';
import { SxProps, Typography } from '@mui/material';
import { AdminStatus, User } from '../../../generated/api';
import UserDetailsGroup from './UserDetailsGroup';
import UserDetailsItem from './UserDetailsItem';
import { UserDetailsItemKey } from '../../../types';
import UserDetailsAdminCreate from './UserDetailsRoleCreate/UserDetailsAdminCreate';
import UserDetailsAdminDelete from './UserDetailsRoleDelete/UserDetailsAdminDelete';

interface UserAdminDetailsProps {
	user: User;
}

const UserAdminDetails: React.FC<UserAdminDetailsProps> = ({ user }) => {
	return (
		<div>
			{user.admin ? (
				<>
					<UserDetailsGroup title="管理资料">
						<UserDetailsItem itemKey={UserDetailsItemKey.ADMIN_MATRIC_ID}>
							<Typography sx={userDetailsValueStyle}>
								{user.admin.matricId}
							</Typography>
						</UserDetailsItem>
						<UserDetailsItem itemKey={UserDetailsItemKey.ADMIN_STATUS}>
							<Typography sx={userDetailsValueStyle}>
								{user.admin.status === AdminStatus.Active ? '在籍' : '不在籍'}
							</Typography>
						</UserDetailsItem>
						<UserDetailsItem itemKey={UserDetailsItemKey.ADMIN_IS_MASTER}>
							<Typography sx={userDetailsValueStyle}>
								{user.admin.isMaster ? '是' : '否'}
							</Typography>
						</UserDetailsItem>
					</UserDetailsGroup>
					<UserDetailsAdminDelete user={user} />
				</>
			) : (
				<UserDetailsAdminCreate user={user} />
			)}
		</div>
	);
};

const userDetailsValueStyle: SxProps = {
	width: '70%'
};

export default UserAdminDetails;
