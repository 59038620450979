import React from 'react';
import CreateStudentForm from '../../../../share-components/User/CreateStudentForm';
import { useUserDetailsRoleCreateStore } from '../../../../stores/useUserDetailsRoleCreateStore';
import shallow from 'zustand/shallow';
import { Button, Paper } from '@mui/material';
import { User, useUpdateUserMutation } from '../../../../generated/api';
import { useAtomValue } from 'jotai/utils';
import { isMasterAtom } from '../../../../atoms';
import { createStudentUpdate } from '../../update/createStudentUpdate';

interface UserDetailsStudentCreateProps {
	user: User;
}

const UserDetailsStudentCreate: React.FC<UserDetailsStudentCreateProps> = ({
	user
}) => {
	const [student, setStudent] = useUserDetailsRoleCreateStore(
		state => [state.student, state.setStudent],
		shallow
	);
	const isMaster = useAtomValue(isMasterAtom);
	const [updateUserMutation] = useUpdateUserMutation({
		variables: {
			id: user.id,
			input: {
				student
			},
			isMaster
		},
		optimisticResponse: {
			__typename: 'Mutation',
			updateUser: {
				...user,
				student: {
					...student,
					user: {
						id: user.id
					}
				}
			}
		},
		update: createStudentUpdate
	});

	const handleSubmit = async (event: React.FormEvent<{}>) => {
		event.preventDefault();

		try {
			await updateUserMutation({});
		} catch {}
	};

	return (
		<form onSubmit={handleSubmit}>
			<Paper
				variant="outlined"
				sx={{ padding: '20px', background: 'transparent' }}
			>
				<CreateStudentForm student={student} setStudent={setStudent} />
			</Paper>
			<br />
			<Button
				type="submit"
				variant="contained"
				color="primary"
				fullWidth
				sx={theme => ({
					color: theme.palette.mode === 'dark' ? '#000' : '#fff'
				})}
			>
				添加此用户为学生
			</Button>
		</form>
	);
};

export default UserDetailsStudentCreate;
