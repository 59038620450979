import React, { useMemo } from 'react';
import { Button, Theme } from '@mui/material';
import TableList from '../../common/TableList';
import { TableFilterHolder, useTableFilterStore } from '../../../stores/useTableFilterStore';
import { useGetTermStudentsQuery, useUpdateTermStudentsMutation } from '../../../generated/api';
import { HeadCell } from '../../../types';
import TermStudentCreateDialog from './TermStudentCreateDialog';
import { useUpdateAtom } from 'jotai/utils';
import { termStudentCreateDialogOpenAtom } from '../../../atoms';
import shallow from 'zustand/shallow';
import { toast } from 'react-toastify';

const HEAD_CELLS: HeadCell[] = [
	{
		id: 'matricId',
		label: '学号',
		disablePadding: false
	},
	{
		id: 'cname',
		label: '中文名',
		disablePadding: false
	},
	{
		id: 'ename',
		label: '英文名',
		disablePadding: false
	}
];

interface TermStudentsProps {
	termId: string;
}

const TermStudents: React.FC<TermStudentsProps> = ({ termId }) => {
	const { data } = useGetTermStudentsQuery({ variables: { id: termId }, skip: !termId });
	const termStudents = useMemo(
		() => (data?.node?.__typename === 'Term' ? data.node.students : []),
		[data]
	);
	const items = useMemo(
		() =>
			data?.node?.__typename === 'Term'
				? data.node.students.map(student => ({
						id: student.user.id,
						matricId: student.matricId,
						cname: student.user.cname,
						ename: student.user.ename
				  }))
				: [],
		[termStudents]
	);
	const [selected, setSelected] = useTableFilterStore(
		state => [
			state.filters[TableFilterHolder.TERM_STUDENT].selected,
			state.setSelected(TableFilterHolder.TERM_STUDENT)
		],
		shallow
	);
	const [updateTermStudents] = useUpdateTermStudentsMutation();
	const setTermStudentCreateDialogOpen = useUpdateAtom(termStudentCreateDialogOpenAtom);

	const handleClick = async () => {
		if (selected.length === 0) {
			setTermStudentCreateDialogOpen(true);
		} else {
			try {
				await updateTermStudents({
					variables: {
						id: termId,
						input: {
							students: {
								deleteStudents: selected
							}
						}
					}
				});
				setSelected([]);
				toast.success('班级学期学生删除成功!');
			} catch (err) {
				toast.error(`班级学期学生删除失败! ${err}`);
			}
		}
	};

	return (
		<>
			<Button
				variant="contained"
				onClick={handleClick}
				fullWidth
				sx={{
					mt: 2,
					mb: 2,
					color: (theme: Theme) => (theme.palette.mode === 'light' ? '#fff' : '#000')
				}}
				color={selected.length === 0 ? 'primary' : 'error'}
			>
				{selected.length === 0 ? '添加新的学生' : `删除选中的${selected.length}位学生`}
			</Button>
			<TableList
				holder={TableFilterHolder.TERM_STUDENT}
				items={items}
				headCells={HEAD_CELLS}
				tableStyle={{ minWidth: 200 }}
				selectable
				disableFirstAndLastPageButton
			/>
			<TermStudentCreateDialog termId={termId} termStudents={termStudents} />
		</>
	);
};

export default TermStudents;
