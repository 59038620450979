import React from 'react';
import { useUserRoleCreateStore } from '../../../../stores/useUserRoleCreateStore';
import shallow from 'zustand/shallow';
import CreateAdminForm from '../../../../share-components/User/CreateAdminForm';
import { useGetAdminsQuery, useGetUsersQuery } from '../../../../generated/api';
import UserRoleCreateUserSelection from './UserRoleCreateUserSelection';

interface UserRoleCreateAdminProps {}

const UserRoleCreateAdmin: React.FC<UserRoleCreateAdminProps> = () => {
	const [admin, setAdmin] = useUserRoleCreateStore(
		state => [state.admin, state.setAdmin],
		shallow
	);
	const { data: userData, loading: userLoading } = useGetUsersQuery({
		variables: {
			roleFilter: {
				includeNoRole: true
			}
		}
	});
	const { data: adminData, loading: adminLoading } = useGetAdminsQuery();
	const users =
		(userData &&
			adminData &&
			userData.users.filter(
				user => !adminData.admins.find(admin => admin.user.id === user.id)
			)) ||
		[];

	return (
		<>
			<UserRoleCreateUserSelection
				users={users}
				loading={userLoading || adminLoading}
				selectedUserId={admin.userId}
				setRoleState={setAdmin}
			/>
			<CreateAdminForm
				admin={admin}
				setAdmin={setAdmin}
				disabled={admin.userId === ''}
			/>
		</>
	);
};

export default UserRoleCreateAdmin;
