import React from 'react';
import { TabPanel } from '@mui/lab';
import TermForm from '../../../../share-components/Term/TermForm';
import TermCreateDialogStudents from './TermCreateDialogStudents';

interface TermCreateDialogContentProps {
	tabs?: React.ReactNode;
	classId: string;
	name: string;
	durationPerLesson: number;
	studentFeePerLesson: number;
	lecturerCommission: number;
	lessonCount: number;
	classStudents: {
		__typename?: 'Student' | undefined;
		matricId: string;
		user: { __typename?: 'User' | undefined; id: string; cname: string; ename: string };
	}[];
	selectedStudents: string[];
	setName: (name: string) => void;
	setDurationPerLesson: (durationPerLesson: number) => void;
	setStudentFeePerLesson: (studentFeePerLesson: number) => void;
	setLecturerCommission: (lecturerCommission: number) => void;
	setLessonCount: (lessonCount: number) => void;
	setSelectedStudents: (selectedStudents: string[]) => void;
}

const TermCreateDialogContent: React.FC<TermCreateDialogContentProps> = ({
	tabs,
	classId,
	name,
	durationPerLesson,
	studentFeePerLesson,
	lecturerCommission,
	lessonCount,
	classStudents,
	selectedStudents,
	setName,
	setDurationPerLesson,
	setStudentFeePerLesson,
	setLecturerCommission,
	setLessonCount,
	setSelectedStudents
}) => {
	return (
		<>
			{tabs}
			<TabPanel value="1" sx={{ p: 0 }}>
				<TermForm
					name={name}
					durationPerLesson={durationPerLesson}
					studentFeePerLesson={studentFeePerLesson}
					lecturerCommission={lecturerCommission}
					lessonCount={lessonCount}
					setName={setName}
					setDurationPerLesson={setDurationPerLesson}
					setStudentFeePerLesson={setStudentFeePerLesson}
					setLecturerCommission={setLecturerCommission}
					setLessonCount={setLessonCount}
				/>
			</TabPanel>
			<TabPanel value="2" sx={{ p: 0 }}>
				<TermCreateDialogStudents
					classStudents={classStudents}
					selectedStudents={selectedStudents}
					setSelectedStudents={setSelectedStudents}
				/>
			</TabPanel>
		</>
	);
};

export default TermCreateDialogContent;
