import React, { useCallback, useEffect, useMemo } from 'react';
import {
	Box,
	IconButton,
	Paper,
	PaperProps,
	StyledComponentProps,
	Table,
	TableBody,
	TableContainer,
	TableHeadProps,
	TablePagination,
	Theme,
	useMediaQuery,
	useTheme
} from '@mui/material';
import TableListHead from './TableListHead';
import TableToolbar from './TableToolbar';
import { Order, TableItemType, TableListToolbar } from '../../types';
import { TableFilterHolder, useTableFilterStore } from '../../stores/useTableFilterStore';
import { useLocation } from 'react-router-dom';
import TableItem from './TableItem';
import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage } from '@mui/icons-material';
import { styled, SxProps } from '@mui/material/styles';
import shallow from 'zustand/shallow';
import SimpleBar from 'simplebar-react';

export function desc<T>(a: T, b: T, orderBy: keyof T) {
	//@ts-ignore
	if (b[orderBy]['value']) {
		//@ts-ignore
		if (b[orderBy]['value'] < a[orderBy]['value']) {
			return -1;
		}
		//@ts-ignore
		if (b[orderBy]['value'] > a[orderBy]['value']) {
			return 1;
		}
		return 0;
	}
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function stableSort<T>(array: Array<T>, cmp: (a: T, b: T) => number): Array<T> {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = cmp(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map(el => el[0]);
}

function getSorting<Key extends keyof any>(
	order: Order,
	orderBy: Key,
	sorter: typeof desc = desc
): (
	a: { [key in Key]: number | string | any },
	b: { [key in Key]: number | string | any }
) => number {
	return order === 'desc' ? (a, b) => sorter(a, b, orderBy) : (a, b) => -sorter(a, b, orderBy);
}

interface TablePaginationActionsProps {
	count: number;
	page: number;
	rowsPerPage: number;
	onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
	disableFirstAndLastPageButton: boolean;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
	const theme = useTheme();
	const { count, page, rowsPerPage, onPageChange, disableFirstAndLastPageButton } = props;
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const showFirstAndLastPageButton = useMemo(
		() => (disableFirstAndLastPageButton ? false : !mdDown),
		[mdDown, disableFirstAndLastPageButton]
	);

	const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, 0);
	};

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, page + 1);
	};

	const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
	};

	return (
		<Box
			sx={{
				flexShrink: 0,
				marginLeft: 2.5
			}}
		>
			{showFirstAndLastPageButton && (
				<IconButton
					onClick={handleFirstPageButtonClick}
					disabled={page === 0}
					aria-label="first page"
					size="large"
				>
					{theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
				</IconButton>
			)}
			<IconButton
				onClick={handleBackButtonClick}
				disabled={page === 0}
				aria-label="previous page"
				size="large"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton
				onClick={handleNextButtonClick}
				disabled={page >= Math.ceil(count / rowsPerPage) - 1}
				aria-label="next page"
				size="large"
			>
				{theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
			{showFirstAndLastPageButton && (
				<IconButton
					onClick={handleLastPageButtonClick}
					disabled={page >= Math.ceil(count / rowsPerPage) - 1}
					aria-label="last page"
					size="large"
				>
					{theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
				</IconButton>
			)}
		</Box>
	);
}

interface TableListContentProps {
	holder: TableFilterHolder;
	items: any[];
	headCells: any[];
	actions?: any[];
	clickFunc?: (props: any) => void;
	toolBar?: TableListToolbar;
	selectable?: boolean;
	contextMenu?: (params: { closeContextMenu: () => void; item: any }) => JSX.Element[];
	tableStyle?: SxProps<Theme>;
	disableFirstAndLastPageButton?: boolean;
	sorter?: typeof desc;
	tabBar?: React.ReactNode;
	filter?: React.ReactNode;
	paperProps?: PaperProps;
	tableHeadProps?: TableHeadProps;
}

const TableListContent: React.FC<TableListContentProps> = ({
	holder,
	items,
	headCells,
	actions = [],
	clickFunc,
	toolBar,
	selectable = false,
	contextMenu,
	tableStyle,
	disableFirstAndLastPageButton = false,
	sorter,
	tabBar,
	filter: filterPanel,
	paperProps,
	tableHeadProps
}) => {
	const location = useLocation();

	const [
		order,
		orderBy,
		rowsPerPage,
		page,
		keywords,
		startFrom,
		endAt,
		setSelected,
		setRowsPerPage,
		setPage
	] = useTableFilterStore(
		state => [
			state.filters[holder].order,
			state.filters[holder].orderBy,
			state.filters[holder].rowsPerPage,
			state.filters[holder].page,
			state.filters[holder].keywords,
			state.filters[holder].startFrom,
			state.filters[holder].endAt,
			state.setSelected(holder),
			state.setRowsPerPage(holder),
			state.setPage(holder)
		],
		shallow
	);

	const keys = headCells.map(headCell => headCell.id);

	useEffect(() => {
		if (page !== 0) {
			setPage(0);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	useEffect(() => {
		const pages = items.length / rowsPerPage - 1;

		if (page > pages) {
			const newPage = Math.ceil(pages);
			setPage(newPage >= 0 ? newPage : 0);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = items.map(r => r.id);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handlePageChange = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const search = (arr: any[]): any[] => {
		if (keywords === '') return arr;

		return arr.filter(
			s =>
				JSON.stringify(s, (key, value) => {
					if (React.isValidElement(value)) {
						return undefined;
					}
					return value;
				})
					.toLowerCase()
					.indexOf(keywords.toLowerCase()) > -1
		);
	};

	const filterDate = (arr: any[]): any[] => {
		let newArr: any[] = [];
		for (const s of arr) {
			if (!s['createdAt'] || (!startFrom && !endAt)) {
				newArr = arr;
				break;
			}
			let createdAt: number;
			if (typeof s['createdAt'] === 'object' && s['createdAt']['value']) {
				createdAt = new Date(s['createdAt']['value']).getTime();
			} else {
				createdAt = new Date(s['createdAt']).getTime();
			}
			if (startFrom && endAt) {
				if (createdAt >= new Date(startFrom).getTime() && createdAt <= new Date(endAt).getTime()) {
					newArr.push(s);
				}
			} else if (startFrom) {
				if (createdAt >= new Date(startFrom).getTime()) {
					newArr.push(s);
				}
			} else if (endAt) {
				if (createdAt <= new Date(endAt).getTime()) {
					newArr.push(s);
				}
			}
		}
		return newArr;
	};

	const filteredUsers = search(stableSort(filterDate(items), getSorting(order, orderBy, sorter)));

	useEffect(() => {
		if (filteredUsers.length > rowsPerPage) {
			setPage(0);
		}
	}, [filteredUsers.length, rowsPerPage]);

	return (
		<Root variant="outlined" {...paperProps}>
			{tabBar}
			{filterPanel}
			{toolBar && <TableToolbar holder={holder} toolBar={toolBar} items={items} />}
			<Container>
				<SimpleBar>
					<StyledTable stickyHeader sx={tableStyle}>
						<TableListHead
							holder={holder}
							headCells={headCells}
							onSelectAllClick={handleSelectAllClick}
							rowCount={items.length}
							hasActions={actions.length > 0}
							selectable={selectable}
							tableHeadProps={tableHeadProps}
						/>
						<TableBody
							sx={{
								'& > .MuiTableRow-root': {
									'&:last-child': {
										'& > .MuiTableCell-root': {
											borderBottom: 'none'
										}
									}
								}
							}}
						>
							{filteredUsers
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((item, index) => (
									<TableItem
										key={item.id}
										holder={holder}
										item={item}
										keys={keys}
										actions={actions}
										clickFunc={clickFunc}
										labelId={`table-checkbox-${index}`}
										showToolbar={toolBar !== undefined}
										selectable={selectable}
										contextMenu={contextMenu}
									/>
								))}
						</TableBody>
					</StyledTable>
				</SimpleBar>
			</Container>
			<TablePagination
				rowsPerPageOptions={[5, 10, 20, 30]}
				component="div"
				count={filteredUsers?.length || 0}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handlePageChange}
				onRowsPerPageChange={handleRowsPerPageChange}
				ActionsComponent={props => (
					<TablePaginationActions
						{...props}
						disableFirstAndLastPageButton={disableFirstAndLastPageButton}
					/>
				)}
				sx={theme => ({
					borderTop: `1px solid ${theme.palette.divider}`
				})}
			/>
		</Root>
	);
};

const Root = styled(Paper)(({ theme }) => ({
	// background: theme.palette.background.paper,
	// border: theme.palette.mode === 'light' ? '1px solid #ccc' : '1px solid #666',
	maxWidth: '90vw',
	overflowX: 'auto',
	backgroundImage: 'none'
}));

const StyledTable = styled(Table)(({ theme }) => ({
	minWidth: '950px',
	'& .MuiTableCell-stickyHeader': {
		backgroundColor:
			theme.palette.mode === 'light'
				? theme.palette.background.default
				: 'rgba(145, 158, 171, 0.16)'
	}
}));

const Container = styled(TableContainer)(({ theme }) => ({
	// maxHeight: 'calc(100vh - 368px)',
	// [theme.breakpoints.down('md')]: {
	// 	maxHeight: 'calc(100vh - 311px)'
	// }
}));

export default TableListContent;
