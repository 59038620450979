import { Typography } from '@mui/material';
import { useGetUsersQuery, UserRole, UserStatus } from 'generated/api';
import { useAtomValue, useUpdateAtom } from 'jotai/utils';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import shallow from 'zustand/shallow';
import { isMasterAtom, userManagementLastRouteAtom } from '../../atoms';
import { useRefetchHandler } from '../../hooks/useRefetchHandler';
import TableSkeletonLoading from '../../share-components/Table/TableSkeletonLoading';
import { TableFilterHolder } from '../../stores/useTableFilterStore';
import { useUserStatusStore } from '../../stores/useUserStatusStore';
import { useUserTableControllerStore } from '../../stores/useUserTableControllerStore';
import TableList from '../common/TableList';

const options = [
	{
		label: '全部用户',
		value: '0' as const
	},
	{
		label: '在籍用户',
		value: UserStatus.Active
	},
	{
		label: '不在籍用户',
		value: UserStatus.Inactive
	}
];

interface UserProps {}

const Users: React.FC<UserProps> = () => {
	const [status, setStatus] = useUserStatusStore(
		state => [state.userStatus, state.setUserStatus],
		shallow
	);
	const [selectedValue, setOptions, setSelectedValue, setRole] = useUserTableControllerStore(
		state => [state.selectedValue, state.setOptions, state.setSelectedValue, state.setRole],
		shallow
	);
	const isMaster = useAtomValue(isMasterAtom);
	const { data, loading, error, refetch, startPolling, stopPolling } = useGetUsersQuery({
		variables: {
			status: status !== '0' ? status : undefined,
			roleFilter: {
				roles: !isMaster ? [UserRole.Student, UserRole.Lecturer] : undefined,
				includeNoRole: true
			}
		}
	});
	const setUserManagementLastRoute = useUpdateAtom(userManagementLastRouteAtom);
	const location = useLocation();
	const navigate = useNavigate();

	useRefetchHandler(refetch, startPolling, stopPolling);

	useEffect(() => {
		console.log(options);
		setOptions(options);
		setSelectedValue(status);
		setUserManagementLastRoute('');
		setRole(null);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (
			selectedValue === UserStatus.Active ||
			selectedValue === UserStatus.Inactive ||
			selectedValue === '0'
		) {
			setStatus(selectedValue);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue]);

	if (!data) {
		if (loading) {
			return <TableSkeletonLoading />;
		}

		if (error) {
			return <>{error.message}</>;
		}

		return <></>;
	}

	const handleItemClick = (user: typeof data['users'][0]) => {
		navigate(`/users/${user.id}`, { state: { backgroundLocation: location } });
	};

	return (
		<>
			{data.users.length === 0 ? (
				<Typography variant="body1" align="center" style={{ marginTop: '3em' }}>
					没有{status !== '0' ? (status === UserStatus.Active ? '在籍用户' : '不在籍用户') : '用户'}
				</Typography>
			) : (
				<TableList
					holder={TableFilterHolder.USER}
					items={data.users}
					headCells={[
						{ id: 'cname', disablePadding: false, label: '中文名' },
						{ id: 'ename', disablePadding: false, label: '英文名' },
						{ id: 'gender', disablePadding: false, label: '性别' },
						{ id: 'email', disablePadding: false, label: 'Email' },
						{ id: 'ic', disablePadding: false, label: 'I/C' },
						{ id: 'phone', disablePadding: false, label: '电话' }
					]}
					clickFunc={handleItemClick}
				/>
			)}
		</>
	);
};

export default Users;
