import React, { useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FilledInput,
	FormHelperText,
	Typography
} from '@mui/material';
import { useAtom } from 'jotai';
import { useDeleteCategoryMutation } from '../../../../generated/api';
import { deleteCategoryUpdate } from '../../update/deleteCategoryUpdate';
import { categoryDeleteItemAtom } from '../../../../atoms';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

interface CategoryDeleteDialogProps {}

const CategoryDeleteDialog: React.FC<CategoryDeleteDialogProps> = () => {
	const [item, setItem] = useAtom(categoryDeleteItemAtom);
	const [deleteCategoryMutation] = useDeleteCategoryMutation({
		update: deleteCategoryUpdate
	});
	const [confirmationText, setConfirmationText] = useState('');
	const [isError, setIsError] = useState(false);
	const navigate = useNavigate();

	const handleClose = () => {
		setItem(null);
		setConfirmationText('');
	};

	const handleDelete = async () => {
		if (!item) {
			return;
		}
		if (confirmationText !== item.name) {
			setIsError(true);
			return;
		}
		try {
			await deleteCategoryMutation({
				variables: {
					id: item.id
				},
				optimisticResponse: {
					__typename: 'Mutation',
					deleteCategory: {
						__typename: 'DeletePayload',
						id: item.id
					}
				}
			});
			handleClose();
			navigate(-1);
			toast.success('成功删除课程种类！');
		} catch (err) {
			toast.error(`删除课程种类失败！ ${err}`);
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (isError) {
			setIsError(false);
		}
		setConfirmationText(event.target.value);
	};

	return (
		<Dialog
			open={!!item}
			onClose={handleClose}
			maxWidth="sm"
			fullWidth
			PaperProps={{ sx: { backgroundImage: 'none' } }}
		>
			<DialogTitle>删除{item?.name}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					确定要删除{item?.name}吗？删除{item?.name}
					将会连带删除所有此课程种类的所有课程副种类、课程和班级。
				</DialogContentText>
				<Typography variant="body2" sx={{ marginTop: '10px', marginBottom: '5px' }}>
					输入
					<Typography variant="body2" color="primary" component="span" sx={{ margin: '0 2px' }}>
						{item?.name}
					</Typography>
					以确认删除
				</Typography>
				<StyledFilledInput
					value={confirmationText}
					onChange={handleChange}
					error={isError}
					fullWidth
					autoFocus
				/>
				<FormHelperText error={isError}>
					{isError ? `请输入${item?.name || ''}` : ''}
				</FormHelperText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>
					取消
				</Button>
				<Button color="primary" onClick={handleDelete}>
					删除
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const StyledFilledInput = styled(FilledInput)({
	'& .MuiFilledInput-input': {
		paddingTop: '16px'
	}
});

export default CategoryDeleteDialog;
