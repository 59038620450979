import { useEffect, useState } from 'react';

export default function useLastNotNullValue<T>(value: T): T {
	const [lastValue, setLastValue] = useState(value);
	useEffect(() => {
		if (!value) {
			return;
		}
		setLastValue(value);
	}, [value]);
	return lastValue;
}
