import { MutationUpdaterFn } from '@apollo/client';
import {
	GetLecturersQuery,
	GetLecturersQueryVariables,
	UpdateUserMutation
} from '../../../generated/api';
import { GET_LECTURERS } from '../query';

export const createLecturerUpdate: MutationUpdaterFn<UpdateUserMutation> = (
	cache,
	mutationResults
) => {
	const updatedUser = mutationResults.data?.updateUser;

	if (!updatedUser || !updatedUser.lecturer) {
		return;
	}

	const variables: GetLecturersQueryVariables = {
		status: updatedUser.lecturer.status
	};

	const data = cache.readQuery<GetLecturersQuery>({
		query: GET_LECTURERS,
		variables
	});

	if (data) {
		cache.writeQuery<GetLecturersQuery>({
			query: GET_LECTURERS,
			variables,
			data: {
				lecturers: [
					...data.lecturers,
					{
						...updatedUser.lecturer,
						user: updatedUser
					}
				]
			}
		});
	}
};
