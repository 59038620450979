import { NavLink, NavLinkProps } from 'react-router-dom';
import React from 'react';
import { preloadRouteComponent } from '../../utils/preloadRouteComponent';

interface NavLinkWithPreloadProps extends NavLinkProps {
	onPreload?: () => void;
}

const NavLinkWithPreload: React.FC<NavLinkWithPreloadProps> = React.forwardRef(
	({ to, onPreload, ...rest }, _) => {
		const handleMouseEnter = () => {
			preloadRouteComponent(to).catch(() => {});
		};

		return <NavLink to={to} onMouseEnter={handleMouseEnter} {...rest} />;
	}
);

export default NavLinkWithPreload;
