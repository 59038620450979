import React from 'react';
import { Grid, InputAdornment, MenuItem, TextField } from '@mui/material';
import { CreateUserLecturerInput, LecturerStatus } from '../../generated/api';

interface CreateLecturerFormProps {
	lecturer: CreateUserLecturerInput;
	setLecturer: (lecturer: Partial<CreateUserLecturerInput>) => void;
	disabled?: boolean;
}

const CreateLecturerForm: React.FC<CreateLecturerFormProps> = ({
	lecturer,
	setLecturer,
	disabled
}) => {
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const name = event.target.name;
		let value = event.target.value;

		switch (name) {
			case 'matricId':
				value = 'L' + value;
				break;
			case 'status':
				break;
			default:
				return;
		}

		setLecturer({ [name]: value });
	};

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="编号"
					name="matricId"
					placeholder="XXX"
					value={lecturer.matricId.slice(1)}
					onChange={handleChange}
					fullWidth
					disabled={disabled}
					InputProps={{
						startAdornment: <InputAdornment position="start">L</InputAdornment>
					}}
					inputProps={{
						pattern: '(^[0-9]{3,}$)'
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="在籍状况"
					name="status"
					value={lecturer.status}
					onChange={handleChange}
					select
					fullWidth
					disabled={disabled}
				>
					<MenuItem value={LecturerStatus.Active}>在籍老师</MenuItem>
					<MenuItem value={LecturerStatus.Inactive}>不在籍老师</MenuItem>
				</TextField>
			</Grid>
		</Grid>
	);
};

export default CreateLecturerForm;
