import React from 'react';
import { CircularProgress, Paper, Slide } from '@mui/material';
import { useRefetchFunctionsStore } from '../stores/useRefetchFunctionsStore';
import { styled } from '@mui/material/styles';

interface LoadingIndicatorProps {}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = () => {
	const loading = useRefetchFunctionsStore(state => state.loading);

	return (
		<Slide direction="down" in={loading} mountOnEnter unmountOnExit>
			<Container elevation={3}>
				<CircularProgress size={30} />
			</Container>
		</Slide>
	);
};

const Container = styled(Paper)({
	position: 'fixed',
	top: 20,
	left: 'calc(50% - 20px)',
	borderRadius: '50%',
	lineHeight: 0,
	padding: 6,
	textAlign: 'center',
	zIndex: 13000
});

export default LoadingIndicator;
