import { matchPath } from 'react-router-dom';
import type { routes as routesType } from '../Routes';

export const findComponentForRoute = (
	path: string,
	routes: typeof routesType
) => {
	const matchingRoute = routes.find(route => matchPath(path, route.path));

	return matchingRoute ? matchingRoute.Component : null;
};
