import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Divider, Link, TextField } from '@mui/material';
import {
	CreateAdminInput,
	CreateLecturerInput,
	CreateStudentInput,
	User
} from '../../../../generated/api';
import { useLocation, useNavigate } from 'react-router-dom';

type BasicUser = Pick<User, 'id' | 'cname' | 'ename' | 'ic' | 'gender' | 'email'>;

interface UserRoleCreateUserSelectionProps {
	users: BasicUser[];
	loading: boolean;
	selectedUserId: string;
	setRoleState:
		| ((student: Partial<CreateStudentInput>) => void)
		| ((lecturer: Partial<CreateLecturerInput>) => void)
		| ((admin: Partial<CreateAdminInput>) => void);
}

const UserRoleCreateUserSelection: React.FC<UserRoleCreateUserSelectionProps> = ({
	users,
	loading,
	selectedUserId,
	setRoleState
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [selectedUser, setSelectedUser] = useState<BasicUser | null>(null);

	useEffect(() => {
		setSelectedUser(users.find(user => user.id === selectedUserId) ?? null);
	}, [selectedUserId, setSelectedUser]);

	const handleChange = (event: React.ChangeEvent<{}>, value: any) => {
		if (!value) {
			setRoleState({ userId: '' });
			return;
		}
		setRoleState({ userId: value.id });
	};

	const handleCreateNewUser = () => {
		navigate('/users/create', {
			state: {
				backgroundLocation: location
			},
			replace: true
		});
	};

	return (
		<Box sx={{ mb: '30px' }}>
			<Autocomplete
				renderInput={params => <TextField {...params} label="选择用户" variant="outlined" />}
				options={users}
				value={selectedUser}
				getOptionLabel={user => `${user.cname} ${user.ename}`}
				isOptionEqualToValue={(option, value) => option.id === value?.id}
				onChange={handleChange}
				loading={loading}
			/>
			<Link
				underline="always"
				sx={{
					margin: '8px 0 2px',
					cursor: 'pointer',
					display: 'block',
					textAlign: 'center'
				}}
				onClick={handleCreateNewUser}
			>
				创建新的用户
			</Link>
			<br />
			<Divider />
		</Box>
	);
};

export default UserRoleCreateUserSelection;
