import React, { useCallback } from 'react';
import {
	Breadcrumbs,
	IconButton,
	lighten,
	Theme,
	Toolbar,
	Tooltip,
	Typography,
	useMediaQuery
} from '@mui/material';
import { Delete, Edit, NavigateNext, PersonAdd } from '@mui/icons-material';
import { TableFilterHolder, useTableFilterStore } from '../../stores/useTableFilterStore';
import { TableListToolbar } from '../../types';
import { Node } from 'generated/api';
import { styled } from '@mui/material/styles';

interface TableToolbarProps<T> {
	holder: TableFilterHolder;
	toolBar: TableListToolbar;
	items: T[];
}

const TableToolbar = <T extends Node>(props: TableToolbarProps<T>) => {
	const { holder, toolBar, items } = props;
	const selected = useTableFilterStore(
		useCallback(state => state.filters[holder].selected, [holder])
	);
	const numSelected = selected.length;
	const isXS = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));

	const handleEdit = () => {
		toolBar.edit!.func(items.find(item => item.id === selected[0]));
	};

	const handleDelete = () => {
		toolBar.delete!.func(items.filter(row => selected.includes(row.id)));
	};

	return (
		<StyledToolbar
			sx={theme =>
				numSelected > 0
					? {
							color:
								theme.palette.mode === 'light'
									? theme.palette.secondary.main
									: theme.palette.text.primary,
							backgroundColor:
								theme.palette.mode === 'light'
									? lighten(theme.palette.secondary.light, 0.85)
									: theme.palette.secondary.dark
					  }
					: {}
			}
		>
			{numSelected > 0 ? (
				<Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
					{numSelected} selected
				</Typography>
			) : (
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					maxItems={isXS ? 2 : 3}
					itemsBeforeCollapse={0}
					itemsAfterCollapse={2}
					sx={{ flex: '1 1 100%' }}
				>
					{toolBar.title.map((e, index) => React.cloneElement(e, { key: index }))}
				</Breadcrumbs>
			)}
			{numSelected > 0 ? (
				<>
					{numSelected === 1 && toolBar.edit && (
						<Tooltip title="Edit">
							{toolBar.edit.button?.({
								onClick: handleEdit
							}) ?? (
								<IconButton aria-label="edit" onClick={handleEdit} size="large">
									<Edit />
								</IconButton>
							)}
						</Tooltip>
					)}
					{toolBar.delete && (
						<Tooltip title="Delete">
							{toolBar.delete.button?.({ onClick: handleDelete }) ?? (
								<IconButton
									aria-label="delete"
									onClick={() =>
										toolBar.delete!.func(items.filter(row => selected.includes(row.id)))
									}
									size="large"
								>
									<Delete />
								</IconButton>
							)}
						</Tooltip>
					)}
				</>
			) : (
				<>
					{toolBar.add && (
						<Tooltip title="Add">
							{toolBar.add.button?.({ onClick: toolBar.add.func }) ?? (
								<IconButton aria-label="add" onClick={toolBar.add.func} size="large">
									<PersonAdd />
								</IconButton>
							)}
						</Tooltip>
					)}
				</>
			)}
		</StyledToolbar>
	);
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
	paddingLeft: theme.spacing(2),
	paddingRight: theme.spacing(1),
	borderBottom: `1px solid ${theme.palette.divider}`
}));

export default TableToolbar;
