import { MutationUpdaterFn } from '@apollo/client';
import { DeleteCategoryMutation } from '../../../generated/api';

export const deleteCategoryUpdate: MutationUpdaterFn<DeleteCategoryMutation> = (
	cache,
	mutationResult
) => {
	const id = mutationResult.data?.deleteCategory?.id;

	if (!id) {
		return;
	}

	cache.evict({
		id: cache.identify({
			id,
			__typename: 'Category'
		})
	});

	cache.gc();
};
