import React, { useEffect } from 'react';
import { LecturerStatus, useGetLecturersQuery } from 'generated/api';
import { useUserStatusStore } from '../../stores/useUserStatusStore';
import shallow from 'zustand/shallow';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserRole } from '../../types';
import { Typography } from '@mui/material';
import TableList from '../common/TableList';
import { useUserTableControllerStore } from '../../stores/useUserTableControllerStore';
import { useAtom } from 'jotai';
import { userManagementLastRouteAtom } from '../../atoms';
import { useRefetchFunctionsStore } from '../../stores/useRefetchFunctionsStore';
import TableSkeletonLoading from '../../share-components/Table/TableSkeletonLoading';
import { useUserPreferenceStore } from '../../stores/useUserPreferenceStore';
import { TableFilterHolder } from '../../stores/useTableFilterStore';

const options = [
	{
		label: '全部老师',
		value: '0' as const
	},
	{
		label: '在籍老师',
		value: LecturerStatus.Active
	},
	{
		label: '不在籍老师',
		value: LecturerStatus.Inactive
	}
];

const activeLabel = {
	[LecturerStatus.Active]: '在籍老师',
	[LecturerStatus.Inactive]: '不在籍老师'
};

interface LecturersProps {}

const Lecturers: React.FC<LecturersProps> = () => {
	const [status, setStatus] = useUserStatusStore(
		state => [state.lecturerStatus, state.setLecturerStatus],
		shallow
	);
	const [selectedValue, setOptions, setSelectedValue, setRole] = useUserTableControllerStore(
		state => [state.selectedValue, state.setOptions, state.setSelectedValue, state.setRole],
		shallow
	);
	const { data, loading, error, refetch, startPolling, stopPolling } = useGetLecturersQuery({
		variables: {
			status: status !== '0' ? status : undefined
		}
	});
	const navigate = useNavigate();
	const [, setUserManagementLastRoute] = useAtom(userManagementLastRouteAtom);
	const [addRefetch, removeRefetch] = useRefetchFunctionsStore(
		state => [state.addFunction, state.removeFunction],
		shallow
	);
	const location = useLocation();

	useEffect(() => {
		setOptions(options);
		setSelectedValue(status);
		setUserManagementLastRoute('/lecturer');
		setRole(UserRole.LECTURER);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const pollingInterval = useUserPreferenceStore.getState().pollingInterval;

		if (!pollingInterval) {
			return;
		}

		startPolling(pollingInterval);

		return () => {
			stopPolling();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (refetch) {
			addRefetch(refetch);
		}

		return () => {
			if (refetch) {
				removeRefetch(refetch);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetch]);

	useEffect(() => {
		switch (selectedValue) {
			case '0':
			case LecturerStatus.Active:
			case LecturerStatus.Inactive:
				setStatus(selectedValue);
				break;
			default:
				break;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue]);

	if (!data) {
		if (loading) {
			return <TableSkeletonLoading />;
		}

		if (error) {
			return <>{error.message}</>;
		}

		return <></>;
	}

	const users = data.lecturers.map(lecturer => ({
		...lecturer,
		...lecturer.user
	}));

	const handleItemClick = (user: typeof users[0]) => {
		navigate(`/users/${user.id}`, { state: { backgroundLocation: location } });
	};

	return (
		<>
			{!users || users.length === 0 ? (
				<Typography variant="body1" align="center" style={{ marginTop: '3em' }}>
					没有{status !== '0' ? activeLabel[status] : '老师'}
				</Typography>
			) : (
				<TableList
					holder={TableFilterHolder.LECTURER}
					items={users}
					headCells={[
						{ id: 'matricId', disablePadding: false, label: '编号' },
						{ id: 'cname', disablePadding: false, label: '中文名' },
						{ id: 'ename', disablePadding: false, label: '英文名' },
						{ id: 'gender', disablePadding: false, label: '性别' },
						{ id: 'email', disablePadding: false, label: 'Email' },
						{ id: 'ic', disablePadding: false, label: 'I/C' },
						{ id: 'phone', disablePadding: false, label: '电话' }
					]}
					clickFunc={handleItemClick}
				/>
			)}
		</>
	);
};

export default Lecturers;
