import { Grid, InputAdornment, MenuItem, TextField, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import shallow from 'zustand/shallow';
import { Gender, UserStatus } from '../../../../generated/api';
import { useUserCreateStore } from '../../../../stores/useUserCreateStore';

interface UserCreateFormContentProps {}

const UserCreateFormContent: React.FC<UserCreateFormContentProps> = () => {
	const [user, setUser] = useUserCreateStore(state => [state.user, state.setUser], shallow);
	const theme = useTheme();
	const isSM = useMediaQuery(theme.breakpoints.down('sm'));

	const handleChange = (event: React.ChangeEvent<{ name: string; value: any }>) => {
		const name = event.target.name;
		let value = event.target.value;

		switch (name) {
			case 'phone':
				if (value === '') {
					break;
				}
				if (isNaN(Number(value)) || value.length > 10) {
					return;
				}
				value = '+60' + value;
				break;
			default:
				break;
		}

		setUser({ [name]: value });
	};

	return (
		<Grid container spacing={isSM ? 4 : 2} alignItems="flex-end">
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="中文名"
					name="cname"
					value={user.cname}
					onChange={handleChange}
					fullWidth
					required
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="英文名"
					name="ename"
					value={user.ename}
					onChange={handleChange}
					fullWidth
					required
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="Email"
					name="email"
					value={user.email}
					onChange={handleChange}
					type="email"
					fullWidth
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="I/C"
					name="ic"
					value={user.ic}
					onChange={handleChange}
					fullWidth
					placeholder="xxxxxx-xx-xxxx"
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					variant="outlined"
					label="性别"
					name="gender"
					value={user.gender}
					onChange={handleChange}
					fullWidth
					select
				>
					<MenuItem value={Gender.Female}>女</MenuItem>
					<MenuItem value={Gender.Male}>男</MenuItem>
				</TextField>
			</Grid>
			<Grid item xs={6}>
				<TextField
					variant="outlined"
					label="在籍"
					name="status"
					value={user.status}
					onChange={handleChange}
					fullWidth
					select
				>
					<MenuItem value={UserStatus.Active}>在籍</MenuItem>
					<MenuItem value={UserStatus.Inactive}>不在籍</MenuItem>
				</TextField>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="电话号码"
					name="phone"
					value={user.phone.slice(3)}
					onChange={handleChange}
					fullWidth
					type="tel"
					InputProps={{
						startAdornment: <InputAdornment position="start">+60</InputAdornment>
					}}
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
			<Grid item xs={12} sm={6}>
				<TextField
					variant="outlined"
					label="地址"
					name="address"
					value={user.address}
					onChange={handleChange}
					fullWidth
					InputLabelProps={{
						shrink: true
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default UserCreateFormContent;
