import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useGetInvoiceQuery } from '../../../generated/api';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Relay } from '../../../utils/relay';
import { useRefetchHandler } from '../../../hooks/useRefetchHandler';
import {
	Box,
	Breadcrumbs,
	Divider,
	IconButton,
	Link,
	Paper,
	SvgIcon,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Theme,
	Typography
} from '@mui/material';
import { Download, NavigateNext, Print, Send, Share, Visibility } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { BlobProvider } from '@react-pdf/renderer';
import SimpleBar from 'simplebar-react';
import { PDFPageProxy } from 'pdfjs-dist';
import * as Pdf from 'pdfjs-dist/build/pdf';
//@ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import ReceiptPdf from './ReceiptPdf';
import ReceiptPdfDialog from './ReceiptPdfDialog';
Pdf.GlobalWorkerOptions.workerSrc = pdfjsWorker;

interface ReceiptDetailsPageProps {}

const ReceiptDetailsPage: React.FC<ReceiptDetailsPageProps> = () => {
	const params = useParams();
	const id = params['id'];
	const { data, refetch, startPolling, stopPolling } = useGetInvoiceQuery({
		variables: { id: Relay.marshalId('Invoice', id || '') },
		skip: id === ''
	});
	const invoice = useMemo(() => (data?.node?.__typename === 'Invoice' ? data.node : null), [data]);
	const [open, setOpen] = useState(false);
	const receiptFileName = useMemo(
		() => (invoice ? `${Relay.unmarshalId(invoice.id)} - ${invoice.user.ename}` : ''),
		[invoice]
	);

	useRefetchHandler(refetch, startPolling, stopPolling);

	if (!invoice || !invoice.payment) {
		return null;
	}

	const handleViewClick = () => {
		setOpen(true);
	};

	const handleShare = (blob: Blob | null) => async () => {
		if (!blob) {
			return;
		}

		try {
			await navigator.share({
				files: [new File([blob], `${receiptFileName}.pdf`, { type: blob.type })],
				title: receiptFileName
			});
		} catch (err) {
			toast.error(`${err}`);
		}
	};

	return (
		<Box sx={{ mt: 2, mb: 8 }}>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Breadcrumbs separator={<NavigateNext fontSize="small" />} aria-label="breadcrumb">
					<Link
						to="/"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						主页
					</Link>
					<Link
						to="/payment-management/invoices"
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						发票
					</Link>
					<Link
						to={`/payment-management/invoices/${id}`}
						component={RouterLink}
						sx={{
							fontSize: '1.1rem',
							color: theme => theme.palette.text.primary,
							textDecoration: 'none',
							':hover': { textDecoration: 'underline' }
						}}
					>
						{id}
					</Link>
					<Typography variant="body2" sx={{ fontSize: '1.1rem', opacity: 0.7 }}>
						收据
					</Typography>
				</Breadcrumbs>
			</Box>
			<Box
				sx={theme => ({
					display: 'flex',
					justifyContent: 'space-between',
					flexWrap: 'wrap',
					alignItems: 'center',
					mt: 6,
					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',
						alignItems: 'flex-start'
					}
				})}
			>
				<Box
					sx={{
						display: 'flex',
						'& > .MuiIconButton-root': {
							mr: 1
						}
					}}
				>
					<IconButton onClick={handleViewClick}>
						<Visibility />
					</IconButton>
					<BlobProvider document={<ReceiptPdf invoice={invoice} />}>
						{({ blob, url, loading, error }) => (
							<>
								<IconButton component="a" href={url || ''} download={receiptFileName}>
									<Download />
								</IconButton>
								<IconButton component="a" href={url || ''} target="_blank">
									<Print />
								</IconButton>
								<IconButton>
									<Send />
								</IconButton>
								{!!navigator.canShare && (
									<IconButton onClick={handleShare(blob)}>
										<Share />
									</IconButton>
								)}
							</>
						)}
					</BlobProvider>
				</Box>
				<Box
					sx={theme => ({
						[theme.breakpoints.down('sm')]: {
							ml: 'auto',
							mr: 0,
							mt: 2
						}
					})}
				>
					{/*<InvoiceDetailsPageActions invoice={invoice} />*/}
				</Box>
			</Box>
			<Paper
				sx={{
					mt: 6,
					minHeight: 600,
					borderRadius: '16px',
					p: 4,
					boxShadow: theme =>
						theme.palette.mode === 'light'
							? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
							: 'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px'
				}}
			>
				<Box sx={{ display: 'flex' }}>
					<Box
						sx={theme => ({
							display: 'flex',
							flexWrap: 'nowrap',
							[theme.breakpoints.down('md')]: {
								flexWrap: 'wrap'
							},
							flex: 1
						})}
					>
						<img
							src="/images/ekids_logo.webp"
							css={{
								maxWidth: 200,
								height: 'max-content',
								marginRight: 16,
								marginBottom: 16,
								'@media(max-width:900px)': {
									maxWidth: 160,
									marginBottom: 8
								}
							}}
						/>
						<Box sx={{ mb: 2 }}>
							<Typography variant="h6">
								ELEMENTE EDUCATION SDN. BHD.{' '}
								<span css={{ fontSize: '0.875rem', fontWeight: 'normal' }}>(Co. No.1280836-M)</span>
							</Typography>
							<Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
								Level 2, L2.27 KL GATEWAY MALL.
								<br />
								2, Jalan Kerinchi, Kampung Kerinchi, 59200 Kuala Lumpur, Wilayah Persekutuan Kuala
								Lumpur, Malaysia.
							</Typography>
							<Typography variant="subtitle2" sx={{ opacity: 0.8 }}>
								H/P: 012-336 6124
							</Typography>
						</Box>
					</Box>
					{/*<Box>*/}
					{/*	<InvoiceStatusChip status={invoice.status} />*/}
					{/*</Box>*/}
				</Box>
				<Box
					sx={theme => ({
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						my: 1,
						[theme.breakpoints.down('md')]: {
							justifyContent: 'flex-start',
							my: 2
						}
					})}
				>
					<Typography variant="h5" sx={{ fontWeight: 'bold' }}>
						OFFICIAL RECEIPT
					</Typography>
				</Box>
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
						flexWrap: 'wrap'
					}}
				>
					<Box sx={{ mt: 1, width: 360, maxWidth: '100%' }}>
						<Typography variant="h6" sx={{ opacity: 0.5, fontSize: '0.875rem !important', mb: 1 }}>
							TO
						</Typography>
						<Typography variant="subtitle1" sx={{ my: 1 }}>
							{invoice.user.cname}
							{invoice.user.cname !== '' ? ' ' : ''}
							{invoice.user.ename}
						</Typography>
						<Typography variant="subtitle1">{invoice.user.address}</Typography>
					</Box>
					<Box
						sx={theme => ({
							display: 'flex',
							flexDirection: 'column',
							mt: 2,
							[theme.breakpoints.down('md')]: {
								width: '100%',
								mt: 4
							}
						})}
					>
						<Typography variant="h6" sx={{ opacity: 0.5, fontSize: '0.875rem !important', mb: 1 }}>
							RECEIPT NO
						</Typography>
						<Typography variant="body1">
							{invoice.payment.receipt ? Relay.unmarshalId(invoice.payment.receipt.id) : ''}
						</Typography>
						<Typography
							variant="h6"
							sx={{ opacity: 0.5, fontSize: '0.875rem !important', mb: 1, mt: 4 }}
						>
							DATE CREATE
						</Typography>
						<Typography variant="body1">
							{new Date(invoice.payment.createdAt).toLocaleDateString('en-MY')}
						</Typography>
					</Box>
				</Box>
				<SimpleBar>
					<Table sx={{ mt: 6 }}>
						<TableHead>
							<TableRow
								sx={{
									'& > .MuiTableCell-root': {
										opacity: 0.7
									}
								}}
							>
								<TableCell>#</TableCell>
								<TableCell>Description</TableCell>
								<TableCell align="right">Quantity</TableCell>
								<TableCell align="right">Unit Price</TableCell>
								<TableCell align="right">Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{invoice.items.map((item, index) => (
								<TableRow key={item.receivable.id}>
									<TableCell>{index + 1}</TableCell>
									<TableCell>
										<Box sx={{ maxWidth: 560 }}>
											<Typography variant="h6" sx={{ fontSize: '1rem !important' }}>
												{item.receivable.name}
											</Typography>
											<Typography
												variant="body2"
												sx={{
													opacity: 0.6,
													textOverflow: 'ellipsis',
													whiteSpace: 'nowrap',
													overflow: 'hidden'
												}}
											>
												{item.description}
											</Typography>
										</Box>
									</TableCell>
									<TableCell align="right">{item.receivable.quantity}</TableCell>
									<TableCell align="right">{item.receivable.price}</TableCell>
									<TableCell align="right">
										{item.receivable.quantity * item.receivable.price}
									</TableCell>
								</TableRow>
							))}
							<TableRow
								sx={{
									'& > td': {
										borderBottom: 'none'
									}
								}}
							>
								<TableCell colSpan={3} />
								<TableCell align="right">
									<Box sx={{ mt: 4 }} />
									<Typography variant="body1">Subtotal</Typography>
								</TableCell>
								<TableCell align="right">
									<Box sx={{ mt: 4 }} />
									<Typography variant="body1">
										RM
										{invoice.items
											.reduce(
												(total, item) => total + item.receivable.price * item.receivable.quantity,
												0
											)
											.toFixed(2)}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow
								sx={{
									'& > td': {
										borderBottom: 'none'
									}
								}}
							>
								<TableCell colSpan={3} />
								<TableCell align="right">
									<Typography variant="body1">Discount</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="body1" color={invoice.discount ? 'error' : 'inherit'}>
										{invoice.discount ? `- RM${invoice.discount.toFixed(2)}` : '-'}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow
								sx={{
									'& > td': {
										borderBottom: 'none'
									}
								}}
							>
								<TableCell colSpan={3} />
								<TableCell align="right">
									<Typography variant="body1">Taxes</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="body1">
										{invoice.tax ? `RM${invoice.tax.toFixed(2)}` : '-'}
									</Typography>
								</TableCell>
							</TableRow>
							<TableRow
								sx={{
									'& > td': {
										borderBottom: 'none'
									}
								}}
							>
								<TableCell colSpan={3}>
									<Box sx={{ display: 'flex' }}>
										<Typography variant="body1" sx={{ mr: 2, fontWeight: 'bold' }}>
											Payment Method
										</Typography>
										<Typography variant="body1">
											{invoice.payment.method} {invoice.payment.methodDescription}
										</Typography>
									</Box>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h6">Total</Typography>
								</TableCell>
								<TableCell align="right">
									<Typography variant="h6">
										{(() => {
											if (invoice.amount < 0) {
												return `- RM${(-invoice.amount).toFixed(2)}`;
											} else {
												return `RM${invoice.amount.toFixed(2)}`;
											}
										})()}
									</Typography>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</SimpleBar>
				{invoice.payment.proofs.length > 0 && (
					<>
						<Divider sx={{ mt: 4, mb: 2 }} />
						<Typography variant="body1" sx={{ mb: 3 }}>
							支付凭证
						</Typography>
						<Box sx={{ display: 'flex' }}>
							{invoice.payment.proofs.map(proof => (
								<FileItem {...proof} fileKey={proof.key} />
							))}
						</Box>
					</>
				)}
			</Paper>
			<ReceiptPdfDialog open={open} setOpen={setOpen} invoice={invoice} />
		</Box>
	);
};

const FileItem: React.FC<{
	fileKey: string;
	name: string;
	size: number;
	lastModified: any;
	url: string;
}> = ({ fileKey, name, size, lastModified, url }) => {
	const preview = useMemo(() => {
		const parts = name.split('.');
		const extension = parts[parts.length - 1];
		switch (extension) {
			case 'pdf':
				return <PdfPreview url={url} />;
			case 'jpg':
			case 'jpeg':
			case 'png':
			case 'webp':
				return <ImagePreview url={url} />;
			default:
				return <></>;
		}
	}, [url]);

	return (
		<Paper
			variant="outlined"
			sx={{
				width: 180,
				height: 120,
				position: 'relative',
				mr: 2,
				'&:hover': {
					'& .file-detail': {
						display: 'flex'
					}
				}
			}}
		>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<Box sx={{ height: 90, overflow: 'hidden' }}>{preview}</Box>
				<Divider />
				<Box sx={{ height: 30, display: 'flex', alignItems: 'center', p: 0.5 }}>
					<SvgIcon
						width={16}
						height={16}
						fill="red"
						viewBox="0 0 16 16"
						sx={{ mr: 0.5, width: 20, height: 20, fill: 'red' }}
					>
						<path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
						<path
							fillRule="evenodd"
							d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"
						/>
					</SvgIcon>
					<Typography
						variant="subtitle2"
						sx={{
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							fontSize: '0.75rem',
							lineHeight: '0.75rem'
						}}
					>
						{name}
					</Typography>
				</Box>
			</Box>
			<Box
				className="file-detail"
				sx={{
					position: 'absolute',
					top: 0,
					left: 0,
					display: 'none',
					width: '100%',
					height: '100%',
					backgroundColor: (theme: Theme) => theme.palette.background.default,
					flexDirection: 'column',
					justifyContent: 'center',
					zIndex: 10,
					overflow: 'hidden',
					p: 2
				}}
			>
				<Box sx={{ display: 'flex' }}>
					<SvgIcon
						width={16}
						height={16}
						fill="red"
						viewBox="0 0 16 16"
						sx={{ mr: 0.5, width: 20, height: 20, fill: 'red' }}
					>
						<path d="M5.523 12.424c.14-.082.293-.162.459-.238a7.878 7.878 0 0 1-.45.606c-.28.337-.498.516-.635.572a.266.266 0 0 1-.035.012.282.282 0 0 1-.026-.044c-.056-.11-.054-.216.04-.36.106-.165.319-.354.647-.548zm2.455-1.647c-.119.025-.237.05-.356.078a21.148 21.148 0 0 0 .5-1.05 12.045 12.045 0 0 0 .51.858c-.217.032-.436.07-.654.114zm2.525.939a3.881 3.881 0 0 1-.435-.41c.228.005.434.022.612.054.317.057.466.147.518.209a.095.095 0 0 1 .026.064.436.436 0 0 1-.06.2.307.307 0 0 1-.094.124.107.107 0 0 1-.069.015c-.09-.003-.258-.066-.498-.256zM8.278 6.97c-.04.244-.108.524-.2.829a4.86 4.86 0 0 1-.089-.346c-.076-.353-.087-.63-.046-.822.038-.177.11-.248.196-.283a.517.517 0 0 1 .145-.04c.013.03.028.092.032.198.005.122-.007.277-.038.465z" />
						<path
							fillRule="evenodd"
							d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3zM4.165 13.668c.09.18.23.343.438.419.207.075.412.04.58-.03.318-.13.635-.436.926-.786.333-.401.683-.927 1.021-1.51a11.651 11.651 0 0 1 1.997-.406c.3.383.61.713.91.95.28.22.603.403.934.417a.856.856 0 0 0 .51-.138c.155-.101.27-.247.354-.416.09-.181.145-.37.138-.563a.844.844 0 0 0-.2-.518c-.226-.27-.596-.4-.96-.465a5.76 5.76 0 0 0-1.335-.05 10.954 10.954 0 0 1-.98-1.686c.25-.66.437-1.284.52-1.794.036-.218.055-.426.048-.614a1.238 1.238 0 0 0-.127-.538.7.7 0 0 0-.477-.365c-.202-.043-.41 0-.601.077-.377.15-.576.47-.651.823-.073.34-.04.736.046 1.136.088.406.238.848.43 1.295a19.697 19.697 0 0 1-1.062 2.227 7.662 7.662 0 0 0-1.482.645c-.37.22-.699.48-.897.787-.21.326-.275.714-.08 1.103z"
						/>
					</SvgIcon>
					<Box>
						<Typography
							variant="subtitle2"
							sx={{
								fontSize: '0.75rem',
								lineHeight: '0.75rem',
								maxWidth: '100%'
							}}
							align="left"
						>
							{name}
						</Typography>
						<Typography
							variant="subtitle2"
							align="left"
							sx={{ fontSize: '0.7rem', mt: 0.5, opacity: 0.6 }}
						>
							{getFileSizeReading(size)}
						</Typography>
					</Box>
				</Box>
				<Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
					<IconButton sx={{ mr: 1 }} size="small" component="a" href={url} target="_blank">
						<Visibility />
					</IconButton>
					<IconButton size="small" component="a" download={name} href={url}>
						<Download />
					</IconButton>
				</Box>
			</Box>
		</Paper>
	);
};

const PdfPreview: React.FC<{ url: string }> = ({ url }) => {
	const previewRef = useRef<HTMLCanvasElement>(null);

	const makeThumb = async (page: PDFPageProxy) => {
		const vp = page.getViewport({ scale: 1 });
		const canvas = previewRef.current;
		if (!canvas) {
			return;
		}
		const scale = Math.min(canvas.width / vp.width, canvas.height / vp.height);
		try {
			return await page.render({
				canvasContext: canvas.getContext('2d')!,
				viewport: page.getViewport({ scale })
			}).promise;
		} catch (e) {}
	};

	useEffect(() => {
		if (!previewRef.current) {
			return;
		}

		Pdf.getDocument(url).promise.then(doc => {
			doc
				.getPage(1)
				.then(makeThumb)
				.catch(() => {});
		});
	}, [previewRef]);

	return <canvas width={180} height={(180 / 210) * 297} ref={previewRef} />;
};

const ImagePreview: React.FC<{ url: string }> = ({ url }) => {
	return <img src={url} width={180} height={90} css={{ objectFit: 'cover' }} />;
};

const AFFIXES = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

const getFileSizeReading = (size: number) => {
	let affix = AFFIXES[0];
	let count = 0;

	while (size > 1000) {
		size = size / 1000;
		count += 1;
		affix = AFFIXES[count];
	}

	return size.toFixed(2) + affix;
};

export default ReceiptDetailsPage;
