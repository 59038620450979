import { atom } from 'jotai';
import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import { atomWithStore } from 'jotai-zustand';
import { ThemeColor, useUserPreferenceStore } from './stores/useUserPreferenceStore';
import { HeaderRoute } from './types';
import { PaletteMode } from '@mui/material';
import { zhCN } from '@mui/material/locale';
import { Page } from './hooks/usePage';
import { GetInvoicesQueryResult } from './generated/api';

export const darkModeAtom = atom(false);
export const userPreferenceAtom = atomWithStore(useUserPreferenceStore);

export const muiThemeAtom = atom(get => {
	const themeColor = get(userPreferenceAtom).themeColor;
	const darkMode = get(darkModeAtom);

	let paletteMode: PaletteMode = 'light';

	switch (themeColor) {
		case ThemeColor.AUTO:
			paletteMode = darkMode ? 'dark' : 'light';
			break;
		case ThemeColor.LIGHT:
			paletteMode = 'light';
			break;
		case ThemeColor.DARK:
			paletteMode = 'dark';
			break;
		default:
			break;
	}

	let theme = createMuiTheme(
		{
			palette: {
				primary: {
					// main: paletteType === 'dark' ? '#90caf9' : '#3f51b5',
					main: paletteMode === 'dark' ? '#90caf9' : '#3f51b5',
					contrastText: '#fff'
				},
				background: {
					// default: paletteMode === 'dark' ? '#102A43' : '#eee',
					default: paletteMode === 'dark' ? 'rgb(22, 28, 36)' : 'rgb(247, 249, 255)',
					// paper: paletteMode === 'dark' ? '#24292E' : '#fff'
					paper: paletteMode === 'dark' ? 'rgb(33, 43, 54)' : '#fff'
				},
				mode: paletteMode
			},
			components: {
				MuiDialog: {
					styleOverrides: {
						paper: {
							backgroundImage: 'none'
						}
					}
				},
				MuiMenu: {
					styleOverrides: {
						paper: {
							backgroundImage: 'none'
						}
					}
				},
				MuiPopover: {
					styleOverrides: {
						paper: {
							backgroundImage: 'none'
						}
					}
				},
				MuiDrawer: {
					styleOverrides: {
						paper: {
							backgroundImage: 'none'
						}
					}
				},
				MuiPaper: {
					styleOverrides: {
						root: {
							backgroundImage: 'none'
						}
					}
				}
			}
		},
		zhCN
	);

	theme = responsiveFontSizes(theme);

	return theme;
});

export const drawerOpenAtom = atom(false);

export const pageAtom = atom<Page>(Page.DASHBOARD);

export const drawerActiveAtom = atom(0);

export const isOnlineAtom = atom(true);

export const headerTitleAtom = atom('');

export const headerRouteAtom = atom<HeaderRoute | null>(null);

export const selectedUserIdAtom = atom('');

export const userManagementLastRouteAtom = atom('');

export const userCreateDialogOpenAtom = atom(false);

export const userDeleteDialogOpenAtom = atom(false);

export const userRoleCreateDialogOpenAtom = atom(false);

export const isMasterAtom = atom(false);

export const currentCategoryIdRouteAtom = atom('');

export const categoryCreateDialogOpenAtom = atom(false);

export const categoryDeleteItemAtom = atom<{ id: string; name: string } | null>(null);

export const categorySelectedSubcategoryIdAtom = atom('');

export const courseCreateDialogOpenAtom = atom(false);

export const courseUpdateIdAtom = atom('');

export const courseDeleteItemAtom = atom<{ id: string; name: string } | null>(null);

export const classCreateCategoryIdAtom = atom('');

export const classDetailsIdAtom = atom('');

export const classDeleteIdAtom = atom('');

export const classTermCreateDialogIdAtom = atom('');

export const termDetailsIdAtom = atom('');

export const termDeleteIdAtom = atom('');

export const termStudentCreateDialogOpenAtom = atom(false);

export const classStudentCreateDialogOpenAtom = atom(false);

export const classStudentDeleteIdsAtom = atom<string[]>([]);

export const lessonCreateDialogOpenAtom = atom(false);

export const lessonUpdateDialogSelectedIdAtom = atom('');

export const lessonDeleteDialogItemAtom = atom<{
	id: string;
	name: string;
} | null>(null);

export const locationDeleteIdAtom = atom('');

export const locationDetailsIdAtom = atom('');

export const spaceCreateDialogOpenAtom = atom(false);

export const spaceDeleteIdAtom = atom('');

export const spaceDetailsIdAtom = atom('');

export const invoiceDeleteIdAtom = atom('');

export const invoiceOpenIdAtom = atom('');

export const invoiceCloseIdAtom = atom('');

export const paymentManagementSelectedOptionAtom = atom('0');

export const paymentDetailsIdAtom = atom('');
