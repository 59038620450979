import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Box, CircularProgress, Divider, Paper, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { InvoiceStatus } from '../../../generated/api';

interface InvoicesOverviewProps {
	statuses: Record<
		InvoiceStatus | '0',
		{
			title: string;
			color: string;
			amount: number;
			total: number;
			count: number;
			icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
			status: InvoiceStatus | '0';
		}
	>;
}

const InvoicesOverview: React.FC<InvoicesOverviewProps> = ({ statuses }) => {
	const [count, setCount] = useState({
		'0': 0,
		[InvoiceStatus.Open]: 0,
		[InvoiceStatus.Paid]: 0,
		[InvoiceStatus.Void]: 0,
		[InvoiceStatus.Draft]: 0
	});

	useEffect(() => {
		setCount({
			'0': statuses['0'].count,
			[InvoiceStatus.Open]: statuses[InvoiceStatus.Open].count,
			[InvoiceStatus.Paid]: statuses[InvoiceStatus.Paid].count,
			[InvoiceStatus.Void]: statuses[InvoiceStatus.Void].count,
			[InvoiceStatus.Draft]: statuses[InvoiceStatus.Draft].count
		});
	}, [statuses]);

	return (
		<Paper
			elevation={0}
			sx={{
				borderRadius: '16px',
				boxShadow: theme =>
					theme.palette.mode !== 'dark'
						? 'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px'
						: 'rgb(0 0 0 / 20%) 0px 0px 2px 0px, rgb(0 0 0 / 12%) 0px 12px 24px -4px'
			}}
		>
			<SimpleBar>
				<Box sx={{ display: 'flex', py: 2 }}>
					{Object.values(statuses).map((item, index) => (
						<React.Fragment key={item.status}>
							<OverviewItem {...item} count={count[item.status]} />
							{Object.keys(statuses).length - 1 !== index && (
								<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />
							)}
						</React.Fragment>
					))}
				</Box>
			</SimpleBar>
		</Paper>
	);
};

const OverviewItem: React.FC<{
	title: string;
	color: string;
	amount: number;
	total: number;
	count: number;
	icon: OverridableComponent<SvgIconTypeMap> & { muiName: string };
}> = ({ title, color, amount, total, count, icon: Icon }) => {
	return (
		<Box sx={{ display: 'flex', width: '100%', minWidth: '200px', justifyContent: 'center' }}>
			<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
				<Box sx={{ position: 'relative', display: 'inline-flex' }}>
					<CircularProgress
						variant="determinate"
						size={56}
						value={100}
						sx={{ color: 'rgba(145, 158, 171, 0.16)' }}
					/>
					<CircularProgress
						variant="determinate"
						size={56}
						value={(count / total) * 100}
						sx={{
							position: 'absolute',
							top: 0,
							left: 0,
							zIndex: 1,
							color,
							opacity: 0.48,
							circle: {
								transitionDuration: '1000ms'
							}
						}}
					/>
					<Box
						sx={{
							top: 0,
							left: 0,
							bottom: 0,
							right: 0,
							position: 'absolute',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center'
						}}
					>
						<Icon htmlColor={color} />
					</Box>
				</Box>
			</Box>
			<Box sx={{ ml: 2 }}>
				<Typography variant="h6" sx={{ fontWeight: 'bold', mb: 0.5 }}>
					{title}
				</Typography>
				<Typography variant="subtitle2" sx={{ mb: 0.5 }}>
					<span css={{ fontWeight: 'bold' }}>{count}</span> 张发票
				</Typography>
				<Typography variant="subtitle2" sx={{ fontWeight: 500, color, minWidth: '90px' }}>
					RM{amount.toFixed(2)}
				</Typography>
			</Box>
		</Box>
	);
};

export default InvoicesOverview;
