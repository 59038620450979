import { createStore, get } from 'idb-keyval';
import { persist } from 'zustand/middleware';
import { StateCreator, StoreMutatorIdentifier } from 'zustand/vanilla';
import { PersistOptions } from 'zustand/middleware/persist';

const worker = new Worker('/worker/app-state.js');

export interface StateStorage {
	getItem: (name: string) => string | null | Promise<string | null>;
	setItem: (name: string, value: string) => void | Promise<void>;
	removeItem: (name: string) => void | Promise<void>;
}

const deleteFunctions = (obj: Record<string, any>) => {
	if (!obj) {
		return;
	}
	Object.keys(obj).forEach(k => {
		if (typeof obj[k] === 'function') delete obj[k];
		else if (typeof obj[k] === 'object' && !(obj[k] instanceof Set)) deleteFunctions(obj[k]);
	});
};

const store = createStore('AppState', 'state');

let t: NodeJS.Timeout | null = null;
let pending: {
	set?: Record<string, any>;
	del?: Record<string, any>;
} = {};

export const IndexedDB = {
	getItem: async (key: string): Promise<any> => {
		return await get(key, store);
	},
	setItem: (key: string, value: any) => {
		if (!pending.set) {
			pending.set = {};
		}
		if (key === 'apollo-cache-persist') {
			delete value['ROOT_MUTATION'];
			delete value['__META'];
			Object.keys(value).forEach(entryKey => {
				if (entryKey.startsWith('DeletePayload:')) {
					delete value[entryKey];
				}
			});
		}
		pending.set[key] = value;
		if (!t) {
			t = setTimeout(() => {
				worker.postMessage(pending);
				pending = {};
				t = null;
			}, 1000);
		}
	},
	removeItem: (key: string) => {
		if (!pending.del) {
			pending.del = {};
		}
		pending.del[key] = true;
		if (!t) {
			t = setTimeout(() => {
				worker.postMessage(pending);
				pending = {};
				t = null;
			}, 1000);
		}
	}
};

export const persistIndexedDB: Persist = (config, options) => {
	return persist(config, {
		...options!,
		getStorage: () => IndexedDB,
		serialize: data => {
			deleteFunctions(data.state as Record<string, any>);
			return data as unknown as string;
		},
		//@ts-ignore
		deserialize: data => data
	});
};

declare type Persist = <
	T,
	Mps extends [StoreMutatorIdentifier, unknown][] = [],
	Mcs extends [StoreMutatorIdentifier, unknown][] = [],
	U = T
>(
	initializer: StateCreator<T, [...Mps, ['zustand/persist', unknown]], Mcs>,
	options?: PersistOptions<T, U>
) => StateCreator<T, Mps, [['zustand/persist', U], ...Mcs]>;
