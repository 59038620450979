import React from 'react';
import { Skeleton } from '@mui/material';

interface TableSkeletonLoadingProps {}

const TableSkeletonLoading: React.FC<TableSkeletonLoadingProps> = () => {
	return <Skeleton variant="rectangular" width="100%" height="400px" />;
};

export default TableSkeletonLoading;
