import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { selectedUserIdAtom } from '../../../atoms';
import { useUpdateAtom } from 'jotai/utils';

interface UserDetailsPageProps {}

const UserDetailsPage: React.FC<UserDetailsPageProps> = () => {
	const setSelectedUserId = useUpdateAtom(selectedUserIdAtom);
	const { id } = useParams();

	useEffect(() => {
		if (!id) {
			return;
		}
		setSelectedUserId(id);
	}, [id]);

	useEffect(() => {
		return () => {
			setSelectedUserId('');
		};
	}, []);

	return <></>;
};

export default UserDetailsPage;
