import React, { useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { AccountBox, Key, Notifications } from '@mui/icons-material';
import { Box, Container, Tab } from '@mui/material';
import TabPanel from '@mui/lab/TabPanel';
import General from '../components/Profile/General';
import ChangePassword from '../components/Profile/ChangePassword';
import { useTitle } from '../hooks/useTitle';
import usePage, { Page } from '../hooks/usePage';

const ProfilePage: React.FC = () => {
	const [value, setValue] = useState('1');

	useTitle('个人主页');
	usePage(Page.USERPAGE);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	return (
		<Container maxWidth="xl" disableGutters sx={{ mt: 1 }}>
			<Box
				sx={{
					flexGrow: 1,
					'& > .MuiTabPanel-root': {
						px: 0,
						'& > *': {
							my: 2
						}
					},
					'& .MuiTabs-indicator': {
						backgroundColor: 'red'
					}
				}}
			>
				<TabContext value={value}>
					<TabList
						onChange={handleChange}
						aria-label="profile-tabs"
						sx={theme => ({
							'& .MuiTab-root': {
								fontSize: '1rem',
								minHeight: 48,
								minWidth: 48,
								p: 0,
								mr: 3,
								[theme.breakpoints.up('lg')]: {
									mr: 5
								},
								'&.Mui-selected': {
									color: 'red'
								}
							}
						})}
					>
						<Tab label="基本" value="1" disableRipple icon={<AccountBox />} iconPosition="start" />
						<Tab
							label="通知"
							value="2"
							disableRipple
							icon={<Notifications />}
							iconPosition="start"
						/>
						<Tab label="更换密码" value="3" disableRipple icon={<Key />} iconPosition="start" />
					</TabList>
					<TabPanel value="1" sx={{ pt: 0 }}>
						<General />
					</TabPanel>
					<TabPanel value="2"></TabPanel>
					<TabPanel value="3">
						<ChangePassword />
					</TabPanel>
				</TabContext>
			</Box>
		</Container>
	);
};

export default ProfilePage;
