import React, { useMemo, useState } from 'react';
import { useAtom } from 'jotai';
import { classDeleteIdAtom } from '../../../../atoms';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FilledInput,
	FormHelperText,
	Typography
} from '@mui/material';
import { useApolloClient } from '@apollo/client';
import { CLASS_FIELDS } from '../../query';
import { ClassFieldsFragment, useDeleteClassMutation } from '../../../../generated/api';
import { styled } from '@mui/material/styles';
import { useUserPreferenceStore } from '../../../../stores/useUserPreferenceStore';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface ClassDeleteDialogProps {}

const ClassDeleteDialog: React.FC<ClassDeleteDialogProps> = () => {
	const [classId, setClassId] = useAtom(classDeleteIdAtom);
	const { cache } = useApolloClient();
	const classItem = useMemo(
		() =>
			classId
				? cache.readFragment<ClassFieldsFragment>({
						id: cache.identify({
							id: classId,
							__typename: 'Class'
						}),
						variables: { language: useUserPreferenceStore.getState().language },
						fragment: CLASS_FIELDS
				  })
				: null,
		[classId]
	);
	const [confirmationText, setConfirmationText] = useState('');
	const [isError, setIsError] = useState(false);
	const [deleteClassMutation] = useDeleteClassMutation({
		update: (cache, mutationResult) => {
			const id = mutationResult.data?.deleteClass?.id;
			if (!id) {
				return;
			}
			cache.evict({
				id: cache.identify({
					id,
					__typename: 'Class'
				})
			});
			cache.gc();
		}
	});
	const navigate = useNavigate();

	const handleClose = () => {
		setClassId('');
		setConfirmationText('');
		setIsError(false);
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (isError) {
			setIsError(false);
		}
		setConfirmationText(event.target.value);
	};

	const handleDelete = async () => {
		if (!classItem) {
			return;
		}

		if (confirmationText !== classItem.translation.name) {
			setIsError(true);
			return;
		}

		const deletingClassId = classId;
		handleClose();

		try {
			await deleteClassMutation({
				variables: {
					id: deletingClassId
				},
				optimisticResponse: {
					deleteClass: {
						id: deletingClassId,
						__typename: 'DeletePayload'
					}
				}
			});
			setConfirmationText('');
			navigate(-1);
			toast.success('删除班级成功!');
			// enqueueSnackbar('删除班级成功!', { variant: 'success' });
		} catch (err) {
			setClassId(deletingClassId);
			toast.error(`删除班级失败! ${err}`);
			// enqueueSnackbar(`删除班级失败! ${err}`, { variant: 'error' });
		}
	};

	return (
		<Dialog open={!!classItem} onClose={handleClose} maxWidth="sm" fullWidth>
			<DialogTitle>删除{classItem?.translation.name}</DialogTitle>
			<DialogContent>
				<DialogContentText>
					确定要删除
					<Typography color="primary" component="span">
						{classItem?.translation.name}
					</Typography>
					吗？删除
					<Typography color="primary" component="span">
						{classItem?.translation.name}
					</Typography>
					将连带删除所有此班级的学期和上课记录。
				</DialogContentText>
				<Typography variant="body2" style={{ marginTop: 10, marginBottom: 5 }}>
					输入
					<Typography variant="body2" color="primary" component="span" sx={{ margin: '0 2px' }}>
						{classItem?.translation.name}
					</Typography>
					以确认删除
				</Typography>
				<StyledFilledInput
					value={confirmationText}
					onChange={handleChange}
					error={isError}
					fullWidth
					autoFocus
				/>
				<FormHelperText error={isError}>
					{isError ? `请输入${classItem?.translation.name || ''}` : ''}
				</FormHelperText>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={handleClose}>
					取消
				</Button>
				<Button color="primary" onClick={handleDelete}>
					删除
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const StyledFilledInput = styled(FilledInput)({
	'& .MuiFilledInput-input': {
		paddingTop: '16px'
	}
});

export default ClassDeleteDialog;
