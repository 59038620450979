import React from 'react';
import { AttendanceStatus, LessonStudent, UpdateLessonAttendanceInput } from '../../generated/api';
import { Checkbox, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';

interface LessonAttendancesProps {
	students: {
		__typename?: 'LessonStudent' | undefined;
		status: AttendanceStatus;
		student: {
			__typename?: 'Student' | undefined;
			matricId: string;
			user: { __typename?: 'User' | undefined; id: string; cname: string; ename: string };
		};
	}[];
	attendances: UpdateLessonAttendanceInput[];
	setAttendances: (attendances: LessonAttendancesProps['attendances']) => void;
}

const LessonAttendances: React.FC<LessonAttendancesProps> = ({
	students,
	attendances,
	setAttendances
}) => {
	const handleChange = (studentId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
		const updatedAttendances = [...attendances];
		const attendance = updatedAttendances.find(attendance => attendance.studentId === studentId);
		if (attendance) {
			attendance.status = event.target.checked
				? AttendanceStatus.Presence
				: AttendanceStatus.Absence;
		}
		setAttendances(updatedAttendances);
	};

	return (
		<List>
			{students.map(({ student }) => (
				<ListItem key={student.user.id} disableGutters>
					<ListItemText
						primary={`${student.matricId} ${student.user.cname} ${student.user.ename}`}
					/>
					<ListItemSecondaryAction>
						<Checkbox
							checked={
								attendances.find(attendance => attendance.studentId === student.user.id)?.status ===
								AttendanceStatus.Presence
							}
							onChange={handleChange(student.user.id)}
						/>
					</ListItemSecondaryAction>
				</ListItem>
			))}
		</List>
	);
};

export default LessonAttendances;
