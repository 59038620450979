import React, { useState } from 'react';
import {
	AppBar,
	Box,
	Button,
	DialogTitle,
	IconButton,
	Tab,
	Toolbar,
	Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { TabList } from '@mui/lab';

interface ClassDetailsDialogHeaderProps {
	title: string;
	isXS: boolean;
	handleClose: () => void;
	handleReset: () => void;
	setTabIndex: (tabIndex: string) => void;
	loading: boolean;
}

const ClassDetailsDialogHeader: React.FC<ClassDetailsDialogHeaderProps> = ({
	title: classTitle,
	isXS,
	handleClose,
	handleReset,
	setTabIndex,
	loading
}) => {
	const [title] = useState(classTitle);

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setTabIndex(newValue);
	};

	return (
		<>
			{isXS ? (
				<>
					<AppBar color="inherit" position={!isXS ? 'static' : 'fixed'}>
						<Toolbar>
							<IconButton onClick={handleClose} size="large">
								<Close />
							</IconButton>
							<Typography variant="h6" sx={{ ml: '10px' }}>
								{title}
							</Typography>
							<Box sx={{ ml: 'auto', mr: 0 }}>
								<Button color="primary" onClick={handleReset}>
									重置
								</Button>
								<Button type="submit" color="primary" disabled={loading}>
									保存
								</Button>
							</Box>
						</Toolbar>
						<Toolbar>
							{isXS && (
								<TabList
									onChange={handleChange}
									aria-label="班级资料标签"
									centered
									sx={{ width: '100%', mb: -1 }}
								>
									<Tab label="资料" value="1" sx={{ fontSize: '1em' }} />
									<Tab label="模板" value="2" sx={{ fontSize: '1em' }} />
									<Tab label="学期" value="3" sx={{ fontSize: '1em' }} />
									<Tab label="学生" value="4" sx={{ fontSize: '1em' }} />
								</TabList>
							)}
						</Toolbar>
					</AppBar>
					<Toolbar />
					<Toolbar />
				</>
			) : (
				<DialogTitle>{title}</DialogTitle>
			)}
		</>
	);
};

export default ClassDetailsDialogHeader;
