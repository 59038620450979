import React from 'react';
import { User, useUpdateUserMutation } from '../../../../generated/api';
import { Button } from '@mui/material';
import { useAtomValue } from 'jotai/utils';
import { isMasterAtom } from '../../../../atoms';

interface UserDetailsStudentDeleteProps {
	user: User;
}

const UserDetailsStudentDelete: React.FC<UserDetailsStudentDeleteProps> = ({
	user
}) => {
	const isMaster = useAtomValue(isMasterAtom);
	const [updateUserMutation] = useUpdateUserMutation({
		variables: {
			id: user.id,
			input: {
				student: null
			},
			isMaster
		},
		optimisticResponse: {
			__typename: 'Mutation',
			updateUser: {
				...user,
				student: null
			}
		},
		update: (cache, mutationResult) => {
			const student = user.student;
			if (!student) {
				return;
			}
			cache.evict({
				id: cache.identify(student)
			});
			cache.gc();
		}
	});

	const handleClick = async () => {
		try {
			await updateUserMutation();
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<Button
			variant="contained"
			fullWidth
			color="secondary"
			size="large"
			onClick={handleClick}
		>
			删除此用户的学生身份
		</Button>
	);
};

export default UserDetailsStudentDelete;
