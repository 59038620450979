import React from 'react';
import { Gender, User, UserStatus } from 'generated/api';
import { Face } from '@mui/icons-material';
import { Typography } from '@mui/material';
import UserDetailsGroup from './UserDetailsGroup';
import UserDetailsItem from './UserDetailsItem';
import { UserDetailsItemKey } from '../../../types';
import { styled } from '@mui/material/styles';

interface UserDetailsProps {
	user: User;
}

const UserDetails: React.FC<UserDetailsProps> = ({ user }) => {
	return (
		<div>
			<ProfilePictureContainer>
				<ProfilePicture />
			</ProfilePictureContainer>
			<UserDetailsGroup title="个人资料">
				<UserDetailsItem itemKey={UserDetailsItemKey.CNAME}>
					<UserDetailsValue>{user.cname}</UserDetailsValue>
				</UserDetailsItem>
				<UserDetailsItem itemKey={UserDetailsItemKey.ENAME}>
					<UserDetailsValue>{user.ename}</UserDetailsValue>
				</UserDetailsItem>
				<UserDetailsItem itemKey={UserDetailsItemKey.GENDER}>
					<UserDetailsValue>
						{user.gender === Gender.Female ? '女' : '男'}
					</UserDetailsValue>
				</UserDetailsItem>
				<UserDetailsItem itemKey={UserDetailsItemKey.IC}>
					<UserDetailsValue>{user.ic}</UserDetailsValue>
				</UserDetailsItem>
			</UserDetailsGroup>
			<UserDetailsGroup title="联络资料">
				<UserDetailsItem itemKey={UserDetailsItemKey.PHONE}>
					<UserDetailsValue>{user.phone}</UserDetailsValue>
				</UserDetailsItem>
				<UserDetailsItem itemKey={UserDetailsItemKey.ADDRESS}>
					<UserDetailsValue>{user.address}</UserDetailsValue>
				</UserDetailsItem>
			</UserDetailsGroup>
			<UserDetailsGroup title="账号资料">
				<UserDetailsItem
					itemKey={UserDetailsItemKey.REGISTER_DATE}
					updateDisabled
				>
					<UserDetailsValue>
						{new Date(user.registerDate).toLocaleDateString()}
					</UserDetailsValue>
				</UserDetailsItem>
				<UserDetailsItem itemKey={UserDetailsItemKey.STATUS}>
					<UserDetailsValue>
						{user.status === UserStatus.Active ? '在籍' : '不在籍'}
					</UserDetailsValue>
				</UserDetailsItem>
				{/*<UserDetailsItem*/}
				{/*	itemKey={UserDetailsItemKey.LAST_LOGIN_TIME}*/}
				{/*	updateDisabled*/}
				{/*>*/}
				{/*	<Typography className={classes.value}>*/}
				{/*		{user.last_login_time &&*/}
				{/*			new Date(user.last_login_time).toLocaleString()}*/}
				{/*	</Typography>*/}
				{/*</UserDetailsItem>*/}
			</UserDetailsGroup>
			<UserDetailsGroup title="安全资料">
				<UserDetailsItem itemKey={UserDetailsItemKey.EMAIL}>
					<UserDetailsValue>{user.email}</UserDetailsValue>
				</UserDetailsItem>
				<UserDetailsItem itemKey={UserDetailsItemKey.PASSWORD}>
					<UserDetailsValue>********</UserDetailsValue>
				</UserDetailsItem>
			</UserDetailsGroup>
		</div>
	);
};

const ProfilePictureContainer = styled('div')({
	display: 'flex',
	flexFlow: 'column',
	alignItems: 'center',
	margin: '0 0 2em'
});

const ProfilePicture = styled(Face)({
	width: '25%',
	minWidth: '200px',
	height: 'auto'
});

const UserDetailsValue = styled(Typography)({
	width: '70%'
});

export default UserDetails;
