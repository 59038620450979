import React from 'react';
import UserDetailsGroup from './UserDetailsGroup';
import UserDetailsItem from './UserDetailsItem';
import { UserDetailsItemKey } from 'types';
import { SxProps, Typography } from '@mui/material';
import { LecturerStatus, User } from 'generated/api';
import UserDetailsLecturerCreate from './UserDetailsRoleCreate/UserDetailsLecturerCreate';
import UserDetailsLecturerDelete from './UserDetailsRoleDelete/UserDetailsLecturerDelete';

interface UserLecturerDetailsProps {
	user: User;
}

const UserLecturerDetails: React.FC<UserLecturerDetailsProps> = ({ user }) => {
	return (
		<div>
			{user.lecturer ? (
				<>
					<UserDetailsGroup title="老师资料">
						<UserDetailsItem itemKey={UserDetailsItemKey.LECTURER_MATRIC_ID}>
							<Typography sx={userDetailsValueStyle}>
								{user.lecturer.matricId}
							</Typography>
						</UserDetailsItem>
						<UserDetailsItem itemKey={UserDetailsItemKey.LECTURER_STATUS}>
							<Typography sx={userDetailsValueStyle}>
								{user.lecturer.status === LecturerStatus.Active
									? '在籍'
									: '不在籍'}
							</Typography>
						</UserDetailsItem>
					</UserDetailsGroup>
					<UserDetailsLecturerDelete user={user} />
				</>
			) : (
				<UserDetailsLecturerCreate user={user} />
			)}
		</div>
	);
};

const userDetailsValueStyle: SxProps = {
	width: '70%'
};

export default UserLecturerDetails;
