import React from 'react';
import { Box, Paper, TextField } from '@mui/material';
import { useUpdateMyPasswordMutation } from '../../generated/api';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { LoadingButton } from '@mui/lab';
import { ApolloError } from '@apollo/client';

type FormValues = {
	password: string;
	newPassword: string;
	confirmNewPassword: string;
};

interface ChangePasswordProps {}

const ChangePassword: React.FC<ChangePasswordProps> = () => {
	const [updateMyPasswordMutation] = useUpdateMyPasswordMutation();
	const {
		handleSubmit,
		control,
		setError,
		formState: { isSubmitting }
	} = useForm<FormValues>({
		defaultValues: {
			password: '',
			newPassword: '',
			confirmNewPassword: ''
		}
	});

	const onSubmit: SubmitHandler<FormValues> = async ({
		password,
		newPassword,
		confirmNewPassword
	}) => {
		try {
			if (newPassword !== confirmNewPassword) {
				setError('confirmNewPassword', {
					type: 'focus',
					message: '新密码不相同'
				});
				return;
			}

			const { data } = await updateMyPasswordMutation({
				variables: {
					input: {
						password,
						newPassword
					}
				}
			});

			if (data) {
				toast.success('修改密码成功');
			}
		} catch (err) {
			if (err instanceof ApolloError) {
				if (err.graphQLErrors?.some(e => e.extensions?.code === 'BAD_USER_INPUT')) {
					setError('password', { type: 'focus', message: '密码错误' });
				}
			}
		}
	};

	return (
		<Paper
			component="form"
			onSubmit={handleSubmit(onSubmit)}
			elevation={0}
			sx={{
				borderRadius: '16px',
				boxShadow:
					'rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px',
				p: 3,
				'& .MuiOutlinedInput-root': {
					borderRadius: '8px',
					fontSize: '1rem',
					borderColor: 'rgb(145, 158, 171)'
				},
				'& .MuiInputLabel-root': {
					fontSize: '1rem',
					color: 'rgb(145, 158, 171)',
					'&.Mui-focused': {
						color: theme => theme.palette.primary.main
					}
				}
			}}
		>
			<Controller
				name="password"
				control={control}
				rules={{
					required: '请输入密码'
				}}
				render={({ field, fieldState: { error } }) => (
					<TextField
						{...field}
						error={!!error}
						label="密码"
						type="password"
						fullWidth
						helperText={error?.message}
					/>
				)}
			/>
			<Controller
				name="newPassword"
				control={control}
				rules={{
					required: '请输入新密码'
				}}
				render={({ field, fieldState: { error } }) => (
					<TextField
						{...field}
						error={!!error}
						label="新密码"
						type="password"
						autoComplete="new-password"
						sx={{ mt: 3 }}
						fullWidth
						helperText={error?.message}
					/>
				)}
			/>
			<Controller
				name="confirmNewPassword"
				control={control}
				rules={{
					required: '请重复输入新密码'
				}}
				render={({ field, fieldState: { error } }) => (
					<TextField
						{...field}
						error={!!error}
						label="重复输入新密码"
						type="password"
						autoComplete="new-password"
						sx={{ mt: 3 }}
						fullWidth
						helperText={error?.message}
					/>
				)}
			/>
			<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
				<LoadingButton
					loading={isSubmitting}
					type="submit"
					variant="contained"
					size="large"
					sx={{ borderRadius: '8px', mt: 4 }}
				>
					更改密码
				</LoadingButton>
			</Box>
		</Paper>
	);
};

export default ChangePassword;
