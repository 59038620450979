import React from 'react';
import { AppBar, IconButton, Toolbar, useMediaQuery, useTheme } from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useAtom } from 'jotai';
import { drawerOpenAtom, headerRouteAtom, isOnlineAtom } from '../../atoms';
import HeaderTitle from './HeaderTitle';
import HeaderActions from './HeaderActions';
import { useAtomValue } from 'jotai/utils';
import ContentHeaderTabs from '../ContentHeaderTabs';
import { styled } from '@mui/material/styles';

interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
	const [drawerOpen, setDrawerOpen] = useAtom(drawerOpenAtom);
	const route = useAtomValue(headerRouteAtom);
	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const isOnline = useAtomValue(isOnlineAtom);

	const handleDrawerToggle = () => setDrawerOpen(!drawerOpen);

	return (
		<Root>
			{!mdDown ? (
				<>
					<StyledHeader>
						<HeaderTitle />
						<HeaderActions />
					</StyledHeader>
					{route && <ContentHeaderTabs route={route} />}
				</>
			) : (
				<>
					<StyledAppBar
						position="fixed"
						elevation={1}
						color="inherit"
						style={!isOnline ? { marginTop: 30 } : {}}
					>
						<Toolbar>
							<IconButton
								color="inherit"
								aria-label="open drawer"
								edge="start"
								onClick={handleDrawerToggle}
								size="large"
							>
								<MenuIcon />
							</IconButton>
							<HeaderTitle />
							<HeaderActions />
						</Toolbar>
						{route && <ContentHeaderTabs route={route} />}
					</StyledAppBar>
					{route && <Toolbar />}
				</>
			)}
		</Root>
	);
};

const Root = styled('div')(({ theme }) => ({
	paddingTop: 40,
	[theme.breakpoints.down('md')]: {
		paddingTop: 65
	}
}));

const StyledHeader = styled('div')({
	display: 'flex',
	flexFlow: 'row nowrap',
	justifyContent: 'flex-start',
	alignItems: 'flex-end',
	width: '100%'
});

const StyledAppBar = styled(AppBar)(({ theme }) => ({
	// backgroundColor:
	// 	theme.palette.mode === 'light'
	// 		? theme.palette.background.paper
	// 		: theme.palette.background.paper,
	...(theme.palette.mode === 'light'
		? {
				boxShadow:
					'0px 2px 1px -1px rgb(133 143 154 / 20%), 0px 1px 1px 0px rgb(133 143 154 / 14%), 0px 1px 3px 0px rgb(133 143 154 / 12%)'
		  }
		: {}),
	backgroundImage: 'none',
	'& *': {
		color: theme.palette.mode === 'light' ? '#000' : '#fff'
	}
}));

export default Header;
