import React from 'react';
import { useAtom } from 'jotai';
import { lessonDeleteDialogItemAtom } from '../../atoms';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material';
import { useDeleteLessonMutation } from '../../generated/api';
import FormWrapper from '../common/FormWrapper';

interface LessonDeleteDialogProps {
	onDeleted: () => void;
}

const LessonDeleteDialog: React.FC<LessonDeleteDialogProps> = ({ onDeleted }) => {
	const [item, setItem] = useAtom(lessonDeleteDialogItemAtom);
	const [deleteLesson, { loading, error, reset }] = useDeleteLessonMutation();

	const handleClose = () => {
		setItem(null);
		reset();
	};

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!item) {
			return;
		}

		try {
			await deleteLesson({
				variables: {
					id: item.id
				},
				optimisticResponse: {
					deleteLesson: {
						id: item.id,
						__typename: 'DeletePayload'
					}
				}
			});

			handleClose();
			onDeleted();
		} catch {}
	};

	return (
		<Dialog open={!!item} onClose={handleClose} maxWidth="xs" fullWidth>
			{item && (
				<FormWrapper loading={loading} error={error}>
					<Box component="form" onSubmit={handleSubmit}>
						<DialogTitle>{item.name}课次</DialogTitle>
						<DialogContent>
							<DialogContentText>确定要删除此课次吗？</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>取消</Button>
							<Button type="submit">删除</Button>
						</DialogActions>
					</Box>
				</FormWrapper>
			)}
		</Dialog>
	);
};

export default LessonDeleteDialog;
